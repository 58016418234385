var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "pa-0" },
        [
          _c(
            "v-list-item",
            [
              _c("v-list-item-content", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("account.staff_add")) +
                    "\n      "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "validation-observer",
        { ref: "observer" },
        [
          _c(
            "v-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.save($event)
                }
              }
            },
            [
              _c(
                "v-card-text",
                [
                  _c("vf-text-field", {
                    attrs: { label: "コード", vees: { rules: "required" } },
                    model: {
                      value: _vm.code,
                      callback: function($$v) {
                        _vm.code = $$v
                      },
                      expression: "code"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-btn", { on: { click: _vm.clear } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("btn.clear")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-btn", { attrs: { type: "submit", color: "primary" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("account.regist")) +
                        "\n        "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }