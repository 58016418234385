var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", [_c("PtTitle", { attrs: { title: _vm.friend.name } })], 1)
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { md: "6" } },
            [
              _c("PtFriendShowBase", {
                attrs: { opts: _vm.opts, vees: _vm.vees, readonly: "" },
                model: {
                  value: _vm.friend,
                  callback: function($$v) {
                    _vm.friend = $$v
                  },
                  expression: "friend"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { md: "6" } },
            [
              _c("PtAdminFriendShowAction", {
                attrs: { readonly: "" },
                model: {
                  value: _vm.friend,
                  callback: function($$v) {
                    _vm.friend = $$v
                  },
                  expression: "friend"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }