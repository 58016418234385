var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "validation-observer",
        { ref: "observer" },
        [
          _c(
            "v-card",
            {
              staticClass: "mx-auto px-12 py-6",
              attrs: { "max-width": "800" }
            },
            [
              _c(
                "v-card-title",
                [
                  _c("v-row", { attrs: { justify: "center" } }, [
                    _c("div", [
                      _vm._v(_vm._s(_vm.$t("meta.title." + _vm.$route.name)))
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "my-6" },
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("lbl.login_channel_create")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("VDivider", { staticClass: "my-0" }),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.$t("lbl.line_login_channel_desc")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "div",
                                { staticClass: "url_conf_explanation" },
                                [
                                  _vm._v(
                                    "\n                ※下記URLをコピーしてCallbackURLを設定してください。\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("v-text-field", {
                                attrs: { label: "CallbackURL", readonly: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append-outer",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-icon",
                                          {
                                            directives: [
                                              {
                                                name: "clipboard",
                                                rawName: "v-clipboard:copy",
                                                value: _vm.callbackUrl,
                                                expression: "callbackUrl",
                                                arg: "copy"
                                              },
                                              {
                                                name: "clipboard",
                                                rawName: "v-clipboard:success",
                                                value: _vm.onClipCopy,
                                                expression: "onClipCopy",
                                                arg: "success"
                                              },
                                              {
                                                name: "clipboard",
                                                rawName: "v-clipboard:error",
                                                value: _vm.onClipError,
                                                expression: "onClipError",
                                                arg: "error"
                                              }
                                            ],
                                            staticClass: "p-0 clipboard-icon"
                                          },
                                          [_vm._v("mdi-content-copy")]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ]),
                                model: {
                                  value: _vm.callbackUrl,
                                  callback: function($$v) {
                                    _vm.callbackUrl = $$v
                                  },
                                  expression: "callbackUrl"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "my-6" },
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("lbl.login_channel_public")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("VDivider", { staticClass: "my-0" }),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c("div", { staticClass: "mb-4" }, [
                        _vm._v(_vm._s(_vm.$t("lbl.login_channel_public_desc")))
                      ]),
                      _vm._v(" "),
                      _c("VfCheckbox", {
                        attrs: {
                          label: _vm.$t("lbl.done_webhook_and_response")
                        },
                        model: {
                          value: _vm.public_done,
                          callback: function($$v) {
                            _vm.public_done = $$v
                          },
                          expression: "public_done"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "my-6" },
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("lbl.set_callback_url")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("VDivider", { staticClass: "my-0" }),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c("div", { staticClass: "mb-4" }, [
                        _vm._v(_vm._s(_vm.$t("lbl.set_callback_url_desc")))
                      ]),
                      _vm._v(" "),
                      _c("VfCheckbox", {
                        attrs: { label: _vm.$t("lbl.done_callback_url") },
                        model: {
                          value: _vm.set_callback_done,
                          callback: function($$v) {
                            _vm.set_callback_done = $$v
                          },
                          expression: "set_callback_done"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("lbl.login_channel_registration")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("VDivider", { staticClass: "my-0" }),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.save($event)
                            }
                          }
                        },
                        [
                          _vm.channel
                            ? _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { md: "12" } },
                                        [
                                          _c("VfTextField", {
                                            attrs: {
                                              label: _vm.$$tm(
                                                "channel.line_login_channel_id"
                                              ),
                                              vees:
                                                _vm.vees.line_login_channel_id,
                                              disabled:
                                                !_vm.public_done ||
                                                !_vm.set_callback_done
                                            },
                                            model: {
                                              value:
                                                _vm.channel
                                                  .line_login_channel_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.channel,
                                                  "line_login_channel_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "channel.line_login_channel_id"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { md: "12" } },
                                        [
                                          _c("VfTextField", {
                                            attrs: {
                                              label: _vm.$$tm(
                                                "channel.line_login_channel_secret_id"
                                              ),
                                              vees:
                                                _vm.vees
                                                  .line_login_channel_secret_id,
                                              disabled:
                                                !_vm.public_done ||
                                                !_vm.set_callback_done,
                                              password: ""
                                            },
                                            model: {
                                              value:
                                                _vm.channel
                                                  .line_login_channel_secret_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.channel,
                                                  "line_login_channel_secret_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "channel.line_login_channel_secret_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                { attrs: { type: "submit", color: "primary" } },
                                [_vm._v(_vm._s(_vm.$t("btn.create")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }