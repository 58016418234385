var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "transparent", attrs: { outlined: "" } },
        [
          _c(
            "v-card-title",
            { staticClass: "pl-0 pa-0 or-card-title" },
            [
              _c(
                "v-list-item",
                { staticClass: "pa-0" },
                [
                  _c("v-list-item-content", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("lbl.friend_transition")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "or-card-title",
                          attrs: { small: "" },
                          on: {
                            click: function($event) {
                              return _vm.$router.push({
                                name: "FriendReportIndex"
                              })
                            }
                          }
                        },
                        [_vm._v("\n            さらに詳しく\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c("VfAlert", {
            attrs: { type: "error" },
            model: {
              value: _vm.errorMsg,
              callback: function($$v) {
                _vm.errorMsg = $$v
              },
              expression: "errorMsg"
            }
          }),
          _vm._v(" "),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.items,
              "disable-sort": true,
              "hide-default-footer": ""
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.block_count",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "FriendIndex",
                              params: { blocked_at: item.report_dt }
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(item.block_count) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "item.report_dt",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$$fdt(item.report_dt)) +
                          "\n      "
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }