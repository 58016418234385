var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "enable-scroll unicast_friend_sticky" },
    [
      !_vm.firstTimeFlg
        ? _c(
            "v-card",
            { staticClass: "pa-4", attrs: { outlined: "" } },
            [
              _c("VfLoadingOverlayAbsolute", {
                attrs: { loading: _vm.loading }
              }),
              _vm._v(" "),
              _vm.friend.id
                ? _c(
                    "router-link",
                    {
                      class: _vm.isMobile ? "disabled-pointer" : "",
                      attrs: {
                        to: {
                          name: "FriendShow",
                          params: { id: _vm.friend.id }
                        },
                        event: _vm.isMobile ? "" : "click"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "profile_image" },
                        [
                          _c("VImg", {
                            attrs: {
                              src: _vm.$$noimageHelper(
                                _vm.friend.api_picture_url
                              )
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-title",
                        {
                          staticClass:
                            "green--text text--darken-3 pl-0 profile_name"
                        },
                        [
                          _vm._v(
                            "\n        " + _vm._s(_vm.friend.name) + "\n      "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pl-0" },
                [
                  _c("div", [_vm._v(_vm._s(_vm.$$fat(_vm.friend.created_at)))]),
                  _vm._v(" "),
                  _c("VDivider"),
                  _vm._v(" "),
                  _vm.friend.id
                    ? _c(
                        "div",
                        [
                          _c("VfSelect", {
                            attrs: {
                              label: _vm.$$tm("friend.message_status"),
                              vees: _vm.vees.message_status,
                              selects: _vm.opts.message_status,
                              disabled: !_vm.hasRole
                            },
                            model: {
                              value: _vm.friend.message_status,
                              callback: function($$v) {
                                _vm.$set(_vm.friend, "message_status", $$v)
                              },
                              expression: "friend.message_status"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pl-0" },
                [
                  _c("VfTextField", {
                    attrs: {
                      label: _vm.$$tm("friend.email"),
                      vees: _vm.vees.email,
                      disabled: ""
                    },
                    model: {
                      value: _vm.friend.email,
                      callback: function($$v) {
                        _vm.$set(_vm.friend, "email", $$v)
                      },
                      expression: "friend.email"
                    }
                  }),
                  _vm._v(" "),
                  _c("VfTextField", {
                    attrs: {
                      label: _vm.$$tm("friend.phone"),
                      vees: _vm.vees.phone,
                      disabled: ""
                    },
                    model: {
                      value: _vm.friend.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.friend, "phone", $$v)
                      },
                      expression: "friend.phone"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { on: { click: _vm.showDialog } }, [
                    _c("label", [_vm._v(_vm._s(_vm.$$tm("friend.memo")))]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        [
                          _c("span", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("truncate")(_vm.friend.memo, 13)
                                ) +
                                "\n            "
                            )
                          ])
                        ]
                      ],
                      2
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.previewDialog,
            callback: function($$v) {
              _vm.previewDialog = $$v
            },
            expression: "previewDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title"),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { md: "12" } },
                        [
                          _c("VfTextArea", {
                            staticClass: "mt-4",
                            attrs: {
                              label: _vm.$$tm("friend.memo"),
                              vees: { rules: "max:2000", max: 2000 }
                            },
                            model: {
                              value: _vm.memoClone,
                              callback: function($$v) {
                                _vm.memoClone = $$v
                              },
                              expression: "memoClone"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("VSpacer"),
                  _vm._v(" "),
                  _c("v-btn", { on: { click: _vm.closeDialog } }, [
                    _vm._v(_vm._s(_vm.$t("btn.cancel")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.saveChange("memo")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("btn.save_data")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }