var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.hasActiveChannels
        ? [
            _c("v-select", {
              staticClass: "mb-7",
              attrs: {
                items: _vm.selectChannels,
                outlined: "",
                dense: "",
                "hide-details": ""
              },
              on: { change: _vm.changeChannel },
              model: {
                value: _vm.selectedChannel,
                callback: function($$v) {
                  _vm.selectedChannel = $$v
                },
                expression: "selectedChannel"
              }
            }),
            _vm._v(" "),
            _vm.hasRole.mobile
              ? [
                  _vm.hasRole.broadcast
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mb-7",
                          attrs: {
                            block: "",
                            color: "primary",
                            to: { name: "SpBroadcastForm" }
                          }
                        },
                        [_vm._v("\n        一斉送信\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasRole.broadcast
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mb-7",
                          attrs: {
                            block: "",
                            color: "primary",
                            to: { name: "SpFixedPhraseForm" }
                          }
                        },
                        [_vm._v("\n        テンプレート送信\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasRole.friend_unicast
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mb-7",
                          attrs: {
                            block: "",
                            color: "green accent-3 white--text",
                            to: { name: "SpUnicastIndex" }
                          }
                        },
                        [_vm._v("\n        個別トーク\n      ")]
                      )
                    : _vm._e()
                ]
              : [
                  _c("v-alert", { attrs: { type: "error" } }, [
                    _c("div", { staticClass: "or-break" }, [
                      _vm._v(_vm._s(_vm.$t("lbl.no_role_mobile")))
                    ])
                  ])
                ],
            _vm._v(" "),
            _c(
              "v-btn",
              {
                staticClass: "mt-7",
                attrs: { block: "", to: { name: "SpNotificatorIndex" } }
              },
              [_vm._v("\n      スマートフォン連携設定\n    ")]
            )
          ]
        : [
            _c("v-alert", { attrs: { type: "error" } }, [
              _c("div", { staticClass: "or-break" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("line_sp_link.nothing_active_channels")) +
                    "\n      "
                )
              ])
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "ma-auto logo-container blue-grey darken-4 mb-7" },
      [
        _c("img", {
          staticClass: "logo-header",
          attrs: { src: require("images/talk_label_logo.png") }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }