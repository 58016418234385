var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    attrs: { name: _vm.label, rules: _vm.rules },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var errors = ref.errors
          return [
            _vm.preview
              ? _c("v-img", {
                  attrs: {
                    src: _vm.previewUrl,
                    height: _vm.height,
                    width: _vm.width
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("v-file-input", {
              attrs: {
                label: _vm.label,
                "error-messages": errors,
                disabled: _vm.disabled,
                readonly: _vm.readonly
              },
              on: { change: _vm.onFileChange }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }