var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "10" } }, [_c("PtTitle")], 1),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "2" } }, [
            _c(
              "div",
              { staticStyle: { "text-align": "right", "margin-top": "20px" } },
              [
                _vm.reminderTimingId !== "new"
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "error",
                          disabled: _vm.frem_active_flg
                        },
                        on: { click: _vm.destroy }
                      },
                      [_vm._v(_vm._s(_vm.$t("btn.delete")))]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.initDone
        ? _c(
            "validation-observer",
            { ref: "observer" },
            [
              _c(
                "v-card",
                { staticClass: "mb-6" },
                [
                  _c("v-card-title", { staticClass: "or-card-title mb-6" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("lbl.reminder_timing.timing")) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "3" } },
                            [
                              _c("VfSelect", {
                                attrs: {
                                  label: _vm.$$tm(
                                    "reminder_timing.reminder_timing_type"
                                  ),
                                  vees: _vm.vees.reminder_timing_type,
                                  selects: _vm.opts.reminder_timing_type,
                                  disabled: _vm.frem_active_flg
                                },
                                model: {
                                  value:
                                    _vm.reminderTiming.reminder_timing_type,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.reminderTiming,
                                      "reminder_timing_type",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "reminderTiming.reminder_timing_type"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.reminderTiming.reminder_timing_type !== "start"
                            ? [
                                _c(
                                  "v-col",
                                  { attrs: { md: "1" } },
                                  [
                                    _c("VfTextField", {
                                      attrs: {
                                        label: _vm.$$tm(
                                          "reminder_timing.sch_day"
                                        ),
                                        vees: _vm.vees.sch_day,
                                        disabled: _vm.frem_active_flg,
                                        number: ""
                                      },
                                      model: {
                                        value: _vm.reminderTiming.sch_day,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.reminderTiming,
                                            "sch_day",
                                            $$v
                                          )
                                        },
                                        expression: "reminderTiming.sch_day"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { md: "3" } },
                                  [
                                    _c("VfDateTimeSelect", {
                                      attrs: {
                                        label: _vm.$$tm(
                                          "reminder_timing.sch_time"
                                        ),
                                        disabled: _vm.frem_active_flg,
                                        format: "HH:mm"
                                      },
                                      model: {
                                        value: _vm.reminderTiming.sch_time,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.reminderTiming,
                                            "sch_time",
                                            $$v
                                          )
                                        },
                                        expression: "reminderTiming.sch_time"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "mb-6" },
                [
                  _c("v-card-title", { staticClass: "or-card-title mb-6" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("lbl.reminder_timing.action")) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c("PtActionSelectedList", {
                        attrs: { "hide-timing-type-select": "" },
                        model: {
                          value: _vm.reminderTiming.action_arr,
                          callback: function($$v) {
                            _vm.$set(_vm.reminderTiming, "action_arr", $$v)
                          },
                          expression: "reminderTiming.action_arr"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.$wait.is("processing")
                          },
                          on: { click: _vm.save }
                        },
                        [_vm._v(_vm._s(_vm.$t("btn.create")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("VfDialogConfirm", {
        attrs: {
          title: _vm.$t("confirm_title.delete"),
          detail: _vm.$t("confirm.delete"),
          "save-path": _vm.savePath,
          "form-type": "delete",
          "target-emit": "delete-success"
        },
        on: { "delete-success": _vm.deleteSuccess },
        model: {
          value: _vm.deleteDialog,
          callback: function($$v) {
            _vm.deleteDialog = $$v
          },
          expression: "deleteDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }