var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PtTitle"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-2" },
        [
          _c("div", [_vm._v(_vm._s(_vm.$t("subtitle.staff_create")))]),
          _vm._v(" "),
          _c(
            "router-link",
            {
              attrs: { to: { name: "ChannelRoleForm", params: { id: "new" } } }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("btn.channel_role_link")) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c("v-card-title", [
            _vm._v("\n      " + _vm._s(_vm.$t("lbl.staff.wait")) + "\n    ")
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.channelTokens,
                  "disable-sort": true,
                  "items-per-page": -1,
                  "hide-default-footer": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.channel_role",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.channel_role
                            ? [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.channel_role.name) +
                                    "\n          "
                                )
                              ]
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "item.actions",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("btn-dialog-destroy", {
                            on: {
                              click: function($event) {
                                return _vm.openDialogDestroy(item)
                              },
                              save: _vm.destroy
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                { staticClass: "mr-2", on: { click: _vm.toStaffIndex } },
                [_vm._v("\n        " + _vm._s(_vm.$t("btn.back")) + "\n      ")]
              ),
              _vm._v(" "),
              _c("btn-dialog-create-token", {
                attrs: { "select-channel-roles": _vm.selectChannelRoles },
                on: { click: _vm.openDialogCreateToken, save: _vm.save },
                model: {
                  value: _vm.selectedChannelRoleId,
                  callback: function($$v) {
                    _vm.selectedChannelRoleId = $$v
                  },
                  expression: "selectedChannelRoleId"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }