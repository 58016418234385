var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PtTitle"),
      _vm._v("\n  " + _vm._s(_vm.$t("subtitle.upload_file")) + "\n  "),
      _c(
        "v-row",
        _vm._l(_vm.disps, function(val, key) {
          return _c(
            "v-col",
            { key: key },
            [
              _c(
                "v-btn",
                {
                  on: {
                    click: function($event) {
                      return _vm.uploadDialog(key)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("btn.upload_" + key)))]
              )
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        _vm._l(_vm.disps, function(val, key) {
          return _c(
            "v-col",
            { key: key },
            [
              _c("VfSwitch", {
                key: key,
                attrs: { inset: "true", label: _vm.$t("btn.disp_" + key) },
                model: {
                  value: _vm.disps[key],
                  callback: function($$v) {
                    _vm.$set(_vm.disps, key, $$v)
                  },
                  expression: "disps[key]"
                }
              })
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            _vm._l(_vm.uploadFiles, function(uploadFile) {
              return _c(
                "v-card",
                {
                  key: uploadFile.id,
                  staticClass: "m-1",
                  attrs: { width: 200 }
                },
                [
                  uploadFile.image_url
                    ? [
                        _c("VImg", {
                          attrs: { src: uploadFile.image_url, height: 200 }
                        })
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  uploadFile.rich_menu_image_url
                    ? [
                        _c("VImg", {
                          attrs: {
                            src: uploadFile.rich_menu_image_url,
                            height: 200
                          }
                        })
                      ]
                    : uploadFile.video_url
                    ? [
                        _c("PtVideo", {
                          key: uploadFile.video_url,
                          attrs: {
                            src: uploadFile.video_url,
                            width: 200,
                            height: 200
                          }
                        })
                      ]
                    : uploadFile.audio_url
                    ? [
                        _c("PtVideo", {
                          key: uploadFile.audio_url,
                          attrs: {
                            src: uploadFile.audio_url,
                            width: 200,
                            height: 200
                          }
                        })
                      ]
                    : uploadFile.document_url
                    ? [
                        _c(
                          "div",
                          { staticClass: "text-center item-document" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "icon-document",
                                attrs: { color: "gray" }
                              },
                              [_vm._v(" mdi-file ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                _c("v-text-field", {
                                  staticClass:
                                    "mt-0 pt-0 pl-1 font-size-x-small",
                                  attrs: {
                                    value: "" + uploadFile.document_url,
                                    readonly: "",
                                    "hide-details": ""
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    directives: [
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:copy",
                                        value: "" + uploadFile.document_url,
                                        expression:
                                          "`${uploadFile.document_url}`",
                                        arg: "copy"
                                      },
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:success",
                                        value: _vm.onClipCopy,
                                        expression: "onClipCopy",
                                        arg: "success"
                                      },
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:error",
                                        value: _vm.onClipError,
                                        expression: "onClipError",
                                        arg: "error"
                                      }
                                    ],
                                    staticClass: "ma-1",
                                    attrs: { small: "" }
                                  },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v(" mdi-content-copy ")
                                    ]),
                                    _vm._v(
                                      _vm._s(_vm.$t("btn.copy")) +
                                        "\n              "
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end" },
                    [
                      _c("PtFileInfo", {
                        attrs: {
                          name: uploadFile.file_name,
                          "file-id": uploadFile.id,
                          "file-url": _vm.fileUrl(uploadFile)
                        }
                      }),
                      _vm._v(" "),
                      _c("VCheckbox", {
                        staticClass: "m-0",
                        attrs: {
                          value: uploadFile.id,
                          dense: true,
                          "hide-details": "",
                          multiple: true
                        },
                        model: {
                          value: _vm.uploadFileIds,
                          callback: function($$v) {
                            _vm.uploadFileIds = $$v
                          },
                          expression: "uploadFileIds"
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { align: "right" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: { click: _vm.openDeleteDialog }
                    },
                    [_vm._v(_vm._s(_vm.$t("btn.delete_checked_media")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          key: _vm.uploadFileType,
          attrs: { "max-width": 800 },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("btn.upload_" + _vm.uploadFileType)) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("PtFilePondForUploadFile", {
                    attrs: {
                      name: _vm.uploadFileType,
                      cols: 5,
                      "init-path": "/api/v1/upload_files",
                      "init-prms": { upload_file_type: _vm.uploadFileType },
                      "max-files": null,
                      "max-file-size": _vm.fileTypeOpts["max-file-size"],
                      "accepted-file-types":
                        _vm.fileTypeOpts["accepted-file-types"],
                      "allow-image-preview":
                        _vm.fileTypeOpts["allow-image-preview"]
                    },
                    on: { "upload-success": _vm.uploadSuccess }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": 500 },
          model: {
            value: _vm.deleteDialog,
            callback: function($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "text-h5" }, [
                  _vm._v(_vm._s(_vm.$t("confirm_title.delete")))
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "12" } },
                            [
                              _c(
                                "div",
                                { staticClass: "text-center or-break" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("confirm.delete")) +
                                      "\n              "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.closeDeleteDialog }
                    },
                    [_vm._v(_vm._s(_vm.$t("btn.cancel")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.deleteAll }
                    },
                    [_vm._v(_vm._s(_vm.$t("btn.yes")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }