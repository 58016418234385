var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.getters.globalMessageType
    ? _c(
        "v-alert",
        {
          attrs: {
            text: _vm.$store.getters.isAlertText,
            type: _vm.$store.getters.globalMessageType
          },
          scopedSlots: _vm._u(
            [
              {
                key: "append",
                fn: function() {
                  return [
                    _c("v-icon", { on: { click: _vm.close } }, [
                      _vm._v("mdi-close-circle")
                    ])
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            2198149445
          )
        },
        [
          _vm.$store.getters.isHtml
            ? _c("div", {
                staticClass: "or-break",
                domProps: {
                  innerHTML: _vm._s(_vm.$store.getters.globalMessage)
                }
              })
            : _c("div", { staticClass: "or-break" }, [
                _vm._v(_vm._s(_vm.$store.getters.globalMessage))
              ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }