<template>
  <div>
    <v-card>
      <v-card-text v-if="internalValue.name">
        <v-row>
          <v-col md="6">
            <v-card :width="200" :height="200">
              <VImg
                :src="$$noimageHelper(internalValue.api_picture_url)"
                :alt="internalValue.name"
              />
            </v-card>
          </v-col>
          <v-col md="6">
            <template v-if="internalValue.blocked_flg">
              <div class="red--text">
                {{ $$tm('friend.blocked_flg') }}
                <p v-if="internalValue.blocked_at_formatted">
                  {{ internalValue.blocked_at_formatted }}
                </p>
              </div>
              <VDivider />
            </template>
            <template v-if="internalValue.blocking_flg">
              <div class="red--text">
                ブロックしています
                <p v-if="internalValue.blocking_at_formatted">
                  {{ internalValue.blocking_at_formatted }}
                </p>
              </div>
              <VDivider />
            </template>
            <div>
              {{ $$tm('friend.api_display_name') }}
            </div>
            <div>
              {{ internalValue.api_display_name }}
            </div>
            <VDivider />
            <div>
              {{ $$tm('friend.api_status_message') }}
            </div>
            <div>
              {{ internalValue.api_status_message || '-' }}
            </div>
            <VDivider />
          </v-col>
        </v-row>
        <v-card>
          <v-card-title class="pa-0">
            <v-list-item>
              <v-list-item-content>
                {{ $t('lbl.friend.sys_setting') }}
              </v-list-item-content>
              <v-list-item-action>
                <v-btn v-if="hasRole" icon outlined @click="openDialog">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card-title>
          <VDivider class="my-0" />
          <v-card-text>
            <v-simple-table>
              <template #default>
                <tbody>
                  <tr>
                    <td>{{ $$tm('friend.message_status') }}</td>
                    <td>{{ $$te('friend.message_status', internalValue) }}</td>
                  </tr>
                  <tr>
                    <td>{{ $$tm('friend.system_disp_name') }}</td>
                    <td>{{ internalValue.system_disp_name || '-' }}</td>
                  </tr>
                  <tr>
                    <td>{{ $$tm('friend.reservation_name') }}</td>
                    <td>{{ internalValue.reservation_name || '-' }}</td>
                  </tr>
                  <tr>
                    <td>{{ $$tm('friend.email') }}</td>
                    <td>{{ internalValue.email || '-' }}</td>
                  </tr>
                  <tr>
                    <td>{{ $$tm('friend.phone') }}</td>
                    <td>{{ internalValue.phone || '-' }}</td>
                  </tr>
                  <tr>
                    <td>{{ $$tm('friend.memo') }}</td>
                    <td>
                      <template>
                        <span v-if="internalValue.memo" @click="showDialog">
                          {{ internalValue.memo | truncate(13) }}
                        </span>
                        <span v-else>-</span>
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $$tm('friend.blocking_flg') }}</td>
                    <td>{{ $$flg(internalValue.blocking_flg) }}</td>
                  </tr>
                  <tr>
                    <td>{{ $$tm('friend.hidden_flg') }}</td>
                    <td>{{ $$flg(internalValue.hidden_flg) }}</td>
                  </tr>
                  <tr>
                    <td>{{ $$tm('friend.test_friend') }}</td>
                    <td>{{ $$flg(internalValue['test_friend?']) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <v-dialog v-if="hasRole" v-model="previewDialog" max-width="500">
      <v-card>
        <v-card-text>
          <div class="or-break memo-content">{{ internalValue.memo }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="hasRole" v-model="dialog" :max-width="500">
      <v-card>
        <v-card-title>
          {{ $t('lbl.friend.sys_setting') }}
        </v-card-title>
        <VfLoadingOverlayAbsolute :loading="loading" />
        <VfAlert v-model="errorMsg" type="error" />
        <validation-observer
          v-if="internalValue.api_display_name"
          ref="observer"
        >
          <v-card-text>
            <v-row>
              <v-col md="12" class="py-0">
                <VfSelect
                  v-model="friendClone.message_status"
                  :label="$$tm('friend.message_status')"
                  :vees="vees.message_status"
                  :selects="opts.message_status"
                />
              </v-col>
              <v-col md="12" class="py-0">
                <VfTextField
                  v-model="friendClone.system_disp_name"
                  :label="$$tm('friend.system_disp_name')"
                  :vees="vees.system_disp_name"
                  counter
                />
              </v-col>
              <v-col md="12" class="py-0">
                <VfTextField
                  v-model="friendClone.reservation_name"
                  :label="$$tm('friend.reservation_name')"
                  :vees="vees.reservation_name"
                />
              </v-col>
              <v-col md="12" class="py-0">
                <VfTextField
                  v-model="friendClone.email"
                  :label="$$tm('friend.email')"
                  :vees="vees.email"
                />
              </v-col>
              <v-col md="12" class="py-0">
                <VfTextField
                  v-model="friendClone.phone"
                  :label="$$tm('friend.phone')"
                  :vees="vees.phone"
                />
              </v-col>
              <v-col md="12" class="py-0">
                <VfTextArea
                  v-model="friendClone.memo"
                  :label="$$tm('friend.memo')"
                  :vees="{ rules: 'max:2000', max: 2000 }"
                />
              </v-col>
              <v-col md="6" class="py-0">
                <VfSwitch
                  v-model="friendClone.blocking_flg"
                  :label="$$tm('friend.blocking_flg')"
                  :vees="vees.blocking_flg"
                />
              </v-col>
              <v-col md="6" class="py-0">
                <VfSwitch
                  v-model="friendClone.hidden_flg"
                  :label="$$tm('friend.hidden_flg')"
                  :vees="vees.hidden_flg"
                />
              </v-col>
              <v-col md="6" class="py-0">
                <VfSwitch
                  v-model="friendClone['test_friend?']"
                  :true-value="true"
                  :false-value="false"
                  :label="$$tm('friend.test_friend')"
                  :vees="vees['test_friend?']"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <VSpacer />
            <v-btn @click="closeDialog">{{ $t('btn.cancel') }}</v-btn>
            <v-btn color="primary" @click="save">{{ $t('btn.update') }}</v-btn>
          </v-card-actions>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    value: { type: Object, required: true },
    opts: {
      type: Object,
      default: function () {
        return {}
      },
    },
    vees: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: () => ({
    loading: false,
    dialog: false,
    errorMsg: '',
    friendClone: {},
    previewDialog: false,
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    hasRole() {
      return this.$store.getters.roles.includes('friend_edit')
    },
  },
  filters: {
    truncate: function (data, num) {
      if (!data) return ''
      if (data.length <= num) return data
      const reqdString = data.slice(0, num).concat('...')
      return reqdString
    },
  },
  methods: {
    openDialog() {
      this.errorMsg = ''
      this.friendClone = this.$$clone(this.internalValue)
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    showDialog() {
      this.previewDialog = true
    },
    async save() {
      this.errorMsg = ''
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      this.loading = true
      const path = `/api/v1/friends/${this.internalValue.id}`
      const prms = { friend: this.friendClone }
      await this.$$sendForm('put', path, this.$$reqPrms(prms))
        .then((res) => {
          Object.assign(this.internalValue, res.data.friend)
          this.$toasted.success(this.$t('success.update'))
          this.dialog = false
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.memo-content {
  padding-top: 20px;
}
</style>
