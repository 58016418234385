var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticClass: "transparent",
          staticStyle: { width: "100%" },
          attrs: { outlined: "" }
        },
        [
          _c(
            "v-card-title",
            { staticClass: "pl-0 pa-0 or-card-title" },
            [
              _c(
                "v-list-item",
                { staticClass: "pa-0" },
                [
                  _c("v-list-item-content", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("lbl.new_info")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "or-card-title",
                          attrs: { small: "" },
                          on: {
                            click: function($event) {
                              return _vm.$router.push({ name: "InfoIndex" })
                            }
                          }
                        },
                        [_vm._v("\n            もっと見る\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c("VfAlert", {
            attrs: { type: "error" },
            model: {
              value: _vm.errorMsg,
              callback: function($$v) {
                _vm.errorMsg = $$v
              },
              expression: "errorMsg"
            }
          }),
          _vm._v(" "),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.items,
              "item-key": "id",
              "disable-sort": true,
              "hide-default-footer": ""
            },
            on: { "click:row": _vm.showDialog },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.title",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "a",
                        {
                          staticClass:
                            "green--text text--darken-1 font-weight-blac"
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(item.title) + "\n        "
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "item.subtitle",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("a", { staticClass: "grey--text text--lighten-1" }, [
                        _vm._v(
                          "\n          " + _vm._s(item.subtitle) + "\n        "
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.disp_st_at",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$$fat(item.disp_st_at)) +
                          "\n      "
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          model: {
            value: _vm.previewDialog,
            callback: function($$v) {
              _vm.previewDialog = $$v
            },
            expression: "previewDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$$fat(_vm.selectInfo.disp_st_at)) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "8" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.selectInfo.title) +
                            "\n          "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("v-card-subtitle", [
                _vm._v(
                  "\n        " + _vm._s(_vm.selectInfo.subtitle) + "\n      "
                )
              ]),
              _vm._v(" "),
              _c("v-card-text", [
                _c("div", { staticClass: "or-break" }, [
                  _vm._v(_vm._s(_vm.selectInfo.desc))
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }