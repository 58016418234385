var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canShow
    ? _c(
        "v-navigation-drawer",
        {
          attrs: {
            clipped: _vm.$vuetify.breakpoint.lgAndUp,
            width: 200,
            app: ""
          },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _c(
            "v-list",
            { attrs: { dense: "", dark: "", color: "blue-grey darken-4" } },
            [
              _vm._l(_vm.drawerMenus, function(drawerMenu) {
                return [
                  drawerMenu.children
                    ? _c(
                        "v-list-group",
                        {
                          key: drawerMenu.text,
                          staticClass: "drawer_group",
                          attrs: {
                            "no-action": "",
                            disabled: "",
                            "append-icon": ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c(
                                          "v-list-item-title",
                                          { staticClass: "drawer-item-text" },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(drawerMenu.text) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: drawerMenu.active,
                            callback: function($$v) {
                              _vm.$set(drawerMenu, "active", $$v)
                            },
                            expression: "drawerMenu.active"
                          }
                        },
                        [
                          _vm._v(" "),
                          _vm._l(drawerMenu.children, function(child, i) {
                            return _c(
                              "v-list-item",
                              {
                                key: i,
                                attrs: {
                                  to: child.to,
                                  href: child.href,
                                  link: "",
                                  target: child.target
                                }
                              },
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "drawer-item-text" },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(child.text) +
                                            "\n            "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.isFriendList(child) &&
                                    _vm.$store.getters.unreadMessageCount
                                      ? _c(
                                          "span",
                                          { staticClass: "unread-count" },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$store.getters
                                                    .unreadMessageCount
                                                ) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    : _c(
                        "v-list-item",
                        {
                          key: drawerMenu.text,
                          attrs: {
                            to: drawerMenu.to,
                            href: drawerMenu.href,
                            link: "",
                            target: drawerMenu.target
                          }
                        },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "drawer-item-text ml-3" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(drawerMenu.text) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                ]
              })
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }