var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "pa-0" },
        [
          _c(
            "v-list-item",
            [
              _c("v-list-item-content", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$$tm("user_address.tname")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-list-item-action",
                [
                  _c("btn-dialog-user-address-form", {
                    attrs: {
                      "user-address": _vm.userAddress,
                      vees: _vm.vees,
                      opts: _vm.opts
                    },
                    on: { save: _vm.save }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _c("v-simple-table", [
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$$tm("user_address.zip")))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.userAddress.zip))])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$$tm("user_address.pref")))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(_vm._s(_vm.$$te("user_address.pref", _vm.userAddress)))
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$$tm("user_address.address1")))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.userAddress.address1))])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$$tm("user_address.address2")))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.userAddress.address2))])
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }