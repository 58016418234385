var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("VfSelectChip", {
                                attrs: {
                                  label: _vm.$$tm("hashtag.name"),
                                  selects: _vm.selects,
                                  readonly: ""
                                },
                                model: {
                                  value: _vm.checkedItemsIds,
                                  callback: function($$v) {
                                    _vm.checkedItemsIds = $$v
                                  },
                                  expression: "checkedItemsIds"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "4" } },
                    [
                      _c("PtFolder", {
                        ref: "folder",
                        attrs: { "folder-type": "hashtag", "select-only": "" },
                        on: { "select-folder": _vm.selectFolder }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { md: "8" } },
                    [
                      _c("v-data-table", {
                        attrs: {
                          headers: _vm.headers,
                          items: _vm.items,
                          "item-key": "id",
                          "show-select": "",
                          "single-select": false,
                          "disable-sort": true,
                          "footer-props": {
                            itemsPerPageOptions: [10, 30, 50, 100]
                          }
                        },
                        on: { "click:row": _vm.rowClick },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.action_group",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c("PtActionLabelMessages", {
                                    staticClass: "or-list",
                                    model: {
                                      value: item.action_group.make_action_arr,
                                      callback: function($$v) {
                                        _vm.$set(
                                          item.action_group,
                                          "make_action_arr",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "item.action_group.make_action_arr"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.hashtagsClone,
                          callback: function($$v) {
                            _vm.hashtagsClone = $$v
                          },
                          expression: "hashtagsClone"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }