var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    attrs: { name: _vm.label, rules: _vm.rules },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var errors = ref.errors
          return [
            !_vm.noLabel
              ? _c(
                  "div",
                  { staticClass: "mb-0 text-caption theme--light v-label" },
                  [_vm._v("\n    " + _vm._s(_vm.label) + "\n  ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "v-radio-group",
              {
                staticClass: "mt-0 vf-radio-group",
                attrs: {
                  "error-messages": errors,
                  disabled: _vm.disabled,
                  readonly: _vm.readonly,
                  row: ""
                },
                model: {
                  value: _vm.internalValue,
                  callback: function($$v) {
                    _vm.internalValue = $$v
                  },
                  expression: "internalValue"
                }
              },
              _vm._l(_vm.selects, function(val, key) {
                return _c("VRadio", {
                  key: key,
                  attrs: { label: key, value: val }
                })
              }),
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }