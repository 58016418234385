var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PtTitle"),
      _vm._v("\n  " + _vm._s(_vm.$t("subtitle.show_answer_url")) + "\n  "),
      _vm.initDone
        ? _c(
            "validation-observer",
            { ref: "observer" },
            [
              _c(
                "v-card",
                { staticClass: "mb-6" },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-title", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  "フォーム「" +
                                    _vm.answer_form.name +
                                    "」用リンク"
                                ) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "mt-4" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-right mt-4",
                              attrs: { md: "2" }
                            },
                            [_vm._v(" 注意点 ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { md: "10" } },
                            [
                              _c(
                                "v-alert",
                                {
                                  staticClass: "blue-grey lighten-5",
                                  attrs: { text: "", outlined: "" }
                                },
                                [
                                  _vm._v(
                                    "\n              このアカウントからメッセージ配信した"
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "red--text font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        "短縮URLをクリックしたことがある友だち"
                                      )
                                    ]
                                  ),
                                  _vm._v(
                                    "しか回答を特定することはできません。"
                                  ),
                                  _c("br"),
                                  _vm._v("\n              もしくは、"),
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v("GETパラメータを引き継ぐ仕組み")]
                                  ),
                                  _vm._v(
                                    "を利用することもできます。 方法がわからない場合は"
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v("TalkLabel公式LINE")]
                                  ),
                                  _vm._v("にご連絡ください。\n            ")
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "mt-4" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-right mt-5",
                              attrs: { md: "3" }
                            },
                            [
                              _vm._v(
                                "\n            外部用回答フォームURL\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { md: "9" } },
                            [
                              _c("v-text-field", {
                                staticClass: "pb-5",
                                attrs: {
                                  value: _vm.answerUrl,
                                  readonly: "",
                                  "single-line": "",
                                  "hide-details": true
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append-outer",
                                      fn: function() {
                                        return [
                                          _c(
                                            "v-icon",
                                            {
                                              directives: [
                                                {
                                                  name: "clipboard",
                                                  rawName: "v-clipboard:copy",
                                                  value: _vm.answerUrl,
                                                  expression: "answerUrl",
                                                  arg: "copy"
                                                },
                                                {
                                                  name: "clipboard",
                                                  rawName:
                                                    "v-clipboard:success",
                                                  value: _vm.onClipCopy,
                                                  expression: "onClipCopy",
                                                  arg: "success"
                                                },
                                                {
                                                  name: "clipboard",
                                                  rawName: "v-clipboard:error",
                                                  value: _vm.onClipError,
                                                  expression: "onClipError",
                                                  arg: "error"
                                                }
                                              ],
                                              staticClass: "p-0 clipboard-icon"
                                            },
                                            [_vm._v("mdi-content-copy")]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  2515393996
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }