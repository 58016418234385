var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PtTitle"),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "6", cols: "12" } },
            [
              _c("channel-show", {
                staticClass: "mb-4",
                attrs: { channel: _vm.channel, vees: _vm.vees },
                on: { save: _vm.updateChannel }
              }),
              _vm._v(" "),
              _c("test-account")
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { md: "6", cols: "12" } },
            [
              _vm.isChannelOwner
                ? _c("credit-card-index", {
                    staticClass: "mb-4",
                    attrs: { "credit-cards": _vm.creditCards },
                    on: {
                      "create:token": _vm.createCreditCard,
                      "destroy:credit-card": _vm.destroyCreditCard,
                      "update:default-card": _vm.updateDefaultCard
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("PtPaymentHistoryIndex", {
                attrs: {
                  headers: _vm.historyHeaders,
                  items: _vm.paymentHistories
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }