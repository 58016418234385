var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "mt-4" }, [
        _vm._v(_vm._s(_vm.$t("lbl.click.statistics")))
      ]),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "6" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _c("v-simple-table", [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("lbl.click.click_count")))
                            ]),
                            _vm._v(" "),
                            _c("th")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.$t("lbl.click.send_count_total"))
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.click.send_count_total) +
                                  _vm._s(_vm.$t("lbl.click.unit_count")) +
                                  "\n                "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.$t("lbl.click.click_count_total"))
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.click.click_count_total) +
                                  _vm._s(_vm.$t("lbl.click.unit_count")) +
                                  "\n                "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("lbl.click.click_count/send_count")
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(_vm.clickCountPerSendCount) + "%")
                            ])
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { md: "6" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _c("v-simple-table", [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(
                                _vm._s(_vm.$t("lbl.click.number_of_peple"))
                              )
                            ]),
                            _vm._v(" "),
                            _c("th")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$t("lbl.click.send_count")))
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.click.send_friends_count) +
                                  _vm._s(_vm.$t("lbl.click.friend_count")) +
                                  "\n                "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$t("lbl.click.visit_count")))
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.click.visit_count) +
                                  _vm._s(_vm.$t("lbl.click.friend_count")) +
                                  "\n                "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("lbl.click.visit_count/send_count")
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(_vm.visitCountPerSendCount) + "%")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("lbl.click.visit_count/friend_count")
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(_vm.visitCountPerFriendCount) + "%")
                            ])
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }