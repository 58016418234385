var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.nonLogged
      ? _c("div", { staticClass: "d-flex justify-content-center" }, [
          _c("div", { staticClass: "redirect-content-wrapper" }),
          _vm._v(" "),
          _c("div", { staticClass: "redirect-content" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "open_app_btn",
                attrs: { href: _vm.openLineAppUrl }
              },
              [_vm._v("アプリで開く")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "description" }, [
              _c(
                "div",
                { staticClass: "p1" },
                [
                  _c("v-icon", [_vm._v("mdi-help-circle")]),
                  _vm._v(
                    "\n          ボタンを押してもLINEアプリに遷移しない場合\n        "
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "p2" }, [
                _vm._v(
                  "\n          ボタンを長押しして「LINEで開く」を選択してください。「LINEで開く」が表示されない場合は、このURLをWebブラウザで開いてください。\n        "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "btn_wrapper" }, [
              _c(
                "a",
                { staticClass: "btn", attrs: { href: _vm.liffLoginUrl } },
                [_vm._v("ブラウザでログインする")]
              ),
              _vm._v(" "),
              _c(
                "a",
                { staticClass: "btn", attrs: { href: "https://line.me/ja" } },
                [_vm._v("LINEアプリをダウンロード")]
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.qrScreen
      ? _c("div", { staticClass: "d-flex justify-content-center" }, [
          _c("div", { staticClass: "redirect-content-wrapper" }),
          _vm._v(" "),
          _c("div", { staticClass: "qr-screen-content" }, [
            _c("div", [_vm._v("スマートフォンでご覧ください")]),
            _vm._v(" "),
            _c("img", { attrs: { src: _vm.qrUrl } }),
            _vm._v(" "),
            _c("div", [_vm._v("LINEアプリを起動して、")]),
            _vm._v(" "),
            _c("div", [_vm._v("QRコードを読み取ってください。")])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line-header" }, [
      _c("img", {
        staticClass: "line_icon",
        attrs: { src: require("images/line_icon.png") }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "line-title" }, [
        _vm._v("LINEアプリを開いて 続行してください。")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }