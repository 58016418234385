var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-card-text",
            [
              _c("VfTextField", {
                attrs: {
                  label: _vm.$$tm("deli_introduction.title"),
                  vees: _vm.vees.title,
                  counter: ""
                },
                model: {
                  value: _vm.internalValue.title,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "title", $$v)
                  },
                  expression: "internalValue.title"
                }
              }),
              _vm._v(" "),
              _c("VfTextField", {
                attrs: {
                  label: _vm.$$tm("deli_introduction.line_account_id"),
                  vees: _vm.vees.line_account_id,
                  counter: ""
                },
                model: {
                  value: _vm.internalValue.line_account_id,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "line_account_id", $$v)
                  },
                  expression: "internalValue.line_account_id"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _c("VfTextArea", {
                    attrs: {
                      label: _vm.$$tm("deli_introduction.message_for_pc"),
                      vees: _vm.vees.message_for_pc,
                      counter: ""
                    },
                    model: {
                      value: _vm.internalValue.message_for_pc,
                      callback: function($$v) {
                        _vm.$set(_vm.internalValue, "message_for_pc", $$v)
                      },
                      expression: "internalValue.message_for_pc"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }