var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "unicast_message_sticky" },
    [
      !_vm.firstTimeFlg
        ? _c(
            "v-card",
            { staticClass: "mb-4", attrs: { outlined: "" } },
            [
              _c("VfLoadingOverlayAbsolute", {
                attrs: { loading: _vm.loading }
              }),
              _vm._v(" "),
              _c("v-card-text", [
                _c(
                  "div",
                  {
                    staticStyle: { height: "500px" },
                    attrs: { id: "chat-area" }
                  },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { align: "center" } },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "", large: "", color: "green" },
                                on: { click: _vm.moreMessages }
                              },
                              [_c("v-icon", [_vm._v("mdi-arrow-up")])],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.messages, function(message) {
                      return _c(
                        "v-col",
                        {
                          key: message.sort_no,
                          attrs: { id: "chat-" + message.sort_no, cols: "12" }
                        },
                        [
                          _c("PtLinePreviewRow", {
                            staticClass: "mx-4",
                            attrs: {
                              deli: message.deli,
                              "showable-status": "",
                              "message-id": message.id,
                              "message-status": message.message_status,
                              "message-type": message.message_type,
                              "action-type": message.message_action_type
                            },
                            on: {
                              "open-dialog-message-status":
                                _vm.openDialogMessageStatus
                            }
                          })
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.friend.id
        ? _c(
            "v-card",
            { staticClass: "message_input_container", attrs: { outlined: "" } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-around"
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "move-up",
                          attrs: { icon: "" },
                          on: { click: _vm.openDialog }
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "message-text-area" },
                    [
                      _c(
                        "validation-observer",
                        { ref: "observer" },
                        [
                          _c("VfTextArea", {
                            attrs: {
                              label: _vm.$$tm("deli_text.tname"),
                              "auto-grow": "",
                              outlined: "",
                              rows: 1,
                              "row-height": "15",
                              vees: _vm.vees.text,
                              counter: ""
                            },
                            model: {
                              value: _vm.oneMessage[0].deli_text.text,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.oneMessage[0].deli_text,
                                  "text",
                                  $$v
                                )
                              },
                              expression: "oneMessage[0].deli_text.text"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "move-up",
                      attrs: {
                        color: "primary",
                        loading: _vm.$wait.is("processing"),
                        icon: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.send("textArea")
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v(" mdi-send-check ")])],
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasRole
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "400" },
              model: {
                value: _vm.dialogMessageStatus,
                callback: function($$v) {
                  _vm.dialogMessageStatus = $$v
                },
                expression: "dialogMessageStatus"
              }
            },
            [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pt-6" },
                    [
                      _c("VfSelect", {
                        attrs: {
                          label: _vm.$$tm("friend.message_status"),
                          selects: _vm.opts.message_status
                        },
                        model: {
                          value: _vm.selectedMessageStatus,
                          callback: function($$v) {
                            _vm.selectedMessageStatus = $$v
                          },
                          expression: "selectedMessageStatus"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("VSpacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        { on: { click: _vm.closeDialogMessageStatus } },
                        [_vm._v(_vm._s(_vm.$t("btn.cancel")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.updateDialogMessageStatus }
                        },
                        [_vm._v(_vm._s(_vm.$t("btn.update")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.friend.id
        ? _c(
            "v-dialog",
            {
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "validation-observer",
                { ref: "childObserver" },
                [
                  _c("PtDeliMobile", {
                    staticClass: "pa-4",
                    attrs: {
                      "flag-dialog-unicast": _vm.dialog,
                      "err-message-text": _vm.errMessageText,
                      "show-date-setting": _vm.showDateSetting
                    },
                    on: {
                      cancel: _vm.closeDialog,
                      save: function($event) {
                        return _vm.send("dialog")
                      },
                      "add-err-text": _vm.addErrText,
                      "remove-error-text": _vm.removeErrorText,
                      "add-open-data-setting": _vm.addOpenDataSetting
                    },
                    model: {
                      value: _vm.delis,
                      callback: function($$v) {
                        _vm.delis = $$v
                      },
                      expression: "delis"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }