var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "my-4" },
    [
      _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("div", { staticClass: "pt-3 mr-3 justify-end d-flex" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("lbl.visit_count", { count: _vm.visit_count })) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.items,
          "item-key": "id",
          "disable-sort": ""
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.rich_menu_id",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v("\n      " + _vm._s(item.rich_menu.name) + "\n    ")
                ]
              }
            },
            {
              key: "item.friend_id",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.friend
                    ? _c("div", [
                        _vm._v(
                          "\n        " + _vm._s(item.friend.name) + "\n      "
                        )
                      ])
                    : _c("div", [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("lbl.unknown")) +
                            "\n      "
                        )
                      ])
                ]
              }
            },
            {
              key: "item.created_at",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$$fat(item.created_at)) + "\n    "
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }