var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": 500 },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("click")
                        }
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("btn.staff_create_code")) +
                      "\n    "
                  )
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "validation-observer",
            { ref: "observer" },
            [
              _c(
                "v-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.save($event)
                    }
                  }
                },
                [
                  _c("v-card-title", [
                    _vm._v(_vm._s(_vm.$t("lbl.staff.token_create_confirm")))
                  ]),
                  _vm._v(" "),
                  _c("v-divider", { staticClass: "mt-2 mb-0" }),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("VfSelect", {
                                attrs: {
                                  label: _vm.$$tm(
                                    "user_channel.channel_role_id"
                                  ),
                                  selects: _vm.selectChannelRoles,
                                  vees: { rules: "required" }
                                },
                                model: {
                                  value: _vm.internalValue,
                                  callback: function($$v) {
                                    _vm.internalValue = $$v
                                  },
                                  expression: "internalValue"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "or-break" }, [
                        _vm._v(_vm._s(_vm.$t("confirm.create_channel_token")))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function($event) {
                              _vm.dialog = false
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("btn.close")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        { attrs: { type: "submit", color: "primary" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("btn.create")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }