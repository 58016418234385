<template>
  <v-app-bar
    v-if="showHeader"
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    color="blue-grey darken-4"
    dark
    flat
  >
    <v-app-bar-nav-icon v-if="canShow" @click.stop="drawer = !drawer" />
    <v-toolbar-title class="ml-0 pl-4">
      <div class="font-weight-black hidden-sm-and-down">
        <img
          class="logo-header"
          src="~images/talk_label_logo.png"
          @click="clickLogo"
        />
      </div>
    </v-toolbar-title>
    <v-spacer />
    <template>
      <p v-if="showBookingTitle" class="app-bar__logo">
        {{ showBookingTitle }}
      </p>
    </template>
    <template v-if="canShow && !isAdmin">
      <v-btn text @click="$router.push({ name: 'AccountShow' })">
        <span class="hidden-sm-and-down">{{ user_name }}</span>
      </v-btn>
      <v-btn text @click="$router.push({ name: 'BaseHome' })">
        <span class="hidden-sm-and-down">{{ channel_name }}</span>
      </v-btn>
    </template>
    <template v-else>
      <v-btn v-if="$store.getters.user != null" text @click="logout">{{
        $t('btn.logout')
      }}</v-btn>
    </template>
  </v-app-bar>
</template>
<script>
export default {
  props: {
    value: { default: null },
    canShow: { type: Boolean, required: true },
    showHeader: { type: Boolean, required: true },
  },
  computed: {
    user_name() {
      const user = this.$store.getters.user
      if (user) {
        return user.name
      }
      return 'guest'
    },
    channel_name() {
      const channel = this.$store.getters.channel
      if (channel) {
        return channel.name
      }
      return ''
    },
    drawer: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    isAdmin() {
      return this.$store.getters.user && this.$store.getters.user.admin_flg == 1
    },
    showBookingTitle() {
      const isReservationPage = this.$route.name
        ?.toLowerCase()
        ?.includes('friendreservationbooking')

      return isReservationPage ? '予約機能' : null
    },
  },
  methods: {
    logout() {
      const url = '/auth/sign_out'
      this.axios
        .delete(url)
        .then(() => {
          this.$store.dispatch('logout')
          this.$toasted.success('logout')
          this.$router.push({ name: 'Login' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
    },
    clickLogo() {
      const routerName = this.isAdmin ? 'AdminUsersIndex' : 'BaseHome'
      if (this.$route.name === routerName) {
        this.$router.go({ name: routerName })
      } else {
        this.$router.push({ name: routerName })
      }
    },
  },
}
</script>
<style scoped>
.logo-header {
  cursor: hand;
  cursor: pointer;
  width: 200px;
}

.app-bar__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
}
</style>
