var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "validation-observer",
    { ref: "observer" },
    [
      _c(
        "v-card",
        { staticClass: "mx-auto mt-8", attrs: { "max-width": "800" } },
        [
          _c("v-card-title", { staticClass: "text-h4" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("cancel_form.title")) + "\n    ")
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-card",
                { staticClass: "mt-4" },
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("cancel_form.name")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { md: "8" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("cancel_form.desc_name")) +
                          "\n          "
                      ),
                      _c("VfTextField", {
                        attrs: {
                          label: _vm.$$tm("cancel_form.name"),
                          vees: _vm.vees.name
                        },
                        model: {
                          value: _vm.cancelForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.cancelForm, "name", $$v)
                          },
                          expression: "cancelForm.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "mt-4" },
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("cancel_form.email")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { md: "8" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("cancel_form.desc_email")) +
                          "\n          "
                      ),
                      _c("VfTextField", {
                        attrs: {
                          label: _vm.$$tm("cancel_form.email"),
                          vees: _vm.vees.email
                        },
                        model: {
                          value: _vm.cancelForm.email,
                          callback: function($$v) {
                            _vm.$set(_vm.cancelForm, "email", $$v)
                          },
                          expression: "cancelForm.email"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "mt-4" },
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("cancel_form.phone")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { md: "8" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("cancel_form.desc_phone")) +
                          "\n          "
                      ),
                      _c("VfTextField", {
                        attrs: {
                          label: _vm.$$tm("cancel_form.phone"),
                          vees: _vm.vees.phone
                        },
                        model: {
                          value: _vm.cancelForm.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.cancelForm, "phone", $$v)
                          },
                          expression: "cancelForm.phone"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "mt-4" },
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("cancel_form.line_account_id")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { md: "8" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("cancel_form.desc_line_account_id")) +
                          "\n          "
                      ),
                      _c("VfTextField", {
                        attrs: {
                          label: _vm.$$tm("cancel_form.line_account_id"),
                          vees: _vm.vees.line_account_id
                        },
                        model: {
                          value: _vm.cancelForm.line_account_id,
                          callback: function($$v) {
                            _vm.$set(_vm.cancelForm, "line_account_id", $$v)
                          },
                          expression: "cancelForm.line_account_id"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "mt-4 mb-4" },
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("cancel_form.plan_name")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { md: "8" } },
                    [
                      _c("VfTextField", {
                        attrs: {
                          label: _vm.$$tm("cancel_form.plan_name"),
                          vees: _vm.vees.plan_name
                        },
                        model: {
                          value: _vm.cancelForm.plan_name,
                          callback: function($$v) {
                            _vm.$set(_vm.cancelForm, "plan_name", $$v)
                          },
                          expression: "cancelForm.plan_name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("VfTextArea", {
                attrs: {
                  label: _vm.$$tm("cancel_form.reason_text"),
                  vees: _vm.vees.reason_text
                },
                model: {
                  value: _vm.cancelForm.reason_text,
                  callback: function($$v) {
                    _vm.$set(_vm.cancelForm, "reason_text", $$v)
                  },
                  expression: "cancelForm.reason_text"
                }
              }),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.sendForm }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("btn.send")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }