var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isSettingAutoFillAvailable
        ? [
            _vm._l(_vm.tempAutoList, function(autoItem, autoItemIndex) {
              return [
                autoItem.isAutoField
                  ? _c(
                      "v-row",
                      {
                        key: autoItem.internal_id,
                        staticClass: "mt-8 auto-fill-row"
                      },
                      [
                        [
                          _c("v-col", { attrs: { md: "3 px-0" } }, [
                            _c("p", { staticClass: "mr-10 font-weight-bold" }, [
                              _vm._v(_vm._s(autoItem.title))
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { md: "9" } },
                            [
                              _c("VfCheckbox", {
                                staticClass: "vf-unset-default mb-10",
                                attrs: {
                                  "checkbox-value": autoItem.internal_id,
                                  label: _vm.$t(
                                    "lbl.answer_form.setting.auto_input.checkbox_label"
                                  )
                                },
                                model: {
                                  value: _vm.allowSettingList,
                                  callback: function($$v) {
                                    _vm.allowSettingList = $$v
                                  },
                                  expression: "allowSettingList"
                                }
                              }),
                              _vm._v(" "),
                              _vm.isReadyForSetting(autoItem.internal_id)
                                ? [
                                    _c(
                                      "v-row",
                                      { staticClass: "align-center" },
                                      [
                                        _c("v-col", { attrs: { md: "2" } }, [
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "lbl.answer_form.setting.auto_input.all_address"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          { attrs: { md: "2" } },
                                          [
                                            _c("v-icon", [
                                              _vm._v("fas fa-angle-right")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          { attrs: { md: "6" } },
                                          [
                                            _c("v-select", {
                                              staticClass: "vf-reset-select",
                                              attrs: {
                                                items: _vm.autoFields(
                                                  autoItem.internal_id
                                                ),
                                                label: "",
                                                "item-text": "title",
                                                "item-value": "internal_id",
                                                "return-object": "",
                                                "single-line": ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.updateSettingsInfo(
                                                    $event,
                                                    autoItem.internal_id,
                                                    "all_address"
                                                  )
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.autoAllAdress[
                                                    autoItemIndex
                                                  ],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.autoAllAdress,
                                                    autoItemIndex,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "autoAllAdress[autoItemIndex]"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-row",
                                      { staticClass: "align-center" },
                                      [
                                        _c("v-col", { attrs: { md: "2" } }, [
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "lbl.answer_form.setting.auto_input.prefecture"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          { attrs: { md: "2" } },
                                          [
                                            _c("v-icon", [
                                              _vm._v("fas fa-angle-right")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          { attrs: { md: "6" } },
                                          [
                                            _c("v-select", {
                                              staticClass: "vf-reset-select",
                                              attrs: {
                                                items: _vm.autoFields(
                                                  autoItem.internal_id
                                                ),
                                                label: "",
                                                "item-text": "title",
                                                "item-value": "internal_id",
                                                "return-object": "",
                                                "single-line": ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.updateSettingsInfo(
                                                    $event,
                                                    autoItem.internal_id,
                                                    "prefecture"
                                                  )
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.autoPrefecture[
                                                    autoItemIndex
                                                  ],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.autoPrefecture,
                                                    autoItemIndex,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "autoPrefecture[autoItemIndex]"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-row",
                                      { staticClass: "align-center" },
                                      [
                                        _c("v-col", { attrs: { md: "2" } }, [
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "lbl.answer_form.setting.auto_input.district"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          { attrs: { md: "2" } },
                                          [
                                            _c("v-icon", [
                                              _vm._v("fas fa-angle-right")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          { attrs: { md: "6" } },
                                          [
                                            _c("v-select", {
                                              staticClass: "vf-reset-select",
                                              attrs: {
                                                items: _vm.autoFields(
                                                  autoItem.internal_id
                                                ),
                                                label: "",
                                                "item-text": "title",
                                                "item-value": "internal_id",
                                                "return-object": "",
                                                "single-line": ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.updateSettingsInfo(
                                                    $event,
                                                    autoItem.internal_id,
                                                    "district"
                                                  )
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.autoDistrict[
                                                    autoItemIndex
                                                  ],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.autoDistrict,
                                                    autoItemIndex,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "autoDistrict[autoItemIndex]"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-row",
                                      { staticClass: "align-center" },
                                      [
                                        _c("v-col", { attrs: { md: "2" } }, [
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "lbl.answer_form.setting.auto_input.town"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          { attrs: { md: "2" } },
                                          [
                                            _c("v-icon", [
                                              _vm._v("fas fa-angle-right")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          { attrs: { md: "6" } },
                                          [
                                            _c("v-select", {
                                              staticClass: "vf-reset-select",
                                              attrs: {
                                                items: _vm.autoFields(
                                                  autoItem.internal_id
                                                ),
                                                label: "",
                                                "item-text": "title",
                                                "item-value": "internal_id",
                                                "return-object": "",
                                                "single-line": ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.updateSettingsInfo(
                                                    $event,
                                                    autoItem.internal_id,
                                                    "town"
                                                  )
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.autoTown[autoItemIndex],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.autoTown,
                                                    autoItemIndex,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "autoTown[autoItemIndex]"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-row",
                                      { staticClass: "align-center" },
                                      [
                                        _c("v-col", { attrs: { md: "2" } }, [
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "lbl.answer_form.setting.auto_input.subsequent_address"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          { attrs: { md: "2" } },
                                          [
                                            _c("v-icon", [
                                              _vm._v("fas fa-angle-right")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          { attrs: { md: "6" } },
                                          [
                                            _c("v-select", {
                                              staticClass: "vf-reset-select",
                                              attrs: {
                                                items: _vm.autoFields(
                                                  autoItem.internal_id
                                                ),
                                                label: "",
                                                "item-text": "title",
                                                "item-value": "internal_id",
                                                "return-object": "",
                                                "single-line": ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.updateSettingsInfo(
                                                    $event,
                                                    autoItem.internal_id,
                                                    "subsequent_address"
                                                  )
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.autoSubsequentAdress[
                                                    autoItemIndex
                                                  ],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.autoSubsequentAdress,
                                                    autoItemIndex,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "autoSubsequentAdress[autoItemIndex]"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ]
                      ],
                      2
                    )
                  : _vm._e()
              ]
            })
          ]
        : _c("p", { staticClass: "mt-10 font-weight-bold" }, [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t("lbl.answer_form.setting.auto_input.no_configure")
                ) +
                "\n  "
            )
          ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }