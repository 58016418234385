var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "mb-8" },
        [
          _c(
            "validation-observer",
            { ref: "observer" },
            [
              _c("VfLoadingOverlayAbsolute", {
                attrs: { loading: _vm.$wait.is("processing") }
              }),
              _vm._v(" "),
              _c("VfAlert", {
                attrs: { type: "error" },
                model: {
                  value: _vm.errorMsg,
                  callback: function($$v) {
                    _vm.errorMsg = $$v
                  },
                  expression: "errorMsg"
                }
              }),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { md: "6" } },
                        [
                          _c("VfRadioButton", {
                            attrs: {
                              label: _vm.$$tm(
                                "action_rich_menu.rich_menu_action_type"
                              ),
                              selects: _vm.richMenuActionType,
                              "label-hidden": ""
                            },
                            model: {
                              value: _vm.rich_menu.rich_menu_action_type,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.rich_menu,
                                  "rich_menu_action_type",
                                  $$v
                                )
                              },
                              expression: "rich_menu.rich_menu_action_type"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.rich_menu.rich_menu_action_type !== "cancel"
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("PtDialogSelect", {
                                attrs: {
                                  "select-type": "rich_menu",
                                  "single-select": true,
                                  vees: { rules: "required" }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item.upload_file_id",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c("VImg", {
                                            attrs: {
                                              width: "120",
                                              src:
                                                item.upload_file.preview_image
                                                  .url
                                            }
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.rich_menu,
                                  callback: function($$v) {
                                    _vm.rich_menu = $$v
                                  },
                                  expression: "rich_menu"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("pt-friend-update-common-bottom", {
        attrs: {
          friends: _vm.friends,
          "search-wrap": _vm.searchWrap,
          "total-count": _vm.totalCount,
          "alert-message": _vm.$t("lbl.confirm_friends_rich_menu_update")
        },
        on: { save: _vm.save },
        model: {
          value: _vm.allFriendsSelected,
          callback: function($$v) {
            _vm.allFriendsSelected = $$v
          },
          expression: "allFriendsSelected"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }