var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _vm.internalValue.name
            ? _c(
                "v-card-text",
                [
                  _c("PtFriendShowActionHashtag", {
                    staticClass: "mb-4",
                    attrs: { "friend-id": _vm.internalValue.id },
                    model: {
                      value: _vm.internalValue.hashtags,
                      callback: function($$v) {
                        _vm.$set(_vm.internalValue, "hashtags", $$v)
                      },
                      expression: "internalValue.hashtags"
                    }
                  }),
                  _vm._v(" "),
                  _c("PtFriendShowActionScenario", {
                    staticClass: "mb-4",
                    attrs: { "friend-id": _vm.internalValue.id },
                    model: {
                      value: _vm.internalValue.active_scenario,
                      callback: function($$v) {
                        _vm.$set(_vm.internalValue, "active_scenario", $$v)
                      },
                      expression: "internalValue.active_scenario"
                    }
                  }),
                  _vm._v(" "),
                  _c("PtFriendShowActionReminder", {
                    staticClass: "mb-4",
                    attrs: { "friend-id": _vm.internalValue.id },
                    model: {
                      value: _vm.internalValue.active_reminders,
                      callback: function($$v) {
                        _vm.$set(_vm.internalValue, "active_reminders", $$v)
                      },
                      expression: "internalValue.active_reminders"
                    }
                  }),
                  _vm._v(" "),
                  _c("PtFriendShowActionSchedule", {
                    staticClass: "mb-4",
                    attrs: { "friend-id": _vm.internalValue.id },
                    model: {
                      value: _vm.internalValue.schedules,
                      callback: function($$v) {
                        _vm.$set(_vm.internalValue, "schedules", $$v)
                      },
                      expression: "internalValue.schedules"
                    }
                  }),
                  _vm._v(" "),
                  _c("PtFriendShowActionRichMenu", {
                    attrs: { "friend-id": _vm.internalValue.id },
                    model: {
                      value: _vm.internalValue.rich_menu,
                      callback: function($$v) {
                        _vm.$set(_vm.internalValue, "rich_menu", $$v)
                      },
                      expression: "internalValue.rich_menu"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }