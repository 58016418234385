import { render, staticRenderFns } from "./PtFormSetupAttachment.vue?vue&type=template&id=36f72460&scoped=true&"
import script from "./PtFormSetupAttachment.vue?vue&type=script&lang=js&"
export * from "./PtFormSetupAttachment.vue?vue&type=script&lang=js&"
import style0 from "./PtFormSetupAttachment.vue?vue&type=style&index=0&id=36f72460&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36f72460",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/talk_label/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36f72460')) {
      api.createRecord('36f72460', component.options)
    } else {
      api.reload('36f72460', component.options)
    }
    module.hot.accept("./PtFormSetupAttachment.vue?vue&type=template&id=36f72460&scoped=true&", function () {
      api.rerender('36f72460', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/packs/components/parts/common/PtFormSetupSection/PtFormSetupAttachment.vue"
export default component.exports