var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("VfAlert", {
        attrs: { type: "error" },
        model: {
          value: _vm.errorMsg,
          callback: function($$v) {
            _vm.errorMsg = $$v
          },
          expression: "errorMsg"
        }
      }),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { md: "6" } },
            [
              _c("VfRadioButton", {
                attrs: {
                  label: _vm.$$tm("action_hashtag.hashtag_action_type"),
                  vees: _vm.vees.hashtag_action_type,
                  selects: _vm.opts.hashtag_action_type,
                  "label-hidden": ""
                },
                model: {
                  value: _vm.internalValue.hashtag_action_type,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "hashtag_action_type", $$v)
                  },
                  expression: "internalValue.hashtag_action_type"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { md: "6" } },
            [
              _c("PtDialogSelect", {
                attrs: { "select-type": "hashtag", vees: _vm.vees.name },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.action_group",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("PtActionLabelMessages", {
                            staticClass: "or-list",
                            model: {
                              value: item.action_group.make_action_arr,
                              callback: function($$v) {
                                _vm.$set(
                                  item.action_group,
                                  "make_action_arr",
                                  $$v
                                )
                              },
                              expression: "item.action_group.make_action_arr"
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.internalValue,
                  callback: function($$v) {
                    _vm.internalValue = $$v
                  },
                  expression: "internalValue"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }