var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "mt-2" },
        [
          _c("v-card-title", [
            _vm._v("\n      " + _vm._s(_vm.$t("lbl.rich_menu.area")) + "\n    ")
          ]),
          _vm._v(" "),
          _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    _vm._l(_vm.internalValue.rich_menu_contents, function(
                      rich_menu_content,
                      contIndex
                    ) {
                      return _c(
                        "v-card",
                        { key: contIndex, staticClass: "mt-2" },
                        [
                          _c(
                            "v-card-text",
                            { key: contIndex },
                            [
                              _c("v-card-title", [
                                _vm._v(
                                  "ボタン" + _vm._s(rich_menu_content.sort_no)
                                )
                              ]),
                              _vm._v(" "),
                              _c("VfSelect", {
                                attrs: {
                                  selects: _vm.actionTypes,
                                  label: _vm.$$tm(
                                    "rich_menu_content.click_text"
                                  ),
                                  vees: _vm.vees.action_type
                                },
                                model: {
                                  value: rich_menu_content.action_type,
                                  callback: function($$v) {
                                    _vm.$set(
                                      rich_menu_content,
                                      "action_type",
                                      $$v
                                    )
                                  },
                                  expression: "rich_menu_content.action_type"
                                }
                              }),
                              _vm._v(" "),
                              rich_menu_content.action_type === "url_open" ||
                              rich_menu_content.action_type === "tell_phone"
                                ? _c("VfTextField", {
                                    key: rich_menu_content.action_type,
                                    attrs: {
                                      label: _vm.$t(
                                        "lbl." +
                                          rich_menu_content.action_type +
                                          "_text"
                                      ),
                                      vees: _vm.vees.multi_text,
                                      counter: ""
                                    },
                                    model: {
                                      value: rich_menu_content.multi_text,
                                      callback: function($$v) {
                                        _vm.$set(
                                          rich_menu_content,
                                          "multi_text",
                                          $$v
                                        )
                                      },
                                      expression: "rich_menu_content.multi_text"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              rich_menu_content.action_type === "action"
                                ? _c(
                                    "v-card-text",
                                    [
                                      _c("PtActionSelectedList", {
                                        model: {
                                          value: rich_menu_content.action_arr,
                                          callback: function($$v) {
                                            _vm.$set(
                                              rich_menu_content,
                                              "action_arr",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "rich_menu_content.action_arr"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              rich_menu_content.action_type === "questionnaire"
                                ? _c("PtDialogSelect", {
                                    attrs: {
                                      "select-type": "answer_form",
                                      "single-select": "",
                                      vees: _vm.vees.answer_form_id
                                    },
                                    model: {
                                      value:
                                        _vm.internalValue.rich_menu_contents[
                                          contIndex
                                        ],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.internalValue.rich_menu_contents,
                                          contIndex,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "internalValue.rich_menu_contents[contIndex]"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              rich_menu_content.action_type === "other"
                                ? _c("VfSelect", {
                                    attrs: {
                                      selects: _vm.otherOpenTypes,
                                      label: _vm.$$tm(
                                        "rich_menu_content.other_text"
                                      ),
                                      vees: _vm.vees.other_open_type
                                    },
                                    model: {
                                      value: rich_menu_content.other_open_type,
                                      callback: function($$v) {
                                        _vm.$set(
                                          rich_menu_content,
                                          "other_open_type",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "rich_menu_content.other_open_type"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.internalValue.length <= 1 ||
                                      contIndex === 0,
                                    small: "",
                                    icon: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.sortContent(contIndex, -1)
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-arrow-collapse-up")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.internalValue.length <= 1 ||
                                      contIndex ===
                                        _vm.internalValue.rich_menu_contents
                                          .length -
                                          1,
                                    small: "",
                                    icon: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.sortContent(contIndex, 0)
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-arrow-collapse-down")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }