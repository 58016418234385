var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "call-tag" },
    [
      _c("p", [_vm._v(_vm._s(_vm.$t("lbl.answer_form.call_tag")))]),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "d-flex" },
            [
              _c("div", { staticClass: "call-tag__value" }, [
                _vm._v("\n        " + _vm._s(_vm.formKey) + "\n      ")
              ]),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "call-tag__button",
                  on: { click: _vm.openForm }
                },
                [_vm._v(_vm._s(_vm.$t("lbl.answer_form.call_tag_btn")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }