var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.hidden
    ? _c(
        "div",
        [
          _c(
            "v-row",
            [
              !_vm.hiddenTitle
                ? _c(
                    "v-col",
                    { attrs: { sm: "4", xs: "12", cols: "12" } },
                    [
                      _c("span", { staticClass: "text-bold" }, [
                        _vm._v(_vm._s(_vm.title))
                      ]),
                      _vm._v(" "),
                      _vm.required
                        ? _c(
                            "v-chip",
                            {
                              staticClass: "ma-2",
                              attrs: {
                                label: "",
                                color: "red",
                                "text-color": "white",
                                small: ""
                              }
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.$t("lbl.answer_form.require")) +
                                  "\n      "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  attrs: { sm: !_vm.hiddenTitle ? 8 : 12, xs: "12", cols: "12" }
                },
                [
                  _c("validation-provider", {
                    ref: "provider",
                    attrs: { name: _vm.title, rules: _vm.vees.rules },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var errors = ref.errors
                            return [
                              _c("input", {
                                key: _vm.forceUpdate,
                                attrs: { type: "file" },
                                on: { change: _vm.onFileChange }
                              }),
                              _vm._v(" "),
                              errors.length
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "v-text-field__details mt-2"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "v-messages theme--light error--text",
                                          attrs: { role: "alert" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "v-messages__wrapper"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "v-messages__message"
                                                },
                                                [_vm._v(_vm._s(errors[0]))]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1357814141
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "subtitle-3 theme--light v-messages" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.getDescriptionUploadFile) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }