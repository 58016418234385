var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("VfAlert", {
        attrs: { type: "error" },
        model: {
          value: _vm.errorMsg,
          callback: function($$v) {
            _vm.errorMsg = $$v
          },
          expression: "errorMsg"
        }
      }),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { md: "6" } },
            [
              _c("VfTextField", {
                attrs: {
                  label: _vm.$t("lbl.answer_form.heading"),
                  vees: _vm.vees.answer_form_input.title
                },
                model: {
                  value: _vm.internalValue.title,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "title", $$v)
                  },
                  expression: "internalValue.title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { md: "6" } },
            [
              _c("VfCheckbox", {
                attrs: { label: _vm.$t("lbl.answer_form.hide_item_name") },
                model: {
                  value: _vm.internalValue.hide_title_flg,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "hide_title_flg", $$v)
                  },
                  expression: "internalValue.hide_title_flg"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "4" } },
            [
              _c("VfSelect", {
                attrs: {
                  label: _vm.$t("lbl.answer_form.image"),
                  selects:
                    _vm.opts.answer_form_input.additional_option_types
                      .subheading
                },
                model: {
                  value: _vm.internalValue.additional_option_type,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "additional_option_type", $$v)
                  },
                  expression: "internalValue.additional_option_type"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { md: "4" } },
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.internalValue.additional_option_type ===
                        "upload_file",
                      expression:
                        "internalValue.additional_option_type === 'upload_file'"
                    }
                  ],
                  staticClass: "mt-3",
                  on: { click: _vm.openDialog }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("btn.select_image")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c("VfTextField", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.internalValue.additional_option_type ===
                      "external_url",
                    expression:
                      "internalValue.additional_option_type === 'external_url'"
                  }
                ],
                attrs: { label: "" },
                on: {
                  input: function($event) {
                    _vm.imageKey += 1
                  }
                },
                model: {
                  value: _vm.internalValue.heading_image,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "heading_image", $$v)
                  },
                  expression: "internalValue.heading_image"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { md: "4" } },
            [
              _vm.internalValue.heading_image &&
              _vm.internalValue.additional_option_type !== "do_nothing"
                ? _c(
                    "v-card",
                    {
                      staticClass: "mt-4",
                      attrs: { outlined: "", tile: "", "max-width": 200 }
                    },
                    [
                      _c("VImg", {
                        key: _vm.imageKey,
                        attrs: { src: _vm.internalValue.heading_image }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "12" } },
            [
              _c("VfTextArea", {
                attrs: {
                  label: _vm.$t("lbl.answer_form.explanatory_text"),
                  vees: _vm.vees.answer_form_input.description,
                  hint: _vm.$t("lbl.answer_form.subheading_desc_hint")
                },
                model: {
                  value: _vm.internalValue.description,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "description", $$v)
                  },
                  expression: "internalValue.description"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "offset-md-10 pb-0 pt-0", attrs: { md: "2" } },
            [
              _c("VfCheckbox", {
                attrs: { label: _vm.$t("lbl.answer_form.hide") },
                model: {
                  value: _vm.internalValue.hidden_flg,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "hidden_flg", $$v)
                  },
                  expression: "internalValue.hidden_flg"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("PtDeliDialogForSelectFile", {
        attrs: { "upload-file-type": "image" },
        on: { "select-file-done": _vm.selectFileDone },
        model: {
          value: _vm.dialog,
          callback: function($$v) {
            _vm.dialog = $$v
          },
          expression: "dialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }