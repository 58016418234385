var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { on: { click: _vm.openDialog } },
    [
      _vm._t("opener", [
        _c(_vm.singleSelect ? "VfSelect" : "VfSelectChip", {
          tag: "component",
          attrs: {
            label: _vm.$$tm(_vm.selectType + ".name"),
            selects: _vm.selects,
            vees: _vm.vees,
            readonly: ""
          },
          model: {
            value: _vm.selectedItemIds,
            callback: function($$v) {
              _vm.selectedItemIds = $$v
            },
            expression: "selectedItemIds"
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          on: {
            "click:outside": _vm.initSelectedItems,
            keydown: _vm.keydownAction
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    _vm.singleSelect
                                      ? "VfSelect"
                                      : "VfSelectChip",
                                    {
                                      tag: "component",
                                      attrs: {
                                        label: _vm.$$tm(
                                          _vm.selectType + ".name"
                                        ),
                                        selects: _vm.selects,
                                        readonly: "",
                                        icon: ""
                                      },
                                      model: {
                                        value: _vm.selectedItemIds,
                                        callback: function($$v) {
                                          _vm.selectedItemIds = $$v
                                        },
                                        expression: "selectedItemIds"
                                      }
                                    }
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      !_vm.hideFolder
                        ? _c(
                            "v-col",
                            { attrs: { md: "4" } },
                            [
                              _c("PtFolder", {
                                ref: "folder",
                                attrs: {
                                  "folder-type": _vm.selectType,
                                  "select-only": ""
                                },
                                on: { "select-folder": _vm.initTable }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { md: _vm.hideFolder ? 12 : 8 } },
                        [
                          _c("v-data-table", {
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.items,
                              "item-key": "id",
                              "show-select": "",
                              "single-select": _vm.singleSelect,
                              "disable-sort": "",
                              "items-per-page": 50,
                              "footer-props": {
                                itemsPerPageOptions: [50, 100, 150, -1]
                              }
                            },
                            on: { "click:row": _vm.rowClick },
                            scopedSlots: _vm._u(
                              [
                                _vm._l(_vm.$scopedSlots, function(
                                  slotContent,
                                  slotName
                                ) {
                                  return {
                                    key: slotName,
                                    fn: function(data) {
                                      return [
                                        _vm._t(slotName, null, null, data)
                                      ]
                                    }
                                  }
                                })
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.selectedItems,
                              callback: function($$v) {
                                _vm.selectedItems = $$v
                              },
                              expression: "selectedItems"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("VSpacer"),
                  _vm._v(" "),
                  _c("v-btn", { on: { click: _vm.cancel } }, [
                    _vm._v(_vm._s(_vm.$t("btn.cancel")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    { attrs: { color: "primary" }, on: { click: _vm.decide } },
                    [_vm._v(_vm._s(_vm.$t("btn.update")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }