var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "pa-0" },
        [
          _c(
            "v-list-item",
            [
              _c("v-list-item-content", [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("account.channel")) + "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-list-item-action",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", outlined: "" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push({
                            name: "RegistrationAccount"
                          })
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-plus")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.channels, function(channel) {
        return _c(
          "v-list-item",
          {
            key: channel.id,
            attrs: { disabled: channel.stop_flg == 1 },
            on: {
              click: function($event) {
                return _vm.$emit("select", channel.id)
              }
            }
          },
          [
            _c(
              "v-list-item-content",
              [
                _c(
                  "v-row",
                  { attrs: { justify: "space-between" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "8" } },
                      [_c("v-list-item-title", [_vm._v(_vm._s(channel.name))])],
                      1
                    ),
                    _vm._v(" "),
                    channel.stop_flg == 1
                      ? _c(
                          "v-col",
                          { attrs: { cols: "2" } },
                          [
                            _c(
                              "v-icon",
                              { attrs: { color: "red lighten-3" } },
                              [_vm._v("far fa-times-circle")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }