<template>
  <div>
    <VfLoadingOverlayAbsolute :loading="loading" />
    <VfAlert v-model="errorMsg" type="error" />
    <template v-if="firstInit">
      <v-card v-for="(action, action_i) in internalValue" :key="action_i">
        <v-card-title class="pa-0 text-subtitle-1 blue-grey lighten-5">
          <v-list-item>
            <v-list-item-content>
              {{ $t(`lbl.action_group.action_types.${action.type}`) }}
            </v-list-item-content>
            <v-list-item-action>
              <v-btn small icon @click="removeAction(action_i)">
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card-title>
        <v-card-text>
          <template v-if="!msgHiddenFlg">
            <PtActionBroadcast
              v-if="action.type === 'broadcast' && !msgHiddenFlg"
              v-model="action.data"
              :vees="allVees[action.type]"
              :opts="opts"
              :hide-timing-type-select="hideTimingTypeSelect"
              @remove-action="removeAction(action_i)"
            />
          </template>
          <PtActionFixedPhrase
            v-if="action.type === 'fixed_phrase'"
            v-model="action.data"
            :vees="allVees[action.type]"
            :opts="opts"
            :hide-timing-type-select="hideTimingTypeSelect"
            @remove-action="removeAction(action_i)"
          />
          <PtActionHashtag
            v-if="action.type === 'hashtag'"
            v-model="action.data"
            :vees="allVees[action.type]"
            :opts="opts"
            @remove-action="removeAction(action_i)"
          />
          <PtActionScenario
            v-if="action.type === 'scenario'"
            v-model="action.data"
            :vees="allVees[action.type]"
            :opts="opts"
            @remove-action="removeAction(action_i)"
          />
          <PtActionReminder
            v-if="action.type === 'reminder'"
            v-model="action.data"
            :vees="allVees[action.type]"
            :opts="opts"
            :hide-timing-reminder="hideTimingReminder"
            @remove-action="removeAction(action_i)"
          />
          <PtActionLabel
            v-if="action.type === 'label'"
            v-model="action.data"
            :vees="allVees[action.type]"
            :opts="opts"
            @remove-action="removeAction(action_i)"
          />
          <PtActionNotice
            v-if="action.type === 'notice'"
            v-model="action.data"
            :vees="allVees[action.type]"
            @remove-action="removeAction(action_i)"
          />
          <PtActionRichMenu
            v-if="action.type === 'rich_menu'"
            v-model="action.data"
            :vees="allVees[action.type]"
            :opts="opts"
            @remove-action="removeAction(action_i)"
          />
        </v-card-text>
      </v-card>
    </template>
    <v-row>
      <v-col>
        <v-btn
          v-for="(actionType, disp) in opts.action_types"
          :key="disp"
          class="ma-2"
          @click="addAction(actionType)"
          >{{ disp }}</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PtActionBroadcast from 'components/parts/common/PtActionGroup/PtActionBroadcast'
import PtActionFixedPhrase from 'components/parts/common/PtActionGroup/PtActionFixedPhrase'
import PtActionHashtag from 'components/parts/common/PtActionGroup/PtActionHashtag'
import PtActionScenario from 'components/parts/common/PtActionGroup/PtActionScenario'
import PtActionReminder from 'components/parts/common/PtActionGroup/PtActionReminder'
import PtActionLabel from 'components/parts/common/PtActionGroup/PtActionLabel'
import PtActionNotice from 'components/parts/common/PtActionGroup/PtActionNotice'
import PtActionRichMenu from 'components/parts/common/PtActionGroup/PtActionRichMenu'
export default {
  components: {
    PtActionBroadcast,
    PtActionFixedPhrase,
    PtActionHashtag,
    PtActionScenario,
    PtActionReminder,
    PtActionLabel,
    PtActionNotice,
    PtActionRichMenu,
  },
  props: {
    hideTimingTypeSelect: { type: Boolean, default: false },
    hideTimingReminder: { type: Boolean, default: false },
    value: { default: null },
    msgHiddenFlg: { default: false },
  },
  data: () => ({
    firstInit: false,
    loading: false,
    errorMsg: '',
    opts: {},
    initData: {},
    allVees: {},
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.errorMsg = ''
      this.loading = true
      const path = '/api/v1/action_groups/setup'
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.initData = res.data.init_data
          this.allVees = res.data.all_vees
          this.opts = res.data.opts
          if (this.msgHiddenFlg) {
            delete this.opts.action_types['メッセージ送信']
          }
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
        .finally(() => {
          this.loading = false
          this.firstInit = true
        })
    },
    addAction(actionType) {
      this.internalValue.push({
        type: actionType,
        data: this.$$clone(this.initData[actionType]),
      })
    },
    removeAction(index) {
      this.internalValue.splice(index, 1)
    },
  },
}
</script>
