var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pl-2 pb-2 d-flex w-100 flex-column" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isEdit,
              expression: "!isEdit"
            }
          ],
          staticClass: "pt-1 file-name font-weight-bold"
        },
        [_vm._v("\n    " + _vm._s(_vm.fileName) + "\n  ")]
      ),
      _vm._v(" "),
      _c("VfTextField", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isEdit,
            expression: "isEdit"
          }
        ],
        staticClass: "mt-0 mb-1 pt-0 pl-1 font-size-x-small",
        attrs: { label: "", "hide-details": "" },
        model: {
          value: _vm.fileName,
          callback: function($$v) {
            _vm.fileName = $$v
          },
          expression: "fileName"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between align-items-center" },
        [
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isEdit,
                  expression: "isEdit"
                }
              ],
              staticClass: "ma-0",
              attrs: { text: "", "x-small": "", icon: "" },
              on: { click: _vm.close }
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEdit,
                  expression: "!isEdit"
                }
              ],
              staticClass: "download-url",
              attrs: { href: _vm.fileUrl, target: "_blank" }
            },
            [_vm._v(_vm._s(_vm.$t("btn.download")))]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEdit,
                  expression: "!isEdit"
                }
              ],
              staticClass: "ma-0 px-1 align-self-end",
              attrs: { small: "" },
              on: {
                click: function($event) {
                  _vm.isEdit = !_vm.isEdit
                }
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("btn.edit_name")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isEdit,
                  expression: "isEdit"
                }
              ],
              staticClass: "ma-0 align-self-end",
              attrs: { small: "" },
              on: { click: _vm.rename }
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("btn.save")) + "\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }