var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.initDone
    ? _c(
        "div",
        [
          _c("PtTitle"),
          _vm._v(" "),
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { md: "4" } }, [
                            _c(
                              "table",
                              { staticClass: "table table-bordered" },
                              [
                                _c("thead", [
                                  _c("tr", [_c("th", [_vm._v("回答数")])])
                                ]),
                                _vm._v(" "),
                                _c("tbody", [
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.data
                                              .answer_form_response_count +
                                              "種(全" +
                                              _vm.data.answer_form_count +
                                              "回)"
                                          ) +
                                          "\n                  "
                                      )
                                    ])
                                  ])
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { md: "8" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { md: "2" } }, [
                                    _c("img", {
                                      staticClass: "orgn-icon",
                                      attrs: {
                                        src: _vm.$$noimageHelper(
                                          _vm.data.api_picture_url
                                        )
                                      }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "mt-3",
                                      attrs: { md: "10" }
                                    },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.data.system_disp_name)
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "mb-0" }, [
                                        _vm._v("LINE登録名")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.data.api_display_name)
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("div", [_c("p", [_vm._v("回答一覧")])]),
                  _vm._v(" "),
                  _vm._l(_vm.dataShow, function(item) {
                    return _vm.dataShow && _vm.dataShow.length
                      ? _c(
                          "div",
                          [
                            _c("hr"),
                            _vm._v(" "),
                            item.hasOwnProperty("answer_form")
                              ? _c(
                                  "v-row",
                                  [
                                    _c("v-col", { attrs: { md: "3" } }, [
                                      _c("div", [
                                        _c("p", { staticClass: "mb-0" }, [
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(item.answer_form.name)
                                            )
                                          ]),
                                          _vm._v("への回答\n              ")
                                        ]),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "mb-0" }, [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                "(回答日" +
                                                  _vm
                                                    .moment(item.created_at)
                                                    .format(
                                                      "YYYY/MM/DD HH:mm:ss"
                                                    ) +
                                                  ")"
                                              ) +
                                              "\n              "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              "推定回答時間 ：" +
                                                item.convert_answer_time
                                            )
                                          )
                                        ])
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("v-col", { attrs: { md: "9" } }, [
                                      _c("p", [
                                        _c("b", [_vm._v("フォームの回答")])
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "table",
                                        {
                                          staticClass:
                                            "table table-bordered table-striped"
                                        },
                                        [
                                          _c(
                                            "tbody",
                                            [
                                              _vm._l(
                                                item.form_response,
                                                function(answer) {
                                                  return [
                                                    answer.hasOwnProperty(
                                                      "beautify_value"
                                                    ) &&
                                                    Array.isArray(
                                                      answer.beautify_value
                                                    ) &&
                                                    answer.beautify_value
                                                      .length > 0 &&
                                                    answer.beautify_value[0]
                                                      ? _c("tr", [
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                width: "40%"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  answer.input_title
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                width: "60%"
                                                              }
                                                            },
                                                            _vm._l(
                                                              answer.beautify_value,
                                                              function(val) {
                                                                return _c("p", [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        val
                                                                      ) +
                                                                      "\n                      "
                                                                  )
                                                                ])
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        ])
                                                      : _c("tr", [
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                width: "40%"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  answer.input_title
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                width: "60%"
                                                              }
                                                            },
                                                            [
                                                              answer.value &&
                                                              !Array.isArray(
                                                                answer.value
                                                              )
                                                                ? [
                                                                    typeof answer.value ==
                                                                    "object"
                                                                      ? [
                                                                          answer.value.hasOwnProperty(
                                                                            "upload_file_type"
                                                                          ) &&
                                                                          answer
                                                                            .value
                                                                            .upload_file_type ==
                                                                            "image"
                                                                            ? _c(
                                                                                "div",
                                                                                [
                                                                                  _c(
                                                                                    "img",
                                                                                    {
                                                                                      attrs: {
                                                                                        src:
                                                                                          answer
                                                                                            .value
                                                                                            .file
                                                                                            .preview,
                                                                                        alt:
                                                                                          ""
                                                                                      }
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "p",
                                                                                    [
                                                                                      _c(
                                                                                        "a",
                                                                                        {
                                                                                          attrs: {
                                                                                            href:
                                                                                              answer
                                                                                                .value
                                                                                                .file
                                                                                                .original
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.getNameImage(
                                                                                                answer
                                                                                                  .value
                                                                                                  .file
                                                                                                  .original
                                                                                              )
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          answer.value.hasOwnProperty(
                                                                            "upload_file_type"
                                                                          ) &&
                                                                          answer
                                                                            .value
                                                                            .upload_file_type ==
                                                                            "document"
                                                                            ? _c(
                                                                                "div",
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-file-document"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "p",
                                                                                    [
                                                                                      _c(
                                                                                        "a",
                                                                                        {
                                                                                          attrs: {
                                                                                            href:
                                                                                              answer
                                                                                                .value
                                                                                                .file
                                                                                                .original
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.getNameImage(
                                                                                                answer
                                                                                                  .value
                                                                                                  .file
                                                                                                  .original
                                                                                              )
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e()
                                                                        ]
                                                                      : [
                                                                          _vm._v(
                                                                            "\n                          " +
                                                                              _vm._s(
                                                                                answer.value
                                                                              ) +
                                                                              "\n                        "
                                                                          )
                                                                        ]
                                                                  ]
                                                                : _vm._e()
                                                            ],
                                                            2
                                                          )
                                                        ])
                                                  ]
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _vm.pagination.totalPage > 1
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "offset-4 justify-content-center",
                                  attrs: { md: "4" }
                                },
                                [
                                  _c("v-pagination", {
                                    attrs: {
                                      length: _vm.pagination.totalPage,
                                      "total-visible": 5
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.paginate($event)
                                      }
                                    },
                                    model: {
                                      value: _vm.pagination.page,
                                      callback: function($$v) {
                                        _vm.$set(_vm.pagination, "page", $$v)
                                      },
                                      expression: "pagination.page"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }