var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c("VfLoadingOverlayAbsolute", {
            attrs: { loading: _vm.$wait.is("processing") }
          }),
          _vm._v(" "),
          _c(
            "v-card-title",
            { staticClass: "pa-0" },
            [
              _c(
                "v-list-item",
                [
                  _c("v-list-item-content", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("lbl.friend.scenario")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    { staticClass: "scenario_caution" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("lbl.scenario.friend_show_caution")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.hasRole
                    ? _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-wait",
                            { attrs: { for: "processing" } },
                            [
                              _c("PtDialogSelect", {
                                attrs: {
                                  "select-type": "scenario",
                                  "single-select": true,
                                  "hide-folder": true,
                                  "only-have-timing": ""
                                },
                                on: { decide: _vm.save },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "opener",
                                      fn: function() {
                                        return [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { icon: "", outlined: "" }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-pencil")
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  602877845
                                ),
                                model: {
                                  value: _vm.scenario,
                                  callback: function($$v) {
                                    _vm.scenario = $$v
                                  },
                                  expression: "scenario"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("VDivider", { staticClass: "my-0" }),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _vm.internalValue.name
                ? _c("v-chip", { staticClass: "ma-2" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.internalValue.name) + "\n      "
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }