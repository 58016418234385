var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "mb-8" },
        [
          _c(
            "validation-observer",
            { ref: "observer" },
            [
              _c("VfLoadingOverlayAbsolute", {
                attrs: { loading: _vm.$wait.is("processing") }
              }),
              _vm._v(" "),
              _c("VfAlert", {
                attrs: { type: "error" },
                model: {
                  value: _vm.errorMsg,
                  callback: function($$v) {
                    _vm.errorMsg = $$v
                  },
                  expression: "errorMsg"
                }
              }),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { md: "6" } },
                        [
                          _c("VfRadioButton", {
                            attrs: {
                              label: _vm.$$tm(
                                "action_scenario.scenario_action_type"
                              ),
                              selects: _vm.scenarioActionType,
                              "label-hidden": ""
                            },
                            model: {
                              value: _vm.scenario.scenario_action_type,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.scenario,
                                  "scenario_action_type",
                                  $$v
                                )
                              },
                              expression: "scenario.scenario_action_type"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.scenario.scenario_action_type !== "cancel"
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("PtDialogSelect", {
                                attrs: {
                                  "select-type": "scenario",
                                  "single-select": true,
                                  "hide-folder": true,
                                  vees: { rules: "required" },
                                  "only-have-timing": ""
                                },
                                model: {
                                  value: _vm.scenario,
                                  callback: function($$v) {
                                    _vm.scenario = $$v
                                  },
                                  expression: "scenario"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.scenario.scenario_action_type !== "cancel" &&
                  _vm.scenario.scenario_id
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("PtDialogSelect", {
                                attrs: {
                                  "select-type": "scenario_timing",
                                  "single-select": true,
                                  "hide-folder": true,
                                  vees: { rules: "required" }
                                },
                                model: {
                                  value: _vm.scenario,
                                  callback: function($$v) {
                                    _vm.scenario = $$v
                                  },
                                  expression: "scenario"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("pt-friend-update-common-bottom", {
        attrs: {
          friends: _vm.friends,
          "total-count": _vm.totalCount,
          "alert-message": _vm.$t("lbl.confirm_friends_scenario_update")
        },
        on: { save: _vm.save },
        model: {
          value: _vm.allFriendsSelected,
          callback: function($$v) {
            _vm.allFriendsSelected = $$v
          },
          expression: "allFriendsSelected"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }