var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "mb-8" },
        [
          _c(
            "validation-observer",
            { ref: "observer" },
            [
              _c("VfLoadingOverlayAbsolute", {
                attrs: { loading: _vm.$wait.is("processing") }
              }),
              _vm._v(" "),
              _c("VfAlert", {
                attrs: { type: "error" },
                model: {
                  value: _vm.errorMsg,
                  callback: function($$v) {
                    _vm.errorMsg = $$v
                  },
                  expression: "errorMsg"
                }
              }),
              _vm._v(" "),
              _c(
                "v-card-title",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "hashtag_caution" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("lbl.hashtag.friend_show_caution")
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "6" } },
                        [
                          _c("PtDialogSelect", {
                            attrs: {
                              "select-type": "hashtag",
                              vees: { rules: "required" }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.action_group",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c("PtActionLabelMessages", {
                                        staticClass: "or-list",
                                        model: {
                                          value:
                                            item.action_group.make_action_arr,
                                          callback: function($$v) {
                                            _vm.$set(
                                              item.action_group,
                                              "make_action_arr",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.action_group.make_action_arr"
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.dialogValue,
                              callback: function($$v) {
                                _vm.dialogValue = $$v
                              },
                              expression: "dialogValue"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("pt-friend-update-hashtag-bottom", {
        attrs: {
          friends: _vm.friends,
          "total-count": _vm.totalCount,
          "alert-message": _vm.$t("lbl.confirm_friends_hashtag_update")
        },
        on: { attach: _vm.attach, detach: _vm.detach },
        model: {
          value: _vm.allFriendsSelected,
          callback: function($$v) {
            _vm.allFriendsSelected = $$v
          },
          expression: "allFriendsSelected"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }