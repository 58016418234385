var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.save($event)
            }
          }
        },
        [
          _c(
            "v-card",
            { attrs: { outlined: "" } },
            [
              _c("v-card-title", [_vm._v("テンプレート")]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-tabs",
                    {
                      model: {
                        value: _vm.selectedTab,
                        callback: function($$v) {
                          _vm.selectedTab = $$v
                        },
                        expression: "selectedTab"
                      }
                    },
                    _vm._l(_vm.tabItems, function(val, key) {
                      return _c("v-tab", { key: key }, [_vm._v(_vm._s(val))])
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.selectedTab,
                        callback: function($$v) {
                          _vm.selectedTab = $$v
                        },
                        expression: "selectedTab"
                      }
                    },
                    _vm._l(_vm.tabItems, function(val, key, tabIndex) {
                      return _c(
                        "v-tab-item",
                        { key: key },
                        [
                          _c(
                            "v-item-group",
                            { attrs: { mandatory: "" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                _vm._l(_vm.areaTemplates[tabIndex], function(
                                  layout
                                ) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: layout.value,
                                      attrs: { cols: "6", md: "1" }
                                    },
                                    [
                                      _c("v-item", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var toggle = ref.toggle
                                                return [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass: "pa-1 ml-3",
                                                      attrs: {
                                                        color:
                                                          layout.value ==
                                                          _vm.internalValue
                                                            .template_type
                                                            ? "primary"
                                                            : ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          toggle()
                                                          _vm.selectLayout(
                                                            layout.value
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("VImg", {
                                                        attrs: {
                                                          src: layout.image
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("PtDialogRichMenuTemplate", {
                attrs: {
                  detail: _vm.$t("confirm.template_done"),
                  "template-type": _vm.templateType,
                  "target-emit": "done-template"
                },
                on: { "done-template": _vm.applyTemplate },
                model: {
                  value: _vm.templateDialog,
                  callback: function($$v) {
                    _vm.templateDialog = $$v
                  },
                  expression: "templateDialog"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }