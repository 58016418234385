var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("VfAlert", {
        attrs: { type: "error" },
        model: {
          value: _vm.errorMsg,
          callback: function($$v) {
            _vm.errorMsg = $$v
          },
          expression: "errorMsg"
        }
      }),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { md: "6" } },
            [
              _c("VfRadioButton", {
                attrs: {
                  label: _vm.$$tm("action_reminder.reminder_action_type"),
                  vees: _vm.vees.reminder_action_type,
                  selects: _vm.opts.reminder_action_type,
                  "label-hidden": ""
                },
                model: {
                  value: _vm.internalValue.reminder_action_type,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "reminder_action_type", $$v)
                  },
                  expression: "internalValue.reminder_action_type"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "6" } },
            [
              _c("PtDialogSelect", {
                attrs: {
                  "select-type": "reminder",
                  "single-select": true,
                  vees: _vm.vees.name,
                  "only-have-timing": ""
                },
                model: {
                  value: _vm.internalValue,
                  callback: function($$v) {
                    _vm.internalValue = $$v
                  },
                  expression: "internalValue"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      !_vm.hideTimingReminder && _vm.shows.goalAt
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("VfDateTimeSelect", {
                    attrs: {
                      label: _vm.$$tm("action_reminder.goal_at"),
                      vees: _vm.vees.disp_st_at,
                      format: "YYYY/MM/DD HH:mm"
                    },
                    model: {
                      value: _vm.internalValue.goal_at,
                      callback: function($$v) {
                        _vm.$set(_vm.internalValue, "goal_at", $$v)
                      },
                      expression: "internalValue.goal_at"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }