var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mb-6" },
    [
      _c("v-card-title", [
        _vm._v("\n    " + _vm._s(_vm.$t("admin.payment_history")) + "\n  ")
      ]),
      _vm._v(" "),
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.items,
          "disable-sort": true,
          "items-per-page": 10,
          "footer-props": {
            itemsPerPageOptions: [10, 50, 100]
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.plan_name",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v("\n      " + _vm._s(item.plan_name) + "\n    ")]
              }
            },
            {
              key: "item.created_at",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$$fat(item.created_at)) + "\n    "
                  )
                ]
              }
            },
            {
              key: "item.payment_status",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$$te("payment_history.payment_status", item)) +
                      "\n    "
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }