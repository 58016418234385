var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    attrs: { name: _vm.label, rules: _vm.rules, vid: _vm.setVid },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var errors = ref.errors
          return [
            _c(
              "VTextField",
              _vm._b(
                {
                  attrs: {
                    "error-messages": errors,
                    type: "number",
                    min: _vm.minValue
                  },
                  on: {
                    change: _vm.onlyNumber,
                    input: function($event) {
                      return _vm.$emit("input", $event)
                    },
                    keypress: _vm.onlyNumber
                  },
                  model: {
                    value: _vm.internalValue,
                    callback: function($$v) {
                      _vm.internalValue = $$v
                    },
                    expression: "internalValue"
                  }
                },
                "VTextField",
                _vm.baseProps,
                false
              )
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }