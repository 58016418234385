var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": _vm.maxWidth },
      model: {
        value: _vm.internalValue,
        callback: function($$v) {
          _vm.internalValue = $$v
        },
        expression: "internalValue"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c("v-card-title", [
            _c("span", { staticClass: "text-h5" }, [_vm._v(_vm._s(_vm.title))])
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c("VfAlert", {
                    attrs: { type: "error" },
                    model: {
                      value: _vm.errorMsg,
                      callback: function($$v) {
                        _vm.errorMsg = $$v
                      },
                      expression: "errorMsg"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12", md: "12" } },
                        [
                          _c("div", { staticClass: "text-center or-break" }, [
                            _vm._v(_vm._s(_vm.detail))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-btn", { on: { click: _vm.close } }, [
                _vm._v(_vm._s(_vm.$t("btn.cancel")))
              ]),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.save } },
                [_vm._v(_vm._s(_vm.$t("btn.action")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }