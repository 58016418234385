var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return "id" in _vm.defDeli
    ? _c(
        "div",
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-center", attrs: { md: "8" } }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("lbl.same_time_send_max")) +
                    "\n    "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "text-right", attrs: { md: "4" } },
                [
                  _c(
                    "v-btn",
                    { staticClass: "m-1", on: { click: _vm.templateCopy } },
                    [_vm._v(_vm._s(_vm.$t("btn.template_copy")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "m-1",
                      on: {
                        click: function($event) {
                          _vm.preview = !_vm.preview
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("btn.preview")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.internalValue, function(deli, deliIndex) {
            return _c(
              "v-row",
              { key: deliIndex },
              [
                _c(
                  "v-col",
                  { attrs: { md: _vm.colMain } },
                  [
                    _c(
                      "v-card",
                      {
                        key: deliIndex,
                        staticClass: "mb-4",
                        attrs: { outlined: "" }
                      },
                      [
                        _c(
                          "v-tabs",
                          {
                            attrs: { grow: "" },
                            on: {
                              change: function($event) {
                                return _vm.tabChange(
                                  deliIndex,
                                  _vm.selectTab[deliIndex]
                                )
                              }
                            },
                            model: {
                              value: _vm.selectTab[deliIndex],
                              callback: function($$v) {
                                _vm.$set(_vm.selectTab, deliIndex, $$v)
                              },
                              expression: "selectTab[deliIndex]"
                            }
                          },
                          _vm._l(_vm.opts.deli_types, function(key, val) {
                            return _c("v-tab", { key: key }, [
                              _vm._v(_vm._s(val))
                            ])
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tabs-items",
                          {
                            on: {
                              change: function($event) {
                                return _vm.tabChange(
                                  deliIndex,
                                  _vm.selectTab[deliIndex]
                                )
                              }
                            },
                            model: {
                              value: _vm.selectTab[deliIndex],
                              callback: function($$v) {
                                _vm.$set(_vm.selectTab, deliIndex, $$v)
                              },
                              expression: "selectTab[deliIndex]"
                            }
                          },
                          _vm._l(_vm.opts.deli_types, function(tab) {
                            return _c(
                              "v-tab-item",
                              { key: tab },
                              [
                                deli.deli_type === "text"
                                  ? _c("PtDeliText", {
                                      key: deliIndex,
                                      attrs: { vees: _vm.allVees.deli_text },
                                      model: {
                                        value: deli.deli_text,
                                        callback: function($$v) {
                                          _vm.$set(deli, "deli_text", $$v)
                                        },
                                        expression: "deli.deli_text"
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                deli.deli_type === "sticker"
                                  ? _c("PtDeliSticker", {
                                      key: deliIndex,
                                      attrs: {
                                        vees: _vm.allVees.deli_sticker,
                                        stickers: _vm.opts.stickers
                                      },
                                      model: {
                                        value: deli.deli_sticker,
                                        callback: function($$v) {
                                          _vm.$set(deli, "deli_sticker", $$v)
                                        },
                                        expression: "deli.deli_sticker"
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                deli.deli_type === "image"
                                  ? _c("PtDeliImage", {
                                      key: deliIndex,
                                      attrs: {
                                        vees: _vm.allVees.deli_image,
                                        "vee-links":
                                          _vm.allVees.deli_image_link,
                                        "link-types": _vm.opts.link_types,
                                        "image-link-actions":
                                          _vm.opts.image_link_actions,
                                        "link-layouts": _vm.opts.link_layouts
                                      },
                                      model: {
                                        value: deli.deli_image,
                                        callback: function($$v) {
                                          _vm.$set(deli, "deli_image", $$v)
                                        },
                                        expression: "deli.deli_image"
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                deli.deli_type === "video"
                                  ? _c("PtDeliVideo", {
                                      key: deliIndex,
                                      attrs: { vees: _vm.allVees.deli_video },
                                      model: {
                                        value: deli.deli_video,
                                        callback: function($$v) {
                                          _vm.$set(deli, "deli_video", $$v)
                                        },
                                        expression: "deli.deli_video"
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                deli.deli_type === "audio"
                                  ? _c("PtDeliAudio", {
                                      key: deliIndex,
                                      attrs: { vees: _vm.allVees.deli_audio },
                                      model: {
                                        value: deli.deli_audio,
                                        callback: function($$v) {
                                          _vm.$set(deli, "deli_audio", $$v)
                                        },
                                        expression: "deli.deli_audio"
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                deli.deli_type === "question"
                                  ? _c("PtDeliQuestion", {
                                      key: deliIndex,
                                      attrs: {
                                        vees: _vm.allVees.deli_question,
                                        "vees-choice":
                                          _vm.allVees.deli_question_choice,
                                        "choice-action-types":
                                          _vm.opts.choice_action_types,
                                        "url-open-types":
                                          _vm.opts.url_open_types,
                                        "multi-text-validates":
                                          _vm.opts.multi_text_validates
                                      },
                                      model: {
                                        value: deli.deli_question,
                                        callback: function($$v) {
                                          _vm.$set(deli, "deli_question", $$v)
                                        },
                                        expression: "deli.deli_question"
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                deli.deli_type === "carousel"
                                  ? _c("PtDeliCarousel", {
                                      key: deliIndex,
                                      attrs: {
                                        vees: _vm.allVees.deli_carousel,
                                        "vees-panel":
                                          _vm.allVees.deli_carousel_panel,
                                        "vees-choice":
                                          _vm.allVees
                                            .deli_carousel_panel_choice,
                                        "choice-action-types":
                                          _vm.opts.choice_action_types,
                                        "url-open-types":
                                          _vm.opts.url_open_types,
                                        "multi-text-validates":
                                          _vm.opts.multi_text_validates,
                                        "init-carousel-panel":
                                          _vm.opts.init_carousel_panel,
                                        "init-carousel-panel-choice":
                                          _vm.opts.init_carousel_panel_choice
                                      },
                                      model: {
                                        value: deli.deli_carousel,
                                        callback: function($$v) {
                                          _vm.$set(deli, "deli_carousel", $$v)
                                        },
                                        expression: "deli.deli_carousel"
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                deli.deli_type === "location"
                                  ? _c("PtDeliLocation", {
                                      key: deliIndex,
                                      attrs: {
                                        vees: _vm.allVees.deli_location
                                      },
                                      model: {
                                        value: deli.deli_location,
                                        callback: function($$v) {
                                          _vm.$set(deli, "deli_location", $$v)
                                        },
                                        expression: "deli.deli_location"
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                deli.deli_type === "introduction"
                                  ? _c("PtDeliIntroduction", {
                                      key: deliIndex,
                                      attrs: {
                                        vees: _vm.allVees.deli_introduction
                                      },
                                      model: {
                                        value: deli.deli_introduction,
                                        callback: function($$v) {
                                          _vm.$set(
                                            deli,
                                            "deli_introduction",
                                            $$v
                                          )
                                        },
                                        expression: "deli.deli_introduction"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-card-actions",
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t("lbl.send_time", {
                                    count: deliIndex + 1
                                  })
                                ) +
                                "\n          "
                            ),
                            _c("v-spacer"),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  disabled:
                                    _vm.internalValue.length <= 1 ||
                                    deliIndex === 0,
                                  small: "",
                                  icon: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.sortMessage(deliIndex, -1)
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("mdi-arrow-collapse-up")])],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  disabled:
                                    _vm.internalValue.length <= 1 ||
                                    deliIndex === _vm.internalValue.length - 1,
                                  small: "",
                                  icon: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.sortMessage(deliIndex, 0)
                                  }
                                }
                              },
                              [
                                _c("v-icon", [
                                  _vm._v("mdi-arrow-collapse-down")
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  disabled: _vm.internalValue.length >= 5,
                                  small: "",
                                  icon: "",
                                  color: "primary"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.appendMessage(deliIndex)
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("mdi-plus")])],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  disabled: _vm.internalValue.length <= 1,
                                  small: "",
                                  icon: "",
                                  color: "error"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteConfirm(deliIndex)
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("mdi-close")])],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.preview
                  ? _c(
                      "v-col",
                      { attrs: { md: _vm.colSub } },
                      [
                        _c(
                          "v-card",
                          { attrs: { outlined: "" } },
                          [
                            _c("v-card-title", [_vm._v("preview")]),
                            _vm._v(" "),
                            _c(
                              "v-card-text",
                              [
                                _c("PtLinePreviewRow", {
                                  attrs: { deli: _vm.internalValue[deliIndex] }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": 300 },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c("v-card-title", [
                    _vm._v(_vm._s(_vm.$t("confirm_title.delete")))
                  ]),
                  _vm._v(" "),
                  _c("v-card-text", [_vm._v(_vm._s(_vm.$t("confirm.delete")))]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function($event) {
                              _vm.dialog = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("btn.cancel")))]
                      ),
                      _vm._v(" "),
                      _c("v-btn", { on: { click: _vm.deleteMessage } }, [
                        _vm._v(_vm._s(_vm.$t("btn.delete")))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": 800 },
              model: {
                value: _vm.selectDialog,
                callback: function($$v) {
                  _vm.selectDialog = $$v
                },
                expression: "selectDialog"
              }
            },
            [
              _c("PtDeliSelectFixedPhrase", {
                ref: "ptselector",
                on: {
                  "decide-item": _vm.decideItem,
                  "cancel-item": _vm.cancelItem
                },
                model: {
                  value: _vm.selectFixedPhrase,
                  callback: function($$v) {
                    _vm.selectFixedPhrase = $$v
                  },
                  expression: "selectFixedPhrase"
                }
              })
            ],
            1
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }