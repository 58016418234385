var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "pa-0" },
        [
          _c(
            "v-list-item",
            [
              _c("v-list-item-content", [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("account.user")) + "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-list-item-action",
                [
                  _c("btn-dialog-user-edit", {
                    attrs: { user: _vm.user, vees: _vm.vees },
                    on: { save: _vm.save }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _c("v-simple-table", [
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$$tm("user.email")))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.user.email))])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$$tm("user.name")))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.user.name))])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$$tm("user.phone")))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.user.phone))])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("lbl.created_at")))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.$$fat(_vm.user.confirmed_at)))])
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }