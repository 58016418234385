var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showHeader
    ? _c(
        "v-app-bar",
        {
          attrs: {
            "clipped-left": _vm.$vuetify.breakpoint.lgAndUp,
            app: "",
            color: "blue-grey darken-4",
            dark: "",
            flat: ""
          }
        },
        [
          _vm.canShow
            ? _c("v-app-bar-nav-icon", {
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    _vm.drawer = !_vm.drawer
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("v-toolbar-title", { staticClass: "ml-0 pl-4" }, [
            _c("div", { staticClass: "font-weight-black hidden-sm-and-down" }, [
              _c("img", {
                staticClass: "logo-header",
                attrs: { src: require("images/talk_label_logo.png") },
                on: { click: _vm.clickLogo }
              })
            ])
          ]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          [
            _vm.showBookingTitle
              ? _c("p", { staticClass: "app-bar__logo" }, [
                  _vm._v("\n      " + _vm._s(_vm.showBookingTitle) + "\n    ")
                ])
              : _vm._e()
          ],
          _vm._v(" "),
          _vm.canShow && !_vm.isAdmin
            ? [
                _c(
                  "v-btn",
                  {
                    attrs: { text: "" },
                    on: {
                      click: function($event) {
                        return _vm.$router.push({ name: "AccountShow" })
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "hidden-sm-and-down" }, [
                      _vm._v(_vm._s(_vm.user_name))
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    attrs: { text: "" },
                    on: {
                      click: function($event) {
                        return _vm.$router.push({ name: "BaseHome" })
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "hidden-sm-and-down" }, [
                      _vm._v(_vm._s(_vm.channel_name))
                    ])
                  ]
                )
              ]
            : [
                _vm.$store.getters.user != null
                  ? _c(
                      "v-btn",
                      { attrs: { text: "" }, on: { click: _vm.logout } },
                      [_vm._v(_vm._s(_vm.$t("btn.logout")))]
                    )
                  : _vm._e()
              ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }