<template>
  <validation-provider
    v-slot="{ errors }"
    :name="label"
    :rules="rules"
    :vid="setVid"
  >
    <VTextField
      v-model="internalValue"
      v-bind="baseProps"
      :error-messages="errors"
      :append-icon="appendIcon"
      :type="textType"
      :placeholder="placeholder"
      :min="minValue"
      @click:append="clickIcon"
      @input="$emit('input', $event)"
    >
      <template v-if="hideLabel" v-slot:label>
        <span></span>
      </template>
    </VTextField>
  </validation-provider>
</template>
<script>
import mixin from 'components/vuetifyParts/commonExport'
export default {
  mixins: [mixin],
  props: {
    value: { default: null },
    placeholder: { default: '' },
    password: { type: Boolean, default: false },
    number: { type: Boolean, default: false },
    min: { type: Number, default: null },
    hideLabel: { type: Boolean, default: false },
  },
  data: () => ({
    showPassword: false,
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    appendIcon() {
      let icon = null
      if (this.password) {
        if (this.showPassword) {
          icon = 'mdi-eye'
        } else {
          icon = 'mdi-eye-off'
        }
      }
      return icon
    },
    textType() {
      let type = 'text'
      if (this.number) {
        type = 'number'
      }
      if (this.password && !this.showPassword) {
        type = 'password'
      }
      return type
    },
    minValue() {
      return this.number && typeof this.min !== 'undefined' ? this.min : ''
    },
    setVid() {
      return Math.random().toString()
    },
  },
  methods: {
    clickIcon() {
      if (this.password) {
        this.showPassword = !this.showPassword
      }
    },
  },
}
</script>
