<template>
  <v-card outlined>
    <v-card-title class="pa-0">
      <v-list-item>
        <v-list-item-content>
          {{ $t('lbl.channel.info') }}
        </v-list-item-content>
        <v-list-item-action>
          <btn-dialog-channel-form
            v-model="cloneChannel"
            :vees="vees"
            is-user
            @click="initCloneChannel"
            @save="save"
          />
        </v-list-item-action>
      </v-list-item>
    </v-card-title>
    <v-card-text>
      <VfTextField
        v-model="channel.name"
        :label="$$tm('channel.name')"
        disabled
      />
      <VfTextField
        v-model="channel.line_account_id"
        :label="$$tm('channel.line_account_id')"
        disabled
      />
      <VfTextField
        v-model="channel.line_channel_id"
        :label="$$tm('channel.line_channel_id')"
        disabled
      />
      <VfTextField
        value="********"
        :label="$$tm('channel.line_channel_secret_id')"
        disabled
      />
      <VfTextField
        v-model="channel.line_login_channel_id"
        :label="$$tm('channel.line_login_channel_id')"
        disabled
      />
      <VfTextField
        value="********"
        :label="$$tm('channel.line_login_channel_secret_id')"
        disabled
      />
      <v-text-field v-model="webhookUrl" label="WebHook" readonly>
        <template #append-outer>
          <v-icon
            v-clipboard:copy="webhookUrl"
            v-clipboard:success="onClipCopy"
            v-clipboard:error="onClipError"
            class="p-0 clipboard-icon"
            >mdi-content-copy</v-icon
          >
        </template>
      </v-text-field>
      <v-text-field
        v-model="channel.reservation_liff_booking_url"
        label="Reservation Liff Booking Url"
        readonly
      >
        <template #append-outer>
          <v-icon
            v-clipboard:copy="channel.reservation_liff_booking_url"
            v-clipboard:success="onClipCopy"
            v-clipboard:error="onClipError"
            class="p-0 clipboard-icon"
            >mdi-content-copy</v-icon
          >
        </template>
      </v-text-field>
      <v-text-field
        v-model="channel.reservation_liff_history_url"
        label="Reservation Liff History Url"
        readonly
      >
        <template #append-outer>
          <v-icon
            v-clipboard:copy="channel.reservation_liff_history_url"
            v-clipboard:success="onClipCopy"
            v-clipboard:error="onClipError"
            class="p-0 clipboard-icon"
            >mdi-content-copy</v-icon
          >
        </template>
      </v-text-field>
    </v-card-text>
  </v-card>
</template>
<script>
import BtnDialogChannelForm from './BtnDialogChannelForm'
export default {
  components: {
    BtnDialogChannelForm,
  },
  props: {
    channel: { type: Object, required: true },
    vees: { type: Object, required: true },
  },
  data: () => ({
    cloneChannel: {},
  }),
  computed: {
    webhookUrl: {
      get() {
        const baseUrl = `${window.location.protocol}//${window.location.host}`
        return `${baseUrl}/api/v1/line_webhook/${this.channel.id}`
      },
    },
  },
  methods: {
    initCloneChannel() {
      this.cloneChannel = this.$$clone(this.channel)
    },
    save() {
      this.$emit('save', this.cloneChannel)
    },
    copy() {
      console.log('copy')
    },
    onClipCopy() {
      this.$toasted.success('コピーしました')
    },
    onClipError() {
      this.$toasted.success('error')
    },
  },
}
</script>
<style scoped>
.clipboard-icon {
  cursor: pointer;
}
</style>
