var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [_c("v-col", { attrs: { cols: "12" } }, [_c("PtTitle")], 1)],
        1
      ),
      _vm._v(" "),
      _vm.user.id
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("user-base-info", {
                    staticClass: "mb-4",
                    attrs: { user: _vm.user, vees: _vm.vees.user },
                    on: { save: _vm.userSave }
                  }),
                  _vm._v(" "),
                  _c("user-address", {
                    staticClass: "mb-4",
                    attrs: {
                      "user-address": _vm.user.user_address,
                      vees: _vm.vees.user_address,
                      opts: _vm.opts
                    },
                    on: { save: _vm.userAddressSave }
                  }),
                  _vm._v(" "),
                  _c("password-edit", {
                    staticClass: "mb-4",
                    on: { save: _vm.passwordSave }
                  }),
                  _vm._v(" "),
                  _c("staff-code-entry", {
                    staticClass: "mb-4",
                    on: { save: _vm.register }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("channel-select-index", {
                    staticClass: "mb-4",
                    attrs: { channels: _vm.user.channels },
                    on: { select: _vm.selectChannel }
                  }),
                  _vm._v(" "),
                  _c("mobile-attach-code", {
                    staticClass: "mb-4",
                    attrs: { user: _vm.user },
                    on: {
                      save: _vm.getSpLinkCode,
                      destroy: _vm.destroySpLinkCode
                    }
                  }),
                  _vm._v(" "),
                  _c("line-notify-setting", { staticClass: "mb-4" })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }