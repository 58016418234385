var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": 800 },
          model: {
            value: _vm.internalValue,
            callback: function($$v) {
              _vm.internalValue = $$v
            },
            expression: "internalValue"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("VfLoadingOverlayAbsolute", {
                attrs: { loading: _vm.loading }
              }),
              _vm._v(" "),
              _c("VfAlert", {
                attrs: { type: "error" },
                model: {
                  value: _vm.errorMsg,
                  callback: function($$v) {
                    _vm.errorMsg = $$v
                  },
                  expression: "errorMsg"
                }
              }),
              _vm._v(" "),
              _c("v-card-title", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("btn.select_" + _vm.uploadFileType)) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: { grow: "" },
                      model: {
                        value: _vm.selectTab,
                        callback: function($$v) {
                          _vm.selectTab = $$v
                        },
                        expression: "selectTab"
                      }
                    },
                    [
                      _c("v-tab", { key: "newUploadFile" }, [
                        _vm._v(_vm._s(_vm.$t("btn.new_upload_file")))
                      ]),
                      _vm._v(" "),
                      _c("v-tab", { key: "selectUploadFile" }, [
                        _vm._v(_vm._s(_vm.$t("btn.select_upload_file")))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.selectTab,
                        callback: function($$v) {
                          _vm.selectTab = $$v
                        },
                        expression: "selectTab"
                      }
                    },
                    [
                      _c(
                        "v-tab-item",
                        { key: "newUploadFileTab" },
                        [
                          _c("PtFilePondForUploadFile", {
                            attrs: {
                              name: _vm.uploadFileType,
                              "init-path": "/api/v1/upload_files",
                              "init-prms": {
                                upload_file_type: _vm.uploadFileType
                              },
                              "allow-multiple": false,
                              "max-file-size":
                                _vm.deliTypeOpts["max-file-size"],
                              "accepted-file-types":
                                _vm.deliTypeOpts["accepted-file-types"],
                              "allow-image-preview":
                                _vm.deliTypeOpts["allow-image-preview"]
                            },
                            on: { "upload-success": _vm.responseSelectedFile }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tab-item",
                        { key: "selectUploadFileTab" },
                        [
                          _c(
                            "v-item-group",
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: { "no-gutters": "", justify: "center" }
                                },
                                _vm._l(_vm.uploadFiles, function(uploadFile) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: uploadFile.id,
                                      staticClass: "text-center",
                                      attrs: { md: _vm.deliTypeOpts["md"] }
                                    },
                                    [
                                      _c("v-item", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var active = ref.active
                                                var toggle = ref.toggle
                                                return [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass: "pa-1",
                                                      attrs: {
                                                        color: active
                                                          ? "primary"
                                                          : ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          toggle()
                                                          _vm.selectFile(
                                                            uploadFile
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm.uploadFileType ===
                                                      "image"
                                                        ? _c("VImg", {
                                                            attrs: {
                                                              src:
                                                                uploadFile.preview_url
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.uploadFileType ===
                                                      "rich_menu_image"
                                                        ? _c("VImg", {
                                                            attrs: {
                                                              src:
                                                                uploadFile.preview_url
                                                            }
                                                          })
                                                        : _vm.uploadFileType ===
                                                          "video"
                                                        ? _c("PtVideo", {
                                                            attrs: {
                                                              width: 200,
                                                              height: 200,
                                                              src:
                                                                uploadFile.video_url
                                                            }
                                                          })
                                                        : _vm.uploadFileType ===
                                                          "audio"
                                                        ? _c("PtVideo", {
                                                            attrs: {
                                                              width: 200,
                                                              height: 100,
                                                              src:
                                                                uploadFile.audio_url
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "text-right" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.responseSelectedFile(
                                                _vm.selectedFile
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("btn.select")))]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }