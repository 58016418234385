var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "mb-8" },
        [
          _c(
            "v-card-text",
            [
              _c("VfLoadingOverlayAbsolute", {
                attrs: { loading: _vm.$wait.is("processing") }
              }),
              _vm._v(" "),
              _c("VfAlert", {
                attrs: { type: "error" },
                model: {
                  value: _vm.errorMsg,
                  callback: function($$v) {
                    _vm.errorMsg = $$v
                  },
                  expression: "errorMsg"
                }
              }),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-0", attrs: { md: "4" } },
                    [
                      _c("VfSelect", {
                        attrs: {
                          label: _vm.$$tm("friend.message_status"),
                          selects: _vm.selects
                        },
                        model: {
                          value: _vm.messageStatus,
                          callback: function($$v) {
                            _vm.messageStatus = $$v
                          },
                          expression: "messageStatus"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("pt-friend-update-common-bottom", {
        attrs: {
          friends: _vm.friends,
          "total-count": _vm.totalCount,
          "alert-message": _vm.$t("lbl.confirm_friends_status_update")
        },
        on: { save: _vm.save },
        model: {
          value: _vm.allFriendsSelected,
          callback: function($$v) {
            _vm.allFriendsSelected = $$v
          },
          expression: "allFriendsSelected"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }