var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "10" } }, [_c("PtTitle")], 1),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "2" } }, [
            _c(
              "div",
              { staticStyle: { "text-align": "right", "margin-top": "20px" } },
              [
                _vm.autoAnswerId !== "new"
                  ? _c(
                      "v-btn",
                      { attrs: { color: "error" }, on: { click: _vm.destroy } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("btn.delete")) +
                            "\n        "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.initDone
        ? _c(
            "validation-observer",
            { ref: "observer" },
            [
              _c(
                "v-card",
                { staticClass: "mb-6" },
                [
                  _c("v-card-title", { staticClass: "or-card-title mb-6" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("lbl.auto_answer.info")) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "5" } },
                            [
                              _c("VfSelect", {
                                attrs: {
                                  label: _vm.$$tm("folder.name"),
                                  vees: _vm.vees.folder_id,
                                  selects: _vm.folders
                                },
                                model: {
                                  value: _vm.autoAnswer.folder_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.autoAnswer, "folder_id", $$v)
                                  },
                                  expression: "autoAnswer.folder_id"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { md: "5" } },
                            [
                              _c("VfTextField", {
                                attrs: {
                                  label: _vm.$$tm("auto_answer.name"),
                                  vees: _vm.vees.name
                                },
                                model: {
                                  value: _vm.autoAnswer.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.autoAnswer, "name", $$v)
                                  },
                                  expression: "autoAnswer.name"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { md: "2" } },
                            [
                              _c("VfSwitch", {
                                attrs: {
                                  label: _vm.$$tm("auto_answer.active_flg"),
                                  vees: _vm.vees.active_flg
                                },
                                model: {
                                  value: _vm.autoAnswer.active_flg,
                                  callback: function($$v) {
                                    _vm.$set(_vm.autoAnswer, "active_flg", $$v)
                                  },
                                  expression: "autoAnswer.active_flg"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "mb-6" },
                [
                  _c("v-card-title", { staticClass: "or-card-title mb-6" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("lbl.auto_answer.response_keyword")) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c("VfSwitch", {
                        attrs: {
                          label: _vm.$$tm("auto_answer.no_keyword_flg"),
                          vees: _vm.vees.no_keyword_flg
                        },
                        model: {
                          value: _vm.autoAnswer.no_keyword_flg,
                          callback: function($$v) {
                            _vm.$set(_vm.autoAnswer, "no_keyword_flg", $$v)
                          },
                          expression: "autoAnswer.no_keyword_flg"
                        }
                      }),
                      _vm._v(" "),
                      !_vm.autoAnswer.no_keyword_flg
                        ? _c(
                            "v-card",
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("VfRadioButton", {
                                    attrs: {
                                      label: _vm.$$tm(
                                        "auto_answer.keyword_match_type"
                                      ),
                                      vees: _vm.vees.keyword_match_type,
                                      selects: _vm.opts.keyword_match_type,
                                      "label-hidden": ""
                                    },
                                    model: {
                                      value: _vm.autoAnswer.keyword_match_type,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.autoAnswer,
                                          "keyword_match_type",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "autoAnswer.keyword_match_type"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "v-alert",
                                    {
                                      staticClass: "or-break",
                                      staticStyle: { "font-size": "12px" },
                                      attrs: {
                                        border: "left",
                                        "colored-border": "",
                                        color: "deep-purple accent-4",
                                        elevation: "2"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "lbl.auto_answer.auto_answer_match_type_desc"
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.autoAnswer.auto_answer_reacts,
                                    function(react, react_i) {
                                      return _c(
                                        "v-card",
                                        { key: react_i, staticClass: "mb-4" },
                                        [
                                          _c(
                                            "v-card-text",
                                            { staticClass: "py-0" },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { md: "8" } },
                                                    [
                                                      _c("VfTextField", {
                                                        attrs: {
                                                          label: _vm.$$tm(
                                                            "auto_answer_react.keyword"
                                                          ),
                                                          vees:
                                                            _vm.veesReact
                                                              .keyword
                                                        },
                                                        model: {
                                                          value: react.keyword,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              react,
                                                              "keyword",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "react.keyword"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { md: "3" } },
                                                    [
                                                      _c("VfSelect", {
                                                        attrs: {
                                                          label: _vm.$$tm(
                                                            "auto_answer_react.react_match_type"
                                                          ),
                                                          vees:
                                                            _vm.veesReact
                                                              .react_match_type,
                                                          selects:
                                                            _vm.opts
                                                              .react_match_type,
                                                          "label-hidden": ""
                                                        },
                                                        model: {
                                                          value:
                                                            react.react_match_type,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              react,
                                                              "react_match_type",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "react.react_match_type"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { md: "1" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "mt-4",
                                                          attrs: {
                                                            icon: "",
                                                            disabled:
                                                              _vm.autoAnswer
                                                                .auto_answer_reacts
                                                                .length <= 1
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.removeReact(
                                                                react_i
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-close-circle"
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { color: "primary" },
                                              on: { click: _vm.addReact }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [
                                                  _vm._v(
                                                    "mdi-plus-circle-outline"
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "btn.add_react_keyword"
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              staticClass: "or-break",
                                              staticStyle: {
                                                "font-size": "12px"
                                              },
                                              attrs: {
                                                border: "left",
                                                "colored-border": "",
                                                color: "deep-purple accent-4",
                                                elevation: "2"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "lbl.auto_answer.auto_answer_desc"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "mb-6" },
                [
                  _c("v-card-title", { staticClass: "or-card-title mb-6" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("lbl.auto_answer.set_datetime")) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c("VfSwitch", {
                        attrs: {
                          label: _vm.$$tm("auto_answer.no_datetime_flg"),
                          vees: _vm.vees.no_datetime_flg
                        },
                        model: {
                          value: _vm.autoAnswer.no_datetime_flg,
                          callback: function($$v) {
                            _vm.$set(_vm.autoAnswer, "no_datetime_flg", $$v)
                          },
                          expression: "autoAnswer.no_datetime_flg"
                        }
                      }),
                      _vm._v(" "),
                      !_vm.autoAnswer.no_datetime_flg
                        ? _c(
                            "v-card",
                            [
                              _c("v-card-title", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("lbl.auto_answer.day_of_the_week")
                                    ) +
                                    "\n          "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                { attrs: { id: "day_of_the_week" } },
                                [
                                  _c("VfCheckbox", {
                                    attrs: {
                                      label: _vm.$t("lbl.auto_answer.all_day"),
                                      indeterminate: _vm.indeterminate
                                    },
                                    model: {
                                      value: _vm.allSelected,
                                      callback: function($$v) {
                                        _vm.allSelected = $$v
                                      },
                                      expression: "allSelected"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("table", [
                                    _c(
                                      "tr",
                                      _vm._l(_vm.DAYS, function(day) {
                                        return _c("td", { key: day }, [
                                          _c(
                                            "div",
                                            { staticClass: "mr-6" },
                                            [
                                              _c("VfCheckbox", {
                                                attrs: {
                                                  label: _vm.$$tm(
                                                    "auto_answer." + day
                                                  )
                                                },
                                                model: {
                                                  value: _vm.autoAnswer[day],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.autoAnswer,
                                                      day,
                                                      $$v
                                                    )
                                                  },
                                                  expression: "autoAnswer[day]"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ])
                                      }),
                                      0
                                    )
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("VDivider"),
                              _vm._v(" "),
                              _c("v-card-title", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("lbl.auto_answer.time")) +
                                    "\n          "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                [
                                  _c("VfSwitch", {
                                    attrs: {
                                      label: _vm.$$tm(
                                        "auto_answer.time_select_flg"
                                      ),
                                      vees: _vm.vees.time_select_flg
                                    },
                                    model: {
                                      value: _vm.autoAnswer.time_select_flg,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.autoAnswer,
                                          "time_select_flg",
                                          $$v
                                        )
                                      },
                                      expression: "autoAnswer.time_select_flg"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.autoAnswer.time_select_flg
                                    ? _c("table", [
                                        _c("tr", [
                                          _c("td", [
                                            _c(
                                              "div",
                                              [
                                                _c("VfDateTimeSelect", {
                                                  attrs: {
                                                    label: "開始時",
                                                    format: "HH:mm"
                                                  },
                                                  model: {
                                                    value: _vm.autoAnswer.st_tm,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.autoAnswer,
                                                        "st_tm",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "autoAnswer.st_tm"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c("div", { staticClass: "mx-2" }, [
                                              _vm._v("〜")
                                            ])
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c(
                                              "div",
                                              [
                                                _c("VfDateTimeSelect", {
                                                  attrs: {
                                                    label: "終了時",
                                                    format: "HH:mm"
                                                  },
                                                  model: {
                                                    value: _vm.autoAnswer.ed_tm,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.autoAnswer,
                                                        "ed_tm",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "autoAnswer.ed_tm"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ])
                                        ])
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "mb-6" },
                [
                  _c(
                    "div",
                    { staticClass: "or-card-title mb-6" },
                    [
                      _c("v-card-title", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("lbl.auto_answer.search")) +
                            "\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-card-subtitle", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("lbl.auto_answer.search_desc")) +
                            "\n        "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c("PtSearchSelectedList", {
                        model: {
                          value: _vm.autoAnswer.target_search_wrap,
                          callback: function($$v) {
                            _vm.$set(_vm.autoAnswer, "target_search_wrap", $$v)
                          },
                          expression: "autoAnswer.target_search_wrap"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "mb-6" },
                [
                  _c("v-card-title", { staticClass: "or-card-title mb-6" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("lbl.auto_answer.action")) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c("PtActionSelectedList", {
                        model: {
                          value: _vm.autoAnswer.action_arr,
                          callback: function($$v) {
                            _vm.$set(_vm.autoAnswer, "action_arr", $$v)
                          },
                          expression: "autoAnswer.action_arr"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$router.push({
                                name: "AutoAnswerIndex"
                              })
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("btn.back")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.$wait.is("processing")
                          },
                          on: { click: _vm.save }
                        },
                        [_vm._v(_vm._s(_vm.$t("btn.create")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("VfDialogConfirm", {
        attrs: {
          title: _vm.$t("confirm_title.delete"),
          detail: _vm.$t("confirm.delete"),
          "save-path": _vm.savePath,
          "form-type": "delete",
          "target-emit": "delete-success"
        },
        on: { "delete-success": _vm.deleteSuccess },
        model: {
          value: _vm.deleteDialog,
          callback: function($$v) {
            _vm.deleteDialog = $$v
          },
          expression: "deleteDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }