var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "8" } }, [_c("PtTitle")], 1),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pt-8", attrs: { cols: "4", align: "right" } },
            [
              _c(
                "v-btn",
                {
                  on: {
                    click: function($event) {
                      return _vm.$router.push({ name: "AdminPlanIndex" })
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("btn.index")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "mb-4" }, [
        _vm._v(_vm._s(_vm.$t("lbl.change_plan_impact")))
      ]),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "pb-1" },
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.items,
              "item-key": "plan_role_type",
              "show-select": "",
              "disable-sort": "",
              "hide-default-footer": "",
              "items-per-page": -1
            },
            on: { "click:row": _vm.rowClick },
            model: {
              value: _vm.selectedItems,
              callback: function($$v) {
                _vm.selectedItems = $$v
              },
              expression: "selectedItems"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { align: "right" } },
            [
              _c(
                "v-btn",
                {
                  on: {
                    click: function($event) {
                      return _vm.$router.push({ name: "AdminPlanIndex" })
                    }
                  }
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("btn.back")) + "\n      ")]
              ),
              _vm._v(" "),
              _c("dialog-confirm", { on: { save: _vm.save } })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }