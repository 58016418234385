var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { md: "6" } },
            [
              _c("PtDialogSelect", {
                attrs: {
                  "select-type": "answer_form",
                  "single-select": true,
                  vees: _vm.vees.answer_form_id
                },
                model: {
                  value: _vm.internalValue,
                  callback: function($$v) {
                    _vm.internalValue = $$v
                  },
                  expression: "internalValue"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", md: "6" } },
            [
              _c("VfSelect", {
                attrs: {
                  label: _vm.$$tm("search_answer_form.search_answer_form_type"),
                  selects: _vm.opts.search_answer_form_type,
                  vees: _vm.vees.search_answer_form_type
                },
                model: {
                  value: _vm.internalValue.search_answer_form_type,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "search_answer_form_type", $$v)
                  },
                  expression: "internalValue.search_answer_form_type"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }