var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasDisplayTabs
    ? _c(
        "div",
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("lbl.friend.all_update")) +
                    "\n    "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: { grow: "" },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    _vm._l(_vm.items, function(item) {
                      return _c("v-tab", { key: item.value }, [
                        _vm._v(
                          "\n          " + _vm._s(item.text) + "\n        "
                        )
                      ])
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _vm.hasRole.friend_edit
                        ? _c(
                            "v-tab-item",
                            [
                              _c("PtFriendUpdateStatus", {
                                attrs: {
                                  friends: _vm.friends,
                                  "checked-friends": _vm.checkedItems,
                                  "search-wrap": _vm.searchWrap,
                                  "total-count": _vm.totalCount
                                },
                                on: { update: _vm.update }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasRole.friend_scenario
                        ? _c(
                            "v-tab-item",
                            [
                              _c("PtFriendUpdateScenario", {
                                attrs: {
                                  friends: _vm.friends,
                                  "checked-friends": _vm.checkedItems,
                                  "search-wrap": _vm.searchWrap,
                                  "total-count": _vm.totalCount
                                },
                                on: { update: _vm.update }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasRole.friend_hashtag
                        ? _c(
                            "v-tab-item",
                            [
                              _c("PtFriendUpdateHashtag", {
                                attrs: {
                                  friends: _vm.friends,
                                  "checked-friends": _vm.checkedItems,
                                  "search-wrap": _vm.searchWrap,
                                  "total-count": _vm.totalCount
                                },
                                on: { update: _vm.update }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasRole.friend_rich_menu
                        ? _c(
                            "v-tab-item",
                            [
                              _c("PtFriendUpdateRichMenu", {
                                attrs: {
                                  friends: _vm.friends,
                                  "checked-friends": _vm.checkedItems,
                                  "search-wrap": _vm.searchWrap,
                                  "total-count": _vm.totalCount
                                },
                                on: { update: _vm.update }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasRole.friend_reminder
                        ? _c(
                            "v-tab-item",
                            [
                              _c("PtFriendUpdateReminder", {
                                attrs: {
                                  friends: _vm.friends,
                                  "checked-friends": _vm.checkedItems,
                                  "search-wrap": _vm.searchWrap,
                                  "total-count": _vm.totalCount
                                },
                                on: { update: _vm.update }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasRole.friend_schedule
                        ? _c(
                            "v-tab-item",
                            [
                              _c("PtFriendUpdateSchedule", {
                                attrs: {
                                  friends: _vm.friends,
                                  "checked-friends": _vm.checkedItems,
                                  "search-wrap": _vm.searchWrap,
                                  "total-count": _vm.totalCount
                                },
                                on: { update: _vm.update }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasRole.friend_action
                        ? _c(
                            "v-tab-item",
                            [
                              _c("PtFriendUpdateAction", {
                                attrs: {
                                  friends: _vm.friends,
                                  "checked-friends": _vm.checkedItems,
                                  "search-wrap": _vm.searchWrap,
                                  "total-count": _vm.totalCount
                                },
                                on: { update: _vm.update }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }