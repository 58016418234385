var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "6", cols: "12" } },
                    [
                      _c("v-btn", { on: { click: _vm.openDialog } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("btn.select_image")) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.internalValue.preview_url
                        ? _c(
                            "v-card",
                            {
                              staticClass: "mt-4",
                              attrs: {
                                outlined: "",
                                tile: "",
                                "max-width": 200
                              }
                            },
                            [
                              _c("VImg", {
                                attrs: { src: _vm.internalValue.preview_url }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { md: "6", cols: "12" } },
                    [
                      _c(
                        "v-item-group",
                        { attrs: { mandatory: "" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            _vm._l(_vm.linkLayouts, function(layout) {
                              return _c(
                                "v-col",
                                {
                                  key: layout.value,
                                  attrs: { cols: "6", md: "2" }
                                },
                                [
                                  _c("v-item", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var toggle = ref.toggle
                                            return [
                                              _c(
                                                "v-card",
                                                {
                                                  staticClass: "pa-1",
                                                  attrs: {
                                                    color:
                                                      layout.value ==
                                                      _vm.internalValue
                                                        .link_type
                                                        ? "primary"
                                                        : ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      toggle()
                                                      _vm.selectLayout(
                                                        layout.value
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("VImg", {
                                                    attrs: { src: layout.image }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("div", [_vm._v(_vm._s(_vm.$t("lbl.image_link_desc")))])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                _vm._l(_vm.areas, function(area) {
                  return _c(
                    "v-col",
                    { key: area, attrs: { cols: "12", md: "4" } },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-subtitle", [
                            _vm._v(_vm._s(_vm.$t("lbl.area")) + _vm._s(area))
                          ]),
                          _vm._v(" "),
                          _c("VDivider", { staticClass: "my-0" }),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            [
                              _c("VfSelect", {
                                attrs: {
                                  label: _vm.$$tm(
                                    "deli_image_link.image_link_action"
                                  ),
                                  vees: _vm.veeLinks.image_link_action,
                                  selects: _vm.imageLinkActions
                                },
                                model: {
                                  value: _vm.areaData[area].image_link_action,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.areaData[area],
                                      "image_link_action",
                                      $$v
                                    )
                                  },
                                  expression: "areaData[area].image_link_action"
                                }
                              }),
                              _vm._v(" "),
                              _vm.areaData[area].image_link_action ===
                              "url_open"
                                ? _c("VfTextField", {
                                    attrs: {
                                      label: _vm.$$tm(
                                        "deli_image_link.site_url"
                                      ),
                                      vees: _vm.veeLinks.site_url
                                    },
                                    model: {
                                      value: _vm.areaData[area].site_url,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.areaData[area],
                                          "site_url",
                                          $$v
                                        )
                                      },
                                      expression: "areaData[area].site_url"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _vm.areas.length > 0
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("VfTextArea", {
                            attrs: {
                              label: _vm.$$tm("deli_image.message_for_pc"),
                              vees: _vm.vees.message_for_pc,
                              counter: ""
                            },
                            model: {
                              value: _vm.internalValue.message_for_pc,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.internalValue,
                                  "message_for_pc",
                                  $$v
                                )
                              },
                              expression: "internalValue.message_for_pc"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("PtDeliDialogForSelectFile", {
        attrs: { "upload-file-type": "image" },
        on: { "select-file-done": _vm.selectFileDone },
        model: {
          value: _vm.dialog,
          callback: function($$v) {
            _vm.dialog = $$v
          },
          expression: "dialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }