var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.hidden
    ? _c(
        "div",
        { staticClass: "mb-3" },
        [
          _c(
            "v-row",
            [
              !_vm.hiddenTitle
                ? _c(
                    "v-col",
                    { attrs: { sm: "4", cols: "12", xs: "12" } },
                    [
                      _c("span", { staticClass: "text-bold" }, [
                        _vm._v(_vm._s(_vm.title))
                      ]),
                      _vm._v(" "),
                      _vm.required
                        ? _c(
                            "v-chip",
                            {
                              staticClass: "ma-2",
                              attrs: {
                                label: "",
                                color: "red",
                                "text-color": "white",
                                small: ""
                              }
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.$t("lbl.answer_form.require")) +
                                  "\n      "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  attrs: { sm: !_vm.hiddenTitle ? 8 : 12, cols: "12", xs: "12" }
                },
                [
                  _c("VfSelect", {
                    attrs: {
                      label: _vm.title,
                      selects: _vm.prefectures,
                      vees: _vm.vees
                    },
                    model: {
                      value: _vm.internalValue,
                      callback: function($$v) {
                        _vm.internalValue = $$v
                      },
                      expression: "internalValue"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "subtitle-3 theme--light v-messages" },
                    [_vm._v(_vm._s(_vm.hint))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }