import { render, staticRenderFns } from "./PtFriendUpdateRichMenu.vue?vue&type=template&id=cc0f110e&scoped=true&"
import script from "./PtFriendUpdateRichMenu.vue?vue&type=script&lang=js&"
export * from "./PtFriendUpdateRichMenu.vue?vue&type=script&lang=js&"
import style0 from "./PtFriendUpdateRichMenu.vue?vue&type=style&index=0&id=cc0f110e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc0f110e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/talk_label/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cc0f110e')) {
      api.createRecord('cc0f110e', component.options)
    } else {
      api.reload('cc0f110e', component.options)
    }
    module.hot.accept("./PtFriendUpdateRichMenu.vue?vue&type=template&id=cc0f110e&scoped=true&", function () {
      api.rerender('cc0f110e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/packs/components/parts/unique/PtFriendUpdate/PtFriendUpdateRichMenu.vue"
export default component.exports