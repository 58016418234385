import { render, staticRenderFns } from "./PtFormSetupTextArea.vue?vue&type=template&id=287f4857&scoped=true&"
import script from "./PtFormSetupTextArea.vue?vue&type=script&lang=js&"
export * from "./PtFormSetupTextArea.vue?vue&type=script&lang=js&"
import style0 from "./PtFormSetupTextArea.vue?vue&type=style&index=0&id=287f4857&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "287f4857",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/talk_label/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('287f4857')) {
      api.createRecord('287f4857', component.options)
    } else {
      api.reload('287f4857', component.options)
    }
    module.hot.accept("./PtFormSetupTextArea.vue?vue&type=template&id=287f4857&scoped=true&", function () {
      api.rerender('287f4857', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/packs/components/parts/common/PtFormSetupSection/PtFormSetupTextArea.vue"
export default component.exports