var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.items,
          "item-key": "id",
          "show-select": "",
          "disable-sort": "",
          "server-items-length": _vm.totalCount,
          options: _vm.tableOptions,
          "items-per-page": 50,
          "footer-props": {
            itemsPerPageOptions: [50, 100, 150]
          }
        },
        on: {
          "update:options": function($event) {
            _vm.tableOptions = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.friend.name",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "FriendShow",
                          params: { id: item.friend.id }
                        }
                      }
                    },
                    [
                      _c(
                        "v-avatar",
                        { staticClass: "mr-2", attrs: { size: "36" } },
                        [
                          _c("v-img", {
                            attrs: {
                              small: "",
                              src: _vm.$$noimageHelper(
                                item.friend.api_picture_url
                              ),
                              alt: item.friend.name
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(
                        "\n        " + _vm._s(item.friend.name) + "\n      "
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "item.created_at",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$$fdt(item.created_at)) + "\n      "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$$ftm(item.created_at, "LTS")) +
                      "\n    "
                  )
                ]
              }
            },
            {
              key: "item.data-table-select",
              fn: function(ref) {
                var item = ref.item
                var isSelected = ref.isSelected
                var select = ref.select
                return [
                  item.message_type === "receive"
                    ? _c("v-simple-checkbox", {
                        attrs: { value: isSelected },
                        on: {
                          input: function($event) {
                            return select($event)
                          }
                        }
                      })
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.message_status",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.message_type === "receive"
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-chip",
                            {
                              attrs: {
                                dark: "",
                                label: "",
                                color: _vm.colors[item.message_status]
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openDialogMessageStatus(item)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$$te("friend.message_status", item)
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.deli",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("PtLinePreviewRow", {
                    staticClass: "my-2",
                    attrs: {
                      deli: item.deli,
                      "message-type": item.message_type,
                      "hide-detail": ""
                    }
                  })
                ]
              }
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.checkedMessages,
          callback: function($$v) {
            _vm.checkedMessages = $$v
          },
          expression: "checkedMessages"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }