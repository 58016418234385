var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto py-4 mt-8", attrs: { "max-width": 500 } },
    [
      _c(
        "validation-observer",
        { ref: "observer" },
        [
          _c(
            "v-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.save($event)
                }
              }
            },
            [
              _c(
                "v-card-title",
                [
                  _c("v-row", { attrs: { justify: "center" } }, [
                    _c("div", [
                      _vm._v(_vm._s(_vm.$t("meta.title." + _vm.$route.name)))
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "mx-4" }),
              _vm._v(" "),
              _c(
                "v-card-text",
                { attrs: { align: "center" } },
                [
                  _c("div", [
                    _vm._v("発行したLine連携コードを入力してください")
                  ]),
                  _vm._v(" "),
                  _c("vf-text-field", {
                    attrs: {
                      label: "line連携コード",
                      vees: { rules: "required" }
                    },
                    model: {
                      value: _vm.code,
                      callback: function($$v) {
                        _vm.code = $$v
                      },
                      expression: "code"
                    }
                  }),
                  _vm._v(" "),
                  _c("vue-recaptcha", {
                    ref: "recaptcha",
                    attrs: {
                      sitekey: "6LeIc-UUAAAAAF_hZmOAtE6maZozgVQCH3nEdvBh"
                    },
                    on: { verify: _vm.onVerify }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { align: "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                width: 200,
                                type: "submit",
                                color: "primary",
                                disabled: _vm.loading
                              }
                            },
                            [_vm._v("\n              送信\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }