var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.initDone
    ? _c("validation-observer", { ref: "observer" }, [
        _vm.systemErrors.length > 0 && _vm.exampleMode
          ? _c("div", {
              staticClass: "admin-tips",
              domProps: { innerHTML: _vm._s(_vm.adminTip) }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row answer-form" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-sm-10 offset-sm-1 col-md-6 offset-md-3 col-lg-6 offset-lg-3 p-0 form-box"
              },
              [
                _vm.showError
                  ? _c(
                      "v-row",
                      { staticClass: "form-header", style: _vm.bg_header },
                      [
                        _c(
                          "v-col",
                          {
                            attrs: {
                              cols: _vm.formHeaderCols.cols,
                              md: _vm.formHeaderCols.md,
                              sm: _vm.formHeaderCols.sm,
                              xs: _vm.formHeaderCols.xs
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "submit_response_message" },
                              [
                                _vm.systemErrors.length > 0
                                  ? _c("p", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.autoLink(
                                            _vm.systemErrors[0].message,
                                            false
                                          )
                                        )
                                      }
                                    })
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("lbl.answer_form.please_close_page")
                                )
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        !_vm.answerForm.answer_form_setting_attributes
                          .hide_header_icon_flg
                          ? _c(
                              "v-col",
                              {
                                staticClass: "icon-header",
                                attrs: { cols: "3", sm: "2", xs: "3", md: "2" }
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { size: "79", color: "#ddd" } },
                                  [_vm._v("mdi-block-helper")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.showError
                  ? _c(
                      "v-row",
                      { staticClass: "form-header", style: _vm.bg_header },
                      [
                        _c(
                          "v-col",
                          {
                            attrs: {
                              cols: _vm.formHeaderCols.cols,
                              md: _vm.formHeaderCols.md,
                              sm: _vm.formHeaderCols.sm,
                              xs: _vm.formHeaderCols.xs
                            }
                          },
                          [
                            _c("h3", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.answerForm.form_title)
                              ),
                              _vm.exampleMode
                                ? _c("small", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("lbl.answer_form.mode_example")
                                      )
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("p", {
                              staticClass: "ml-2 style-des",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.answerForm.form_description
                                )
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        !_vm.answerForm.answer_form_setting_attributes
                          .hide_header_icon_flg
                          ? _c(
                              "v-col",
                              {
                                staticClass: "icon-header",
                                attrs: { cols: "3", md: "2", sm: "2", xs: "3" }
                              },
                              [
                                !_vm.showThankPage
                                  ? _c(
                                      "v-icon",
                                      { attrs: { size: "79", color: "#ddd" } },
                                      [_vm._v("mdi-lead-pencil")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showThankPage
                                  ? _c(
                                      "v-icon",
                                      { attrs: { size: "79", color: "#ddd" } },
                                      [_vm._v("mdi-check")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.showError
                  ? _c(
                      "v-row",
                      { staticClass: "form-content", style: _vm.bg_content },
                      [
                        _c(
                          "v-col",
                          [
                            _vm._l(
                              _vm.answerForm.answer_form_inputs_attributes,
                              function(section, section_i) {
                                return _c(
                                  "div",
                                  [
                                    _vm.page === section_i + 1 &&
                                    !_vm.showThankPage
                                      ? [
                                          _vm._l(section, function(
                                            input,
                                            input_id
                                          ) {
                                            return _c(
                                              "div",
                                              [
                                                input._type === "text_field" &&
                                                input.validation_type !==
                                                  "date_format" &&
                                                input.validation_type !==
                                                  "time" &&
                                                !input.hidden_flg
                                                  ? _c(
                                                      "PtFormRenderTextField",
                                                      {
                                                        attrs: {
                                                          value:
                                                            _vm.form_response[
                                                              input.id
                                                            ].value,
                                                          "internal-id":
                                                            input.internal_id,
                                                          "auto-input":
                                                            _vm.answerForm
                                                              .answer_form_setting_attributes
                                                              .auto_input_setting,
                                                          "data-address":
                                                            _vm.dataAddress,
                                                          placeholder:
                                                            input.placeholder,
                                                          title: input.title,
                                                          "hidden-title":
                                                            input.hide_title_flg,
                                                          hint:
                                                            input.description,
                                                          label: input.title,
                                                          required:
                                                            input.require_flg,
                                                          "validation-type":
                                                            input.validation_type,
                                                          "max-length": 200
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            _vm.form_response[
                                                              input.id
                                                            ].value = $event
                                                          },
                                                          "fill-address": function(
                                                            $event
                                                          ) {
                                                            _vm.dataAddress = $event
                                                          }
                                                        }
                                                      }
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                input._type === "text_field" &&
                                                input.validation_type ===
                                                  "time" &&
                                                !input.hidden_flg
                                                  ? _c("PtFormRenderTime", {
                                                      attrs: {
                                                        value:
                                                          _vm.form_response[
                                                            input.id
                                                          ].value,
                                                        "internal-id":
                                                          input.internal_id,
                                                        "auto-input":
                                                          _vm.answerForm
                                                            .answer_form_setting_attributes
                                                            .auto_input_setting,
                                                        "data-address":
                                                          _vm.dataAddress,
                                                        placeholder:
                                                          input.placeholder,
                                                        title: input.title,
                                                        "hidden-title":
                                                          input.hide_title_flg,
                                                        hint: input.description,
                                                        label: input.title,
                                                        required:
                                                          input.require_flg,
                                                        "validation-type":
                                                          input.validation_type,
                                                        "max-length": 200
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          _vm.form_response[
                                                            input.id
                                                          ].value = $event
                                                        },
                                                        "fill-address": function(
                                                          $event
                                                        ) {
                                                          _vm.dataAddress = $event
                                                        }
                                                      }
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                input._type === "text_area" &&
                                                !input.hidden_flg
                                                  ? _c("PtFormRenderTextArea", {
                                                      attrs: {
                                                        value:
                                                          _vm.form_response[
                                                            input.id
                                                          ].value,
                                                        placeholder:
                                                          input.placeholder,
                                                        title: input.title,
                                                        "hidden-title":
                                                          input.hide_title_flg,
                                                        hint: input.description,
                                                        label: input.title,
                                                        required:
                                                          input.require_flg
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          _vm.form_response[
                                                            input.id
                                                          ].value = $event
                                                        }
                                                      }
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                input._type === "checkbox" &&
                                                !input.hidden_flg
                                                  ? _c("PtFormRenderCheckbox", {
                                                      attrs: {
                                                        value:
                                                          _vm.form_response[
                                                            input.id
                                                          ].value,
                                                        placeholder:
                                                          input.placeholder,
                                                        title: input.title,
                                                        "hidden-title":
                                                          input.hide_title_flg,
                                                        hint: input.description,
                                                        label: input.title,
                                                        required:
                                                          input.require_flg,
                                                        "input-options":
                                                          input.answer_form_input_options_attributes,
                                                        "option-count":
                                                          _vm.answerForm
                                                            .selected_opt_count[
                                                            input.id
                                                          ],
                                                        "select-flg":
                                                          input.limit_select_option_flg,
                                                        "min-select":
                                                          input.limit_select_option_min,
                                                        "max-select":
                                                          input.limit_select_option_max,
                                                        friend: _vm.friend,
                                                        color:
                                                          _vm.answerForm
                                                            .answer_form_setting_attributes
                                                            .button_color
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          _vm.form_response[
                                                            input.id
                                                          ].value = $event
                                                        }
                                                      }
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                input._type === "radio" &&
                                                !input.hidden_flg
                                                  ? _c("PtFormRenderRadio", {
                                                      attrs: {
                                                        value:
                                                          _vm.form_response[
                                                            input.id
                                                          ].value,
                                                        placeholder:
                                                          input.placeholder,
                                                        title: input.title,
                                                        "hidden-title":
                                                          input.hide_title_flg,
                                                        hint: input.description,
                                                        label: input.title,
                                                        required:
                                                          input.require_flg,
                                                        "input-options":
                                                          input.answer_form_input_options_attributes,
                                                        "option-count":
                                                          _vm.answerForm
                                                            .selected_opt_count[
                                                            input.id
                                                          ],
                                                        friend: _vm.friend,
                                                        column: true,
                                                        row: false,
                                                        color:
                                                          _vm.answerForm
                                                            .answer_form_setting_attributes
                                                            .button_color,
                                                        "no-label": "",
                                                        "label-hidden": ""
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          _vm.form_response[
                                                            input.id
                                                          ].value = $event
                                                        }
                                                      }
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                input._type === "dropdown" &&
                                                !input.hidden_flg
                                                  ? _c("PtFormRenderSelect", {
                                                      attrs: {
                                                        value:
                                                          _vm.form_response[
                                                            input.id
                                                          ].value,
                                                        placeholder:
                                                          input.placeholder,
                                                        title: input.title,
                                                        "hidden-title":
                                                          input.hide_title_flg,
                                                        hint: input.description,
                                                        label: input.title,
                                                        required:
                                                          input.require_flg,
                                                        "input-options":
                                                          input.answer_form_input_options_attributes,
                                                        "option-count":
                                                          _vm.answerForm
                                                            .selected_opt_count[
                                                            input.id
                                                          ],
                                                        friend: _vm.friend,
                                                        column: true,
                                                        row: false,
                                                        "no-label": "",
                                                        "label-hidden": ""
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          _vm.form_response[
                                                            input.id
                                                          ].value = $event
                                                        }
                                                      }
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                input._type === "subheading" &&
                                                !input.hidden_flg
                                                  ? _c(
                                                      "PtFormRenderSubHeading",
                                                      {
                                                        attrs: {
                                                          src:
                                                            input.heading_image,
                                                          title: input.title,
                                                          "show-img":
                                                            input.additional_option_type,
                                                          description:
                                                            input.description
                                                        }
                                                      }
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                input._type ===
                                                  "middleheading" &&
                                                !input.hidden_flg
                                                  ? _c(
                                                      "PtFormRenderMiddleHeading",
                                                      {
                                                        attrs: {
                                                          src:
                                                            input.heading_image,
                                                          title: input.title,
                                                          "show-img":
                                                            input.additional_option_type,
                                                          description:
                                                            input.description
                                                        }
                                                      }
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                input._type === "attachment" &&
                                                !input.hidden_flg
                                                  ? _c(
                                                      "PtFormRenderAttachment",
                                                      {
                                                        attrs: {
                                                          uuid:
                                                            _vm.answerFormId,
                                                          type:
                                                            input.additional_option_type,
                                                          placeholder:
                                                            input.placeholder,
                                                          title: input.title,
                                                          hint:
                                                            input.description,
                                                          label: input.title,
                                                          required:
                                                            input.require_flg,
                                                          hidden:
                                                            input.hidden_flg,
                                                          "hidden-title":
                                                            input.hide_title_flg,
                                                          "input-options":
                                                            input.answer_form_input_options_attributes
                                                        },
                                                        on: {
                                                          "upload-file": function(
                                                            $event
                                                          ) {
                                                            _vm.disabledBtnSubmit = $event
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form_response[
                                                              input.id
                                                            ].value,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form_response[
                                                                input.id
                                                              ],
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form_response[input.id].value"
                                                        }
                                                      }
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                (input._type === "date" ||
                                                  (input._type ===
                                                    "text_field" &&
                                                    input.validation_type ===
                                                      "date_format")) &&
                                                !input.hidden_flg
                                                  ? _c("PtFormRenderDate", {
                                                      attrs: {
                                                        placeholder:
                                                          input.placeholder,
                                                        title: input.title,
                                                        hint: input.description,
                                                        label: input.title,
                                                        required:
                                                          input.require_flg,
                                                        hidden:
                                                          input.hidden_flg,
                                                        "hidden-title":
                                                          input.hide_title_flg,
                                                        "input-options":
                                                          input.answer_form_input_options_attributes,
                                                        data: input
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form_response[
                                                            input.id
                                                          ].value,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form_response[
                                                              input.id
                                                            ],
                                                            "value",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form_response[input.id].value"
                                                      }
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                input._type === "address" &&
                                                !input.hidden_flg
                                                  ? _c("PtFormRenderAddress", {
                                                      attrs: {
                                                        opts: _vm.opts,
                                                        placeholder:
                                                          input.placeholder,
                                                        title: input.title,
                                                        hint: input.description,
                                                        label: input.title,
                                                        required:
                                                          input.require_flg,
                                                        hidden:
                                                          input.hidden_flg,
                                                        "hidden-title":
                                                          input.hide_title_flg,
                                                        "input-options":
                                                          input.answer_form_input_options_attributes
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form_response[
                                                            input.id
                                                          ].value,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form_response[
                                                              input.id
                                                            ],
                                                            "value",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form_response[input.id].value"
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-row",
                                            { staticClass: "mt-5 d-flex" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    md: "2",
                                                    sm: "3",
                                                    xs: "4",
                                                    cols: "12"
                                                  }
                                                },
                                                [
                                                  _vm.pageStack.length
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              _vm.disabledBtnSubmit,
                                                            large: "",
                                                            block: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.handleBack(
                                                                section_i
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("btn.back")
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    md: "8",
                                                    sm: "6",
                                                    xs: "12",
                                                    cols: "12"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      style: _vm.bg_button,
                                                      attrs: {
                                                        color: "primary",
                                                        disabled:
                                                          _vm.disabledBtnSubmit,
                                                        large: "",
                                                        block: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleNext(
                                                            section_i
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          section_i + 1 ===
                                                            _vm.totalPage
                                                            ? _vm.$t("btn.send")
                                                            : _vm.$t(
                                                                "btn.move_on"
                                                              )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              }
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showThankPage,
                                    expression: "showThankPage"
                                  }
                                ],
                                staticClass: "form-finish"
                              },
                              [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("lbl.answer_form.send_complete")
                                    )
                                  )
                                ]),
                                _vm.exampleMode
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "lbl.answer_form.not_actually_sent"
                                          )
                                        )
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "submit_response_message" },
                                  [
                                    _c("p", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.autoLink(
                                            _vm.answerForm
                                              .answer_form_setting_attributes
                                              .submit_response_message,
                                            true
                                          )
                                        )
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("br")
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "lbl.answer_form.please_close_page"
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "v-dialog",
                  {
                    attrs: { persistent: "", "max-width": "290" },
                    model: {
                      value: _vm.dialog,
                      callback: function($$v) {
                        _vm.dialog = $$v
                      },
                      expression: "dialog"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title"),
                        _vm._v(" "),
                        _c("v-card-text", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "lbl.answer_form.confirm_send_answer_form"
                                )
                              ) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.dialog = false
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("btn.cancel")) +
                                    "\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  disabled: _vm.disabledBtnSubmit,
                                  color: "primary"
                                },
                                on: { click: _vm.save }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("btn.send")) +
                                    "\n              "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }