var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      !_vm.hiddenTitle
        ? _c(
            "v-col",
            { attrs: { sm: "4", cols: "12", xs: "12" } },
            [
              _c("span", { staticClass: "text-bold" }, [
                _vm._v(_vm._s(_vm.title))
              ]),
              _vm._v(" "),
              _vm.required
                ? _c(
                    "v-chip",
                    {
                      staticClass: "ma-2",
                      attrs: {
                        label: "",
                        color: "red",
                        "text-color": "white",
                        small: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("lbl.answer_form.require")) +
                          "\n    "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { sm: "8", cols: "12", xs: "12" } },
        [
          _c("validation-provider", {
            attrs: { name: _vm.title, rules: _vm.localVees.rules },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  return [
                    _c(
                      "v-select",
                      _vm._b(
                        {
                          staticClass: "mt-0 pt-0",
                          attrs: {
                            "error-messages": errors,
                            items: _vm.items,
                            placeholder: _vm.placeholder,
                            "menu-props": "auto",
                            required: ""
                          },
                          on: {
                            change: function($event) {
                              return _vm.$emit("change", $event)
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "append-outer",
                                fn: function() {
                                  return [_vm._t("append-outer")]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.localValue,
                            callback: function($$v) {
                              _vm.localValue = $$v
                            },
                            expression: "localValue"
                          }
                        },
                        "v-select",
                        _vm.baseProps,
                        false
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm._l(_vm.inputOptions, function(option, option_i) {
            return [
              _vm.localValue === option.id &&
              option.id !== null &&
              option.is_other_flg
                ? _c("VfTextField", {
                    key: option_i,
                    staticClass: "vf-hidden-label pt-0",
                    attrs: { label: _vm.title, placeholder: "", hint: "" },
                    model: {
                      value: _vm.customOptions[option.id],
                      callback: function($$v) {
                        _vm.$set(_vm.customOptions, option.id, $$v)
                      },
                      expression: "customOptions[option.id]"
                    }
                  })
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }