var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("VfAlert", {
        attrs: { type: "error" },
        model: {
          value: _vm.errorMsg,
          callback: function($$v) {
            _vm.errorMsg = $$v
          },
          expression: "errorMsg"
        }
      }),
      _vm._v(" "),
      _c(
        "v-expansion-panels",
        {
          attrs: { accordion: "" },
          model: {
            value: _vm.panel,
            callback: function($$v) {
              _vm.panel = $$v
            },
            expression: "panel"
          }
        },
        [
          _c(
            "draggable",
            {
              staticClass: "w-100",
              attrs: {
                list: _vm.internalValue,
                disabled: false,
                "ghost-class": "ghost",
                move: _vm.checkMove,
                handle: ".drag-handle",
                animation: 200,
                delay: 0
              },
              on: {
                start: function($event) {
                  _vm.dragging = true
                },
                end: function($event) {
                  return _vm.updatePanel($event)
                }
              }
            },
            _vm._l(_vm.internalValue, function(formSetup, formSetup_i) {
              return _c(
                "v-expansion-panel",
                { key: formSetup_i },
                [
                  _c("validation-observer", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var invalid = ref.invalid
                            return [
                              _c(
                                "v-expansion-panel-header",
                                {
                                  staticClass: "pa-0 text-subtitle-1 lighten-5",
                                  class: invalid ? "red" : "blue-grey",
                                  attrs: { "expand-icon": "" }
                                },
                                [
                                  _c(
                                    "v-list-item",
                                    [
                                      _c("div", { staticClass: "mr-1" }, [
                                        _c(
                                          "p",
                                          { staticClass: "mt-3 mb-3" },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(formSetup.title))
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-subtitle-2 grey--text text--lighten-1"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "enum.answer_form_input.input_types." +
                                                        formSetup["_type"]
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            formSetup.require_flg
                                              ? _c(
                                                  "v-chip",
                                                  {
                                                    attrs: {
                                                      label: "",
                                                      small: "",
                                                      color: "red",
                                                      "text-color": "white"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "lbl.answer_form.require"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("v-list-item-content"),
                                      _vm._v(" "),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c(
                                            "v-btn",
                                            { attrs: { small: "", icon: "" } },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  "mdi-square-edit-outline"
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "drag-handle",
                                              attrs: { small: "", icon: "" },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-cursor-move")
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { small: "", icon: "" },
                                              on: {
                                                click: [
                                                  function($event) {
                                                    $event.stopPropagation()
                                                  },
                                                  function($event) {
                                                    return _vm.duplicateSetup(
                                                      formSetup_i
                                                    )
                                                  }
                                                ]
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-content-copy")
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { small: "", icon: "" },
                                              on: {
                                                click: [
                                                  function($event) {
                                                    $event.stopPropagation()
                                                  },
                                                  function($event) {
                                                    _vm.removeDialog = true
                                                    _vm.removeSetupIndex = formSetup_i
                                                  }
                                                ]
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-close-circle")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-expansion-panel-content",
                                [
                                  _c(_vm.inputTypeMap[formSetup._type], {
                                    tag: "component",
                                    attrs: {
                                      "init-data": _vm.initData,
                                      targets: _vm.targets,
                                      vees: _vm.vees,
                                      opts: _vm.opts,
                                      "section-opts": _vm.sectionOpts,
                                      "input-type": formSetup._type,
                                      "setup-index": formSetup_i,
                                      "checked-interact-index":
                                        _vm.checkedInteractIndex,
                                      "section-length": _vm.sectionLength,
                                      "section-list": _vm.sectionList,
                                      "section-index": _vm.sectionIndex
                                    },
                                    on: {
                                      update: function($event) {
                                        return _vm.handleUpdateForm(
                                          $event,
                                          _vm.sectionIndex,
                                          formSetup_i
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.internalValue[formSetup_i],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.internalValue,
                                          formSetup_i,
                                          $$v
                                        )
                                      },
                                      expression: "internalValue[formSetup_i]"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-row", { staticClass: "d-flex justify-content-center mt-5" }, [
        _c(
          "div",
          { staticClass: "text-subtitle-2 grey--text text--lighten-1" },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("lbl.answer_form.select_element")) +
                "\n    "
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            _vm._l(_vm.opts.answer_form_input.input_type, function(
              inputType,
              disp
            ) {
              return _c(
                "v-btn",
                {
                  key: disp,
                  staticClass: "ma-2",
                  on: {
                    click: function($event) {
                      return _vm.addSetup(inputType)
                    }
                  }
                },
                [_vm._v("\n        " + _vm._s(disp) + "\n      ")]
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "300" },
          model: {
            value: _vm.removeDialog,
            callback: function($$v) {
              _vm.removeDialog = $$v
            },
            expression: "removeDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(_vm._s(""))]),
              _vm._v(" "),
              _c("v-card-text", [
                _c("div", { staticClass: "or-break" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "lbl.answer_form.do_you_really_want_to_delete_the_item"
                        )
                      ) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("VSpacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function($event) {
                          _vm.removeDialog = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("btn.cancel")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: {
                        click: function($event) {
                          return _vm.removeSetup(_vm.removeSetupIndex)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("btn.yes")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }