var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [
        _vm._v("\n    " + _vm._s(_vm.$t("notificator.account")) + "\n  ")
      ]),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-0" }),
      _vm._v(" "),
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.channels,
          "items-per-page": -1,
          "disable-sort": "",
          "hide-default-footer": ""
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.name",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$emit("click:row", item.id)
                        }
                      }
                    },
                    [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }