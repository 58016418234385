var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [_c("PtTitle", { attrs: { title: _vm.crossAnalysis.name } })],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "5" } },
            [
              _c("v-btn", { on: { click: _vm.downloadCSV } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("lbl.cross_analysis.download_csv")) +
                    "\n      "
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "12", md: "1" } }, [
            _c("p", {
              staticClass: "pt-2",
              domProps: {
                textContent: _vm._s(_vm.$t("lbl.cross_analysis.sort"))
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { cols: "12", md: "2" } },
            [
              _c("VfSelect", {
                attrs: {
                  label: "",
                  selects: _vm.optionSelectCols,
                  "hide-details": ""
                },
                model: {
                  value: _vm.selectCol,
                  callback: function($$v) {
                    _vm.selectCol = $$v
                  },
                  expression: "selectCol"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { cols: "12", md: "1" } },
            [
              _c("VfSelect", {
                attrs: {
                  label: "",
                  selects: _vm.optionSorts,
                  "hide-details": ""
                },
                model: {
                  value: _vm.typeSort,
                  callback: function($$v) {
                    _vm.typeSort = $$v
                  },
                  expression: "typeSort"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", align: "right" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "warning" },
                  on: { click: _vm.recalculation }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-autorenew")
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("lbl.cross_analysis.recalculation")) +
                      "\n      "
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.editItem } },
                [_vm._v("\n        " + _vm._s(_vm.$t("btn.edit")) + "\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c("v-simple-table", { staticClass: "cross-analysis-table" }, [
                _c("thead", [
                  _c(
                    "tr",
                    [
                      _vm._l(_vm.tableHeaders, function(item) {
                        return [
                          item.csv_col_no === 0
                            ? _c("td", [_vm._v(_vm._s(item.disp))])
                            : item.disp === "is_search_wrap"
                            ? _c(
                                "td",
                                { attrs: { colspan: _vm.tableColspan } },
                                [
                                  _c("PtSearchSelectedList", {
                                    staticClass: "or-list",
                                    attrs: { "disp-type": "list" },
                                    model: {
                                      value: item.search_wrap.as_json_form,
                                      callback: function($$v) {
                                        _vm.$set(
                                          item.search_wrap,
                                          "as_json_form",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "item.search_wrap.as_json_form"
                                    }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "td",
                                { attrs: { colspan: _vm.tableColspan } },
                                [_vm._v(_vm._s(item.disp))]
                              )
                        ]
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.dispBlockedCount
                    ? _c(
                        "tr",
                        [
                          _vm._l(_vm.tableHeaders, function(item) {
                            return [
                              item.csv_col_no === 0
                                ? [_c("td")]
                                : [
                                    _c("td", [_vm._v("到達数")]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v("相手からブロック")])
                                  ]
                            ]
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.tableBodies, function(row) {
                    return _c(
                      "tr",
                      [
                        _vm._l(row, function(item) {
                          return [
                            item.csv_col_no === 0
                              ? [_c("td", [_vm._v(_vm._s(item.disp))])]
                              : [
                                  _c("td", [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "underline-link",
                                        on: {
                                          click: function($event) {
                                            return _vm.searchFriend(item)
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(item.disp))]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _vm.dispBlockedCount
                                    ? _c("td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "underline-link",
                                            on: {
                                              click: function($event) {
                                                return _vm.searchBlockedFriend(
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(_vm._s(item.blocked_count))]
                                        )
                                      ])
                                    : _vm._e()
                                ]
                          ]
                        })
                      ],
                      2
                    )
                  }),
                  0
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }