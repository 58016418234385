var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PtTitle"),
      _vm._v("\n  " + _vm._s(_vm.$t("subtitle.scenario")) + "\n  "),
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "8" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Search",
                          "append-icon": "mdi-magnify",
                          clearable: "",
                          "single-line": "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "text-right", attrs: { md: "4" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.newItem }
                        },
                        [_vm._v(_vm._s(_vm.$t("btn.new_item")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.items,
              search: _vm.search,
              "item-key": "id",
              "disable-sort": true,
              "items-per-page": 50,
              "footer-props": {
                itemsPerPageOptions: [50, 100, 150]
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.name",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              return _vm.editItem(item)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(item.name) + "\n        "
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "item.scenario_status",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$$te("scenario.scenario_status", item)) +
                          "\n      "
                      )
                    ]
                  }
                },
                {
                  key: "item.scenario_type",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$$te("scenario.scenario_type", item)) +
                          "\n      "
                      )
                    ]
                  }
                },
                {
                  key: "item.created_at",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$$fdt(item.created_at)) +
                          "\n      "
                      )
                    ]
                  }
                },
                {
                  key: "item.action",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function($event) {
                              return _vm.setCopyId(item.id)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("btn.copy")) + " ")]
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("VfDialogConfirm", {
        attrs: {
          title: _vm.$t("confirm_title.delete"),
          detail: _vm.$t("confirm.delete"),
          "save-path": _vm.savePath,
          "form-type": "delete",
          "target-emit": "delete-success"
        },
        on: { "delete-success": _vm.deleteSuccess },
        model: {
          value: _vm.deleteDialog,
          callback: function($$v) {
            _vm.deleteDialog = $$v
          },
          expression: "deleteDialog"
        }
      }),
      _vm._v(" "),
      _c("PtDialogLinePreview", {
        key: _vm.selectedItem.id,
        attrs: { type: "scenario", "model-id": _vm.selectedItem.id },
        model: {
          value: _vm.previewDialog,
          callback: function($$v) {
            _vm.previewDialog = $$v
          },
          expression: "previewDialog"
        }
      }),
      _vm._v(" "),
      _c("BtnDialogCopyScenario", {
        attrs: { "scenario-copy-id": _vm.selectedCopyId },
        on: { "clear-copy-id": _vm.clearCopyId },
        model: {
          value: _vm.copyDialog,
          callback: function($$v) {
            _vm.copyDialog = $$v
          },
          expression: "copyDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }