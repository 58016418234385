var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "friend_index_table",
        attrs: {
          headers: _vm.headers,
          items: _vm.items,
          options: _vm.tableOptions,
          "item-key": "id",
          "sort-by": "created_at",
          "sort-desc": "",
          "show-select": "",
          "disable-sort": "",
          "server-items-length": _vm.totalCount,
          "items-per-page": 50,
          "footer-props": {
            itemsPerPageOptions: [50, 100, 150]
          }
        },
        on: {
          "update:options": function($event) {
            _vm.tableOptions = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.message_status",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-chip",
                    {
                      attrs: {
                        dark: "",
                        label: "",
                        color: _vm.colors[item.message_status]
                      },
                      on: {
                        click: function($event) {
                          return _vm.openDialogMessageStatus(item)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$$te("friend.message_status", item)) +
                          "\n      "
                      )
                    ]
                  )
                ]
              }
            },
            {
              key: "item.is_blocked",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.blocked_flg === 1
                    ? _c("div", [_vm._v("ブロックされています")])
                    : _vm._e(),
                  _vm._v(" "),
                  item.blocking_flg === 1
                    ? _c("div", [_vm._v("ブロックしています")])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.name",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "FriendShow", params: { id: item.id } }
                      }
                    },
                    [
                      _c(
                        "v-avatar",
                        {
                          staticClass: "mr-2 mt-2 mb-2",
                          attrs: { size: "48" }
                        },
                        [
                          _c("v-img", {
                            attrs: {
                              small: "",
                              src: _vm.$$noimageHelper(item.api_picture_url),
                              alt: item.name
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v("\n        " + _vm._s(item.name) + "\n      ")
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "item.active_scenario",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.active_scenario
                    ? _c("div", [
                        _vm._v(
                          "\n        " +
                            _vm._s(item.active_scenario.name) +
                            "\n      "
                        )
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.last_receive_deli",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.last_receive_deli
                    ? [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$$fdt(item.last_receive_at)) +
                            "\n        "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$$ftm(item.last_receive_at, "LTS")) +
                            "\n        "
                        ),
                        _c("PtLinePreviewRow", {
                          attrs: {
                            deli: item.last_receive_deli,
                            "message-type": "receive",
                            "hide-detail": ""
                          }
                        })
                      ]
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.hashtags",
              fn: function(ref) {
                var item = ref.item
                return _vm._l(item.hashtags, function(hashtag, hashtag_idx) {
                  return _c("v-chip", { key: hashtag_idx }, [
                    _vm._v(_vm._s(hashtag.name))
                  ])
                })
              }
            },
            {
              key: "item.created_at",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$$fdt(item.created_at)) + "\n      "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$$ftm(item.created_at, "LTS")) +
                      "\n    "
                  )
                ]
              }
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.checkedFriends,
          callback: function($$v) {
            _vm.checkedFriends = $$v
          },
          expression: "checkedFriends"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }