var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("VfAlert", {
        attrs: { type: "error" },
        model: {
          value: _vm.errorMsg,
          callback: function($$v) {
            _vm.errorMsg = $$v
          },
          expression: "errorMsg"
        }
      }),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { md: "6" } },
            [
              _c("VfRadioButton", {
                attrs: {
                  label: _vm.$$tm("action_scenario.scenario_action_type"),
                  vees: _vm.vees.scenario_action_type,
                  selects: _vm.opts.scenario_action_type,
                  "label-hidden": ""
                },
                model: {
                  value: _vm.internalValue.scenario_action_type,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "scenario_action_type", $$v)
                  },
                  expression: "internalValue.scenario_action_type"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { md: "6" } },
            [
              _vm.internalValue.scenario_action_type !== "cancel"
                ? _c("PtDialogSelect", {
                    attrs: {
                      "select-type": "scenario",
                      "single-select": true,
                      "hide-folder": true,
                      vees: _vm.vees.name,
                      "only-have-timing": ""
                    },
                    model: {
                      value: _vm.internalValue,
                      callback: function($$v) {
                        _vm.internalValue = $$v
                      },
                      expression: "internalValue"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { md: "6" } },
            [
              _vm.internalValue.scenario_action_type !== "cancel" &&
              _vm.internalValue.scenario_id
                ? _c("PtDialogSelect", {
                    attrs: {
                      "select-type": "scenario_timing",
                      "single-select": true,
                      "hide-folder": true,
                      vees: _vm.vees.start_position
                    },
                    model: {
                      value: _vm.internalValue,
                      callback: function($$v) {
                        _vm.internalValue = $$v
                      },
                      expression: "internalValue"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }