<template>
  <validation-provider
    v-slot="{ errors }"
    :name="label || vvName"
    :rules="rules"
    :vid="Math.random().toString()"
  >
    <v-select
      ref="vselect"
      v-model="internalValue"
      v-bind="baseProps"
      :error-messages="errors"
      :items="items"
      :placeholder="placeholder"
      menu-props="auto"
      @change="$emit('change', $event)"
      :multiple="multiple"
    >
      <template #append-outer>
        <slot name="append-outer" />
      </template>
    </v-select>
  </validation-provider>
</template>
<script>
import mixin from 'components/vuetifyParts/commonExport'
export default {
  mixins: [mixin],
  props: {
    value: { default: null },
    placeholder: { default: '' },
    selects: { required: true },
    multiple: { default: false },
    activeMenu: { default: false },
    vvName: { default: '' },
  },
  watch: {
    activeMenu: {
      handler(value) {
        if (value) {
          this.$refs.vselect.focus()
          this.$refs.vselect.activateMenu()
        }
      },
      deep: true,
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    items() {
      const cls = Object.prototype.toString.call(this.selects).slice(8, -1)
      if (cls === 'Array') {
        return this.selects
      }
      let arr = []
      for (let [k, v] of Object.entries(this.selects)) {
        arr.push({ text: k, value: v })
      }
      return arr
    },
  },
}
</script>
