var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-text-field", {
        staticClass: "webhook-url-text",
        attrs: { readonly: "" },
        scopedSlots: _vm._u([
          {
            key: "append-outer",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: _vm.webhook,
                        expression: "webhook",
                        arg: "copy"
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:success",
                        value: _vm.onClipCopy,
                        expression: "onClipCopy",
                        arg: "success"
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:error",
                        value: _vm.onClipError,
                        expression: "onClipError",
                        arg: "error"
                      }
                    ],
                    staticClass: "ml-4"
                  },
                  [
                    _c("v-icon", [_vm._v("mdi-content-copy")]),
                    _vm._v("\n        copy\n      ")
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.webhook,
          callback: function($$v) {
            _vm.webhook = $$v
          },
          expression: "webhook"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }