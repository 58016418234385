var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "8" } },
            [_c("PtTitle", { attrs: { title: _vm.click.name } })],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "4", align: "right" } },
            [
              this.selectedTab.value != "info"
                ? _c(
                    "v-btn",
                    { staticClass: "mt-5", on: { click: _vm.downloadCSV } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("lbl.click.download_csv")) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-5",
                  attrs: {
                    color: "primary",
                    to: { name: "ClickForm", params: { id: _vm.click.id } }
                  }
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("btn.edit")) + "\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mb-4" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-card",
                { attrs: { "min-height": 100 } },
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " + _vm._s(_vm.click.name) + "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-card-text", [
                    _c(
                      "a",
                      { attrs: { href: _vm.click.site_url, target: "_blank" } },
                      [_vm._v(_vm._s(_vm.click.site_url))]
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-card",
                { attrs: { "min-height": 100 } },
                [
                  _c("v-simple-table", [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("lbl.click.click_count_total")))
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("lbl.click.visit_count")))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", [_vm._v(_vm._s(_vm.click.click_count_total))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.click.visit_count))])
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("PtClick", {
        ref: "ptClick",
        attrs: { click: _vm.click },
        on: { selectTab: _vm.selectTab },
        model: {
          value: _vm.click,
          callback: function($$v) {
            _vm.click = $$v
          },
          expression: "click"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }