var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { md: "6" } },
            [
              _c("PtDialogSelect", {
                attrs: { "select-type": "inflow_route", vees: _vm.vees.name },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.action_group",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("PtActionLabelMessages", {
                            staticClass: "or-list",
                            model: {
                              value: item.action_group.make_action_arr,
                              callback: function($$v) {
                                _vm.$set(
                                  item.action_group,
                                  "make_action_arr",
                                  $$v
                                )
                              },
                              expression: "item.action_group.make_action_arr"
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.internalValue,
                  callback: function($$v) {
                    _vm.internalValue = $$v
                  },
                  expression: "internalValue"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { md: "6" } },
            [
              _c("VfSelect", {
                attrs: {
                  label: _vm.$$tm(
                    "search_inflow_route.search_inflow_route_type"
                  ),
                  selects: _vm.opts.search_inflow_route_type,
                  vees: _vm.vees.search_inflow_route_type
                },
                model: {
                  value: _vm.internalValue.search_inflow_route_type,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "search_inflow_route_type", $$v)
                  },
                  expression: "internalValue.search_inflow_route_type"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }