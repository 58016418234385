var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "8" } }, [_c("PtTitle")], 1),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pt-8", attrs: { cols: "4", align: "right" } },
            [
              !_vm.isNewRecord
                ? _c(
                    "v-btn",
                    { attrs: { color: "error" }, on: { click: _vm.destroy } },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.$t("btn.delete")) + "\n      "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "validation-observer",
        { ref: "observer" },
        [
          _c(
            "v-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.save($event)
                }
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "pa-4" },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("vf-text-field", {
                        attrs: {
                          label: _vm.$$tm("admin_info.title"),
                          vees: _vm.vees.title
                        },
                        model: {
                          value: _vm.info.title,
                          callback: function($$v) {
                            _vm.$set(_vm.info, "title", $$v)
                          },
                          expression: "info.title"
                        }
                      }),
                      _vm._v(" "),
                      _c("vf-text-field", {
                        attrs: {
                          label: _vm.$$tm("admin_info.subtitle"),
                          vees: _vm.vees.subtitle,
                          counter: ""
                        },
                        model: {
                          value: _vm.info.subtitle,
                          callback: function($$v) {
                            _vm.$set(_vm.info, "subtitle", $$v)
                          },
                          expression: "info.subtitle"
                        }
                      }),
                      _vm._v(" "),
                      _c("VfTextArea", {
                        attrs: {
                          label: _vm.$$tm("admin_info.desc"),
                          vees: _vm.vees.desc,
                          counter: ""
                        },
                        model: {
                          value: _vm.info.desc,
                          callback: function($$v) {
                            _vm.$set(_vm.info, "desc", $$v)
                          },
                          expression: "info.desc"
                        }
                      }),
                      _vm._v(" "),
                      _c("VfDateTimeSelect", {
                        attrs: {
                          label: _vm.$$tm("admin_info.disp_st_at"),
                          vees: _vm.vees.disp_st_at,
                          format: "YYYY/MM/DD HH:mm"
                        },
                        model: {
                          value: _vm.info.disp_st_at,
                          callback: function($$v) {
                            _vm.$set(_vm.info, "disp_st_at", $$v)
                          },
                          expression: "info.disp_st_at"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            { attrs: { color: "primary", type: "submit" } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("btn.save")) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("VfDialogConfirm", {
        attrs: {
          title: _vm.$t("confirm_title.delete"),
          detail: _vm.$t("confirm.delete"),
          "save-path": _vm.savePath,
          "form-type": "delete",
          "target-emit": "delete-success"
        },
        on: { "delete-success": _vm.deleteSuccess },
        model: {
          value: _vm.deleteDialog,
          callback: function($$v) {
            _vm.deleteDialog = $$v
          },
          expression: "deleteDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }