var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "pa-0" },
            [
              _c(
                "v-list-item",
                [
                  _c("v-list-item-content", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("lbl.friend.reminder")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.hasRole
                    ? _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", outlined: "" },
                              on: { click: _vm.openSelectDialog }
                            },
                            [_c("v-icon", [_vm._v("mdi-pencil")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("VDivider", { staticClass: "my-0" }),
          _vm._v(" "),
          _c(
            "v-card-text",
            _vm._l(_vm.internalValue, function(reminder, reminder_idx) {
              return _c(
                "v-chip",
                {
                  key: reminder_idx,
                  staticClass: "ma-2 multiple_lines",
                  attrs: { large: "" }
                },
                [
                  _vm._v("\n        " + _vm._s(reminder.name) + "\n        "),
                  _c("br"),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$$fat(reminder.goal_at, "YYYY/MM/DD HH:mm")) +
                      "\n      "
                  )
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasRole
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "1200" },
              model: {
                value: _vm.selectDialog,
                callback: function($$v) {
                  _vm.selectDialog = $$v
                },
                expression: "selectDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "validation-observer",
                    { ref: "observer" },
                    [
                      _c("VfLoadingOverlayAbsolute", {
                        attrs: { loading: _vm.$wait.is("processing") }
                      }),
                      _vm._v(" "),
                      _c("v-card-title", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("lbl.action_group.action_types.reminder")
                            ) +
                            "\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "pb-0", attrs: { md: "6" } },
                                [
                                  _c("VfRadioButton", {
                                    attrs: {
                                      label: _vm.$$tm(
                                        "action_reminder.reminder_action_type"
                                      ),
                                      selects: _vm.reminderActionType,
                                      "label-hidden": ""
                                    },
                                    model: {
                                      value: _vm.reminderActionTypeModel,
                                      callback: function($$v) {
                                        _vm.reminderActionTypeModel = $$v
                                      },
                                      expression: "reminderActionTypeModel"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { md: "6" } },
                                [
                                  _c("PtDialogSelect", {
                                    attrs: {
                                      "select-type": "reminder",
                                      "single-select": true,
                                      vees: { rules: "required" },
                                      "only-have-timing": ""
                                    },
                                    model: {
                                      value: _vm.reminder,
                                      callback: function($$v) {
                                        _vm.reminder = $$v
                                      },
                                      expression: "reminder"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _vm.reminderActionTypeModel === "start"
                                    ? _c("VfDateTimeSelect", {
                                        attrs: {
                                          label: _vm.$$tm(
                                            "action_reminder.goal_at"
                                          ),
                                          format: "YYYY/MM/DD HH:mm"
                                        },
                                        model: {
                                          value: _vm.goalAt,
                                          callback: function($$v) {
                                            _vm.goalAt = $$v
                                          },
                                          expression: "goalAt"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c("VSpacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            { on: { click: _vm.closeSelectDialog } },
                            [_vm._v(_vm._s(_vm.$t("btn.cancel")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                loading: _vm.$wait.is("processing")
                              },
                              on: { click: _vm.save }
                            },
                            [_vm._v(_vm._s(_vm.$t("btn.update")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }