var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [
        _vm._v("\n    " + _vm._s(_vm.$t("notificator.notify")) + "\n  ")
      ]),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.$emit("save")
                }
              }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        attrs: {
                          label: _vm.$t("notificator.channel"),
                          items: _vm.channels,
                          "item-text": "name",
                          "item-value": "id",
                          disabled: _vm.edit
                        },
                        model: {
                          value: _vm.internalValue.channel_id,
                          callback: function($$v) {
                            _vm.$set(_vm.internalValue, "channel_id", $$v)
                          },
                          expression: "internalValue.channel_id"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        attrs: {
                          label: _vm.$t("notificator.timing"),
                          items: _vm.noticeTypes
                        },
                        model: {
                          value: _vm.internalValue.notice_type,
                          callback: function($$v) {
                            _vm.$set(_vm.internalValue, "notice_type", $$v)
                          },
                          expression: "internalValue.notice_type"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-title", { staticClass: "pb-0" }, [
                            _c("h6", [
                              _vm._v(
                                _vm._s(_vm.$t("notificator.classification"))
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            [
                              _c("VfCheckbox", {
                                attrs: {
                                  label: _vm.$t("notificator.receive"),
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.internalValue.normal_flg,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.internalValue,
                                      "normal_flg",
                                      $$v
                                    )
                                  },
                                  expression: "internalValue.normal_flg"
                                }
                              }),
                              _vm._v(" "),
                              _c("VfCheckbox", {
                                attrs: {
                                  label: _vm.$t("notificator.auto_answer"),
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.internalValue.auto_answer_flg,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.internalValue,
                                      "auto_answer_flg",
                                      $$v
                                    )
                                  },
                                  expression: "internalValue.auto_answer_flg"
                                }
                              }),
                              _vm._v(" "),
                              _c("VfCheckbox", {
                                attrs: {
                                  label: _vm.$t("notificator.action"),
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.internalValue.action_flg,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.internalValue,
                                      "action_flg",
                                      $$v
                                    )
                                  },
                                  expression: "internalValue.action_flg"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { align: "right" } },
                    [
                      _c(
                        "v-btn",
                        { attrs: { color: "primary", type: "submit" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("btn.create")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }