var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    attrs: {
      name: _vm.label || _vm.vvName,
      rules: _vm.rules,
      vid: Math.random().toString()
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var errors = ref.errors
          return [
            _c(
              "v-select",
              _vm._b(
                {
                  ref: "vselect",
                  attrs: {
                    "error-messages": errors,
                    items: _vm.items,
                    placeholder: _vm.placeholder,
                    "menu-props": "auto",
                    multiple: _vm.multiple
                  },
                  on: {
                    change: function($event) {
                      return _vm.$emit("change", $event)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "append-outer",
                        fn: function() {
                          return [_vm._t("append-outer")]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.internalValue,
                    callback: function($$v) {
                      _vm.internalValue = $$v
                    },
                    expression: "internalValue"
                  }
                },
                "v-select",
                _vm.baseProps,
                false
              )
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }