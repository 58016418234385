var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c("v-card-title", [
            _vm._v("\n      " + _vm._s(_vm.$t("lbl.folder")) + "\n    ")
          ]),
          _vm._v(" "),
          _vm.folders.length > 0
            ? _c(
                "v-list",
                { staticClass: "py-0" },
                [
                  _c(
                    "v-list-item-group",
                    {
                      attrs: { mandatory: "" },
                      model: {
                        value: _vm.selectedFolderId,
                        callback: function($$v) {
                          _vm.selectedFolderId = $$v
                        },
                        expression: "selectedFolderId"
                      }
                    },
                    [
                      _c(
                        "draggable",
                        {
                          attrs: {
                            draggable: ".sortable",
                            handle: ".drag-handle",
                            group: "ITEMS",
                            animation: 200,
                            "force-fallback": true,
                            delay: 50
                          },
                          on: { end: _vm.sortFolder },
                          model: {
                            value: _vm.folders,
                            callback: function($$v) {
                              _vm.folders = $$v
                            },
                            expression: "folders"
                          }
                        },
                        [
                          _vm._l(_vm.folders, function(folder, folder_i) {
                            return _c(
                              "v-list-item",
                              {
                                key: folder.id,
                                class: { sortable: folder_i > 0 },
                                attrs: {
                                  value: folder.id,
                                  "data-folder-id": folder.id
                                }
                              },
                              [
                                !_vm.selectOnly
                                  ? _c(
                                      "v-list-item-icon",
                                      {
                                        staticClass: "mr-2",
                                        class: { "drag-handle": folder_i > 0 }
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-format-list-bulleted")
                                        ])
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(folder.name) +
                                          "\n                (" +
                                          _vm._s(folder.item_count) +
                                          ")\n              "
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                !_vm.selectOnly &&
                                folder.id === _vm.selectedFolderId &&
                                folder_i > 0
                                  ? [
                                      _c(
                                        "v-list-item-icon",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.openDialogEdit(folder)
                                            }
                                          }
                                        },
                                        [_c("v-icon", [_vm._v("mdi-pencil")])],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item-icon",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.openDialogDelete(
                                                folder
                                              )
                                            }
                                          }
                                        },
                                        [_c("v-icon", [_vm._v("mdi-delete")])],
                                        1
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          }),
                          _vm._v(" "),
                          !_vm.selectOnly
                            ? _c(
                                "v-list-item",
                                {
                                  key: "add",
                                  attrs: { value: "add" },
                                  on: { click: _vm.openDialogAdd }
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    { staticClass: "mr-2" },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-plus-circle-outline")
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(_vm.$t("btn.add")))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "300" },
          model: {
            value: _vm.formDialog,
            callback: function($$v) {
              _vm.formDialog = $$v
            },
            expression: "formDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("VfLoadingOverlayAbsolute", {
                attrs: { loading: _vm.dialogLoading }
              }),
              _vm._v(" "),
              _c("VfAlert", {
                attrs: { type: "error" },
                model: {
                  value: _vm.errorMsg,
                  callback: function($$v) {
                    _vm.errorMsg = $$v
                  },
                  expression: "errorMsg"
                }
              }),
              _vm._v(" "),
              _c(
                "validation-observer",
                { ref: "observer" },
                [
                  _c(
                    "v-form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.save($event)
                        }
                      }
                    },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("VfTextField", {
                            attrs: {
                              label: _vm.$$tm("folder.name"),
                              vees: _vm.vees.name
                            },
                            model: {
                              value: _vm.targetFolder.name,
                              callback: function($$v) {
                                _vm.$set(_vm.targetFolder, "name", $$v)
                              },
                              expression: "targetFolder.name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c("VSpacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function($event) {
                                  _vm.formDialog = false
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("btn.cancel")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            { attrs: { color: "primary", type: "submit" } },
                            [_vm._v(_vm._s(_vm.$t("btn.create")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "300" },
          model: {
            value: _vm.deleteDialog,
            callback: function($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("VfLoadingOverlayAbsolute", {
                attrs: { loading: _vm.dialogLoading }
              }),
              _vm._v(" "),
              _c("VfAlert", {
                attrs: { type: "error" },
                model: {
                  value: _vm.errorMsg,
                  callback: function($$v) {
                    _vm.errorMsg = $$v
                  },
                  expression: "errorMsg"
                }
              }),
              _vm._v(" "),
              _c("v-card-title", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("confirm_title.delete")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("v-card-text", [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("confirm.delete")) + "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("VSpacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function($event) {
                          _vm.deleteDialog = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("btn.cancel")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: { click: _vm.deleteFolder }
                    },
                    [_vm._v(_vm._s(_vm.$t("btn.delete")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }