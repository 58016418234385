<template>
  <v-app>
    <VfLoadingOverlay />
    <LaDrawer v-if="show" v-model="drawer" :can-show="canShow" />
    <LaHeader
      v-if="show"
      v-model="drawer"
      :can-show="canShow"
      :show-header="showHeader"
    />
    <v-main>
      <VfLoadingOverlayAbsolute
        :loading="$store.getters.loadingOverlayContent"
      />
      <v-container
        class="app-container"
        fluid
        :class="fullFixed ? 'full-fixed' : ''"
      >
        <VfGlobalMessage />
        <RouterView />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import LaHeader from 'components/layouts/LaHeader'
import LaDrawer from 'components/layouts/LaDrawer'

export default {
  components: {
    LaHeader,
    LaDrawer,
  },
  data: () => ({
    drawer: null,
  }),
  computed: {
    canShow() {
      const rejectList = [
        this.$router.resolve({ name: 'Login' }).href,
        this.$router.resolve({ name: 'Registration' }).href,
        this.$router.resolve({ name: 'RegistrationEmail' }).href,
        this.$router.resolve({ name: 'RegistrationAccount' }).href,
        this.$router.resolve({ name: 'RegistrationChannel' }).href,
        this.$router.resolve({ name: 'RegistrationChannelApi' }).href,
        this.$router.resolve({ name: 'RegistrationWebhook' }).href,
        this.$router.resolve({ name: 'RegistrationLoginChannel' }).href,
        this.$router.resolve({ name: 'RegistrationLiffApp' }).href,
        this.$router.resolve({ name: 'RegistrationDone' }).href,
        this.$router.resolve({ name: 'CancelForm' }).href,
        this.$router.resolve({ name: 'SentForm' }).href,
        this.$router.resolve({
          name: 'AnswerFormAnswer',
          params: {
            uuid: this.$route.params.uuid || 'uuid',
            friend_id: this.$route.params.friend_id,
          },
        }).href,
        this.$router.resolve({
          name: 'ClickRedirect',
          params: { id: this.$route.params.id || 'id' },
        }).href,
        this.$router.resolve({ name: 'FriendReservationBooking' }).href,
        this.$router.resolve({ name: 'FriendReservationBookingNew' }).href,
        this.$router.resolve({ name: 'FriendReservationHistory' }).href,
        this.$router.resolve({
          name: 'FriendReservationHistoryDetail',
          params: {
            id: this.$route.params.id || 'id',
          },
        }).href,
      ]
      return !rejectList.some((path) => path === this.$route.path)
    },
    showHeader() {
      const rejectList = [
        this.$router.resolve({
          name: 'AnswerFormAnswer',
          params: {
            uuid: this.$route.params.uuid || 'uuid',
            friend_id: this.$route.params.friend_id,
          },
        }).href,
      ]
      return !rejectList.some((path) => path === this.$route.path)
    },
    show() {
      const paramsWithId = { id: this.$route.params.id || 'id' }
      const rejectList = [
        this.$router.resolve({ name: 'SpIndex' }).href,
        this.$router.resolve({ name: 'SpVerify' }).href,
        this.$router.resolve({ name: 'SpUnicastIndex' }).href,
        this.$router.resolve({ name: 'SpNotificatorIndex' }).href,
        this.$router.resolve({
          name: 'SpNotificatorForm',
          params: paramsWithId,
        }).href,
        this.$router.resolve({
          name: 'SpNotificatorShow',
          params: paramsWithId,
        }).href,
        this.$router.resolve({ name: 'SpBroadcastForm' }).href,
        this.$router.resolve({ name: 'SpFixedPhraseForm' }).href,
        this.$router.resolve({ name: 'InflowRouteRedirect' }).href,
        this.$router.resolve({ name: 'NotFoundPage' }).href,
      ]
      return !rejectList.some((path) => path === this.$route.path)
    },
    fullFixed() {
      const list = [
        this.$router.resolve({ name: 'SpVerify' }).href,
        this.$router.resolve({ name: 'SpUnicastIndex' }).href,
      ]
      return list.some((path) => path === this.$route.path)
    },
  },
}
</script>
<style scoped>
.full-fixed.app-container {
  max-width: 100%;
  width: 100%;
  margin: 0;
  padding: 0px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
