var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      !_vm.hiddenTitle
        ? _c(
            "v-col",
            { attrs: { sm: "4", cols: "12", xs: "12" } },
            [
              _c("span", { staticClass: "text-bold" }, [
                _vm._v(_vm._s(_vm.title))
              ]),
              _vm._v(" "),
              _vm.required
                ? _c(
                    "v-chip",
                    {
                      staticClass: "ma-2",
                      attrs: {
                        label: "",
                        color: "red",
                        "text-color": "white",
                        small: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("lbl.answer_form.require")) +
                          "\n    "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { sm: "8", cols: "12", xs: "12" } },
        [
          _c("validation-provider", {
            ref: "provider",
            attrs: { name: _vm.label, rules: _vm.localVees.rules },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  return [
                    _vm._l(_vm.inputOptions, function(option) {
                      return _c(
                        "div",
                        [
                          _c("VfCheckbox", {
                            staticClass: "m-0",
                            attrs: {
                              "checkbox-value": option.id,
                              label: _vm.isDisabled(option)
                                ? option.capacity_warning ||
                                  _vm.$t("lbl.answer_form.can_not_select") +
                                    option.value
                                : option.value,
                              disabled: _vm.isDisabled(option),
                              "persistent-hint": true,
                              "hide-details": "auto",
                              color: _vm.color
                            },
                            model: {
                              value: _vm.checkedOptions,
                              callback: function($$v) {
                                _vm.checkedOptions = $$v
                              },
                              expression: "checkedOptions"
                            }
                          }),
                          _vm._v(" "),
                          _vm.checkedOptions.includes(option.id) &&
                          option.is_other_flg
                            ? _c("VfTextField", {
                                staticClass: "vf-hidden-label pt-0",
                                attrs: {
                                  label: _vm.title,
                                  placeholder: "",
                                  hint: ""
                                },
                                model: {
                                  value: _vm.customOptions[option.id],
                                  callback: function($$v) {
                                    _vm.$set(_vm.customOptions, option.id, $$v)
                                  },
                                  expression: "customOptions[option.id]"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    errors.length
                      ? _c("div", { staticClass: "v-text-field__details" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "v-messages theme--light error--text",
                              attrs: { role: "alert" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "v-messages__wrapper" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "v-messages__message" },
                                    [_vm._v(_vm._s(errors[0]))]
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("div", { staticClass: "subtitle-3 theme--light v-messages" }, [
            _vm._v(_vm._s(_vm.hint))
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }