var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-0" },
            [
              _c("VfTextField", {
                attrs: {
                  label: _vm.$$tm("search_name.name"),
                  vees: _vm.vees.name
                },
                model: {
                  value: _vm.internalValue.name,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "name", $$v)
                  },
                  expression: "internalValue.name"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-0" },
            [
              _c("VfCheckbox", {
                staticClass: "float-left my-0 mr-6",
                attrs: { label: _vm.$$tm("search_name.api_display_name_flg") },
                model: {
                  value: _vm.internalValue.api_display_name_flg,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "api_display_name_flg", $$v)
                  },
                  expression: "internalValue.api_display_name_flg"
                }
              }),
              _vm._v(" "),
              _c("VfCheckbox", {
                staticClass: "float-left my-0 mr-6",
                attrs: { label: _vm.$$tm("search_name.system_disp_name_flg") },
                model: {
                  value: _vm.internalValue.system_disp_name_flg,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "system_disp_name_flg", $$v)
                  },
                  expression: "internalValue.system_disp_name_flg"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }