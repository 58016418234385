var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PtTitle", {
        attrs: {
          title: _vm.$t("lbl.answer_form.change_status_title", {
            form_name: _vm.answer_form.name
          })
        }
      }),
      _vm._v(" "),
      _vm.initDone
        ? _c(
            "validation-observer",
            { ref: "observer" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { md: "6" } },
                    [
                      _c("VfCheckbox", {
                        attrs: { label: _vm.$$tm("answer_form.publish_flg") },
                        model: {
                          value: _vm.answer_form.publish_flg,
                          callback: function($$v) {
                            _vm.$set(_vm.answer_form, "publish_flg", $$v)
                          },
                          expression: "answer_form.publish_flg"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              !_vm.answer_form.publish_flg
                ? [
                    _c(
                      "v-row",
                      { staticClass: "mt-0" },
                      [
                        _c(
                          "v-col",
                          { attrs: { md: "6 py-0" } },
                          [
                            _c("VfTextArea", {
                              attrs: {
                                label: _vm.$t(
                                  "lbl.answer_form.text_display_instead"
                                ),
                                vees: { rules: "max:1200", max: 1200 }
                              },
                              model: {
                                value: _vm.answer_form.unpublish_message,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.answer_form,
                                    "unpublish_message",
                                    $$v
                                  )
                                },
                                expression: "answer_form.unpublish_message"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { md: "6 py-0" } },
                          [
                            _c(
                              "v-alert",
                              {
                                staticClass: "light-blue lighten-5",
                                attrs: { text: "" }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "lbl.answer_form.preview_is_possible"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "px-10",
                          attrs: { large: "", color: "primary" },
                          on: { click: _vm.save }
                        },
                        [_vm._v(_vm._s(_vm.$t("btn.save")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }