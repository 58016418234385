var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": 500 },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { icon: "", outlined: "" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_c("v-icon", [_vm._v("mdi-pencil")])],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("account.password_edit")) + "\n    "
            )
          ]),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "validation-observer",
            { ref: "observer" },
            [
              _c(
                "v-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.save($event)
                    }
                  }
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("vf-text-field", {
                        attrs: {
                          label: "パスワード",
                          vees: { rules: "required" },
                          password: ""
                        },
                        model: {
                          value: _vm.passwordForm.password,
                          callback: function($$v) {
                            _vm.$set(_vm.passwordForm, "password", $$v)
                          },
                          expression: "passwordForm.password"
                        }
                      }),
                      _vm._v(" "),
                      _c("vf-text-field", {
                        attrs: {
                          label: "パスワード（確認）",
                          vees: { rules: "required" },
                          password: ""
                        },
                        model: {
                          value: _vm.passwordForm.password_confirmation,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.passwordForm,
                              "password_confirmation",
                              $$v
                            )
                          },
                          expression: "passwordForm.password_confirmation"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function($event) {
                              _vm.dialog = false
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("btn.cancel")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        { attrs: { type: "submit", color: "primary" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("btn.update")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }