var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "mx-auto py-4", attrs: { "max-width": "800" } },
        [
          _c("v-card-title", [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("account.password_edit")) + "\n    "
            )
          ]),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c("v-card-subtitle", [
            _vm._v(
              "\n      お手数ですがセキュリティ維持のため、パスワード変更をお願い致します。\n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("vf-text-field", {
                attrs: { label: "パスワード", password: "" },
                model: {
                  value: _vm.item.password,
                  callback: function($$v) {
                    _vm.$set(_vm.item, "password", $$v)
                  },
                  expression: "item.password"
                }
              }),
              _vm._v(" "),
              _c("vf-text-field", {
                attrs: { label: "パスワード（確認）", password: "" },
                model: {
                  value: _vm.item.password_confirmation,
                  callback: function($$v) {
                    _vm.$set(_vm.item, "password_confirmation", $$v)
                  },
                  expression: "item.password_confirmation"
                }
              }),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.passwordReset }
                    },
                    [_vm._v(" 変更 ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }