var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "mb-8" },
        [
          _c(
            "validation-observer",
            { ref: "observer" },
            [
              _c("VfLoadingOverlayAbsolute", {
                attrs: { loading: _vm.$wait.is("processing") }
              }),
              _vm._v(" "),
              _c("VfAlert", {
                attrs: { type: "error" },
                model: {
                  value: _vm.errorMsg,
                  callback: function($$v) {
                    _vm.errorMsg = $$v
                  },
                  expression: "errorMsg"
                }
              }),
              _vm._v(" "),
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("lbl.action_group.action_types.reminder")
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { md: "6" } },
                            [
                              _c("VfRadioButton", {
                                attrs: {
                                  label: _vm.$$tm(
                                    "action_reminder.reminder_action_type"
                                  ),
                                  selects: _vm.reminderActionType,
                                  "label-hidden": ""
                                },
                                model: {
                                  value: _vm.reminderActionTypeModel,
                                  callback: function($$v) {
                                    _vm.reminderActionTypeModel = $$v
                                  },
                                  expression: "reminderActionTypeModel"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("PtDialogSelect", {
                                attrs: {
                                  "select-type": "reminder",
                                  "single-select": true,
                                  vees: { rules: "required" },
                                  "only-have-timing": ""
                                },
                                model: {
                                  value: _vm.reminder,
                                  callback: function($$v) {
                                    _vm.reminder = $$v
                                  },
                                  expression: "reminder"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _vm.reminderActionTypeModel === "start"
                                ? _c("VfDateTimeSelect", {
                                    attrs: {
                                      label: _vm.$$tm(
                                        "action_reminder.goal_at"
                                      ),
                                      format: "YYYY/MM/DD HH:mm"
                                    },
                                    model: {
                                      value: _vm.goalAt,
                                      callback: function($$v) {
                                        _vm.goalAt = $$v
                                      },
                                      expression: "goalAt"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("pt-friend-update-common-bottom", {
        attrs: {
          friends: _vm.friends,
          "total-count": _vm.totalCount,
          "alert-message": _vm.$t("lbl.confirm_friends_reminder_update")
        },
        on: { save: _vm.save },
        model: {
          value: _vm.allFriendsSelected,
          callback: function($$v) {
            _vm.allFriendsSelected = $$v
          },
          expression: "allFriendsSelected"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }