var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-tabs",
            {
              attrs: {
                "background-color": "blue-grey darken-4",
                centered: "",
                dark: "",
                "icons-and-text": ""
              },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c(
                "v-tab",
                { attrs: { href: "#tab-1" } },
                [
                  _vm._v("\n        リスト\n        "),
                  _c("v-icon", [_vm._v("mdi-account")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab",
                { attrs: { href: "#tab-2" } },
                [
                  _vm._v("\n        メッセージ\n        "),
                  _c("v-icon", [_vm._v("mdi-message")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab",
                { attrs: { href: "#tab-3" } },
                [
                  _vm._v("\n        情報\n        "),
                  _c("v-icon", [_vm._v("mdi-clipboard-account")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c(
                "v-tab-item",
                { key: 1, attrs: { value: "tab-1" } },
                [
                  _c("PtUnicastFriendIndex", {
                    on: {
                      "select-friend": _vm.selectFriend,
                      "active-tab": _vm.activeTab
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                { key: 2, attrs: { value: "tab-2" } },
                [
                  _c("PtUnicastMessageIndex", {
                    key: _vm.friendId,
                    attrs: {
                      "friend-id": _vm.friendId,
                      "first-time-flg": _vm.first_time_flg
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                { key: 3, attrs: { value: "tab-3" } },
                [
                  _c("PtUnicastFriendShow", {
                    key: _vm.friendId,
                    attrs: {
                      "friend-id": _vm.friendId,
                      "first-time-flg": _vm.first_time_flg,
                      "is-mobile": ""
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }