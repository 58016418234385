var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "10" } }, [_c("PtTitle")], 1),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "mt-5", attrs: { cols: "2", align: "right" } },
            [
              _vm.broadcastId !== "new"
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "error",
                        disabled: _vm.broadcast.disabled
                      },
                      on: { click: _vm.destroy }
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.$t("btn.delete")) + "\n      "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "validation-observer",
        { ref: "observer" },
        [
          _c(
            "v-card",
            { staticClass: "mb-6", attrs: { outlined: "" } },
            [
              _c("v-card-title", { staticClass: "or-card-title" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("lbl.send_target")) + "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("VfSwitch", {
                    attrs: {
                      label: _vm.$$tm("broadcast.all_cast_flg"),
                      vees: _vm.vees.all_cast_flg
                    },
                    on: { "vf-switch-chenge": _vm.getFriendCount },
                    model: {
                      value: _vm.broadcast.all_cast_flg,
                      callback: function($$v) {
                        _vm.$set(_vm.broadcast, "all_cast_flg", $$v)
                      },
                      expression: "broadcast.all_cast_flg"
                    }
                  }),
                  _vm._v(" "),
                  !_vm.broadcast.all_cast_flg
                    ? _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("PtSearchSelectedList", {
                                attrs: { "hide-bottom": "" },
                                on: {
                                  "pt-search-selected-list-save":
                                    _vm.getFriendCount,
                                  "pt-search-selected-list-clear":
                                    _vm.getFriendCount
                                },
                                model: {
                                  value: _vm.broadcast.target_search_wrap,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.broadcast,
                                      "target_search_wrap",
                                      $$v
                                    )
                                  },
                                  expression: "broadcast.target_search_wrap"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-5 red--text" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("lbl.broadcast.friend_count", {
                            count: _vm.friendCount
                          })
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  !_vm.broadcast.all_cast_flg
                    ? _c("div", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("lbl.broadcast.warning")) +
                            "\n        "
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            { staticClass: "mb-6", attrs: { outlined: "" } },
            [
              _c("v-card-title", { staticClass: "or-card-title" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("lbl.send_timing")) + "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("VfSwitch", {
                    attrs: {
                      label: _vm.$$tm("broadcast.immediate_flg"),
                      vees: _vm.vees.immediate_flg,
                      disabled: _vm.broadcast.disabled
                    },
                    model: {
                      value: _vm.broadcast.immediate_flg,
                      callback: function($$v) {
                        _vm.$set(_vm.broadcast, "immediate_flg", $$v)
                      },
                      expression: "broadcast.immediate_flg"
                    }
                  }),
                  _vm._v(" "),
                  _vm.broadcast.immediate_flg === 0 && "id" in _vm.broadcast
                    ? _c(
                        "v-card",
                        { attrs: { outlined: "" } },
                        [
                          _c("v-card-title", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("lbl.set_send_at")) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            [
                              _c("VfDateTimeSelect", {
                                attrs: {
                                  disabled: _vm.broadcast.disabled,
                                  label: ""
                                },
                                model: {
                                  value: _vm.broadcast.reserve_at,
                                  callback: function($$v) {
                                    _vm.$set(_vm.broadcast, "reserve_at", $$v)
                                  },
                                  expression: "broadcast.reserve_at"
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "error--text" }, [
                                _vm._v(_vm._s(_vm.$t("lbl.reserve_at_warning")))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            { staticClass: "mb-6", attrs: { outlined: "" } },
            [
              _c("v-card-title", { staticClass: "or-card-title" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("lbl.send_message")) + "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _vm.initDone
                    ? _c("PtDeli", {
                        model: {
                          value: _vm.delis,
                          callback: function($$v) {
                            _vm.delis = $$v
                          },
                          expression: "delis"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { align: "right" } },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$router.push({ name: "BroadcastIndex" })
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("btn.back")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: _vm.broadcast.disabled },
                      on: {
                        click: function($event) {
                          _vm.testSendDialog = true
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("btn.test_send")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        disabled: _vm.broadcast.disabled
                      },
                      on: { click: _vm.sendConfirm }
                    },
                    [_vm._v(_vm._s(_vm.$t("btn.send")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": 300 },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c("v-card-title", [
                    _vm._v(_vm._s(_vm.$t("confirm_title.send")))
                  ]),
                  _vm._v(" "),
                  _c("v-card-text", { staticClass: "or-break" }, [
                    _vm._v(_vm._s(_vm.$t("confirm.send")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function($event) {
                              _vm.dialog = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("btn.cancel")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { loading: _vm.$wait.is("processing") },
                          on: { click: _vm.send }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("btn.send")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "800px" },
              model: {
                value: _vm.testSendDialog,
                callback: function($$v) {
                  _vm.testSendDialog = $$v
                },
                expression: "testSendDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("VfLoadingOverlayAbsolute", {
                    attrs: { loading: _vm.$wait.is("processing") }
                  }),
                  _vm._v(" "),
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("btn.test_send")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _vm.testFriends.length == 0
                        ? [
                            _c(
                              "v-alert",
                              { attrs: { color: "red lighten-2", dark: "" } },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("lbl.broadcast.no_tester")) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        : [
                            _c(
                              "v-container",
                              { attrs: { fluid: "" } },
                              [
                                _c(
                                  "v-row",
                                  _vm._l(_vm.testFriends, function(
                                    friend,
                                    friend_idx
                                  ) {
                                    return _c(
                                      "v-col",
                                      {
                                        key: friend_idx,
                                        attrs: { cols: "12", sm: "4", md: "4" }
                                      },
                                      [
                                        _c("VfCheckbox", {
                                          attrs: {
                                            label:
                                              friend.friend.api_display_name,
                                            name:
                                              friend.friend.api_display_name,
                                            "hide-details": ""
                                          },
                                          model: {
                                            value: friend.checked,
                                            callback: function($$v) {
                                              _vm.$set(friend, "checked", $$v)
                                            },
                                            expression: "friend.checked"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-card-actions",
                              [
                                _c("v-spacer"),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "primary",
                                      disabled: _vm.noTestFriendsAreChecked,
                                      loading: _vm.$wait.is("processing")
                                    },
                                    on: { click: _vm.sendTest }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("btn.send")) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("VfDialogConfirm", {
        attrs: {
          title: _vm.$t("confirm_title.delete"),
          detail: _vm.$t("confirm.delete"),
          "save-path": _vm.savePath,
          "form-type": "delete",
          "target-emit": "delete-success"
        },
        on: { "delete-success": _vm.deleteSuccess },
        model: {
          value: _vm.deleteDialog,
          callback: function($$v) {
            _vm.deleteDialog = $$v
          },
          expression: "deleteDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }