var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c("v-card-title", { staticClass: "mb-2" }, [
            _c(
              "div",
              { staticClass: "d-flex align-items-center" },
              [
                _c("search-dialog", {
                  attrs: { "default-search-wrap": _vm.defaultSearchWrap },
                  on: { decide: _vm.decide, clear: _vm.searchFriends }
                }),
                _vm._v(" "),
                _c("v-text-field", {
                  attrs: {
                    "prepend-inner-icon": "mdi-magnify",
                    dense: "",
                    clearable: "",
                    "single-line": "",
                    "hide-details": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "prepend-inner",
                      fn: function() {
                        return [_c("v-icon", [_vm._v("mdi-magnify")])]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.search,
                    callback: function($$v) {
                      _vm.search = $$v
                    },
                    expression: "search"
                  }
                }),
                _vm._v(" "),
                _c(
                  "v-card-actions",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary" },
                        on: { click: _vm.searchWord }
                      },
                      [_vm._v(_vm._s(_vm.$t("btn.search")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "submit" },
                        on: { click: _vm.searchClear }
                      },
                      [_vm._v(_vm._s(_vm.$t("btn.clear")))]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("v-divider", { staticClass: "my-0" }),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.items,
                  search: _vm.search,
                  "item-key": "id",
                  "disable-sort": "",
                  "mobile-breakpoint": "0",
                  "hide-default-header": "",
                  "hide-default-footer": _vm.items.length < 10,
                  "server-items-length": _vm.totalCount,
                  options: _vm.options,
                  "footer-props": {
                    itemsPerPageOptions: [10, 30, 50],
                    itemsPerPageText: ""
                  }
                },
                on: {
                  "update:options": function($event) {
                    _vm.options = $event
                  },
                  "click:row": _vm.rowClick
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.name",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "div",
                            { staticClass: "mt-4" },
                            [
                              _c(
                                "v-chip",
                                { staticClass: "mr-2", attrs: { pill: "" } },
                                [
                                  _c(
                                    "v-avatar",
                                    { attrs: { left: "" } },
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          src: _vm.$$noimageHelper(
                                            item.api_picture_url
                                          ),
                                          alt: item.name
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.name) +
                                      "\n            "
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.showUnreadMessages(item)
                                ? _c("v-chip", { attrs: { dark: "" } }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.unconfirmed_message_count) +
                                        "\n            "
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "or-break ma-4 deil_line_wrap" },
                            [_vm._v(_vm._s(item.deli))]
                          ),
                          _vm._v(" "),
                          item.last_receive_at
                            ? _c(
                                "div",
                                { staticClass: "text-end text-caption mb-2" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm
                                          .$$moment(item.last_receive_at)
                                          .format("MM/DD")
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.blocking_flg === 1
                            ? _c("div", { staticClass: "red--text text-end" }, [
                                _vm._v(
                                  "\n            ブロックしています\n            "
                                ),
                                item.blocking_at_formatted
                                  ? _c("p", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.blocking_at_formatted) +
                                          "\n            "
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          item.blocked_flg === 1
                            ? _c("div", { staticClass: "red--text text-end" }, [
                                _vm._v(
                                  "\n            ブロックされています\n            "
                                ),
                                item.blocked_at_formatted
                                  ? _c("p", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.blocked_at_formatted) +
                                          "\n            "
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }