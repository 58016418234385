var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-btn",
                {
                  on: {
                    click: function($event) {
                      _vm.dialog = true
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("btn.select_audio")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _vm.internalValue.audio_url
                ? _c(
                    "v-card-text",
                    [
                      _c(
                        "v-card",
                        { attrs: { outlined: "", tile: "", "max-width": 200 } },
                        [
                          _c("PtVideo", {
                            key: _vm.internalValue.audio_url,
                            attrs: {
                              src: _vm.internalValue.audio_url,
                              width: 200,
                              height: 100
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("PtDeliDialogForSelectFile", {
        attrs: { "upload-file-type": "audio" },
        on: { "select-file-done": _vm.selectFileDone },
        model: {
          value: _vm.dialog,
          callback: function($$v) {
            _vm.dialog = $$v
          },
          expression: "dialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }