var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _c("PtTitle"),
          _vm._v(" "),
          _c("div", { staticClass: "or-break" }, [
            _vm._v(_vm._s(_vm.$t("subtitle.line_notice")))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("notify-form", {
        staticClass: "mb-4",
        attrs: {
          channels: _vm.channels,
          vees: _vm.vees,
          "notice-types": _vm.opts.notice_type
        },
        on: { save: _vm.save },
        model: {
          value: _vm.lineNotice,
          callback: function($$v) {
            _vm.lineNotice = $$v
          },
          expression: "lineNotice"
        }
      }),
      _vm._v(" "),
      _c("setting-table", {
        staticClass: "mb-4",
        attrs: { "line-notices": _vm.lineNotices },
        on: {
          "click:row": _vm.toEdit,
          "click:testNotification": _vm.sendTestNotification
        }
      }),
      _vm._v(" "),
      _c("account-table", {
        staticClass: "mb-4",
        attrs: { channels: _vm.channelsWithCount },
        on: { "click:row": _vm.toShow }
      }),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("account.sp_link_title")) + "\n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "error" },
                  on: { click: _vm.destroySpLinkCode }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("line_sp_link.unsync")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "text-right" },
            [
              _c(
                "v-btn",
                {
                  on: {
                    click: function($event) {
                      return _vm.$router.push({ name: "SpIndex" })
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("btn.back")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }