var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PtTitle"),
      _vm._v(" "),
      _c("notify-form", {
        staticClass: "mb-4",
        attrs: {
          channels: _vm.channels,
          vees: _vm.vees,
          "notice-types": _vm.opts.notice_type,
          edit: ""
        },
        on: { save: _vm.save },
        model: {
          value: _vm.lineNotice,
          callback: function($$v) {
            _vm.lineNotice = $$v
          },
          expression: "lineNotice"
        }
      }),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { align: "right" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "error" },
                  on: { click: _vm.openDialogDestroy }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("btn.delete")) + "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("VfDialogConfirm", {
        attrs: {
          title: _vm.$t("confirm_title.delete"),
          detail: _vm.$t("confirm.delete"),
          "save-path": _vm.PATH.destroy,
          "form-type": "delete",
          "target-emit": "delete-success"
        },
        on: { "delete-success": _vm.destroySuccess },
        model: {
          value: _vm.dialog,
          callback: function($$v) {
            _vm.dialog = $$v
          },
          expression: "dialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }