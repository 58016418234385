var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("VfAlert", {
        attrs: { type: "error" },
        model: {
          value: _vm.errorMsg,
          callback: function($$v) {
            _vm.errorMsg = $$v
          },
          expression: "errorMsg"
        }
      }),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { md: "6" } },
            [
              _c("VfTextField", {
                attrs: {
                  label: _vm.$t("lbl.answer_form.item_name"),
                  vees: _vm.vees.answer_form_input.title
                },
                model: {
                  value: _vm.internalValue.title,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "title", $$v)
                  },
                  expression: "internalValue.title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { md: "6" } },
            [
              _c("VfCheckbox", {
                attrs: { label: _vm.$t("lbl.answer_form.hide_item_name") },
                model: {
                  value: _vm.internalValue.hide_title_flg,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "hide_title_flg", $$v)
                  },
                  expression: "internalValue.hide_title_flg"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-4", attrs: { md: "6" } },
            [
              _c("VfTextField", {
                attrs: {
                  label: _vm.$t("lbl.answer_form.description"),
                  vees: _vm.vees.answer_form_input.short_description,
                  hint: _vm.$t("lbl.answer_form.text_field_desc_hint")
                },
                model: {
                  value: _vm.internalValue.description,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "description", $$v)
                  },
                  expression: "internalValue.description"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pt-6 pb-4" },
        [
          _c(
            "v-card",
            [
              _c("v-card-subtitle", { staticClass: "or-card-title mb-0" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("lbl.answer_form.option")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                { key: _vm.forceRerenderOption },
                [
                  _c(
                    "draggable",
                    {
                      staticClass: "w-100",
                      attrs: {
                        list:
                          _vm.internalValue
                            .answer_form_input_options_attributes,
                        disabled: false,
                        "ghost-class": "ghost",
                        move: _vm.checkMove,
                        handle: ".drag-handle",
                        animation: 200,
                        delay: 0
                      },
                      on: {
                        start: function($event) {
                          _vm.dragging = true
                        },
                        end: function($event) {
                          _vm.dragging = false
                        }
                      }
                    },
                    _vm._l(
                      _vm.internalValue.answer_form_input_options_attributes,
                      function(option, option_i) {
                        return _c(
                          "v-card",
                          { key: option_i, staticClass: "mb-2" },
                          [
                            _c(
                              "v-card-title",
                              {
                                staticClass:
                                  "pa-0 text-subtitle-1 blue-grey lighten-5"
                              },
                              [
                                _c(
                                  "v-list-item",
                                  [
                                    _c("div", { staticClass: "pr-1" }, [
                                      _vm._v(_vm._s(_vm.titleOption(option_i)))
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item-content",
                                      { staticClass: "px-3 py-0" },
                                      [
                                        _c("VfTextField", {
                                          staticClass: "hidden-label",
                                          attrs: {
                                            label: _vm.titleOption(option_i),
                                            vees:
                                              _vm.vees.answer_form_input_option
                                                .value
                                          },
                                          model: {
                                            value:
                                              _vm.internalValue
                                                .answer_form_input_options_attributes[
                                                option_i
                                              ].value,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.internalValue
                                                  .answer_form_input_options_attributes[
                                                  option_i
                                                ],
                                                "value",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                      internalValue.answer_form_input_options_attributes[\n                        option_i\n                      ].value\n                    "
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-card-actions",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "drag-handle",
                                            attrs: { small: "", icon: "" },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-cursor-move")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { small: "", icon: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.copyOption(option_i)
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-content-copy")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              small: "",
                                              icon: "",
                                              disabled:
                                                _vm.inputOptionsLength === 1
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.removeOption(
                                                  option_i
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-close-circle")
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "mt-4" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "font-weight-bold",
                                        attrs: { md: "2" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t(
                                                "lbl.answer_form.configuration"
                                              )
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { md: "10" } },
                                      [
                                        _c(
                                          "v-row",
                                          { attrs: { "no-gutters": "" } },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pa-2 border border-right-0 border-bottom-0 d-flex align-items-center font-weight-bold",
                                                attrs: { md: "2" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "lbl.answer_form.initial_display"
                                                      )
                                                    ) +
                                                    "\n                    "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pa-2 border border-right-0 border-bottom-0"
                                              },
                                              [
                                                _c("VfSelect", {
                                                  attrs: {
                                                    value:
                                                      _vm.internalValue
                                                        .answer_form_input_options_attributes[
                                                        option_i
                                                      ].default_type,
                                                    label: "",
                                                    selects: _vm.defaultType
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.handleDefaultSelect(
                                                        $event,
                                                        option_i
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pa-2 border border-left-0 border-bottom-0"
                                              },
                                              [
                                                _vm.internalValue
                                                  .answer_form_input_options_attributes[
                                                  option_i
                                                ].default_type ===
                                                  "only_with_tag" ||
                                                _vm.internalValue
                                                  .answer_form_input_options_attributes[
                                                  option_i
                                                ].default_type ===
                                                  "only_without_tag"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openDialogDefault(
                                                              option_i
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("VfSelect", {
                                                          attrs: {
                                                            label: "",
                                                            "vv-name": _vm.$$tm(
                                                              "hashtag.name"
                                                            ),
                                                            placeholder: _vm.$t(
                                                              "lbl.answer_form.enter_the_tag_name"
                                                            ),
                                                            selects:
                                                              _vm
                                                                .selectDefaultTags[
                                                                option_i
                                                              ],
                                                            vees: {
                                                              rules: "required"
                                                            },
                                                            readonly: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.internalValue
                                                                .answer_form_input_options_attributes[
                                                                option_i
                                                              ].default_tag_id,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .internalValue
                                                                  .answer_form_input_options_attributes[
                                                                  option_i
                                                                ],
                                                                "default_tag_id",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "\n                            internalValue\n                              .answer_form_input_options_attributes[option_i]\n                              .default_tag_id\n                          "
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-row",
                                          { attrs: { "no-gutters": "" } },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pa-2 border border-right-0 d-flex align-items-center font-weight-bold",
                                                attrs: { md: "2" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "lbl.answer_form.capacity"
                                                      )
                                                    ) +
                                                    "\n                    "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pa-2 border border-right-0"
                                              },
                                              [
                                                _c("VfSelect", {
                                                  attrs: {
                                                    label: "",
                                                    selects:
                                                      _vm.opts.answer_form_input
                                                        .limit_type
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.internalValue
                                                        .answer_form_input_options_attributes[
                                                        option_i
                                                      ].limit_type,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.internalValue
                                                          .answer_form_input_options_attributes[
                                                          option_i
                                                        ],
                                                        "limit_type",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          internalValue.answer_form_input_options_attributes[\n                            option_i\n                          ].limit_type\n                        "
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pa-2 border border-left-0"
                                              },
                                              [
                                                _vm.internalValue
                                                  .answer_form_input_options_attributes[
                                                  option_i
                                                ].limit_type === "use"
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "VfTextFieldOnlyNumber",
                                                          {
                                                            attrs: {
                                                              label: _vm.$t(
                                                                "lbl.answer_form.capacity_number"
                                                              ),
                                                              vees: {
                                                                rules:
                                                                  "|max:9|min_value:1|required"
                                                              },
                                                              minValue: 0
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .internalValue
                                                                  .answer_form_input_options_attributes[
                                                                  option_i
                                                                ].capacity,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm
                                                                    .internalValue
                                                                    .answer_form_input_options_attributes[
                                                                    option_i
                                                                  ],
                                                                  "capacity",
                                                                  _vm._n($$v)
                                                                )
                                                              },
                                                              expression:
                                                                "\n                            internalValue\n                              .answer_form_input_options_attributes[option_i]\n                              .capacity\n                          "
                                                            }
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _c("VfTextField", {
                                                          attrs: {
                                                            label: _vm.$t(
                                                              "lbl.answer_form.capacity_warning"
                                                            ),
                                                            placeholder:
                                                              _vm.$t(
                                                                "lbl.answer_form.can_not_select"
                                                              ) +
                                                              (_vm.internalValue
                                                                .answer_form_input_options_attributes[
                                                                option_i
                                                              ].value || "")
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.internalValue
                                                                .answer_form_input_options_attributes[
                                                                option_i
                                                              ]
                                                                .capacity_warning,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .internalValue
                                                                  .answer_form_input_options_attributes[
                                                                  option_i
                                                                ],
                                                                "capacity_warning",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "\n                            internalValue\n                              .answer_form_input_options_attributes[option_i]\n                              .capacity_warning\n                          "
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass:
                                          "font-weight-bold pt-8 py-0",
                                        attrs: { md: "2" }
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t("lbl.answer_form.action")
                                              ) +
                                              "\n                  "
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0",
                                        attrs: { md: "10" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "pt-5",
                                            on: {
                                              click: function($event) {
                                                _vm.optionIndex = option_i
                                              }
                                            }
                                          },
                                          [
                                            _c("PtActionSelectedList", {
                                              on: {
                                                "pt-action-selected-list-save": function(
                                                  $event
                                                ) {
                                                  return _vm.incrementForceRerenderOption(
                                                    $event,
                                                    option_i
                                                  )
                                                },
                                                "pt-action-selected-list-clear": function(
                                                  $event
                                                ) {
                                                  return _vm.incrementForceRerenderOption(
                                                    $event,
                                                    option_i
                                                  )
                                                },
                                                "pt-search-selected-list-save": function(
                                                  $event
                                                ) {
                                                  return _vm.incrementForceRerenderOption(
                                                    $event,
                                                    option_i
                                                  )
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.internalValue
                                                    .answer_form_input_options_attributes[
                                                    option_i
                                                  ].action_arr_temp,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.internalValue
                                                      .answer_form_input_options_attributes[
                                                      option_i
                                                    ],
                                                    "action_arr_temp",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                        internalValue.answer_form_input_options_attributes[\n                          option_i\n                        ].action_arr_temp\n                      "
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.internalValue.on_page_interact_flg
                                  ? _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "font-weight-bold pt-6 py-0",
                                            attrs: { md: "2" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "lbl.answer_form.on_page_interact_action"
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: { md: "10" }
                                          },
                                          [
                                            _c("VfSelect", {
                                              staticClass: "hidden-label",
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl.answer_form.on_page_interact_action"
                                                ),
                                                selects: _vm.sectionOpts,
                                                vees: {
                                                  rules: _vm.internalValue
                                                    .on_page_interact_flg
                                                    ? "required"
                                                    : ""
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.internalValue
                                                    .answer_form_input_options_attributes[
                                                    option_i
                                                  ].on_page_interact_action,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.internalValue
                                                      .answer_form_input_options_attributes[
                                                      option_i
                                                    ],
                                                    "on_page_interact_action",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                      internalValue.answer_form_input_options_attributes[\n                        option_i\n                      ].on_page_interact_action\n                    "
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }
                    ),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "d-flex justify-content-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-2",
                              on: {
                                click: function($event) {
                                  return _vm.addOption("choice")
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("btn.add_choice")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-2",
                              on: {
                                click: function($event) {
                                  return _vm.addOption("other")
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("btn.add_other")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.inputType === "checkbox"
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pb-0 pr-0", attrs: { md: "2" } },
                [
                  _c("VfCheckbox", {
                    attrs: { label: _vm.$t("lbl.answer_form.selection_limit") },
                    model: {
                      value: _vm.internalValue.limit_select_option_flg,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.internalValue,
                          "limit_select_option_flg",
                          $$v
                        )
                      },
                      expression: "internalValue.limit_select_option_flg"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pb-0", attrs: { md: "5" } },
                [
                  _vm.internalValue.limit_select_option_flg
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "px-0 pb-0", attrs: { md: "5" } },
                            [
                              _c(
                                "validation-provider",
                                { attrs: { name: "min_value" } },
                                [
                                  _c("VfTextField", {
                                    staticClass: "hidden-label",
                                    attrs: {
                                      label: "min_value",
                                      vees: _vm.compareMax,
                                      min: 0,
                                      number: ""
                                    },
                                    model: {
                                      value:
                                        _vm.internalValue
                                          .limit_select_option_min,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.internalValue,
                                          "limit_select_option_min",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "internalValue.limit_select_option_min"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "d-flex justify-content-center align-items-center px-0 pb-0",
                              attrs: { md: "1" }
                            },
                            [_vm._v("～")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "px-0 pb-0", attrs: { md: "5" } },
                            [
                              _c(
                                "validation-provider",
                                { attrs: { name: "max_value" } },
                                [
                                  _c("VfTextFieldOnlyNumber", {
                                    staticClass: "hidden-label",
                                    attrs: {
                                      label: "max_value",
                                      vees: _vm.compareMin,
                                      minValue: 0
                                    },
                                    model: {
                                      value:
                                        _vm.internalValue
                                          .limit_select_option_max,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.internalValue,
                                          "limit_select_option_max",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "internalValue.limit_select_option_max"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pb-0 offset-1", attrs: { md: "2" } },
                [
                  _c("VfCheckbox", {
                    attrs: { label: _vm.$t("lbl.answer_form.require") },
                    model: {
                      value: _vm.internalValue.require_flg,
                      callback: function($$v) {
                        _vm.$set(_vm.internalValue, "require_flg", $$v)
                      },
                      expression: "internalValue.require_flg"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pb-0", attrs: { md: "2" } },
                [
                  _c("VfCheckbox", {
                    attrs: { label: _vm.$t("lbl.answer_form.hide") },
                    model: {
                      value: _vm.internalValue.hidden_flg,
                      callback: function($$v) {
                        _vm.$set(_vm.internalValue, "hidden_flg", $$v)
                      },
                      expression: "internalValue.hidden_flg"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.inputType === "radio" || _vm.inputType === "dropdown"
        ? _c(
            "v-row",
            { staticClass: "pb-4" },
            [
              _c(
                "v-col",
                { staticClass: "pb-0 pr-0", attrs: { md: "3" } },
                [
                  _vm.checkedInteractIndex === _vm.setupIndex ||
                  _vm.checkedInteractIndex === -1
                    ? _c("VfCheckbox", {
                        attrs: {
                          label: _vm.$t("lbl.answer_form.move_to_section"),
                          "persistent-hint": true,
                          hint: _vm.$t(
                            "lbl.answer_form.please_create_destination"
                          ),
                          "hide-details": "auto"
                        },
                        on: { change: _vm.updateOnPageInteractAction },
                        model: {
                          value: _vm.internalValue.on_page_interact_flg,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.internalValue,
                              "on_page_interact_flg",
                              $$v
                            )
                          },
                          expression: "internalValue.on_page_interact_flg"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("v-col", { staticClass: "pb-0", attrs: { md: "4" } }),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pb-0 offset-1", attrs: { md: "2" } },
                [
                  _c("VfCheckbox", {
                    attrs: { label: _vm.$t("lbl.answer_form.require") },
                    model: {
                      value: _vm.internalValue.require_flg,
                      callback: function($$v) {
                        _vm.$set(_vm.internalValue, "require_flg", $$v)
                      },
                      expression: "internalValue.require_flg"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pb-0", attrs: { md: "2" } },
                [
                  _c("VfCheckbox", {
                    attrs: { label: _vm.$t("lbl.answer_form.hide") },
                    model: {
                      value: _vm.internalValue.hidden_flg,
                      callback: function($$v) {
                        _vm.$set(_vm.internalValue, "hidden_flg", $$v)
                      },
                      expression: "internalValue.hidden_flg"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          model: {
            value: _vm.dialogDefaultTag,
            callback: function($$v) {
              _vm.dialogDefaultTag = $$v
            },
            expression: "dialogDefaultTag"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("VfSelect", {
                                    attrs: {
                                      label: _vm.$$tm("scenario.name"),
                                      selects: _vm.selectDialogDefault,
                                      readonly: ""
                                    },
                                    model: {
                                      value: _vm.checkedDefaultTagId,
                                      callback: function($$v) {
                                        _vm.checkedDefaultTagId = $$v
                                      },
                                      expression: "checkedDefaultTagId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c("PtFolder", {
                            ref: "folder",
                            attrs: {
                              "folder-type": "hashtag",
                              "select-only": ""
                            },
                            on: { "select-folder": _vm.selectFolder }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { md: "8" } },
                        [
                          _c("v-data-table", {
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.items,
                              "item-key": "id",
                              "show-select": "",
                              "single-select": true,
                              "disable-sort": true,
                              "items-per-page": 50,
                              "footer-props": {
                                itemsPerPageOptions: [50, 100, 150]
                              }
                            },
                            on: { "click:row": _vm.rowClick },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.action_group",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c("PtActionLabelMessages", {
                                        staticClass: "or-list",
                                        model: {
                                          value:
                                            item.action_group.make_action_arr,
                                          callback: function($$v) {
                                            _vm.$set(
                                              item.action_group,
                                              "make_action_arr",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.action_group.make_action_arr"
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.checkedDefaultTags,
                              callback: function($$v) {
                                _vm.checkedDefaultTags = $$v
                              },
                              expression: "checkedDefaultTags"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }