var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      !_vm.hiddenTitle
        ? _c(
            "v-col",
            { attrs: { sm: "4", cols: "12", xs: "12" } },
            [
              _c("span", { staticClass: "text-bold" }, [
                _vm._v(_vm._s(_vm.title))
              ]),
              _vm._v(" "),
              _vm.required
                ? _c(
                    "v-chip",
                    {
                      staticClass: "ma-2",
                      attrs: {
                        label: "",
                        color: "red",
                        "text-color": "white",
                        small: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("lbl.answer_form.require")) +
                          "\n    "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { sm: "8", cols: "12", xs: "12" } },
        [
          _c("VfTextArea", {
            staticClass: "vf-p-0 vf-hidden-label",
            attrs: {
              label: _vm.label,
              placeholder: _vm.placeholder,
              hint: _vm.hint,
              vees: _vm.vees,
              "auto-grow": false
            },
            model: {
              value: _vm.internalValue,
              callback: function($$v) {
                _vm.internalValue = $$v
              },
              expression: "internalValue"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }