var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto py-4", attrs: { "max-width": "800" } },
    [
      _c(
        "v-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitForm($event)
            }
          }
        },
        [
          _c(
            "v-card-title",
            [
              _c("v-row", { attrs: { justify: "center" } }, [
                _c("div", [
                  _vm._v(_vm._s(_vm.$t("meta.title." + _vm.$route.name)))
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mx-4" }),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "text-h5 font-weight-bold" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("vf-text-field", {
                        attrs: { label: "メールアドレス" },
                        model: {
                          value: _vm.item.email,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "email", $$v)
                          },
                          expression: "item.email"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("vue-recaptcha", {
                        ref: "recaptcha",
                        attrs: {
                          sitekey: "6LeIc-UUAAAAAF_hZmOAtE6maZozgVQCH3nEdvBh"
                        },
                        on: { verify: _vm.onVerify }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    type: "submit",
                    color: "primary",
                    disabled: _vm.submiting
                  }
                },
                [_vm._v("送信")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }