var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "10" } }, [_c("PtTitle")], 1),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "2" } }, [
            _c(
              "div",
              { staticStyle: { "text-align": "right", "margin-top": "20px" } },
              [
                _vm.fixedPhraseId !== "new"
                  ? _c(
                      "v-btn",
                      { attrs: { color: "error" }, on: { click: _vm.destroy } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("btn.delete")) +
                            "\n        "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.initDone
        ? _c(
            "validation-observer",
            { ref: "observer" },
            [
              _c(
                "v-card",
                { staticClass: "transparent mb-6", attrs: { outlined: "" } },
                [
                  _c("v-card-title", { staticClass: "pl-0 or-card-title" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("lbl.fixed_phrase.info")) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    { staticClass: "white" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("VfSelect", {
                                attrs: {
                                  label: _vm.$$tm("folder.name"),
                                  vees: _vm.vees.folder_id,
                                  selects: _vm.folders
                                },
                                model: {
                                  value: _vm.fixedPhrase.folder_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.fixedPhrase, "folder_id", $$v)
                                  },
                                  expression: "fixedPhrase.folder_id"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("VfTextField", {
                                attrs: {
                                  label: _vm.$$tm("fixed_phrase.name"),
                                  vees: _vm.vees.name
                                },
                                model: {
                                  value: _vm.fixedPhrase.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.fixedPhrase, "name", $$v)
                                  },
                                  expression: "fixedPhrase.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "transparent mb-6", attrs: { outlined: "" } },
                [
                  _c("v-card-title", { staticClass: "pl-0 or-card-title" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("lbl.send_message")) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    { staticClass: "grey lighten-4" },
                    [
                      _vm.initDone
                        ? _c("PtDeli", {
                            model: {
                              value: _vm.delis,
                              callback: function($$v) {
                                _vm.delis = $$v
                              },
                              expression: "delis"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.$wait.is("processing")
                          },
                          on: { click: _vm.save }
                        },
                        [_vm._v(_vm._s(_vm.$t("btn.create")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("VfDialogConfirm", {
        attrs: {
          title: _vm.$t("confirm_title.delete"),
          detail: _vm.$t("confirm.delete"),
          "save-path": _vm.savePath,
          "form-type": "delete",
          "target-emit": "delete-success"
        },
        on: { "delete-success": _vm.deleteSuccess },
        model: {
          value: _vm.deleteDialog,
          callback: function($$v) {
            _vm.deleteDialog = $$v
          },
          expression: "deleteDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }