var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c("VfLoadingOverlayAbsolute", {
            attrs: { loading: _vm.$wait.is("processing") }
          }),
          _vm._v(" "),
          _c(
            "v-card-title",
            { staticClass: "pa-0" },
            [
              _c(
                "v-list-item",
                [
                  _c("v-list-item-content", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("lbl.friend.schedule")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.hasRole
                    ? _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-wait",
                            { attrs: { for: "processing" } },
                            [
                              _c("PtDialogSelect", {
                                attrs: { "select-type": "schedule" },
                                on: { decide: _vm.save },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "opener",
                                      fn: function() {
                                        return [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { icon: "", outlined: "" }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-pencil")
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "item.action_group",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c("PtActionLabelMessages", {
                                            staticClass: "or-list",
                                            model: {
                                              value:
                                                item.action_group
                                                  .make_action_arr,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item.action_group,
                                                  "make_action_arr",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.action_group.make_action_arr"
                                            }
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.schedule,
                                  callback: function($$v) {
                                    _vm.schedule = $$v
                                  },
                                  expression: "schedule"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("VDivider", { staticClass: "my-0" }),
          _vm._v(" "),
          _c(
            "v-card-text",
            _vm._l(_vm.internalValue, function(schedule, schedule_idx) {
              return _c("v-chip", { key: schedule_idx, staticClass: "ma-2" }, [
                _vm._v("\n        " + _vm._s(schedule.name) + "\n      ")
              ])
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }