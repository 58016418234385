var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [_c("v-col", { attrs: { cols: "10" } }, [_c("PtTitle")], 1)],
        1
      ),
      _vm._v(" "),
      _vm.initDone
        ? _c(
            "validation-observer",
            { ref: "observer" },
            [
              _c(
                "v-card",
                { staticClass: "mb-6" },
                [
                  _c("v-card-title", { staticClass: "or-card-title mb-6" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("lbl.action_template.info")) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("VfSelect", {
                                attrs: {
                                  label: _vm.$$tm("folder.name"),
                                  vees: _vm.vees.folder_id,
                                  selects: _vm.folders
                                },
                                model: {
                                  value: _vm.answer_form.folder_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.answer_form, "folder_id", $$v)
                                  },
                                  expression: "answer_form.folder_id"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("VfTextField", {
                                attrs: {
                                  label: _vm.$$tm("answer_form.name"),
                                  vees: _vm.vees.answer_form.name
                                },
                                model: {
                                  value: _vm.answer_form.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.answer_form, "name", $$v)
                                  },
                                  expression: "answer_form.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "mb-6" },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("VfTextField", {
                                attrs: {
                                  label: _vm.$$tm("answer_form.form_title"),
                                  vees: _vm.vees.answer_form.form_title
                                },
                                model: {
                                  value: _vm.answer_form.form_title,
                                  callback: function($$v) {
                                    _vm.$set(_vm.answer_form, "form_title", $$v)
                                  },
                                  expression: "answer_form.form_title"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { md: "12" } },
                            [
                              _c("VfTextArea", {
                                attrs: {
                                  label: _vm.$$tm(
                                    "answer_form.form_description"
                                  ),
                                  vees: _vm.vees.answer_form.form_description
                                },
                                model: {
                                  value: _vm.answer_form.form_description,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.answer_form,
                                      "form_description",
                                      $$v
                                    )
                                  },
                                  expression: "answer_form.form_description"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pb-0 pt-0", attrs: { md: "6" } },
                            [
                              _c("VfCheckbox", {
                                attrs: {
                                  label: _vm.$$tm(
                                    "answer_form.spreadsheet_replication_flg"
                                  ),
                                  vees:
                                    _vm.vees.answer_form
                                      .spreadsheet_replication_flg
                                },
                                model: {
                                  value:
                                    _vm.answer_form.spreadsheet_replication_flg,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.answer_form,
                                      "spreadsheet_replication_flg",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "answer_form.spreadsheet_replication_flg"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _vm._l(_vm.sections, function(section, section_i) {
                        return [
                          _c(
                            "v-card-title",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.sections.length > 1,
                                  expression: "sections.length > 1"
                                }
                              ],
                              key: section_i
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { md: "8" } }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t("lbl.answer_form.section") +
                                            (section_i + 1)
                                        ) +
                                        "\n              "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-right",
                                      attrs: { md: "4" }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { small: "" },
                                          on: {
                                            click: function($event) {
                                              _vm.removeDialog = true
                                              _vm.removeSectionIndex = section_i
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-close")]
                                          ),
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "lbl.answer_form.delete_section"
                                              )
                                            ) + "\n                "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("PtFormSetupSection", {
                            key: "PtFormSetupSection_" + section_i,
                            attrs: {
                              "init-data": _vm.initData,
                              titles: _vm.titles,
                              targets: _vm.allAssignTarget,
                              "section-length": _vm.sections.length,
                              "section-list": _vm.sectionList,
                              "section-index": section_i,
                              vees: _vm.vees,
                              opts: _vm.opts
                            },
                            on: { update: _vm.handleFormUpdate },
                            model: {
                              value: _vm.sections[section_i],
                              callback: function($$v) {
                                _vm.$set(_vm.sections, section_i, $$v)
                              },
                              expression: "sections[section_i]"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-divider", { key: "divider_" + section_i })
                        ]
                      }),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-block",
                                  on: { click: _vm.addSection }
                                },
                                [
                                  _c("v-icon", [_vm._v("mdi-plus")]),
                                  _vm._v(_vm._s(_vm.$t("btn.add_section")))
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "mb-6" },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: _vm.tabActive(_vm.settingOption)
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeSettingTab(
                                        _vm.settingOption
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("lbl.answer_form.btn_option"))
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: _vm.tabActive(_vm.settingDesign)
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeSettingTab(
                                        _vm.settingDesign
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "lbl.answer_form.btn_design_setting"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: _vm.tabActive(_vm.settingAutoInput)
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeSettingTab(
                                        _vm.settingAutoInput
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("lbl.answer_form.btn_auto_input")
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.currentSettingTab === _vm.settingOption
                        ? [
                            _c("v-divider"),
                            _vm._v(" "),
                            _c(
                              "v-row",
                              { staticClass: "align-center mx-6" },
                              [
                                _c("v-col", { attrs: { md: "3" } }, [
                                  _c("span", { staticClass: "text-16" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "lbl.answer_form.expired_at_flag"
                                        )
                                      )
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { md: "2" } },
                                  [
                                    _c("VfCheckbox", {
                                      staticClass: "vf-unset-default",
                                      attrs: {
                                        label: _vm.$t(
                                          "lbl.answer_form.expired_at_flag_value"
                                        )
                                      },
                                      on: { change: _vm.changeExpiredAt },
                                      model: {
                                        value:
                                          _vm.answer_form_setting
                                            .expired_at_flag,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.answer_form_setting,
                                            "expired_at_flag",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "answer_form_setting.expired_at_flag"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.isDisplayExpiredAt
                                  ? _c(
                                      "v-col",
                                      { attrs: { md: "7" } },
                                      [
                                        _c("VfDateTimeSelect", {
                                          attrs: {
                                            label: "",
                                            vees: _vm.vees.disp_st_at,
                                            format: "YYYY/MM/DD HH:mm"
                                          },
                                          model: {
                                            value:
                                              _vm.answer_form_setting
                                                .expired_at,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.answer_form_setting,
                                                "expired_at",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "answer_form_setting.expired_at"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.isDisplayExpiredAt
                              ? _c(
                                  "v-row",
                                  { staticClass: "mx-6" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "align-self-start",
                                        attrs: { md: "3" }
                                      },
                                      [
                                        _c("span", { staticClass: "text-16" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "lbl.answer_form.expired_at_flag_desc"
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { md: "9" } },
                                      [
                                        _c("VfTextArea", {
                                          staticClass: "vf-hidden-label",
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl.answer_form.expired_at_flag_desc"
                                            ),
                                            vees:
                                              _vm.vees.answer_form_setting
                                                .deadline_text
                                          },
                                          model: {
                                            value:
                                              _vm.answer_form_setting
                                                .deadline_text,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.answer_form_setting,
                                                "deadline_text",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "answer_form_setting.deadline_text"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "v-row",
                              { staticClass: "align-center mx-6" },
                              [
                                _c("v-col", { attrs: { md: "3" } }, [
                                  _c("span", { staticClass: "text-16" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("lbl.answer_form.response_limit")
                                      )
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { md: "2" } },
                                  [
                                    _c("VfCheckbox", {
                                      staticClass: "vf-unset-default",
                                      attrs: {
                                        label: _vm.$t(
                                          "lbl.answer_form.response_limit_value"
                                        ),
                                        "true-value": 0,
                                        "false-value": 1
                                      },
                                      model: {
                                        value:
                                          _vm.answer_form_setting
                                            .response_limit,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.answer_form_setting,
                                            "response_limit",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "answer_form_setting.response_limit"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.answer_form_setting.response_limit
                                  ? _c(
                                      "v-col",
                                      { attrs: { md: "7" } },
                                      [
                                        _c("VfTextField", {
                                          attrs: {
                                            label: "",
                                            number: true,
                                            min: 0,
                                            vees:
                                              _vm.vees.answer_form_setting
                                                .response_limit
                                          },
                                          on: {
                                            input: _vm.responseLimitChange
                                          },
                                          model: {
                                            value:
                                              _vm.answer_form_setting
                                                .response_limit,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.answer_form_setting,
                                                "response_limit",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "answer_form_setting.response_limit"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.answer_form_setting.response_limit
                              ? _c(
                                  "v-row",
                                  { staticClass: "align-center mx-6" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "align-self-start",
                                        attrs: { md: "3" }
                                      },
                                      [
                                        _c("span", { staticClass: "text-16" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "lbl.answer_form.response_limit_desc"
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { md: "9" } },
                                      [
                                        _c("VfTextArea", {
                                          staticClass: "vf-hidden-label",
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl.answer_form.response_limit_desc"
                                            ),
                                            vees:
                                              _vm.vees.answer_form_setting
                                                .response_limit_text
                                          },
                                          model: {
                                            value:
                                              _vm.answer_form_setting
                                                .response_limit_text,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.answer_form_setting,
                                                "response_limit_text",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "answer_form_setting.response_limit_text"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "v-row",
                              { staticClass: "align-center mx-6" },
                              [
                                _c("v-col", { attrs: { md: "3" } }, [
                                  _c("span", { staticClass: "text-16" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "lbl.answer_form.response_limit_per_person"
                                        )
                                      )
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { md: "9" } },
                                  [
                                    _c("VfSelect", {
                                      attrs: {
                                        label: "",
                                        selects: _vm.response_limit_options
                                      },
                                      model: {
                                        value:
                                          _vm.answer_form_setting
                                            .response_limit_per_person,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.answer_form_setting,
                                            "response_limit_per_person",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "answer_form_setting.response_limit_per_person"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.answer_form_setting.response_limit_per_person
                              ? _c(
                                  "v-row",
                                  { staticClass: "align-center mx-6" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "align-self-start",
                                        attrs: { md: "3" }
                                      },
                                      [
                                        _c("span", { staticClass: "text-16" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "lbl.answer_form.response_limit_per_person_desc"
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { md: "9" } },
                                      [
                                        _c("VfTextArea", {
                                          staticClass: "vf-hidden-label",
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl.answer_form.response_limit_per_person_desc"
                                            ),
                                            vees:
                                              _vm.vees.answer_form_setting
                                                .response_limit_per_person_text
                                          },
                                          model: {
                                            value:
                                              _vm.answer_form_setting
                                                .response_limit_per_person_text,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.answer_form_setting,
                                                "response_limit_per_person_text",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "answer_form_setting.response_limit_per_person_text"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("v-divider"),
                            _vm._v(" "),
                            _c(
                              "v-row",
                              { staticClass: "align-center mx-6" },
                              [
                                _c("v-col", { attrs: { md: "3" } }, [
                                  _c("span", { staticClass: "text-16" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("lbl.answer_form.thank_page_url")
                                      )
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { md: "9" } },
                                  [
                                    _c("VfTextField", {
                                      staticClass: "vf-hidden-label",
                                      attrs: {
                                        label: _vm.$t(
                                          "lbl.answer_form.thank_page_url"
                                        ),
                                        vees:
                                          _vm.vees.answer_form_setting
                                            .thank_page_url
                                      },
                                      model: {
                                        value:
                                          _vm.answer_form_setting
                                            .thank_page_url,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.answer_form_setting,
                                            "thank_page_url",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "answer_form_setting.thank_page_url"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            !_vm.answer_form_setting.thank_page_url ||
                            _vm.answer_form_setting.thank_page_url.length == 0
                              ? _c(
                                  "v-row",
                                  { staticClass: "mx-6" },
                                  [
                                    _c("v-col", { attrs: { md: "3" } }, [
                                      _c("span", { staticClass: "text-16" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "lbl.answer_form.submit_response_message"
                                            )
                                          )
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { md: "9" } },
                                      [
                                        _c("VfTextArea", {
                                          staticClass: "vf-hidden-label",
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl.answer_form.submit_response_message"
                                            ),
                                            vees:
                                              _vm.vees.answer_form_setting
                                                .submit_response_message
                                          },
                                          model: {
                                            value:
                                              _vm.answer_form_setting
                                                .submit_response_message,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.answer_form_setting,
                                                "submit_response_message",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "answer_form_setting.submit_response_message"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "text-12" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "lbl.answer_form.submit_response_message_helper"
                                              )
                                            )
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "v-row",
                              { staticClass: "mx-6" },
                              [
                                _c("v-col", { attrs: { md: "3" } }, [
                                  _c("span", { staticClass: "text-16" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "lbl.answer_form.action_after_answer"
                                        )
                                      )
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { md: "9" } },
                                  [
                                    _c("PtActionSelectedList", {
                                      model: {
                                        value: _vm.actionsAnswerSetting,
                                        callback: function($$v) {
                                          _vm.actionsAnswerSetting = $$v
                                        },
                                        expression: "actionsAnswerSetting"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-divider"),
                            _vm._v(" "),
                            _c(
                              "v-row",
                              { staticClass: "mx-6" },
                              [
                                _c("v-col", { attrs: { md: "3" } }, [
                                  _c("span", { staticClass: "text-16" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "lbl.answer_form.answer_restoration_flg"
                                        )
                                      )
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { md: "9" } },
                                  [
                                    _c("VfCheckbox", {
                                      staticClass: "vf-unset-default",
                                      attrs: {
                                        label: _vm.$t(
                                          "lbl.answer_form.answer_restoration_flg_value"
                                        )
                                      },
                                      model: {
                                        value:
                                          _vm.answer_form_setting
                                            .answer_restoration_flg,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.answer_form_setting,
                                            "answer_restoration_flg",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "answer_form_setting.answer_restoration_flg"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-caption grey--text text--lighten-1"
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                "lbl.answer_form.answer_restoration_flg_helper"
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isSetDefaultSettingDone
                        ? [
                            _c("color-design-setting", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.currentSettingTab === _vm.settingDesign,
                                  expression:
                                    "currentSettingTab === settingDesign"
                                }
                              ],
                              attrs: {
                                mode: _vm.mode,
                                value: _vm.answer_form_setting,
                                rules: _vm.vees.answer_form_setting
                              },
                              on: { change: _vm.updateDesignSettings }
                            })
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isSetDefaultSettingDone
                        ? [
                            _c("auto-input-setting", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.currentSettingTab ===
                                    _vm.settingAutoInput,
                                  expression:
                                    "currentSettingTab === settingAutoInput"
                                }
                              ],
                              attrs: {
                                mode: _vm.mode,
                                "auto-list": _vm.autoInputList,
                                value: _vm.autoInputSettings,
                                rules: _vm.vees.answer_form_setting
                              },
                              on: { change: _vm.updateAutoInputSettings }
                            })
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        { staticClass: "mt-1" },
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _vm.answerFormId !== "new"
                                ? _c("call-tag", {
                                    attrs: {
                                      "form-key": _vm.answer_form.alt_uuid
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-right align-self-end",
                              attrs: { md: "6" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "px-10",
                                  attrs: {
                                    large: "",
                                    color: "success",
                                    loading: _vm.$wait.is("processing")
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.save(false)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("btn.save_data")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "px-10",
                                  attrs: {
                                    large: "",
                                    color: "primary",
                                    loading: _vm.$wait.is("processing")
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.save(true)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("btn.save_preview")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "300" },
          model: {
            value: _vm.removeDialog,
            callback: function($$v) {
              _vm.removeDialog = $$v
            },
            expression: "removeDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(_vm._s(""))]),
              _vm._v(" "),
              _c("v-card-text", [
                _c("div", { staticClass: "or-break" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("lbl.answer_form.confirm_delete_section")) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("VSpacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function($event) {
                          _vm.removeDialog = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("btn.cancel")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: {
                        click: function($event) {
                          return _vm.removeSection(_vm.removeSectionIndex)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("btn.yes")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }