var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type && _vm.internalValue
    ? _c(
        "v-alert",
        {
          attrs: { type: _vm.type },
          scopedSlots: _vm._u(
            [
              {
                key: "append",
                fn: function() {
                  return [
                    _c("v-icon", { on: { click: _vm.close } }, [
                      _vm._v("mdi-close-circle")
                    ])
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            2198149445
          )
        },
        [
          _c("div", { staticClass: "or-break" }, [
            _vm._v(_vm._s(_vm.internalValue))
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }