var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      ref: "dateMenu",
      attrs: {
        "close-on-content-click": false,
        transition: "scale-transition",
        "offset-y": "",
        "max-width": "290px",
        "min-width": "290px"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c("validation-provider", {
                attrs: { name: _vm.label, rules: _vm.rules },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var errors = ref.errors
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              {
                                attrs: {
                                  label: _vm.label,
                                  placeholder: _vm.placeholder,
                                  "error-messages": errors,
                                  disabled: _vm.disabled,
                                  readonly: true,
                                  autocomplete: "off",
                                  clearable: ""
                                },
                                on: {
                                  "click:clear": function($event) {
                                    _vm.datePick = null
                                  }
                                },
                                model: {
                                  value: _vm.internalValue,
                                  callback: function($$v) {
                                    _vm.internalValue = $$v
                                  },
                                  expression: "internalValue"
                                }
                              },
                              on
                            )
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ]
          }
        }
      ]),
      model: {
        value: _vm.dateMenu,
        callback: function($$v) {
          _vm.dateMenu = $$v
        },
        expression: "dateMenu"
      }
    },
    [
      _vm._v(" "),
      _c("v-date-picker", {
        attrs: {
          "no-title": "",
          min: _vm.min,
          max: _vm.max,
          "day-format": function(date) {
            return new Date(date).getDate()
          },
          "allowed-dates": _vm.allowedDates
        },
        on: {
          input: function($event) {
            _vm.dateMenu = false
          }
        },
        model: {
          value: _vm.datePick,
          callback: function($$v) {
            _vm.datePick = $$v
          },
          expression: "datePick"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }