var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _vm.internalValue.name
            ? _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "6" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { width: 200, height: 200 } },
                            [
                              _c("VImg", {
                                attrs: {
                                  src: _vm.$$noimageHelper(
                                    _vm.internalValue.api_picture_url
                                  ),
                                  alt: _vm.internalValue.name
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { md: "6" } },
                        [
                          _vm.internalValue.blocked_flg
                            ? [
                                _c("div", { staticClass: "red--text" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$$tm("friend.blocked_flg")) +
                                      "\n              "
                                  ),
                                  _vm.internalValue.blocked_at_formatted
                                    ? _c("p", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.internalValue
                                                .blocked_at_formatted
                                            ) +
                                            "\n              "
                                        )
                                      ])
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("VDivider")
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.internalValue.blocking_flg
                            ? [
                                _c("div", { staticClass: "red--text" }, [
                                  _vm._v(
                                    "\n              ブロックしています\n              "
                                  ),
                                  _vm.internalValue.blocking_at_formatted
                                    ? _c("p", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.internalValue
                                                .blocking_at_formatted
                                            ) +
                                            "\n              "
                                        )
                                      ])
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("VDivider")
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$$tm("friend.api_display_name")) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.internalValue.api_display_name) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("VDivider"),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$$tm("friend.api_status_message")) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.internalValue.api_status_message || "-"
                                ) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("VDivider")
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "pa-0" },
                        [
                          _c(
                            "v-list-item",
                            [
                              _c("v-list-item-content", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("lbl.friend.sys_setting")) +
                                    "\n            "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-list-item-action",
                                [
                                  _vm.hasRole
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "", outlined: "" },
                                          on: { click: _vm.openDialog }
                                        },
                                        [_c("v-icon", [_vm._v("mdi-pencil")])],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("VDivider", { staticClass: "my-0" }),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c("v-simple-table", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function() {
                                    return [
                                      _c("tbody", [
                                        _c("tr", [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$$tm(
                                                  "friend.message_status"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$$te(
                                                  "friend.message_status",
                                                  _vm.internalValue
                                                )
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$$tm(
                                                  "friend.system_disp_name"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.internalValue
                                                  .system_disp_name || "-"
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$$tm(
                                                  "friend.reservation_name"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.internalValue
                                                  .reservation_name || "-"
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(_vm.$$tm("friend.email"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.internalValue.email || "-"
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(_vm.$$tm("friend.phone"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.internalValue.phone || "-"
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(_vm.$$tm("friend.memo"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            [
                                              [
                                                _vm.internalValue.memo
                                                  ? _c(
                                                      "span",
                                                      {
                                                        on: {
                                                          click: _vm.showDialog
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "truncate"
                                                              )(
                                                                _vm
                                                                  .internalValue
                                                                  .memo,
                                                                13
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : _c("span", [_vm._v("-")])
                                              ]
                                            ],
                                            2
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$$tm("friend.blocking_flg")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$$flg(
                                                  _vm.internalValue.blocking_flg
                                                )
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$$tm("friend.hidden_flg")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$$flg(
                                                  _vm.internalValue.hidden_flg
                                                )
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$$tm("friend.test_friend")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$$flg(
                                                  _vm.internalValue[
                                                    "test_friend?"
                                                  ]
                                                )
                                              )
                                            )
                                          ])
                                        ])
                                      ])
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              4050764359
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasRole
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.previewDialog,
                callback: function($$v) {
                  _vm.previewDialog = $$v
                },
                expression: "previewDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-text", [
                    _c("div", { staticClass: "or-break memo-content" }, [
                      _vm._v(_vm._s(_vm.internalValue.memo))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasRole
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": 500 },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("lbl.friend.sys_setting")) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c("VfLoadingOverlayAbsolute", {
                    attrs: { loading: _vm.loading }
                  }),
                  _vm._v(" "),
                  _c("VfAlert", {
                    attrs: { type: "error" },
                    model: {
                      value: _vm.errorMsg,
                      callback: function($$v) {
                        _vm.errorMsg = $$v
                      },
                      expression: "errorMsg"
                    }
                  }),
                  _vm._v(" "),
                  _vm.internalValue.api_display_name
                    ? _c(
                        "validation-observer",
                        { ref: "observer" },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { md: "12" }
                                    },
                                    [
                                      _c("VfSelect", {
                                        attrs: {
                                          label: _vm.$$tm(
                                            "friend.message_status"
                                          ),
                                          vees: _vm.vees.message_status,
                                          selects: _vm.opts.message_status
                                        },
                                        model: {
                                          value: _vm.friendClone.message_status,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.friendClone,
                                              "message_status",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "friendClone.message_status"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { md: "12" }
                                    },
                                    [
                                      _c("VfTextField", {
                                        attrs: {
                                          label: _vm.$$tm(
                                            "friend.system_disp_name"
                                          ),
                                          vees: _vm.vees.system_disp_name,
                                          counter: ""
                                        },
                                        model: {
                                          value:
                                            _vm.friendClone.system_disp_name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.friendClone,
                                              "system_disp_name",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "friendClone.system_disp_name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { md: "12" }
                                    },
                                    [
                                      _c("VfTextField", {
                                        attrs: {
                                          label: _vm.$$tm(
                                            "friend.reservation_name"
                                          ),
                                          vees: _vm.vees.reservation_name
                                        },
                                        model: {
                                          value:
                                            _vm.friendClone.reservation_name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.friendClone,
                                              "reservation_name",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "friendClone.reservation_name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { md: "12" }
                                    },
                                    [
                                      _c("VfTextField", {
                                        attrs: {
                                          label: _vm.$$tm("friend.email"),
                                          vees: _vm.vees.email
                                        },
                                        model: {
                                          value: _vm.friendClone.email,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.friendClone,
                                              "email",
                                              $$v
                                            )
                                          },
                                          expression: "friendClone.email"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { md: "12" }
                                    },
                                    [
                                      _c("VfTextField", {
                                        attrs: {
                                          label: _vm.$$tm("friend.phone"),
                                          vees: _vm.vees.phone
                                        },
                                        model: {
                                          value: _vm.friendClone.phone,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.friendClone,
                                              "phone",
                                              $$v
                                            )
                                          },
                                          expression: "friendClone.phone"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { md: "12" }
                                    },
                                    [
                                      _c("VfTextArea", {
                                        attrs: {
                                          label: _vm.$$tm("friend.memo"),
                                          vees: { rules: "max:2000", max: 2000 }
                                        },
                                        model: {
                                          value: _vm.friendClone.memo,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.friendClone,
                                              "memo",
                                              $$v
                                            )
                                          },
                                          expression: "friendClone.memo"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { staticClass: "py-0", attrs: { md: "6" } },
                                    [
                                      _c("VfSwitch", {
                                        attrs: {
                                          label: _vm.$$tm(
                                            "friend.blocking_flg"
                                          ),
                                          vees: _vm.vees.blocking_flg
                                        },
                                        model: {
                                          value: _vm.friendClone.blocking_flg,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.friendClone,
                                              "blocking_flg",
                                              $$v
                                            )
                                          },
                                          expression: "friendClone.blocking_flg"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { staticClass: "py-0", attrs: { md: "6" } },
                                    [
                                      _c("VfSwitch", {
                                        attrs: {
                                          label: _vm.$$tm("friend.hidden_flg"),
                                          vees: _vm.vees.hidden_flg
                                        },
                                        model: {
                                          value: _vm.friendClone.hidden_flg,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.friendClone,
                                              "hidden_flg",
                                              $$v
                                            )
                                          },
                                          expression: "friendClone.hidden_flg"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { staticClass: "py-0", attrs: { md: "6" } },
                                    [
                                      _c("VfSwitch", {
                                        attrs: {
                                          "true-value": true,
                                          "false-value": false,
                                          label: _vm.$$tm("friend.test_friend"),
                                          vees: _vm.vees["test_friend?"]
                                        },
                                        model: {
                                          value:
                                            _vm.friendClone["test_friend?"],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.friendClone,
                                              "test_friend?",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "friendClone['test_friend?']"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            [
                              _c("VSpacer"),
                              _vm._v(" "),
                              _c("v-btn", { on: { click: _vm.closeDialog } }, [
                                _vm._v(_vm._s(_vm.$t("btn.cancel")))
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: { click: _vm.save }
                                },
                                [_vm._v(_vm._s(_vm.$t("btn.update")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }