<template>
  <v-navigation-drawer
    v-if="canShow"
    v-model="drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
    :width="200"
    app
  >
    <v-list dense dark color="blue-grey darken-4">
      <template v-for="drawerMenu in drawerMenus">
        <v-list-group
          v-if="drawerMenu.children"
          :key="drawerMenu.text"
          v-model="drawerMenu.active"
          no-action
          disabled
          class="drawer_group"
          append-icon=""
        >
          <template #activator>
            <v-list-item-content>
              <v-list-item-title class="drawer-item-text">
                {{ drawerMenu.text }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="(child, i) in drawerMenu.children"
            :key="i"
            :to="child.to"
            :href="child.href"
            link
            :target="child.target"
          >
            <v-list-item-content>
              <v-list-item-title class="drawer-item-text">
                {{ child.text }}
              </v-list-item-title>
              <span
                v-if="isFriendList(child) && $store.getters.unreadMessageCount"
                class="unread-count"
              >
                {{ $store.getters.unreadMessageCount }}
              </span>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-else
          :key="drawerMenu.text"
          :to="drawerMenu.to"
          :href="drawerMenu.href"
          link
          :target="drawerMenu.target"
        >
          <v-list-item-content>
            <v-list-item-title class="drawer-item-text ml-3">
              {{ drawerMenu.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    value: { default: null },
    canShow: { type: Boolean, required: true },
  },
  computed: {
    guestMenus() {
      return [{ text: 'Registration' }, { text: 'Login' }]
    },
    noChannelMenus() {
      return [{ text: 'AccountShow' }, { text: 'Logout' }]
    },
    userMenus() {
      const menus = [
        { text: 'AccountShow' },
        { text: 'BaseHome' },
        {
          text: 'OneByOneTalkSetting',
          active: true,
          children: [
            { text: 'FriendIndex' },
            { text: 'MessageIndex' },
            { text: 'UnicastIndex', role: 'friend_unicast' },
          ],
        },
        {
          text: 'MessageSetting',
          active: true,
          children: [
            { text: 'ScenarioIndex', role: 'scenario' },
            { text: 'BroadcastIndex', role: 'broadcast' },
            { text: 'AutoAnswerIndex', role: 'auto_answer' },
            { text: 'FixedPhraseIndex' },
            { text: 'ReminderIndex', role: 'reminder' },
            { text: 'ScheduleIndex', role: 'schedule' },
            { text: 'FriendStartSettingIndex', role: 'friend_start_setting' },
            { text: 'ActionTemplateIndex' },
            { text: 'HashtagIndex', role: 'hashtag' },
            { text: 'SearchTemplateIndex' },
          ],
        },
        {
          text: 'Reserved',
          active: true,
          children: [
            { text: 'ReservedBookingList', role: 'friend_reservation' },
            { text: 'ReservedShopList', role: 'friend_reservation' },
            { text: 'ReservedCourseList', role: 'friend_reservation' },
            { text: 'ReservedReserveList', role: 'friend_reservation' },
          ],
        },
        {
          text: 'StatisticsInformation',
          active: true,
          children: [
            { text: 'AnswerFormIndex', role: 'answer_form' },
            { text: 'ClickIndex', role: 'click' },
            { text: 'CrossAnalysisIndex', role: 'cross_analysis' },
            { text: 'InflowRouteIndex', role: 'inflow_route' },
          ],
        },
        {
          text: 'ContentsInformation',
          active: true,
          children: [
            { text: 'RichMenuIndex', role: 'rich_menu' },
            { text: 'UploadFileIndex' },
          ],
        },
        {
          text: 'OtherSetting',
          active: true,
          children: [
            {
              text: 'LineOfficial',
              href: 'https://manager.line.biz/',
              target: '_blank',
            },
            { text: 'ChannelForm', role: 'channel' },
            { text: 'StaffIndex', role: 'staff' },
            { text: 'DataMigrationIndex', role: 'staff' },
            { text: 'Logout' },
          ],
        },
      ]
      const menusFilteredByChildrenLength = menus.filter((menu) => {
        if (!menu.children) {
          return true
        }
        const childrenFilteredByRoles = menu.children.filter((child) => {
          if (!child.role) {
            return true
          }
          if (!this.$store.getters.roles) {
            return false
          }
          return this.$store.getters.roles.includes(child.role)
        })
        menu.children = childrenFilteredByRoles
        return menu.children.length > 0
      })
      return menusFilteredByChildrenLength
    },
    adminMenus() {
      return [
        { text: 'AdminUsersIndex' },
        { text: 'AdminFriendsIndex' },
        { text: 'AdminChannelsIndex' },
        { text: 'AdminInfosIndex' },
        { text: 'AdminPlanIndex' },
        { text: 'AdminCancelFormIndex' },
      ]
    },
    drawerMenus() {
      function selectMenus(_this) {
        if (_this.isAdmin) {
          return _this.adminMenus
        }
        if (!_this.$store.getters.user) {
          return _this.guestMenus
        }
        if (!_this.$store.getters.channel) {
          return _this.noChannelMenus
        }
        _this.init()
        return _this.userMenus
      }
      const menus = selectMenus(this)
      return menus.map((menu) => {
        const ret = this.format(menu)
        ret.active = menu.active
        if (menu.children) {
          ret.children = menu.children.map((child) => this.format(child))
        }
        return ret
      })
    },
    drawer: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    isAdmin() {
      return this.$store.getters.user && this.$store.getters.user.admin_flg == 1
    },
  },
  methods: {
    isFriendList(route) {
      if (route.to && route.to.name) {
        return route.to.name === 'UnicastIndex'
      }
      return false
    },
    i18nTitle(val) {
      let optVal = `menu.${val}`
      if (this.$t(optVal) !== optVal) {
        return this.$t(optVal)
      }
      optVal = `meta.title.${val}`
      return this.$t(optVal)
    },
    format(menu) {
      const ret = {}
      ret.text = this.i18nTitle(menu.text)
      if (menu.href) {
        ret.href = menu.href
      }
      if (!menu.href) {
        ret.to = { name: menu.text }
      }
      if (menu.target) {
        ret.target = menu.target
      }
      return ret
    },
    async init() {
      this.errorMsg = ''
      this.loading = true
      const path = '/api/v1/friends/unconfirmed_message_count'

      await this.axios
        .get(path)
        .then((res) => {
          this.$$updateUnreadCount(res.data.unconfirmed_message_count || 0)
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style>
.drawer_group .v-list-group__header {
  background-color: #6ab55a;
}

.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 30px;
}

.drawer-item-text {
  color: #fff !important;
  text-decoration: none;
}

.unread-count {
  position: absolute;
  right: 16px;
  padding: 6px;
  background: gray;
  border-radius: 50px;
  min-width: 25px;
  text-align: center;
  font-size: 12px;
  color: #fff;
}
</style>
