var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PtTitle"),
      _vm._v(" "),
      _c("div", { staticClass: "or-break mb-4" }, [
        _vm._v(_vm._s(_vm.$t("subtitle.line_notice")))
      ]),
      _vm._v(" "),
      _c("notify-form", {
        staticClass: "mb-4",
        attrs: {
          channels: _vm.channels,
          vees: _vm.vees,
          "notice-types": _vm.opts.notice_type
        },
        on: { save: _vm.save },
        model: {
          value: _vm.lineNotice,
          callback: function($$v) {
            _vm.lineNotice = $$v
          },
          expression: "lineNotice"
        }
      }),
      _vm._v(" "),
      _c("setting-table", {
        staticClass: "mb-4",
        attrs: { "line-notices": _vm.lineNotices },
        on: {
          "click:row": _vm.toEdit,
          "click:testNotification": _vm.sendTestNotification
        }
      }),
      _vm._v(" "),
      _c("account-table", {
        attrs: { channels: _vm.channelsWithCount },
        on: { "click:row": _vm.toShow }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }