var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "mx-auto px-12 py-6", attrs: { "max-width": "800" } },
        [
          _c(
            "v-card-title",
            { staticClass: "mb-4" },
            [
              _c("v-row", { attrs: { justify: "center" } }, [
                _c("div", [
                  _vm._v(_vm._s(_vm.$t("meta.title." + _vm.$route.name)))
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            { staticClass: "mb-4" },
            [
              _c("v-card-title", [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("lbl.set_webhook")) + "\n      "
                )
              ]),
              _vm._v(" "),
              _c("VDivider", { staticClass: "my-0" }),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("div", { staticClass: "or-break" }, [
                    _vm._v(_vm._s(_vm.$t("lbl.set_webhook_desc")))
                  ]),
                  _vm._v(" "),
                  _vm.channel.id
                    ? _c("PtWebhookCopy", {
                        attrs: { channel_id: _vm.channel.id }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            { staticClass: "mb-4" },
            [
              _c("v-card-title", [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("lbl.set_response")) + "\n      "
                )
              ]),
              _vm._v(" "),
              _c("VDivider", { staticClass: "my-0" }),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("div", { staticClass: "or-break" }, [
                    _vm._v(_vm._s(_vm.$t("lbl.set_response_desc")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { staticClass: "mt-4" },
                    [
                      _c("VImg", {
                        attrs: {
                          src: "/cstms/line_response_setting.png",
                          alt: "response setting"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c("VfCheckbox", {
                    attrs: { label: _vm.$t("lbl.done_webhook_and_response") },
                    model: {
                      value: _vm.next_ready,
                      callback: function($$v) {
                        _vm.next_ready = $$v
                      },
                      expression: "next_ready"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", disabled: !_vm.next_ready },
                      on: {
                        click: function($event) {
                          return _vm.$router.push({
                            name: "RegistrationLoginChannel"
                          })
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("btn.next")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }