var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c("PtTitle"),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("subtitle.inflow_route_friend")) +
                  "\n    "
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "2" } }, [
            _c(
              "div",
              { staticStyle: { "text-align": "right", "margin-top": "20px" } },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary" },
                    on: { click: _vm.frirndListBtActoin }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("lbl.inflow_route.friend_list")) +
                        "\n        "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.items,
              "item-key": "id",
              "disable-sort": true,
              "items-per-page": 50,
              "footer-props": {
                itemsPerPageOptions: [50, 100, 150]
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.api_display_name",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.friend_id
                        ? [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "FriendShow",
                                    params: { id: item.friend_id }
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.api_display_name) +
                                    "\n          "
                                )
                              ]
                            )
                          ]
                        : [
                            _vm._v(
                              "\n          " +
                                _vm._s(item.api_display_name) +
                                "\n        "
                            )
                          ]
                    ]
                  }
                },
                {
                  key: "item.created_at",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$$fat(item.created_at)) +
                          "\n      "
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "text-right" },
            [
              _c(
                "v-btn",
                {
                  on: {
                    click: function($event) {
                      return _vm.$router.push({ name: "InflowRouteIndex" })
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("btn.back")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }