var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { md: "4", sm: "6", cols: "12" } },
        [
          _c("VfSelect", {
            attrs: {
              label: _vm.$t("lbl.timing_type"),
              vees: _vm.vees.timing_type,
              selects: _vm.timing_types,
              "label-hidden": ""
            },
            model: {
              value: _vm.internalValue.timing_type,
              callback: function($$v) {
                _vm.$set(_vm.internalValue, "timing_type", $$v)
              },
              expression: "internalValue.timing_type"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.internalValue.timing_type === "delay"
        ? _c(
            "v-col",
            {
              staticClass: "d-flex align-items-center justify-content-around",
              attrs: { md: "8", sm: "6", cols: "12" }
            },
            [
              _c(
                "div",
                [
                  _c("v-icon", [_vm._v("mdi-arrow-right-bold")]),
                  _vm._v(_vm._s(_vm.$t("lbl.reaction")))
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "w-50 mx-3" },
                [
                  _c("VfSelect", {
                    attrs: {
                      selects: _vm.minute,
                      vees: _vm.vees.second,
                      label: " "
                    },
                    model: {
                      value: _vm.internalValue.minute,
                      callback: function($$v) {
                        _vm.$set(_vm.internalValue, "minute", $$v)
                      },
                      expression: "internalValue.minute"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.$t("lbl.after_minute")))])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }