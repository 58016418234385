var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PtTitle"),
      _vm._v(" "),
      _c("div", [_vm._v(_vm._s(_vm.$t("subtitle.data_migration")))]),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "my-5" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("data_migration.friend_title")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-card-text", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("data_migration.friend_body")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "text-decoration-none",
                          attrs: {
                            href:
                              "/api/v1/data_migrations/friends_migration.csv"
                          }
                        },
                        [
                          _c("v-btn", { attrs: { color: "primary" } }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("data_migration.export")) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("data_migration.tag_title")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-card-text", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("data_migration.tag_body")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "text-decoration-none",
                          attrs: {
                            href:
                              "/api/v1/data_migrations/hashtags_migration.csv"
                          }
                        },
                        [
                          _c("v-btn", { attrs: { color: "primary" } }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("data_migration.export")) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("data_migration.friend_tag_title")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-card-text", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("data_migration.friend_tag_body")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "text-decoration-none",
                          attrs: {
                            href:
                              "/api/v1/data_migrations/friend_hashtags_migration.csv"
                          }
                        },
                        [
                          _c("v-btn", { attrs: { color: "primary" } }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("data_migration.export")) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "my-5" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("data_migration.scenario_title")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-card-text", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("data_migration.scenario_body")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "text-decoration-none",
                          attrs: {
                            href:
                              "/api/v1/data_migrations/scenarios_migration.csv"
                          }
                        },
                        [
                          _c("v-btn", { attrs: { color: "primary" } }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("data_migration.export")) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("data_migration.scenario_timing_title")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-card-text", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("data_migration.scenario_timing_body")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "text-decoration-none",
                          attrs: {
                            href:
                              "/api/v1/data_migrations/scenario_timings_migration.csv"
                          }
                        },
                        [
                          _c("v-btn", { attrs: { color: "primary" } }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("data_migration.export")) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("data_migration.friend_scenario_title")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-card-text", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("data_migration.friend_scenario_body")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "text-decoration-none",
                          attrs: {
                            href:
                              "/api/v1/data_migrations/friend_scenarios_migration.csv"
                          }
                        },
                        [
                          _c("v-btn", { attrs: { color: "primary" } }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("data_migration.export")) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }