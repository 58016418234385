var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("PtTitle", { attrs: { title: _vm.friend.name } }),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { disabled: _vm.friend.hidden_flg } },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "text-decoration-none black--text",
                      attrs: {
                        to: {
                          name: "UnicastIndex",
                          query: { id: _vm.friend.id }
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("btn.unicast_screen")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.friend.hidden_flg
                ? _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "hidden_caution" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("lbl.friend.hidden_caution")) +
                            "\n        "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { md: "6" } },
            [
              _c("PtFriendShowBase", {
                attrs: { opts: _vm.opts, vees: _vm.vees },
                model: {
                  value: _vm.friend,
                  callback: function($$v) {
                    _vm.friend = $$v
                  },
                  expression: "friend"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { md: "6" } },
            [
              _c("PtFriendShowAction", {
                model: {
                  value: _vm.friend,
                  callback: function($$v) {
                    _vm.friend = $$v
                  },
                  expression: "friend"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }