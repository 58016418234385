var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("VfAlert", {
        attrs: { type: "error" },
        model: {
          value: _vm.errorMsg,
          callback: function($$v) {
            _vm.errorMsg = $$v
          },
          expression: "errorMsg"
        }
      }),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { md: "6" } },
            [
              _c("VfTextField", {
                attrs: {
                  label: _vm.$t("lbl.answer_form.item_name"),
                  vees: _vm.vees.answer_form_input.title
                },
                model: {
                  value: _vm.internalValue.title,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "title", $$v)
                  },
                  expression: "internalValue.title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { md: "6" } },
            [
              _c("VfCheckbox", {
                attrs: { label: _vm.$t("lbl.answer_form.hide_item_name") },
                model: {
                  value: _vm.internalValue.hide_title_flg,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "hide_title_flg", $$v)
                  },
                  expression: "internalValue.hide_title_flg"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-4", attrs: { md: "6" } },
            [
              _c("VfTextField", {
                attrs: {
                  label: _vm.$t("lbl.answer_form.description"),
                  vees: _vm.vees.answer_form_input.short_description,
                  hint: _vm.$t("lbl.answer_form.text_field_desc_hint")
                },
                model: {
                  value: _vm.internalValue.description,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "description", $$v)
                  },
                  expression: "internalValue.description"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.internalValue.action_flg
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "py-0",
                  attrs: { md: "6" },
                  on: { click: _vm.openDialog }
                },
                [
                  _c("VfSelect", {
                    attrs: {
                      label: _vm.$$tm("reminder.name"),
                      selects: _vm.selects,
                      vees: { rules: "required", required: true },
                      readonly: ""
                    },
                    model: {
                      value: _vm.internalValue.action_arr[0].data.reminder_id,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.internalValue.action_arr[0].data,
                          "reminder_id",
                          $$v
                        )
                      },
                      expression: "internalValue.action_arr[0].data.reminder_id"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.internalValue.action_flg
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("VfTextField", {
                            attrs: {
                              label: _vm.$t(
                                "lbl.answer_form.goal_date_and_time"
                              ),
                              placeholder: _vm.$t(
                                "lbl.answer_form.placeholder_goal_date_and_time"
                              ),
                              filled: "",
                              readonly: ""
                            },
                            model: {
                              value: _vm.date,
                              callback: function($$v) {
                                _vm.date = $$v
                              },
                              expression: "date"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "mt-5" },
                [
                  _c("VfDateTimeSelect", {
                    attrs: {
                      label: _vm.$$tm("action_reminder.goal_at"),
                      vees: _vm.vees.disp_st_at,
                      format: "HH:mm",
                      "only-time": true
                    },
                    model: {
                      value: _vm.timePick,
                      callback: function($$v) {
                        _vm.timePick = $$v
                      },
                      expression: "timePick"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "8" } },
            [
              _c("VfCheckbox", {
                attrs: {
                  label: _vm.$t("lbl.answer_form.set_a_reminder"),
                  hint: _vm.$t("lbl.answer_form.reminder_note"),
                  "hide-details": "auto",
                  "persistent-hint": true
                },
                model: {
                  value: _vm.internalValue.action_flg,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "action_flg", $$v)
                  },
                  expression: "internalValue.action_flg"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { md: "4" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-0", attrs: { md: "6" } },
                    [
                      _c("VfCheckbox", {
                        attrs: { label: _vm.$t("lbl.answer_form.require") },
                        model: {
                          value: _vm.internalValue.require_flg,
                          callback: function($$v) {
                            _vm.$set(_vm.internalValue, "require_flg", $$v)
                          },
                          expression: "internalValue.require_flg"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pb-0", attrs: { md: "6" } },
                    [
                      _c("VfCheckbox", {
                        attrs: { label: _vm.$t("lbl.answer_form.hide") },
                        model: {
                          value: _vm.internalValue.hidden_flg,
                          callback: function($$v) {
                            _vm.$set(_vm.internalValue, "hidden_flg", $$v)
                          },
                          expression: "internalValue.hidden_flg"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.internalValue.action_flg
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "800" },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("VfSelect", {
                                        attrs: {
                                          label: _vm.$$tm("reminder.name"),
                                          selects: _vm.selects,
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.internalValue.action_arr[0].data
                                              .reminder_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.internalValue.action_arr[0]
                                                .data,
                                              "reminder_id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "internalValue.action_arr[0].data.reminder_id"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "4" } },
                            [
                              _c("PtFolder", {
                                ref: "folder",
                                attrs: {
                                  "folder-type": "reminder",
                                  "select-only": ""
                                },
                                on: { "select-folder": _vm.selectFolder }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { md: "8" } },
                            [
                              _c("v-data-table", {
                                attrs: {
                                  headers: _vm.headers,
                                  items: _vm.items,
                                  "item-key": "id",
                                  "show-select": "",
                                  "single-select": true,
                                  "disable-sort": true,
                                  "items-per-page": 50,
                                  "footer-props": {
                                    itemsPerPageOptions: [50, 100, 150]
                                  }
                                },
                                on: { "click:row": _vm.rowClick },
                                model: {
                                  value: _vm.checkedItems,
                                  callback: function($$v) {
                                    _vm.checkedItems = $$v
                                  },
                                  expression: "checkedItems"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }