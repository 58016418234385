var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "my-4" },
    [
      _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pt-3 mr-3 align-items-end d-flex flex-column" },
        [
          _c("div", [
            _vm._v(
              _vm._s(_vm.$t("lbl.visit_count", { count: _vm.visit_count }))
            )
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v(_vm._s(_vm.$t("lbl.sent_count", { count: _vm.sent_count })))
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          !_vm.loading && _vm.questions.length > 0
            ? _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.questions,
                  "item-key": "id",
                  "disable-sort": "",
                  loading: _vm.loading
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "body",
                      fn: function(ref) {
                        var items = ref.items
                        return [
                          _vm._l(items, function(item) {
                            return [
                              [
                                _c("PtClickQuestionDetail", {
                                  key: item.id,
                                  attrs: {
                                    "click-id": _vm.clickId,
                                    question: item,
                                    friends: _vm.click_question_choice_friends
                                  }
                                })
                              ]
                            ]
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2974757083
                )
              })
            : _c("v-data-table", {
                attrs: { headers: _vm.headers, "hide-default-footer": true }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }