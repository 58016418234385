var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.delis, function(deli) {
      return _c(
        "div",
        { staticClass: "mb-4" },
        [_c("PtLinePreviewRow", { attrs: { deli: deli } })],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }