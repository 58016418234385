var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "10" } }, [_c("PtTitle")], 1),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "2" } }, [
            _c(
              "div",
              { staticStyle: { "text-align": "right", "margin-top": "20px" } },
              [
                _vm.hashtagId !== "new"
                  ? _c(
                      "v-btn",
                      { attrs: { color: "error" }, on: { click: _vm.destroy } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("btn.delete")) +
                            "\n        "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.initDone
        ? _c(
            "validation-observer",
            { ref: "observer" },
            [
              _c(
                "v-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.save($event)
                    }
                  }
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "mb-6" },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "or-card-title mb-6" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("lbl.hashtag.info")) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { md: "6" } },
                                [
                                  _c("VfSelect", {
                                    attrs: {
                                      label: _vm.$$tm("folder.name"),
                                      vees: _vm.vees.folder_id,
                                      selects: _vm.folders
                                    },
                                    model: {
                                      value: _vm.hashtag.folder_id,
                                      callback: function($$v) {
                                        _vm.$set(_vm.hashtag, "folder_id", $$v)
                                      },
                                      expression: "hashtag.folder_id"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { md: "6" } },
                                [
                                  _c("VfTextField", {
                                    attrs: {
                                      label: _vm.$$tm("hashtag.name"),
                                      vees: _vm.vees.name
                                    },
                                    model: {
                                      value: _vm.hashtag.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.hashtag, "name", $$v)
                                      },
                                      expression: "hashtag.name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { staticClass: "mb-6" },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "or-card-title mb-6" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("lbl.hashtag.action")) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-card-subtitle", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("lbl.hashtag.action_detail")) +
                            "\n          "
                        ),
                        _c("div", { staticClass: "hashtag_caution" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("lbl.hashtag.action_caution")) +
                              "\n          "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c("PtActionSelectedList", {
                            model: {
                              value: _vm.hashtag.action_arr,
                              callback: function($$v) {
                                _vm.$set(_vm.hashtag, "action_arr", $$v)
                              },
                              expression: "hashtag.action_arr"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { staticClass: "mb-6" },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "or-card-title mb-6" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("lbl.hashtag.limit")) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-card-subtitle", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("lbl.hashtag.limit_detail")) +
                            "\n          "
                        ),
                        _c("div", { staticClass: "hashtag_caution" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("lbl.hashtag.limit_caution")) +
                              "\n          "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { md: "3" } },
                                [
                                  _c("VfSwitch", {
                                    attrs: {
                                      label: _vm.$$tm("hashtag.limit_flg"),
                                      vees: _vm.vees.limit_flg
                                    },
                                    model: {
                                      value: _vm.hashtag.limit_flg,
                                      callback: function($$v) {
                                        _vm.$set(_vm.hashtag, "limit_flg", $$v)
                                      },
                                      expression: "hashtag.limit_flg"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.hashtag.limit_flg
                                ? _c(
                                    "v-col",
                                    { attrs: { md: "3" } },
                                    [
                                      _c("VfTextField", {
                                        attrs: {
                                          label: _vm.$$tm("hashtag.limit_num"),
                                          vees: _vm.vees.limit_num,
                                          number: ""
                                        },
                                        model: {
                                          value: _vm.hashtag.limit_num,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.hashtag,
                                              "limit_num",
                                              $$v
                                            )
                                          },
                                          expression: "hashtag.limit_num"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.hashtag.limit_flg
                            ? _c(
                                "v-card",
                                [
                                  _c("v-card-title", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("lbl.hashtag.limited_action")
                                        ) +
                                        "\n            "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("PtActionSelectedList", {
                                            model: {
                                              value:
                                                _vm.hashtag.limited_action_arr,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.hashtag,
                                                  "limited_action_arr",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "hashtag.limited_action_arr"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                type: "submit",
                                loading: _vm.$wait.is("processing"),
                                color: "primary"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("btn.create")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("VfDialogConfirm", {
        attrs: {
          title: _vm.$t("confirm_title.delete"),
          detail: _vm.$t("confirm.delete"),
          "save-path": _vm.savePath,
          "form-type": "delete",
          "target-emit": "delete-success"
        },
        on: { "delete-success": _vm.deleteSuccess },
        model: {
          value: _vm.deleteDialog,
          callback: function($$v) {
            _vm.deleteDialog = $$v
          },
          expression: "deleteDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }