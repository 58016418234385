var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("VfAlert", {
        attrs: { type: "error" },
        model: {
          value: _vm.errorMsg,
          callback: function($$v) {
            _vm.errorMsg = $$v
          },
          expression: "errorMsg"
        }
      }),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { md: "6" } },
            [
              _c("VfTextField", {
                attrs: {
                  label: _vm.$t("lbl.answer_form.item_name"),
                  vees: _vm.vees.answer_form_input.title
                },
                model: {
                  value: _vm.internalValue.title,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "title", $$v)
                  },
                  expression: "internalValue.title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { md: "6" } },
            [
              _c("VfCheckbox", {
                attrs: { label: _vm.$t("lbl.answer_form.hide_item_name") },
                model: {
                  value: _vm.internalValue.hide_title_flg,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "hide_title_flg", $$v)
                  },
                  expression: "internalValue.hide_title_flg"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-4", attrs: { md: "6" } },
            [
              _c("VfTextField", {
                attrs: {
                  label: _vm.$t("lbl.answer_form.description"),
                  vees: _vm.vees.answer_form_input.short_description,
                  hint: _vm.$t("lbl.answer_form.text_field_desc_hint")
                },
                model: {
                  value: _vm.internalValue.description,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "description", $$v)
                  },
                  expression: "internalValue.description"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pb-4", attrs: { md: "6" } },
            [
              _c("VfTextField", {
                attrs: {
                  label: _vm.$t("lbl.answer_form.placeholder"),
                  vees: _vm.vees.answer_form_input.placeholder,
                  placeholder: _vm.$t("lbl.answer_form.placeholder_hint")
                },
                model: {
                  value: _vm.internalValue.placeholder,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "placeholder", $$v)
                  },
                  expression: "internalValue.placeholder"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { md: "6" } },
            [
              _c("VfSelect", {
                attrs: {
                  label: _vm.$t("lbl.answer_form.initial_display"),
                  selects:
                    _vm.opts.answer_form_input.additional_option_types
                      .text_field
                },
                model: {
                  value: _vm.internalValue.additional_option_type,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "additional_option_type", $$v)
                  },
                  expression: "internalValue.additional_option_type"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { md: "6" } },
            [
              _vm.internalValue.additional_option_type === "prepopulate"
                ? _c("VfTextField", {
                    attrs: {
                      label: "",
                      vees: _vm.vees.answer_form_input.prepopulate_value
                    },
                    model: {
                      value: _vm.internalValue.prepopulate_value,
                      callback: function($$v) {
                        _vm.$set(_vm.internalValue, "prepopulate_value", $$v)
                      },
                      expression: "internalValue.prepopulate_value"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-0 offset-8", attrs: { md: "2" } },
            [
              _c("VfCheckbox", {
                attrs: { label: _vm.$t("lbl.answer_form.require") },
                model: {
                  value: _vm.internalValue.require_flg,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "require_flg", $$v)
                  },
                  expression: "internalValue.require_flg"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { md: "2" } },
            [
              _c("VfCheckbox", {
                attrs: { label: _vm.$t("lbl.answer_form.hide") },
                model: {
                  value: _vm.internalValue.hidden_flg,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "hidden_flg", $$v)
                  },
                  expression: "internalValue.hidden_flg"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }