var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { outlined: "" } },
    [
      _c(
        "v-card-title",
        { staticClass: "pa-0" },
        [
          _c(
            "v-list-item",
            [
              _c("v-list-item-content", [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("lbl.channel.info")) + "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-list-item-action",
                [
                  _c("btn-dialog-channel-form", {
                    attrs: { vees: _vm.vees, "is-user": "" },
                    on: { click: _vm.initCloneChannel, save: _vm.save },
                    model: {
                      value: _vm.cloneChannel,
                      callback: function($$v) {
                        _vm.cloneChannel = $$v
                      },
                      expression: "cloneChannel"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _c("VfTextField", {
            attrs: { label: _vm.$$tm("channel.name"), disabled: "" },
            model: {
              value: _vm.channel.name,
              callback: function($$v) {
                _vm.$set(_vm.channel, "name", $$v)
              },
              expression: "channel.name"
            }
          }),
          _vm._v(" "),
          _c("VfTextField", {
            attrs: { label: _vm.$$tm("channel.line_account_id"), disabled: "" },
            model: {
              value: _vm.channel.line_account_id,
              callback: function($$v) {
                _vm.$set(_vm.channel, "line_account_id", $$v)
              },
              expression: "channel.line_account_id"
            }
          }),
          _vm._v(" "),
          _c("VfTextField", {
            attrs: { label: _vm.$$tm("channel.line_channel_id"), disabled: "" },
            model: {
              value: _vm.channel.line_channel_id,
              callback: function($$v) {
                _vm.$set(_vm.channel, "line_channel_id", $$v)
              },
              expression: "channel.line_channel_id"
            }
          }),
          _vm._v(" "),
          _c("VfTextField", {
            attrs: {
              value: "********",
              label: _vm.$$tm("channel.line_channel_secret_id"),
              disabled: ""
            }
          }),
          _vm._v(" "),
          _c("VfTextField", {
            attrs: {
              label: _vm.$$tm("channel.line_login_channel_id"),
              disabled: ""
            },
            model: {
              value: _vm.channel.line_login_channel_id,
              callback: function($$v) {
                _vm.$set(_vm.channel, "line_login_channel_id", $$v)
              },
              expression: "channel.line_login_channel_id"
            }
          }),
          _vm._v(" "),
          _c("VfTextField", {
            attrs: {
              value: "********",
              label: _vm.$$tm("channel.line_login_channel_secret_id"),
              disabled: ""
            }
          }),
          _vm._v(" "),
          _c("v-text-field", {
            attrs: { label: "WebHook", readonly: "" },
            scopedSlots: _vm._u([
              {
                key: "append-outer",
                fn: function() {
                  return [
                    _c(
                      "v-icon",
                      {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:copy",
                            value: _vm.webhookUrl,
                            expression: "webhookUrl",
                            arg: "copy"
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:success",
                            value: _vm.onClipCopy,
                            expression: "onClipCopy",
                            arg: "success"
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:error",
                            value: _vm.onClipError,
                            expression: "onClipError",
                            arg: "error"
                          }
                        ],
                        staticClass: "p-0 clipboard-icon"
                      },
                      [_vm._v("mdi-content-copy")]
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.webhookUrl,
              callback: function($$v) {
                _vm.webhookUrl = $$v
              },
              expression: "webhookUrl"
            }
          }),
          _vm._v(" "),
          _c("v-text-field", {
            attrs: { label: "Reservation Liff Booking Url", readonly: "" },
            scopedSlots: _vm._u([
              {
                key: "append-outer",
                fn: function() {
                  return [
                    _c(
                      "v-icon",
                      {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:copy",
                            value: _vm.channel.reservation_liff_booking_url,
                            expression: "channel.reservation_liff_booking_url",
                            arg: "copy"
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:success",
                            value: _vm.onClipCopy,
                            expression: "onClipCopy",
                            arg: "success"
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:error",
                            value: _vm.onClipError,
                            expression: "onClipError",
                            arg: "error"
                          }
                        ],
                        staticClass: "p-0 clipboard-icon"
                      },
                      [_vm._v("mdi-content-copy")]
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.channel.reservation_liff_booking_url,
              callback: function($$v) {
                _vm.$set(_vm.channel, "reservation_liff_booking_url", $$v)
              },
              expression: "channel.reservation_liff_booking_url"
            }
          }),
          _vm._v(" "),
          _c("v-text-field", {
            attrs: { label: "Reservation Liff History Url", readonly: "" },
            scopedSlots: _vm._u([
              {
                key: "append-outer",
                fn: function() {
                  return [
                    _c(
                      "v-icon",
                      {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:copy",
                            value: _vm.channel.reservation_liff_history_url,
                            expression: "channel.reservation_liff_history_url",
                            arg: "copy"
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:success",
                            value: _vm.onClipCopy,
                            expression: "onClipCopy",
                            arg: "success"
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:error",
                            value: _vm.onClipError,
                            expression: "onClipError",
                            arg: "error"
                          }
                        ],
                        staticClass: "p-0 clipboard-icon"
                      },
                      [_vm._v("mdi-content-copy")]
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.channel.reservation_liff_history_url,
              callback: function($$v) {
                _vm.$set(_vm.channel, "reservation_liff_history_url", $$v)
              },
              expression: "channel.reservation_liff_history_url"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }