var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _vm.tableItems.friend.length > 0
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [
                        _vm._v(_vm._s(_vm.$t("lbl.channel_role.friend")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c("v-data-table", {
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.tableItems.friend,
                              "item-key": "value",
                              "show-select": "",
                              "disable-sort": "",
                              "items-per-page": -1,
                              "hide-default-footer": ""
                            },
                            on: { "click:row": _vm.rowClick },
                            model: {
                              value: _vm.internalValue,
                              callback: function($$v) {
                                _vm.internalValue = $$v
                              },
                              expression: "internalValue"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.tableItems.sender.length > 0
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [
                        _vm._v(_vm._s(_vm.$t("lbl.channel_role.sender")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c("v-data-table", {
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.tableItems.sender,
                              "item-key": "value",
                              "show-select": "",
                              "disable-sort": "",
                              "items-per-page": -1,
                              "hide-default-footer": ""
                            },
                            on: { "click:row": _vm.rowClick },
                            model: {
                              value: _vm.internalValue,
                              callback: function($$v) {
                                _vm.internalValue = $$v
                              },
                              expression: "internalValue"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.tableItems.statistics.length > 0
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [
                        _vm._v(_vm._s(_vm.$t("lbl.channel_role.statistics")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c("v-data-table", {
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.tableItems.statistics,
                              "item-key": "value",
                              "show-select": "",
                              "disable-sort": "",
                              "items-per-page": -1,
                              "hide-default-footer": ""
                            },
                            on: { "click:row": _vm.rowClick },
                            model: {
                              value: _vm.internalValue,
                              callback: function($$v) {
                                _vm.internalValue = $$v
                              },
                              expression: "internalValue"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.tableItems.other.length > 0
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [
                        _vm._v(_vm._s(_vm.$t("lbl.channel_role.other")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c("v-data-table", {
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.tableItems.other,
                              "item-key": "value",
                              "show-select": "",
                              "disable-sort": "",
                              "items-per-page": -1,
                              "hide-default-footer": ""
                            },
                            on: { "click:row": _vm.rowClick },
                            model: {
                              value: _vm.internalValue,
                              callback: function($$v) {
                                _vm.internalValue = $$v
                              },
                              expression: "internalValue"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }