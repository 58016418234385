var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tbody",
    { staticClass: "question-divider" },
    [
      _vm._l(_vm.question.deli_question_choices, function(choice, choideIdx) {
        return _vm._l(choice.click_question_choice_friends, function(
          item,
          friendIdx
        ) {
          return _c("tr", { key: item.id }, [
            choideIdx === 0 && friendIdx === 0
              ? _c(
                  "td",
                  {
                    staticClass: "text-start py-3",
                    staticStyle: { "border-bottom": "none" },
                    attrs: { rowspan: _vm.rowSpan }
                  },
                  [
                    _c(
                      "v-card",
                      {
                        staticStyle: { height: "fit-content" },
                        attrs: { width: 250 }
                      },
                      [
                        _c("v-card-text", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.question.title) +
                              "\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          {
                            staticClass: "text-center",
                            attrs: { "no-gutters": "" }
                          },
                          _vm._l(_vm.question.deli_question_choices, function(
                            choice,
                            choice_idx
                          ) {
                            return _c(
                              "v-col",
                              { key: choice_idx },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "px-2 py-4 h-100",
                                    staticStyle: { "word-break": "break-all" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(choice.choice_name) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("td", { staticClass: "text-start", attrs: { width: "120" } }, [
              _vm._v(_vm._s(choice.choice_name))
            ]),
            _vm._v(" "),
            _c(
              "td",
              {
                class: item.no_data
                  ? "text-start v-data-table__empty-wrapper"
                  : "text-start",
                attrs: { width: "220" }
              },
              [_vm._v("\n        " + _vm._s(item.friend_name) + "\n      ")]
            ),
            _vm._v(" "),
            _c("td", { staticClass: "text-start", attrs: { width: "220" } }, [
              _vm._v(_vm._s(item.click_count))
            ]),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass: "text-start",
                staticStyle: { "min-width": "210px" },
                attrs: { width: "210" }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$$fat(item.created_at)) + "\n      "
                )
              ]
            )
          ])
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }