import { render, staticRenderFns } from "./PtFilePondForUploadFile.vue?vue&type=template&id=95aa078c&"
import script from "./PtFilePondForUploadFile.vue?vue&type=script&lang=js&"
export * from "./PtFilePondForUploadFile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/talk_label/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('95aa078c')) {
      api.createRecord('95aa078c', component.options)
    } else {
      api.reload('95aa078c', component.options)
    }
    module.hot.accept("./PtFilePondForUploadFile.vue?vue&type=template&id=95aa078c&", function () {
      api.rerender('95aa078c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/packs/components/parts/common/PtFilePondForUploadFile.vue"
export default component.exports