var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "10" } }, [_c("PtTitle")], 1),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "2" } }, [
            _c(
              "div",
              { staticStyle: { "text-align": "right", "margin-top": "20px" } },
              [
                _vm.scenarioId !== "new"
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "error",
                          disabled: _vm.fsce_active_flg
                        },
                        on: { click: _vm.destroy }
                      },
                      [_vm._v(_vm._s(_vm.$t("btn.delete")))]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.initDone
        ? _c(
            "validation-observer",
            { ref: "observer" },
            [
              _c(
                "v-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.save($event)
                    }
                  }
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "mb-6" },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "or-card-title mb-6" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("lbl.scenario.info")) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { md: "5" } },
                                [
                                  _c("VfTextField", {
                                    attrs: {
                                      label: _vm.$$tm("scenario.name"),
                                      vees: _vm.vees.name
                                    },
                                    model: {
                                      value: _vm.scenario.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.scenario, "name", $$v)
                                      },
                                      expression: "scenario.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { md: "5" } },
                                [
                                  _c("VfSelect", {
                                    attrs: {
                                      label: _vm.$$tm("scenario.scenario_type"),
                                      vees: _vm.vees.scenario_type,
                                      selects: _vm.opts.scenario_type,
                                      disabled: _vm.scenarioId !== "new"
                                    },
                                    model: {
                                      value: _vm.scenario.scenario_type,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "scenario_type",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.scenario_type"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { md: "2" } },
                                [
                                  _c("VfSelect", {
                                    attrs: {
                                      label: _vm.$$tm(
                                        "scenario.scenario_status"
                                      ),
                                      vees: _vm.vees.scenario_status,
                                      selects: _vm.opts.scenario_status
                                    },
                                    model: {
                                      value: _vm.scenario.scenario_status,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "scenario_status",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.scenario_status"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panels",
                            {
                              attrs: { accordion: "" },
                              model: {
                                value: _vm.scenarioTypeCaution,
                                callback: function($$v) {
                                  _vm.scenarioTypeCaution = $$v
                                },
                                expression: "scenarioTypeCaution"
                              }
                            },
                            [
                              _c(
                                "v-expansion-panel",
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    { attrs: { color: "warning white--text" } },
                                    [
                                      _c("div", { attrs: { color: "red" } }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t(
                                                "lbl.scenario.cant_change_scenario_type"
                                              )
                                            ) +
                                            "\n                "
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel-content",
                                    { staticClass: "py-6" },
                                    [
                                      _c("div", { staticClass: "or-break" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t(
                                                "lbl.scenario.scenario_type_time"
                                              )
                                            ) +
                                            "\n                "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "my-6" }),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "or-break" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t(
                                                "lbl.scenario.scenario_type_progress"
                                              )
                                            ) +
                                            "\n                "
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c("VSpacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({
                                    name: "ScenarioIndex"
                                  })
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("btn.back")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                type: "submit",
                                color: "primary",
                                loading: _vm.$wait.is("processing")
                              },
                              on: { click: _vm.update }
                            },
                            [_vm._v(_vm._s(_vm.$t("btn.create")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.scenarioId !== "new" && _vm.initDone
        ? _c("PtScenarioTimingIndex", {
            attrs: {
              "scenario-id": _vm.scenarioId,
              "fsce-active-flg": _vm.fsce_active_flg
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("VfDialogConfirm", {
        attrs: {
          title: _vm.$t("confirm_title.delete"),
          detail: _vm.deleteConfirm,
          "save-path": _vm.savePath,
          "form-type": "delete",
          "target-emit": "delete-success"
        },
        on: { "delete-success": _vm.deleteSuccess },
        model: {
          value: _vm.deleteDialog,
          callback: function($$v) {
            _vm.deleteDialog = $$v
          },
          expression: "deleteDialog"
        }
      }),
      _vm._v(" "),
      _c("VfDialogConfirmWithoutPath", {
        attrs: {
          "max-width": 300,
          detail: _vm.$t("lbl.scenario.update_delivery_confirm"),
          "target-emit": "update-success"
        },
        on: { "update-success": _vm.updateSuccess },
        model: {
          value: _vm.updateDialog,
          callback: function($$v) {
            _vm.updateDialog = $$v
          },
          expression: "updateDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }