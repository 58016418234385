var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto", attrs: { "max-width": "500" } },
    [
      _c("v-card-title", [
        _vm._v("\n    " + _vm._s(_vm.$t("account.user_logout")) + "\n  ")
      ]),
      _vm._v(" "),
      _c("v-divider", { staticClass: "mx-4" }),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            { attrs: { color: "error" }, on: { click: _vm.logout } },
            [_vm._v("ログアウト")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }