var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("VfAlert", {
        attrs: { type: "error" },
        model: {
          value: _vm.errorMsg,
          callback: function($$v) {
            _vm.errorMsg = $$v
          },
          expression: "errorMsg"
        }
      }),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { md: "6" } },
            [
              _c("PtDialogSelect", {
                attrs: {
                  "select-type": "fixed_phrase",
                  "single-select": true,
                  vees: _vm.vees.name
                },
                model: {
                  value: _vm.internalValue,
                  callback: function($$v) {
                    _vm.internalValue = $$v
                  },
                  expression: "internalValue"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { xl: "4", md: "7", cols: "12" } },
            [
              !_vm.hideTimingTypeSelect
                ? _c("PtTimingTypeSelect", {
                    attrs: {
                      timing_types: _vm.opts.timing_type,
                      vees: _vm.vees.timing
                    },
                    model: {
                      value: _vm.internalValue.timing,
                      callback: function($$v) {
                        _vm.$set(_vm.internalValue, "timing", $$v)
                      },
                      expression: "internalValue.timing"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }