var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("file-pond", {
    ref: "pond",
    attrs: {
      name: _vm.name,
      "allow-revert": false,
      "max-files": _vm.maxFiles,
      "max-file-size": _vm.maxFileSize,
      "class-name": _vm.className,
      files: _vm.defaultFiles,
      server: _vm.server,
      "instant-upload": _vm.instantUpload,
      "allow-multiple": _vm.allowMultiple,
      "allow-reorder": _vm.allowReorder,
      disabled: _vm.disabled,
      "accepted-file-types": _vm.acceptedFileTypes,
      "max-parallel-uploads": _vm.maxParallelUploads,
      "image-resize-target-width": _vm.imageResizeTargetWidth,
      "image-resize-target-height": _vm.imageResizeTargetHeight,
      "allow-image-preview": _vm.allowImagePreview,
      "allow-image-edit": _vm.allowImageEdit,
      "image-edit-instant-edit": _vm.imageEditInstantEdit,
      "label-idle":
        "ここにファイルをドロップするか、ファイルを選択してください。"
    },
    on: { processfile: _vm.processfile }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }