var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "4" } },
            [
              _c("PtUnicastFriendIndex", {
                on: { "select-friend": _vm.selectFriend }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { md: "5" } },
            [
              _c("PtUnicastMessageIndex", {
                key: _vm.friendId,
                attrs: {
                  "friend-id": _vm.friendId,
                  "first-time-flg": _vm.first_time_flg
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { md: "3" } },
            [
              _c("PtUnicastFriendShow", {
                key: _vm.friendId,
                attrs: {
                  "friend-id": _vm.friendId,
                  "first-time-flg": _vm.first_time_flg
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }