var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("VfAlert", {
        attrs: { type: "error" },
        model: {
          value: _vm.errorMsg,
          callback: function($$v) {
            _vm.errorMsg = $$v
          },
          expression: "errorMsg"
        }
      }),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-0" },
            [
              _c("div", { staticClass: "red--text" }, [
                _vm._v(_vm._s(_vm.$t("lbl.notify.caution")))
              ]),
              _vm._v(" "),
              _c("VfTextArea", {
                attrs: {
                  label: _vm.$$tm("action_notice.text"),
                  vees: _vm.vees.text,
                  counter: ""
                },
                model: {
                  value: _vm.internalValue.text,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "text", $$v)
                  },
                  expression: "internalValue.text"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }