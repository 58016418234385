import { render, staticRenderFns } from "./PtFormRenderSelect.vue?vue&type=template&id=6063b194&scoped=true&"
import script from "./PtFormRenderSelect.vue?vue&type=script&lang=js&"
export * from "./PtFormRenderSelect.vue?vue&type=script&lang=js&"
import style0 from "./PtFormRenderSelect.vue?vue&type=style&index=0&id=6063b194&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6063b194",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/talk_label/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6063b194')) {
      api.createRecord('6063b194', component.options)
    } else {
      api.reload('6063b194', component.options)
    }
    module.hot.accept("./PtFormRenderSelect.vue?vue&type=template&id=6063b194&scoped=true&", function () {
      api.rerender('6063b194', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/packs/components/parts/common/PtFormRender/PtFormRenderSelect.vue"
export default component.exports