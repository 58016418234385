var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-card-text",
            [
              _c("VfTextField", {
                attrs: {
                  label: _vm.$$tm("deli_question.title"),
                  vees: _vm.vees.title,
                  counter: "",
                  outlined: ""
                },
                model: {
                  value: _vm.internalValue.title,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "title", $$v)
                  },
                  expression: "internalValue.title"
                }
              }),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                _vm._l(_vm.internalValue.deli_question_choices, function(
                  choice,
                  choice_i
                ) {
                  return _c(
                    "v-col",
                    { key: choice_i },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-title", [
                            _vm._v(
                              _vm._s(_vm.$t("lbl.choice")) +
                                " " +
                                _vm._s(choice_i + 1)
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            [
                              _c("VfTextField", {
                                attrs: {
                                  label: _vm.$$tm(
                                    "deli_question_choice.choice_name"
                                  ),
                                  vees: _vm.veesChoice.choice_name,
                                  counter: ""
                                },
                                model: {
                                  value: choice.choice_name,
                                  callback: function($$v) {
                                    _vm.$set(choice, "choice_name", $$v)
                                  },
                                  expression: "choice.choice_name"
                                }
                              }),
                              _vm._v(" "),
                              _c("VfSelect", {
                                attrs: {
                                  label: _vm.$$tm(
                                    "deli_question_choice.choice_action_type"
                                  ),
                                  vees: _vm.veesChoice.choice_action_type,
                                  selects: _vm.choiceActionTypes
                                },
                                model: {
                                  value: choice.choice_action_type,
                                  callback: function($$v) {
                                    _vm.$set(choice, "choice_action_type", $$v)
                                  },
                                  expression: "choice.choice_action_type"
                                }
                              }),
                              _vm._v(" "),
                              choice.choice_action_type !== "action" &&
                              choice.choice_action_type !== "none"
                                ? _c("VfTextField", {
                                    key: choice.choice_action_type,
                                    attrs: {
                                      label: _vm.$t(
                                        "lbl." +
                                          choice.choice_action_type +
                                          "_text"
                                      ),
                                      vees:
                                        _vm.multiTextValidates[
                                          choice.choice_action_type
                                        ]
                                    },
                                    model: {
                                      value: choice.multi_text,
                                      callback: function($$v) {
                                        _vm.$set(choice, "multi_text", $$v)
                                      },
                                      expression: "choice.multi_text"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              choice.choice_action_type === "action"
                                ? _c(
                                    "v-card-text",
                                    [
                                      _c("PtActionSelectedList", {
                                        attrs: { "msg-hidden-flg": true },
                                        model: {
                                          value: choice.action_arr,
                                          callback: function($$v) {
                                            _vm.$set(choice, "action_arr", $$v)
                                          },
                                          expression: "choice.action_arr"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _c("VfTextArea", {
                    attrs: {
                      label: _vm.$$tm("deli_question.message_for_pc"),
                      vees: _vm.vees.message_for_pc,
                      counter: ""
                    },
                    model: {
                      value: _vm.internalValue.message_for_pc,
                      callback: function($$v) {
                        _vm.$set(_vm.internalValue, "message_for_pc", $$v)
                      },
                      expression: "internalValue.message_for_pc"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }