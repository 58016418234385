var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "validation-observer",
        { ref: "observer" },
        [
          _c(
            "v-card",
            {
              staticClass: "mx-auto px-12 py-6",
              attrs: { "max-width": "800" }
            },
            [
              _c(
                "v-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.save($event)
                    }
                  }
                },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("v-row", { attrs: { justify: "center" } }, [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("meta.title." + _vm.$route.name))
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("VDivider", { staticClass: "mx-4" }),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    {
                      staticClass: "mt-8 mb-4 text-center",
                      attrs: { color: "grey lighten-4" }
                    },
                    [
                      _c("v-card-text", [
                        _c("div", { staticClass: "or-break" }, [
                          _vm._v(_vm._s(_vm.$t("lbl.create_line_account_id")))
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.channel
                    ? _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("VfTextField", {
                                    attrs: {
                                      label: _vm.$$tm(
                                        "channel.line_channel_id"
                                      ),
                                      vees: _vm.vees.line_channel_id
                                    },
                                    model: {
                                      value: _vm.channel.line_channel_id,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.channel,
                                          "line_channel_id",
                                          $$v
                                        )
                                      },
                                      expression: "channel.line_channel_id"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("VfTextField", {
                                    attrs: {
                                      label: _vm.$$tm(
                                        "channel.line_channel_secret_id"
                                      ),
                                      vees: _vm.vees.line_channel_secret_id,
                                      password: ""
                                    },
                                    model: {
                                      value: _vm.channel.line_channel_secret_id,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.channel,
                                          "line_channel_secret_id",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "channel.line_channel_secret_id"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        { attrs: { type: "submit", color: "primary" } },
                        [_vm._v(_vm._s(_vm.$t("btn.create")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }