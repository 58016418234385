var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": 500 },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("lbl.credit_card.default_card")) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mt-0" }),
          _vm._v(" "),
          _c("v-card-text", [
            _c("div", { staticClass: "or-break" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("lbl.credit_card.default_card_desc")) +
                  "\n      "
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-btn", { on: { click: _vm.cancel } }, [
                _vm._v("\n        " + _vm._s(_vm.$t("btn.cancel")) + "\n      ")
              ]),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.save } },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("btn.update")) + "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }