var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [_c("v-col", { attrs: { cols: "8" } }, [_c("PtTitle")], 1)],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "mx-auto mt-8", attrs: { "max-width": "800" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-card",
                { staticClass: "mt-4" },
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("cancel_form.name")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "ml-4", attrs: { md: "8" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.cancelForm.name) +
                            "\n          "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "mt-4" },
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("cancel_form.email")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "ml-4", attrs: { md: "8" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.cancelForm.email) +
                            "\n          "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "mt-4" },
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("cancel_form.phone")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "ml-4", attrs: { md: "8" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.cancelForm.phone) +
                            "\n          "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "mt-4" },
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("cancel_form.line_account_id")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "ml-4", attrs: { md: "8" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.cancelForm.line_account_id) +
                            "\n          "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "mt-4" },
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("cancel_form.plan_name")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "ml-4", attrs: { md: "8" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.cancelForm.plan_name) +
                            "\n          "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "mt-4" },
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("cancel_form.reason_text")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "ml-4", attrs: { md: "8" } }, [
                        _c("div", { staticClass: "or-break" }, [
                          _vm._v(_vm._s(_vm.cancelForm.reason_text))
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }