var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "mx-auto px-12 py-6", attrs: { "max-width": "800" } },
        [
          _c(
            "v-card-title",
            { staticClass: "mb-4" },
            [
              _c("v-row", { attrs: { justify: "center" } }, [
                _c("div", [
                  _vm._v(_vm._s(_vm.$t("meta.title." + _vm.$route.name)))
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            { staticClass: "mb-4" },
            [
              _c("v-card-title", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("lbl.liff_app.registration_title")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("VDivider", { staticClass: "my-0" }),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("div", { staticClass: "or-break" }, [
                    _vm._v(_vm._s(_vm.$t("lbl.liff_app.registration_desc")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { staticClass: "mt-4" },
                    [
                      _c("VImg", {
                        attrs: {
                          src: "/cstms/line_liff_app.png",
                          alt: "liff app"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            { staticClass: "mb-4" },
            [
              _c("v-card-title", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("lbl.liff_app.registration_scope_title")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("VDivider", { staticClass: "my-0" }),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("div", { staticClass: "or-break" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("lbl.liff_app.registration_scope_desc")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { staticClass: "mt-4" },
                    [
                      _c("VImg", {
                        attrs: {
                          src: "/cstms/line_liff_app_scope_setting.png",
                          alt: "liff app scope setting"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("lbl.liff_app.registration_confirm_title")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("div", { staticClass: "or-break" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("lbl.liff_app.registration_confirm_desc")
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("VfCheckbox", {
                    attrs: {
                      label: _vm.$t(
                        "lbl.liff_app.registration_confirm_checkbox"
                      )
                    },
                    model: {
                      value: _vm.nextReady,
                      callback: function($$v) {
                        _vm.nextReady = $$v
                      },
                      expression: "nextReady"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "w-100 d-flex justify-content-end" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", disabled: !_vm.nextReady },
                          on: {
                            click: function($event) {
                              return _vm.$router.push({
                                name: "RegistrationDone"
                              })
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("btn.create")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }