var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-tabs",
            { attrs: { "background-color": "green lighten-4" } },
            [
              _vm._l(_vm.stickers, function(stickerIds, packageId) {
                return _c(
                  "v-tab",
                  { key: packageId },
                  [
                    _c("VImg", {
                      attrs: {
                        height: "50",
                        width: "50",
                        "aspect-ratio": 1 / 1,
                        src: _vm.stickerImg(stickerIds[0])
                      }
                    })
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm._l(_vm.stickers, function(stickerIds, packageId) {
                return _c(
                  "v-tab-item",
                  [
                    _c(
                      "v-card",
                      { attrs: { flat: "" } },
                      _vm._l(_vm.stickerSeparate(stickerIds), function(rowSet) {
                        return _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          _vm._l(rowSet, function(stickerId) {
                            return _c(
                              "v-col",
                              {
                                key: stickerId,
                                on: {
                                  click: function($event) {
                                    return _vm.selectSticker(
                                      packageId,
                                      stickerId
                                    )
                                  }
                                }
                              },
                              [
                                _c("VImg", {
                                  attrs: {
                                    height: "50",
                                    width: "50",
                                    "aspect-ratio": 1 / 1,
                                    src: _vm.stickerImg(stickerId)
                                  }
                                })
                              ],
                              1
                            )
                          }),
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("lbl.selected_sticker")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _vm.internalValue.sticker_id
                        ? _c("VImg", {
                            attrs: {
                              height: "180",
                              width: "180",
                              src: _vm.stickerImg(_vm.internalValue.sticker_id)
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }