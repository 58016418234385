var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PtTitle"),
      _vm._v(" "),
      _c(
        "v-row",
        _vm._l(_vm.startTypes, function(type, type_idx) {
          return _c(
            "v-col",
            { key: type_idx, attrs: { md: "6" } },
            [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "or-card-title mb-6" },
                    [
                      _c("v-card-title", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("lbl.friend_start_setting.title_" + type)
                            ) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-card-subtitle", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "lbl.friend_start_setting.title_" +
                                  type +
                                  "_desc"
                              )
                            ) +
                            "\n          "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("VfLoadingOverlayAbsolute", {
                    attrs: { loading: _vm.loading }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _vm.friendStartSettings[type]
                        ? _c("PtActionSelectedList", {
                            on: {
                              "pt-action-selected-list-save": function($event) {
                                return _vm.PtActionSelectedListSave(type)
                              },
                              "pt-action-selected-list-clear": function(
                                $event
                              ) {
                                return _vm.PtActionSelectedListSave(type)
                              }
                            },
                            model: {
                              value: _vm.friendStartSettings[type].action_arr,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.friendStartSettings[type],
                                  "action_arr",
                                  $$v
                                )
                              },
                              expression: "friendStartSettings[type].action_arr"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }