var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "8" } }, [_c("PtTitle")], 1),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pt-8", attrs: { cols: "4", align: "right" } },
            [
              !_vm.isNewRecord
                ? _c("btn-dialog-destroy", { on: { save: _vm.destroy } })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "validation-observer",
        { ref: "observer" },
        [
          _c(
            "v-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.save($event)
                }
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("VfLoadingOverlayAbsolute", {
                    attrs: { loading: _vm.loading }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("vf-text-field", {
                                attrs: {
                                  label: _vm.$$tm("channel_role.name"),
                                  vees: _vm.vees.name
                                },
                                model: {
                                  value: _vm.channelRole.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.channelRole, "name", $$v)
                                  },
                                  expression: "channelRole.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("choice-tables", {
                        attrs: {
                          "available-role-types": _vm.availableRoleTypes
                        },
                        model: {
                          value: _vm.selectedItems,
                          callback: function($$v) {
                            _vm.selectedItems = $$v
                          },
                          expression: "selectedItems"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-btn", { on: { click: _vm.toIndex } }, [
                        _vm._v(_vm._s(_vm.$t("btn.back")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            type: "submit",
                            loading: _vm.$wait.is("processing"),
                            color: "primary"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("btn.create")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }