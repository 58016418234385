var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PtTitle"),
      _vm._v("\n  " + _vm._s(_vm.$t("subtitle.answer_form")) + "\n  "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "3" } },
            [
              _c("PtFolder", {
                ref: "folder",
                attrs: { "folder-type": "answer_form" },
                on: {
                  "select-folder": _vm.selectFolder,
                  "pt-init-folders": _vm.ptInitFolders
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { outlined: "", md: "9" } },
            [
              _c(
                "v-card",
                [
                  _c("VfLoadingOverlayAbsolute", {
                    attrs: { loading: _vm.loading }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Search",
                                  "append-icon": "mdi-magnify",
                                  clearable: "",
                                  "single-line": "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "text-right", attrs: { md: "6" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.checkedItems
                                                        .length === 0
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(_vm.$t("btn.multi")) +
                                                  "\n                    "
                                              ),
                                              _c(
                                                "v-icon",
                                                { attrs: { right: "" } },
                                                [_vm._v("mdi-chevron-down")]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.selectedActionConfirm(
                                                "delete"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("lbl.selected_delete")
                                              )
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("VDivider"),
                                      _vm._v(" "),
                                      _vm._l(_vm.folders, function(
                                        folder,
                                        folder_idx
                                      ) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            key: folder_idx,
                                            on: {
                                              click: function($event) {
                                                return _vm.moveFolder(folder)
                                              }
                                            }
                                          },
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("lbl.move_to", {
                                                    to: folder.name
                                                  })
                                                )
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c("VDivider"),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.favorite(
                                                _vm.checkedIds,
                                                1
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [_vm._v("mdi-star")]),
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("lbl.answer_form.puton")
                                              )
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.favorite(
                                                _vm.checkedIds,
                                                0
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [_vm._v("mdi-star")]),
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("lbl.answer_form.remove")
                                              )
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("VDivider"),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.bulkMute(
                                                _vm.checkedIds,
                                                1
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "lbl.answer_form.mute_bulk"
                                                )
                                              )
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.bulkMute(
                                                _vm.checkedIds,
                                                0
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "lbl.answer_form.unmute_bulk"
                                                )
                                              )
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: { click: _vm.newItem }
                                },
                                [_vm._v(_vm._s(_vm.$t("btn.new_item")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.items,
                      search: _vm.search,
                      "item-key": "id",
                      "show-select": "",
                      "disable-sort": true,
                      "items-per-page": 50,
                      "footer-props": {
                        itemsPerPageOptions: [50, 100, 150]
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.name",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-row",
                                { staticClass: "m-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "p-0 d-flex align-center",
                                      attrs: { md: "9" }
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "javascript:;" },
                                          on: {
                                            click: function($event) {
                                              return _vm.editItem(item)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item.name) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      !item.publish_flg
                                        ? _c(
                                            "v-chip",
                                            {
                                              staticClass: "ma-2 chip-publish",
                                              attrs: {
                                                label: "",
                                                color: "red",
                                                "text-color": "white",
                                                small: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  停止中\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { staticClass: "p-0", attrs: { md: "3" } },
                                    [
                                      item.favourite_flg
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "ma-2",
                                              attrs: {
                                                text: "",
                                                icon: "",
                                                small: "",
                                                color: "orange"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.favorite(
                                                    [item.id],
                                                    0
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [_vm._v("mdi-star")])
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-btn",
                                            {
                                              staticClass: "ma-2",
                                              attrs: {
                                                text: "",
                                                icon: "",
                                                small: "",
                                                color: "gray"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.favorite(
                                                    [item.id],
                                                    1
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-star-outline")
                                              ])
                                            ],
                                            1
                                          ),
                                      _vm._v(" "),
                                      item.mute_notification_flg
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "ma-0",
                                              attrs: {
                                                text: "",
                                                icon: "",
                                                small: "",
                                                color: "gray"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.muteNotification(
                                                    item.id,
                                                    0
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-volume-mute")
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { staticClass: "m-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "p-0 d-flex align-center",
                                      attrs: { md: "9" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticClass:
                                          "mt-0 pt-0 font-size-x-small",
                                        attrs: {
                                          value: "[FORM_" + item.alt_uuid + "]",
                                          readonly: "",
                                          "hide-details": true,
                                          "persistent-hint": false
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          directives: [
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:copy",
                                              value:
                                                "[FORM_" + item.alt_uuid + "]",
                                              expression:
                                                "`[FORM_${item.alt_uuid}]`",
                                              arg: "copy"
                                            },
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:success",
                                              value: _vm.onClipCopy,
                                              expression: "onClipCopy",
                                              arg: "success"
                                            },
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:error",
                                              value: _vm.onClipError,
                                              expression: "onClipError",
                                              arg: "error"
                                            }
                                          ],
                                          staticClass: "ma-1",
                                          attrs: { small: "" }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-content-copy")
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "text-decoration-none",
                                          attrs: { small: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.$router.push({
                                                name: "AnswerFormShowUrl",
                                                params: {
                                                  id: item.id,
                                                  alt_uuid: item.alt_uuid
                                                }
                                              })
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "lbl.answer_form.external"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { staticClass: "p-0", attrs: { md: "3" } },
                                    [
                                      item.spreadsheet_replication_flg &&
                                      item.sheet_id
                                        ? _c(
                                            "a",
                                            {
                                              staticClass:
                                                "text-decoration-none",
                                              attrs: {
                                                target: "_blank",
                                                href:
                                                  "https://docs.google.com/spreadsheets/d/" +
                                                  item.sheet_id
                                              }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ma-2",
                                                  attrs: {
                                                    text: "",
                                                    icon: "",
                                                    small: "",
                                                    color: "green"
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      "mdi-google-spreadsheet"
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "item.answer_form_status",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    "" +
                                      item.response_count +
                                      _vm.$t("lbl.answer_form.reply") +
                                      "/" +
                                      item.total_friends_answer +
                                      _vm.$t("lbl.answer_form.people")
                                  ) +
                                  "\n            "
                              ),
                              item.total_answer
                                ? [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mb-2",
                                        attrs: { small: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.$router.push({
                                              name: "AnswerFormShow",
                                              params: { id: item.id },
                                              query: { tab: 3 }
                                            })
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t("lbl.answer_form.display")
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-decoration-none",
                                        attrs: {
                                          href:
                                            "/api/v1/answer_form_responses/export_csv.csv?answer_form_id=" +
                                            item.id
                                        }
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mb-2",
                                            attrs: { small: "" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "lbl.answer_form.response_list_dl"
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: "item.buttons",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mb-2",
                                  attrs: { small: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push({
                                        name: "AnswerFormChangeStatus",
                                        params: { id: item.id }
                                      })
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("lbl.answer_form.stop_switching")
                                      ) +
                                      "\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "text-decoration-none",
                                  attrs: {
                                    target: "_blank",
                                    href:
                                      _vm.$router.resolve({
                                        name: "AnswerFormAnswer",
                                        params: { uuid: item.alt_uuid }
                                      }).href + "?mode=example"
                                  }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { small: "" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("lbl.answer_form.preview")
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mb-2",
                                  attrs: { small: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push({
                                        name: "AnswerFormShow",
                                        params: { id: item.id }
                                      })
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("lbl.answer_form.detail")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          }
                        },
                        {
                          key: "item.action",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mb-2",
                                                    attrs: {
                                                      small: "",
                                                      color: "primary"
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t("btn.operation")
                                                    ) +
                                                    "\n                  "
                                                ),
                                                _c(
                                                  "v-icon",
                                                  { attrs: { right: "" } },
                                                  [_vm._v("mdi-chevron-down")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-list",
                                    [
                                      item.favourite_flg
                                        ? _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.favorite(
                                                    [item.id],
                                                    0
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-star")
                                              ]),
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "lbl.answer_form.remove"
                                                    )
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !item.favourite_flg
                                        ? _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.favorite(
                                                    [item.id],
                                                    1
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-star")
                                              ]),
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "lbl.answer_form.puton"
                                                    )
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.editItem(item)
                                            }
                                          }
                                        },
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "lbl.answer_form.edit_form"
                                                )
                                              )
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.copyForm(item)
                                            }
                                          }
                                        },
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "lbl.answer_form.copy_and_edit"
                                                )
                                              )
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      !item.mute_notification_flg
                                        ? _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.muteNotification(
                                                    item.id,
                                                    1
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "lbl.answer_form.mute_notification"
                                                    )
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.mute_notification_flg
                                        ? _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.muteNotification(
                                                    item.id,
                                                    0
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "lbl.answer_form.un_mute_notification"
                                                    )
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteItem(item)
                                            }
                                          }
                                        },
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "lbl.answer_form.delete_form"
                                                )
                                              )
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "item.folder",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", { staticClass: "text-caption" }, [
                                _vm._v(_vm._s(item.folder_name))
                              ]),
                              _c("br"),
                              _vm._v(" "),
                              _c("span", { staticClass: "text-caption" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatDate(item.created_at) +
                                      _vm.$t("lbl.answer_form.registration")
                                  )
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.used_count",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("lbl.people", {
                                      count: item.used_count
                                    })
                                  ) +
                                  "\n            "
                              ),
                              item.limit_flg
                                ? [
                                    _vm._v(
                                      "\n              /\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "lbl.hashtag.limit_used_count",
                                            { count: item.limit_num }
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: "item.action_group",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("PtActionLabelMessages", {
                                staticClass: "or-list",
                                model: {
                                  value: item.action_group.make_action_arr,
                                  callback: function($$v) {
                                    _vm.$set(
                                      item.action_group,
                                      "make_action_arr",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "item.action_group.make_action_arr"
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.checkedItems,
                      callback: function($$v) {
                        _vm.checkedItems = $$v
                      },
                      expression: "checkedItems"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("VfDialogConfirm", {
        attrs: {
          title: _vm.$t("confirm_title.delete"),
          detail: _vm.$t("confirm.delete"),
          "save-path": _vm.savePath,
          "form-type": "delete",
          "target-emit": "delete-success"
        },
        on: { "delete-success": _vm.deleteSuccess },
        model: {
          value: _vm.deleteDialog,
          callback: function($$v) {
            _vm.deleteDialog = $$v
          },
          expression: "deleteDialog"
        }
      }),
      _vm._v(" "),
      _c("PtDialogLinePreview", {
        key: _vm.selectedItem.id,
        attrs: { type: "answer_form", "model-id": _vm.selectedItem.id },
        model: {
          value: _vm.previewDialog,
          callback: function($$v) {
            _vm.previewDialog = $$v
          },
          expression: "previewDialog"
        }
      }),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.commonDialog,
            callback: function($$v) {
              _vm.commonDialog = $$v
            },
            expression: "commonDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("VfLoadingOverlayAbsolute", {
                attrs: { loading: _vm.commonDialogLoading }
              }),
              _vm._v(" "),
              _c("v-card-text", { staticClass: "pt-6" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("lbl.confirm_selected_" + _vm.selectedAction)
                    ) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("VSpacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function($event) {
                          _vm.commonDialog = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("btn.cancel")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.selectedSave(_vm.selectedAction)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("btn.action")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }