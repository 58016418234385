var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PtTitle"),
      _vm._v(" "),
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c("v-data-table", {
            staticClass: "friend_index_table",
            attrs: {
              headers: _vm.headers,
              items: _vm.items,
              "item-key": "id",
              "hide-default-footer": "",
              "disable-sort": true,
              "items-per-page": -1
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.name",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "FriendShow",
                              params: { id: item.friend.id }
                            }
                          }
                        },
                        [
                          _c(
                            "v-avatar",
                            {
                              staticClass: "mr-2 mt-2 mb-2",
                              attrs: { size: "48" }
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  small: "",
                                  src: _vm.$$noimageHelper(
                                    item.friend.api_picture_url
                                  ),
                                  alt: item.friend.api_display_name
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(
                            "\n          " +
                              _vm._s(item.friend.api_display_name) +
                              "\n        "
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "item.action",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "red white--text" },
                          on: {
                            click: function($event) {
                              return _vm.deleteTestAccount(item.id)
                            }
                          }
                        },
                        [_vm._v("登録削除")]
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }