var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "mt-3" },
        [
          _c(
            "v-col",
            { attrs: { md: "9" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("span", { staticStyle: { "font-size": "14px" } }, [
                      _c("b", { staticStyle: { color: "red" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("lbl.answer_form.list_of_respondents_not_1")
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "pl-2",
                        staticStyle: { "font-size": "14px" }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("lbl.answer_form.list_of_respondents_not_2")
                          )
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { md: "3" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "blue lighten-2 color-white",
                  on: { click: _vm.totalAnsweredFriends }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("btn.view_friends_list")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { md: "12" } }, [
            _c("table", { staticClass: "table .table-hover" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("対応マーク")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("名前")]),
                  _vm._v(" "),
                  _c("th"),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      class: !_vm.formSetting.response_limit_per_person
                        ? "opacity-blur"
                        : ""
                    },
                    [_vm._v("\n              再回答\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      class: !_vm.formSetting.response_limit_per_person
                        ? "opacity-blur"
                        : ""
                    },
                    [
                      _c("VfCheckbox", {
                        attrs: { label: "再回答可にする", "hide-details": "" },
                        model: {
                          value: _vm.selectAllAccept,
                          callback: function($$v) {
                            _vm.selectAllAccept = $$v
                          },
                          expression: "selectAllAccept"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      class: !_vm.formSetting.response_limit_per_person
                        ? "opacity-blur"
                        : ""
                    },
                    [
                      _c("VfCheckbox", {
                        attrs: {
                          label: "再回答不可にする",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.selectAllDenied,
                          callback: function($$v) {
                            _vm.selectAllDenied = $$v
                          },
                          expression: "selectAllDenied"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                [
                  _vm._l(_vm.listFriend, function(friend, friend_i) {
                    return _c(
                      "tr",
                      { key: friend_i },
                      [
                        _c(
                          "td",
                          [
                            _c(
                              "v-chip",
                              {
                                attrs: {
                                  dark: "",
                                  label: "",
                                  color: _vm.colors[friend.message_status]
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$$te("friend.message_status", friend)
                                    ) +
                                    "\n              "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.$router.resolve({
                                  name: "FriendShow",
                                  params: { id: friend.id }
                                }).href
                              }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(friend.name) +
                                  "\n              "
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.$router.resolve({
                                  name: "ListAnswerResponseMember",
                                  params: { friend_id: friend.id }
                                }).href
                              }
                            },
                            [
                              _c("v-btn", { attrs: { color: "primary" } }, [
                                _vm._v("回答表示")
                              ])
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        Array.isArray(_vm.limitExceptionFriends) &&
                        !_vm.limitExceptionFriends.includes(friend.id)
                          ? [
                              _c(
                                "td",
                                {
                                  class: !_vm.formSetting
                                    .response_limit_per_person
                                    ? "opacity-blur"
                                    : ""
                                },
                                [
                                  _vm._v(
                                    "\n                再回答不可\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: !_vm.formSetting
                                    .response_limit_per_person
                                    ? "opacity-blur"
                                    : ""
                                },
                                [
                                  _c("VfCheckbox", {
                                    attrs: {
                                      label: "",
                                      "checkbox-value": friend.id
                                    },
                                    model: {
                                      value: _vm.selectedAccept,
                                      callback: function($$v) {
                                        _vm.selectedAccept = $$v
                                      },
                                      expression: "selectedAccept"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("td")
                            ]
                          : [
                              _c(
                                "td",
                                {
                                  staticClass: "red--text",
                                  class: !_vm.formSetting
                                    .response_limit_per_person
                                    ? "opacity-blur"
                                    : ""
                                },
                                [
                                  _vm._v(
                                    "\n                再回答可\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("td"),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: !_vm.formSetting
                                    .response_limit_per_person
                                    ? "opacity-blur"
                                    : ""
                                },
                                [
                                  _c("VfCheckbox", {
                                    attrs: {
                                      label: "",
                                      "checkbox-value": friend.id
                                    },
                                    model: {
                                      value: _vm.selectedDenied,
                                      callback: function($$v) {
                                        _vm.selectedDenied = $$v
                                      },
                                      expression: "selectedDenied"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                      ],
                      2
                    )
                  }),
                  _vm._v(" "),
                  _vm.answerData &&
                  _vm.answerData.hasOwnProperty("total_anonymous_answer")
                    ? _c("tr", [
                        _c("td"),
                        _vm._v(" "),
                        _c("td"),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                "匿名(" +
                                  _vm.answerData.total_anonymous_answer +
                                  "名)"
                              ) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v("-")]),
                        _vm._v(" "),
                        _c("td", [_vm._v("-")]),
                        _vm._v(" "),
                        _c("td", [_vm._v("-")])
                      ])
                    : _vm._e()
                ],
                2
              )
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "text-right", attrs: { md: "6", "offset-md": "6" } },
            [
              _c(
                "v-btn",
                {
                  class: !_vm.formSetting.response_limit_per_person
                    ? "opacity-blur"
                    : "",
                  attrs: { color: "primary" },
                  on: { click: _vm.exceptFriend }
                },
                [
                  _vm._v(
                    "\n        チェックしたメンバーの再回答を変更する\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }