var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PtTitle"),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "6" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s("「" + _vm.name + "」への回答") +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("v-card-text", [
                    _vm.answerDetail &&
                    _vm.answerDetail.hasOwnProperty("created_at")
                      ? _c("p", [
                          _c("span", [
                            _vm._v("回答日:\n              "),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm
                                    .moment(_vm.answerDetail.created_at)
                                    .format("YYYY/MM/DD HH:mm:ss")
                                )
                              )
                            ])
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.answerDetail &&
                    _vm.answerDetail.hasOwnProperty("form_response")
                      ? _c(
                          "table",
                          { staticClass: "table table-bordered table-striped" },
                          [
                            _c(
                              "tbody",
                              [
                                _vm._l(_vm.answerDetail.form_response, function(
                                  answer
                                ) {
                                  return [
                                    answer.hasOwnProperty("beautify_value") &&
                                    Array.isArray(answer.beautify_value) &&
                                    answer.beautify_value.length > 0 &&
                                    answer.beautify_value[0]
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            { attrs: { width: "40%" } },
                                            [_vm._v(_vm._s(answer.input_title))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { attrs: { width: "60%" } },
                                            _vm._l(
                                              answer.beautify_value,
                                              function(val) {
                                                return _c("p", [
                                                  _vm._v(_vm._s(val))
                                                ])
                                              }
                                            ),
                                            0
                                          )
                                        ])
                                      : _c("tr", [
                                          _c(
                                            "td",
                                            { attrs: { width: "40%" } },
                                            [_vm._v(_vm._s(answer.input_title))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { attrs: { width: "60%" } },
                                            [
                                              answer.value &&
                                              !Array.isArray(answer.value)
                                                ? [
                                                    typeof answer.value ==
                                                    "object"
                                                      ? [
                                                          answer.value.hasOwnProperty(
                                                            "upload_file_type"
                                                          ) &&
                                                          answer.value
                                                            .upload_file_type ==
                                                            "image"
                                                            ? _c("div", [
                                                                _c("img", {
                                                                  attrs: {
                                                                    src:
                                                                      answer
                                                                        .value
                                                                        .file
                                                                        .preview,
                                                                    alt: ""
                                                                  }
                                                                }),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href:
                                                                          answer
                                                                            .value
                                                                            .file
                                                                            .original
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.getNameImage(
                                                                            answer
                                                                              .value
                                                                              .file
                                                                              .original
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                ])
                                                              ])
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          answer.value.hasOwnProperty(
                                                            "upload_file_type"
                                                          ) &&
                                                          answer.value
                                                            .upload_file_type ==
                                                            "document"
                                                            ? _c(
                                                                "div",
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "mdi-file-document"
                                                                    )
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c("p", [
                                                                    _c(
                                                                      "a",
                                                                      {
                                                                        attrs: {
                                                                          href:
                                                                            answer
                                                                              .value
                                                                              .file
                                                                              .original
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.getNameImage(
                                                                              answer
                                                                                .value
                                                                                .file
                                                                                .original
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      : [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                answer.value
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        ])
                                  ]
                                })
                              ],
                              2
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { md: "6" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v(" 回答を登録する友だち ")]),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _vm.friends.length > 0 && _vm.detailData == null
                        ? _c("v-row", [
                            _c("div", { staticClass: "d-block" }, [
                              _c(
                                "p",
                                { staticClass: "font-weight-bold ml-3 mb-0" },
                                [
                                  _vm._v(
                                    "\n                友だちを選択してください\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("p", { staticClass: "ml-3" }, [
                                _vm._v("検索名: " + _vm._s(_vm.search))
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "table",
                              { staticClass: "table table-bordered ml-3" },
                              [
                                _c(
                                  "tbody",
                                  _vm._l(_vm.friends, function(item) {
                                    return _c("tr", [
                                      _c("td", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "javascript:void(0)"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.detail(item)
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "orgn-icon",
                                              attrs: {
                                                src: _vm.$$noimageHelper(
                                                  item.api_picture_url
                                                )
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(_vm._s(item.name))
                                            ])
                                          ]
                                        )
                                      ])
                                    ])
                                  }),
                                  0
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.friends.length &&
                      _vm.search &&
                      _vm.isSearch &&
                      _vm.detailData == null
                        ? _c("v-row", [
                            _c("p", { staticClass: "ml-3" }, [
                              _vm._v("検索名: " + _vm._s(_vm.search))
                            ]),
                            _vm._v(" "),
                            _c(
                              "table",
                              { staticClass: "table table-bordered ml-3" },
                              [
                                _c("tbody", [
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v("該当する友だちがいません")
                                    ])
                                  ])
                                ])
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.detailData
                        ? _c(
                            "v-row",
                            { staticClass: "ml-1" },
                            [
                              _c("v-col", { attrs: { md: "12" } }, [
                                _c(
                                  "div",
                                  [
                                    _c("img", {
                                      staticClass: "orgn-icon",
                                      attrs: {
                                        src: _vm.$$noimageHelper(
                                          _vm.detailData.api_picture_url
                                        )
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "pl-2" }, [
                                      _c("b", [
                                        _vm._v(_vm._s(_vm.detailData.name))
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.detailData.id))
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { small: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.detailFriend(
                                              _vm.detailData.id
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("詳細")]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-chip",
                                {
                                  staticClass: "ma-2 pl-2",
                                  attrs: {
                                    color: "green",
                                    "text-color": "white",
                                    small: ""
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { "x-small": "" } }, [
                                    _vm._v("mdi-message")
                                  ]),
                                  _vm._v("LINE\n            ")
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", { attrs: { md: "12" } }, [
                                _c("div", [
                                  _c("p", { staticClass: "font-weight-bold" }, [
                                    _vm._v("タグ")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "table",
                                    { staticClass: "table table-bordered" },
                                    [
                                      _c("tbody", [
                                        _c("tr", [
                                          !_vm.detailData.hashtags.length
                                            ? _c("td", [_vm._v("登録なし")])
                                            : _c(
                                                "td",
                                                [
                                                  _vm._l(
                                                    _vm.detailData.hashtags,
                                                    function(item) {
                                                      return [
                                                        _c(
                                                          "v-chip",
                                                          {
                                                            staticClass: "ma-2"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            " +
                                                                _vm._s(
                                                                  item.name
                                                                ) +
                                                                "\n                          "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                        ])
                                      ])
                                    ]
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { md: "12" } }, [
                                _c(
                                  "table",
                                  { staticClass: "table table-bordered ml-3" },
                                  [
                                    _c(
                                      "tbody",
                                      _vm._l(_vm.friends, function(item) {
                                        return _c("tr", [
                                          _c("td", [
                                            _c("a", {
                                              attrs: {
                                                href: "javascript:void(0)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.detail(item)
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("img", {
                                              staticClass: "orgn-icon",
                                              attrs: {
                                                src: _vm.$$noimageHelper(
                                                  item.api_picture_url
                                                )
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(_vm._s(item.name))
                                            ])
                                          ])
                                        ])
                                      }),
                                      0
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c("div", { staticClass: "d-block" }, [
                            _vm.detailData
                              ? _c("p", { staticClass: "ml-3 mb-0" }, [
                                  _vm._v("検索をやり直す")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "p",
                              { staticClass: "font-weight-bold mt-3 ml-3" },
                              [
                                _vm._v(
                                  "\n                検索する友だち名を入力してください\n              "
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { md: "10" } },
                            [
                              _c("v-text-field", {
                                model: {
                                  value: _vm.search,
                                  callback: function($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { md: "2" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "success" },
                                  on: { click: _vm.onSearch }
                                },
                                [_vm._v("検索")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.detailData != null
        ? _c(
            "div",
            [
              _c("hr"),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { height: "100px", color: "#fcf8e3" } },
                        [
                          _c("v-card-text", [
                            _c("p", [_vm._v("確認事項")]),
                            _vm._v(" "),
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  "\n                フォームにメッセージ送信を設定していた場合、照合時に配信されます。\n              "
                                )
                              ])
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { height: "100px" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-dialog",
                                {
                                  attrs: { persistent: "", "max-width": "400" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mt-3",
                                                    attrs: {
                                                      color: "success",
                                                      width: "100%"
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.detailData.name.trim() +
                                                        "の回答として登録する"
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    146658721
                                  ),
                                  model: {
                                    value: _vm.dialog,
                                    callback: function($$v) {
                                      _vm.dialog = $$v
                                    },
                                    expression: "dialog"
                                  }
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-card",
                                    [
                                      _c("v-card-title"),
                                      _vm._v(" "),
                                      _c("v-card-text", [
                                        _vm._v(
                                          "\n                  操作は取り消せません。本当にこの友だちに登録しますか？\n                "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              on: {
                                                click: function($event) {
                                                  _vm.dialog = false
                                                }
                                              }
                                            },
                                            [_vm._v("いいえ")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { color: "primary" },
                                              on: { click: _vm.addFriend }
                                            },
                                            [_vm._v("はい")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }