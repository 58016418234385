var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-card-text",
            [
              _c("v-card", { staticClass: "py-4" }, [
                _c("div", { staticClass: "text-center red--text" }, [
                  _vm._v(_vm._s(_vm.$t("lbl.caution_panel")))
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-card",
                [
                  _vm._l(_vm.internalValue.deli_carousel_panels, function(
                    panel,
                    panel_i
                  ) {
                    return _c(
                      "v-chip",
                      {
                        key: panel_i,
                        staticClass: "ma-2",
                        attrs: { color: "orange", dark: "" },
                        on: {
                          click: function($event) {
                            return _vm.selectPanel(panel)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("lbl.panel")) +
                            " " +
                            _vm._s(panel_i + 1) +
                            "\n          "
                        ),
                        panel_i > 0
                          ? _c(
                              "v-icon",
                              {
                                attrs: { right: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.removePanel(panel_i)
                                  }
                                }
                              },
                              [_vm._v("mdi-close-circle")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _vm.canAddPanel
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "ma-2",
                          attrs: { color: "primary", dark: "" },
                          on: { click: _vm.addPanel }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("btn.add")) +
                              "\n          "
                          ),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("mdi-plus-circle")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "pl-2 pb-2 red--text" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("lbl.caution_panel_count")) +
                        "\n        "
                    )
                  ])
                ],
                2
              ),
              _vm._v(" "),
              _vm._l(_vm.internalValue.deli_carousel_panels, function(
                panel,
                panel_i
              ) {
                return _c("div", { key: panel_i }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectedPanel == panel,
                          expression: "selectedPanel == panel"
                        }
                      ]
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "mb-8" },
                        [
                          _c("v-card-title", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("lbl.panel")) +
                                " " +
                                _vm._s(panel_i + 1) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            [
                              _c("VfTextField", {
                                attrs: {
                                  label: _vm.$$tm("deli_carousel_panel.title"),
                                  vees: _vm.veesPanel.title,
                                  counter: ""
                                },
                                model: {
                                  value: panel.title,
                                  callback: function($$v) {
                                    _vm.$set(panel, "title", $$v)
                                  },
                                  expression: "panel.title"
                                }
                              }),
                              _vm._v(" "),
                              _c("VfTextField", {
                                attrs: {
                                  label: _vm.$$tm("deli_carousel_panel.text"),
                                  vees: _vm.veesPanel.text,
                                  counter: ""
                                },
                                model: {
                                  value: panel.text,
                                  callback: function($$v) {
                                    _vm.$set(panel, "text", $$v)
                                  },
                                  expression: "panel.text"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c("VImg", {
                                        attrs: {
                                          src: panel.image_url,
                                          height: 200,
                                          width: 200
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-col", { attrs: { md: "6" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "mb-4" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.openDialog(panel)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.$t("btn.select_image")
                                                ) +
                                                "\n                    "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    panel.upload_file_id
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.removeImage(
                                                      panel
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t("btn.remove_image")
                                                    ) +
                                                    "\n                    "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card",
                        [
                          _vm._l(panel.deli_carousel_panel_choices, function(
                            choice,
                            choice_i
                          ) {
                            return _c(
                              "v-chip",
                              {
                                key: choice_i,
                                staticClass: "ma-2",
                                attrs: { color: "orange", dark: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.selectChoice(choice)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("lbl.choice")) +
                                    " " +
                                    _vm._s(choice_i + 1) +
                                    "\n              "
                                ),
                                choice_i > 0
                                  ? _c(
                                      "v-icon",
                                      {
                                        attrs: { right: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.removeChoice(choice_i)
                                          }
                                        }
                                      },
                                      [_vm._v("mdi-close-circle")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          _vm._v(" "),
                          _vm.canAddChoice
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "ma-2",
                                  attrs: { color: "primary", dark: "" },
                                  on: { click: _vm.addChoice }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("btn.add")) +
                                      "\n              "
                                  ),
                                  _c("v-icon", { attrs: { right: "" } }, [
                                    _vm._v("mdi-plus-circle")
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "pl-2 pb-2 red--text" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("lbl.caution_panel_choice_count")
                                ) +
                                "\n            "
                            )
                          ])
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm._l(panel.deli_carousel_panel_choices, function(
                        choice,
                        choice_i
                      ) {
                        return _c("div", { key: choice_i }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.selectedChoice == choice,
                                  expression: "selectedChoice == choice"
                                }
                              ]
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c("v-card-title", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.$t("lbl.choice")) +
                                        " " +
                                        _vm._s(choice_i + 1) +
                                        "\n                "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("VfTextField", {
                                        attrs: {
                                          label: _vm.$$tm(
                                            "deli_carousel_panel_choice.choice_name"
                                          ),
                                          vees: _vm.veesChoice.choice_name,
                                          counter: ""
                                        },
                                        model: {
                                          value: choice.choice_name,
                                          callback: function($$v) {
                                            _vm.$set(choice, "choice_name", $$v)
                                          },
                                          expression: "choice.choice_name"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("VfSelect", {
                                        attrs: {
                                          label: _vm.$$tm(
                                            "deli_carousel_panel_choice.choice_action_type"
                                          ),
                                          vees:
                                            _vm.veesChoice.choice_action_type,
                                          selects: _vm.choiceActionTypes
                                        },
                                        model: {
                                          value: choice.choice_action_type,
                                          callback: function($$v) {
                                            _vm.$set(
                                              choice,
                                              "choice_action_type",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "choice.choice_action_type"
                                        }
                                      }),
                                      _vm._v(" "),
                                      choice.choice_action_type !== "action" &&
                                      choice.choice_action_type !== "none"
                                        ? _c("VfTextField", {
                                            key: choice.choice_action_type,
                                            attrs: {
                                              label: _vm.$t(
                                                "lbl." +
                                                  choice.choice_action_type +
                                                  "_text"
                                              ),
                                              vees:
                                                _vm.multiTextValidates[
                                                  choice.choice_action_type
                                                ]
                                            },
                                            model: {
                                              value: choice.multi_text,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  choice,
                                                  "multi_text",
                                                  $$v
                                                )
                                              },
                                              expression: "choice.multi_text"
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      choice.choice_action_type === "action"
                                        ? _c(
                                            "v-card-text",
                                            [
                                              _c("PtActionSelectedList", {
                                                attrs: {
                                                  "msg-hidden-flg": true
                                                },
                                                model: {
                                                  value: choice.action_arr,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      choice,
                                                      "action_arr",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "choice.action_arr"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      })
                    ],
                    2
                  )
                ])
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _c("VfTextArea", {
                    attrs: {
                      label: _vm.$$tm("deli_question.message_for_pc"),
                      vees: _vm.vees.message_for_pc,
                      counter: ""
                    },
                    model: {
                      value: _vm.internalValue.message_for_pc,
                      callback: function($$v) {
                        _vm.$set(_vm.internalValue, "message_for_pc", $$v)
                      },
                      expression: "internalValue.message_for_pc"
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("PtDeliDialogForSelectFile", {
        attrs: { "upload-file-type": "image" },
        on: { "select-file-done": _vm.selectFileDone },
        model: {
          value: _vm.dialog,
          callback: function($$v) {
            _vm.dialog = $$v
          },
          expression: "dialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }