var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "3" } },
            [
              _c("VfCheckbox", {
                attrs: {
                  label: _vm.$t("lbl.answer_form.setting.design.is_use_custom")
                },
                on: { change: _vm.updateSettings },
                model: {
                  value: _vm.isUseCustomDesign,
                  callback: function($$v) {
                    _vm.isUseCustomDesign = $$v
                  },
                  expression: "isUseCustomDesign"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.isUseCustomDesign
        ? [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { md: "4" } },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-start align-center px-4" },
                      [
                        _c("span", { staticClass: "font-weight-bold mr-2" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "lbl.answer_form.setting.design.button_color"
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "example-box",
                          style: { background: _vm.buttonColor }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("v-color-picker", {
                      staticClass: "ma-2 v-color-picker_custom",
                      attrs: {
                        value: _vm.buttonColor,
                        "hide-inputs": "",
                        "hide-sliders": "",
                        "hide-canvas": "",
                        "show-swatches": "",
                        "swatches-max-height": "240",
                        swatches: _vm.swatches
                      },
                      on: { "update:color": _vm.changeButtonColor }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-col",
                  { attrs: { md: "4" } },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-start align-center px-4" },
                      [
                        _c("span", { staticClass: "font-weight-bold mr-2" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "lbl.answer_form.setting.design.background_color"
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "example-box",
                          style: { background: _vm.backgroundColor }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("v-color-picker", {
                      staticClass: "ma-2 v-color-picker_custom",
                      attrs: {
                        value: _vm.backgroundColor,
                        "hide-inputs": "",
                        "hide-sliders": "",
                        "hide-canvas": "",
                        "show-swatches": "",
                        "swatches-max-height": "240",
                        swatches: _vm.swatches
                      },
                      on: { "update:color": _vm.changeBackroundColor }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-col",
                  { attrs: { md: "4" } },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-start align-center px-4" },
                      [
                        _c("span", { staticClass: "font-weight-bold mr-2" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "lbl.answer_form.setting.design.form_part_color"
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "example-box",
                          style: { background: _vm.formPartColor }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("v-color-picker", {
                      staticClass: "ma-2 v-color-picker_custom",
                      attrs: {
                        value: _vm.formPartColor,
                        "hide-inputs": "",
                        "hide-sliders": "",
                        "hide-canvas": "",
                        "show-swatches": "",
                        "swatches-max-height": "240",
                        swatches: _vm.swatches
                      },
                      on: { "update:color": _vm.changeFormPartColor }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              { staticClass: "mt-10 px-5" },
              [
                _c(
                  "v-col",
                  { attrs: { md: "6" } },
                  [
                    _c("p", { staticClass: "font-weight-bold mb-0" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "lbl.answer_form.setting.design.header_image_url"
                            )
                          ) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("VfTextField", {
                      staticClass: "vf-hidden-label pt-0",
                      attrs: {
                        label: _vm.$t(
                          "lbl.answer_form.setting.design.header_image_url"
                        ),
                        vees: {} || _vm.rules.header_image_url
                      },
                      on: { input: _vm.updateSettings },
                      model: {
                        value: _vm.headerImageUrl,
                        callback: function($$v) {
                          _vm.headerImageUrl = $$v
                        },
                        expression: "headerImageUrl"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-col",
                  { attrs: { md: "6" } },
                  [
                    _c("p", { staticClass: "font-weight-bold mb-0" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "lbl.answer_form.setting.design.background_image_url"
                            )
                          ) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("VfTextField", {
                      staticClass: "vf-hidden-label pt-0",
                      attrs: {
                        label: _vm.$t(
                          "lbl.answer_form.setting.design.background_image_url"
                        ),
                        vees: {} || _vm.rules.background_image_url
                      },
                      on: { input: _vm.updateSettings },
                      model: {
                        value: _vm.backgroundImageUrl,
                        callback: function($$v) {
                          _vm.backgroundImageUrl = $$v
                        },
                        expression: "backgroundImageUrl"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              { staticClass: "px-5" },
              [
                _c(
                  "v-col",
                  { attrs: { md: "3" } },
                  [
                    _c("VfCheckbox", {
                      attrs: {
                        label: _vm.$t(
                          "lbl.answer_form.setting.design.hide_header_icon"
                        )
                      },
                      on: { change: _vm.updateSettings },
                      model: {
                        value: _vm.isHideHeaderIcon,
                        callback: function($$v) {
                          _vm.isHideHeaderIcon = $$v
                        },
                        expression: "isHideHeaderIcon"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }