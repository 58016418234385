var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.actionLabelMsgs, function(msg) {
        return _c("div", { key: msg.no }, [
          _vm._v("\n      " + _vm._s(msg.label) + "\n    ")
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }