var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "home" } }, [
    _c(
      "div",
      {
        staticClass: "text-h5 ma-6 green--text text--darken-1 font-weight-black"
      },
      [_vm._v("\n    " + _vm._s(_vm.titleDisp) + "\n  ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }