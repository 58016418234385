var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            _vm._l(_vm.opts.message_status, function(
              message_status,
              message_status_idx
            ) {
              return _c("VfCheckbox", {
                key: message_status_idx,
                staticClass: "float-left my-0 mr-6",
                attrs: {
                  label: _vm.$t(
                    "enum.search_message_status_choice.message_status." +
                      message_status
                  ),
                  "checkbox-value": message_status
                },
                model: {
                  value: _vm.internalValue.target_message_statuses,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "target_message_statuses", $$v)
                  },
                  expression: "internalValue.target_message_statuses"
                }
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }