var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.initDone && !_vm.isExpired
        ? _c(
            "v-card",
            { staticClass: "mx-auto py-4", attrs: { "max-width": "800" } },
            [
              _c(
                "v-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitForm($event)
                    }
                  }
                },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("v-row", { attrs: { justify: "center" } }, [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("meta.title." + _vm.$route.name))
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider", { staticClass: "mx-4" }),
                  _vm._v(" "),
                  _vm.$route.query.is_user_confirmed === "1"
                    ? _c(
                        "v-card",
                        {
                          staticClass: "ma-8",
                          attrs: { color: "grey lighten-4" }
                        },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("v-row", { attrs: { justify: "center" } }, [
                                _c("div", { staticClass: "or-break" }, [
                                  _vm._v(_vm._s(_vm.$t("lbl.email_done")))
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    { staticClass: "text-h5 font-weight-bold" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "12" } },
                            [
                              _c("vf-text-field", {
                                attrs: { label: "パスワード", password: "" },
                                model: {
                                  value: _vm.item.password,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "password", $$v)
                                  },
                                  expression: "item.password"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "12" } },
                            [
                              _c("vf-text-field", {
                                attrs: {
                                  label: "パスワード確認",
                                  password: ""
                                },
                                model: {
                                  value: _vm.item.password_confirmation,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.item,
                                      "password_confirmation",
                                      $$v
                                    )
                                  },
                                  expression: "item.password_confirmation"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            type: "submit",
                            color: "primary",
                            disabled: _vm.submiting
                          }
                        },
                        [_vm._v("送信")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm.initDone && _vm.isExpired
        ? _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row answer-form" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-sm-10 offset-sm-1 col-md-6 offset-md-3 col-lg-6 offset-lg-3 p-0 form-box"
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "form-header", style: _vm.bg_header },
                    [
                      _c("v-col", { attrs: { cols: "9" } }, [
                        _c("p", [_vm._v(_vm._s(_vm.$t("lbl.expired")))]),
                        _vm._v(" "),
                        _c("p", [_vm._v("ページを閉じて下さい。")])
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "icon-header",
                          attrs: { cols: "3", sm: "2", xs: "3", md: "2" }
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { size: "79", color: "#ddd" } },
                            [_vm._v("mdi-block-helper")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }