var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto", attrs: { "max-width": "800" } },
    [
      _c("v-card-title", [
        _vm._v("\n    " + _vm._s(_vm.$t("cancel_form.title")) + "\n  ")
      ]),
      _vm._v(" "),
      _c("v-card-text", [
        _vm._v("\n    ご解約申請を承りました。"),
        _c("br"),
        _vm._v(
          "\n    3営業日以内に解約処理を行わせていただき、完了次第メールにてご連絡させていただきますのでしばらくお待ちください。\n  "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }