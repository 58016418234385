var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": 500 },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b({ attrs: { small: "" } }, "v-btn", attrs, false),
                  on
                ),
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("btn.registor_url")) + "\n    "
                  )
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("アカウント登録用URL")]),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "pt-6" },
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("lbl.registor_url"),
                  value: _vm.registorUrl,
                  readonly: ""
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("btn.close")) + "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: { click: _vm.clipboardCopy }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("btn.clipboard_copy")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }