var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    attrs: { name: _vm.label, rules: _vm.rules },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var errors = ref.errors
          return [
            !_vm.hideLabel
              ? _c(
                  "div",
                  { staticClass: "mb-0 text-caption theme--light v-label" },
                  [_vm._v("\n    " + _vm._s(_vm.label) + "\n  ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("v-switch", {
              staticClass: "mt-0",
              attrs: {
                "error-messages": errors,
                "true-value": _vm.trueValue,
                "false-value": _vm.falseValue,
                disabled: _vm.disabled,
                readonly: _vm.readonly,
                inset: ""
              },
              on: { change: _vm.onChange },
              model: {
                value: _vm.internalValue,
                callback: function($$v) {
                  _vm.internalValue = $$v
                },
                expression: "internalValue"
              }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }