var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-alert",
        {
          staticStyle: { "font-size": "12px" },
          attrs: {
            border: "left",
            "colored-border": "",
            color: "deep-purple accent-4",
            elevation: "2"
          }
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.$t("lbl.friend.all_friends_selected_desc", {
                  count: _vm.totalCount
                })
              ) +
              "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c("VfCheckbox", {
                staticClass: "label-mb-0 my-0",
                attrs: {
                  label: _vm.$t("lbl.friend.all_friends_selected"),
                  "hide-details": ""
                },
                model: {
                  value: _vm.internalValue,
                  callback: function($$v) {
                    _vm.internalValue = $$v
                  },
                  expression: "internalValue"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", align: "right" } },
            [
              _c("btn-dialog-detach", {
                attrs: { detail: _vm.alertMessage },
                on: {
                  save: function($event) {
                    return _vm.$emit("detach")
                  }
                }
              }),
              _vm._v(" "),
              _c("btn-dialog-attach", {
                attrs: { detail: _vm.alertMessage },
                on: {
                  save: function($event) {
                    return _vm.$emit("attach")
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }