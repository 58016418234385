var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("VfSelect", {
                                attrs: {
                                  label: _vm.$$tm("fixed_phrase.name"),
                                  selects: _vm.selects,
                                  readonly: ""
                                },
                                model: {
                                  value: _vm.internalValue.fixed_phrase_id,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.internalValue,
                                      "fixed_phrase_id",
                                      $$v
                                    )
                                  },
                                  expression: "internalValue.fixed_phrase_id"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "4", cols: "12" } },
                    [
                      _c("PtFolder", {
                        ref: "folder",
                        attrs: {
                          "folder-type": "fixed_phrase",
                          "select-only": ""
                        },
                        on: { "select-folder": _vm.selectFolder }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { md: "8", cols: "12" } },
                    [
                      _c("v-data-table", {
                        attrs: {
                          headers: _vm.headers,
                          items: _vm.items,
                          "item-key": "id",
                          "show-select": "",
                          "mobile-breakpoint": "0",
                          "single-select": true,
                          "disable-sort": true,
                          "items-per-page": 50,
                          "footer-props": {
                            itemsPerPageOptions: [50, 100, 150]
                          }
                        },
                        on: { "click:row": _vm.rowClick },
                        model: {
                          value: _vm.checkedItems,
                          callback: function($$v) {
                            _vm.checkedItems = $$v
                          },
                          expression: "checkedItems"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("VSpacer"),
              _vm._v(" "),
              _c("v-btn", { on: { click: _vm.cancel } }, [
                _vm._v(_vm._s(_vm.$t("btn.cancel")))
              ]),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.decide } },
                [_vm._v(_vm._s(_vm.$t("btn.decide")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }