var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "my-4" },
    [
      _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.items,
          "item-key": "id",
          "disable-sort": true
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.scenario_id",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v("\n      " + _vm._s(item.scenario.name) + "\n    ")
                ]
              }
            },
            {
              key: "item.scenario_timing_id",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.timingDisplay(item.scenario_timing)) +
                      "\n    "
                  )
                ]
              }
            },
            {
              key: "item.deli_id",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("PtLinePreviewRow", {
                    staticClass: "my-2",
                    attrs: { deli: item.deli }
                  })
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }