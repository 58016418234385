var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [_c("v-col", { attrs: { cols: "8" } }, [_c("PtTitle")], 1)],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "ml-4", attrs: { md: "8" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Search",
                      "append-icon": "mdi-magnify",
                      clearable: "",
                      "single-line": "",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.items,
              search: _vm.search,
              "item-key": "id",
              "disable-sort": true,
              "items-per-page": 50,
              "footer-props": {
                itemsPerPageOptions: [50, 100, 150]
              }
            },
            on: { "click:row": _vm.rowClick }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }