var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "w-fc transparent", attrs: { outlined: "" } },
        [
          _c(
            "v-card-title",
            { staticClass: "pa-0 or-card-title" },
            [
              _c(
                "v-list-item",
                { staticClass: "pl-0" },
                [
                  _c("v-list-item-content", [_vm._v(" 月別メッセージ送信数 ")]),
                  _vm._v(" "),
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "or-card-title",
                          attrs: { small: "" },
                          on: {
                            click: function($event) {
                              return _vm.$router.push({ name: "MessageCount" })
                            }
                          }
                        },
                        [_vm._v("\n            もっと見る\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c("VfAlert", {
            attrs: { type: "error" },
            model: {
              value: _vm.errorMsg,
              callback: function($$v) {
                _vm.errorMsg = $$v
              },
              expression: "errorMsg"
            }
          }),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "pl-0 pt-0" },
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.items,
                  "disable-sort": "",
                  "hide-default-footer": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.month",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$$fdt(item.month, "YYYY/MM")) +
                              "\n        "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }