var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("VfAlert", {
        attrs: { type: "error" },
        model: {
          value: _vm.errorMsg,
          callback: function($$v) {
            _vm.errorMsg = $$v
          },
          expression: "errorMsg"
        }
      }),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { md: "6" } },
            [
              _c("VfRadioButton", {
                attrs: {
                  label: _vm.$$tm("action_rich_menu.rich_menu_action_type"),
                  vees: _vm.vees.rich_menu_action_type,
                  selects: _vm.opts.rich_menu_action_type,
                  "label-hidden": ""
                },
                model: {
                  value: _vm.internalValue.rich_menu_action_type,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "rich_menu_action_type", $$v)
                  },
                  expression: "internalValue.rich_menu_action_type"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "6" } },
            [
              _vm.internalValue.rich_menu_action_type !== "cancel"
                ? _c("PtDialogSelect", {
                    attrs: {
                      "select-type": "rich_menu",
                      "single-select": true,
                      vees: _vm.vees.name
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.upload_file_id",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("VImg", {
                                attrs: {
                                  width: "120",
                                  src: item.upload_file.preview_image.url
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.internalValue,
                      callback: function($$v) {
                        _vm.internalValue = $$v
                      },
                      expression: "internalValue"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }