var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.lineNotices,
          "items-per-page": -1,
          "disable-sort": "",
          "hide-default-footer": ""
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.user.name",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$emit("click:row", item.id)
                        }
                      }
                    },
                    [_vm._v("\n        " + _vm._s(item.user.name) + "\n      ")]
                  )
                ]
              }
            },
            {
              key: "item.notice_type",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$$te("line_notice.notice_type", item)) +
                      "\n    "
                  )
                ]
              }
            },
            {
              key: "item.actions",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        loading: _vm.$wait.is("processing"),
                        small: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.testNotification(item)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("notificator.test")) +
                          "\n      "
                      )
                    ]
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }