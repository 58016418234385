var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-tabs",
            {
              attrs: { grow: "" },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            _vm._l(_vm.items, function(item) {
              return _c("v-tab", { key: item.value }, [
                _vm._v("\n        " + _vm._s(item.text) + "\n      ")
              ])
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "v-tabs-items",
            {
              staticClass: "px-4 pb-4",
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c(
                "v-tab-item",
                [_c("PtClickInfo", { attrs: { click: _vm.click } })],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                [
                  _c("PtClickScenario", {
                    ref: "scenario",
                    attrs: { "click-id": _vm.click.id }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                [
                  _c("PtClickBroadcast", {
                    ref: "broadcast",
                    attrs: { "click-id": _vm.click.id }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                [
                  _c("PtClickUnicast", {
                    ref: "unicast",
                    attrs: { "click-id": _vm.click.id }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                [
                  _c("PtClickRichMenu", {
                    ref: "richmenu",
                    attrs: { "click-id": _vm.click.id }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                [
                  _c("PtClickRichMessage", {
                    ref: "richmessage",
                    attrs: { "click-id": _vm.click.id }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                [
                  _c("PtClickQuestion", {
                    ref: "question",
                    attrs: { "click-id": _vm.click.id }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                [
                  _c("PtClickCarousel", {
                    ref: "carousel",
                    attrs: { "click-id": _vm.click.id }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }