var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "mx-auto px-12 py-6", attrs: { "max-width": 800 } },
        [
          _c(
            "validation-observer",
            { ref: "observer" },
            [
              _c(
                "v-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.save($event)
                    }
                  }
                },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("v-row", { attrs: { justify: "center" } }, [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("meta.title." + _vm.$route.name))
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider", { staticClass: "mx-4" }),
                  _vm._v(" "),
                  _vm.user.email
                    ? _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("VfTextField", {
                                    attrs: {
                                      label: _vm.$$tm("user.name"),
                                      vees: _vm.vees.user.name
                                    },
                                    model: {
                                      value: _vm.user.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.user, "name", $$v)
                                      },
                                      expression: "user.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("VfTextField", {
                                    attrs: {
                                      label: _vm.$$tm("user.phone"),
                                      vees: _vm.vees.user.phone
                                    },
                                    model: {
                                      value: _vm.user.phone,
                                      callback: function($$v) {
                                        _vm.$set(_vm.user, "phone", $$v)
                                      },
                                      expression: "user.phone"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("VfTextField", {
                                    attrs: {
                                      label: _vm.$$tm("user_address.zip"),
                                      vees: _vm.vees.user_address.zip
                                    },
                                    model: {
                                      value: _vm.userAddress.zip,
                                      callback: function($$v) {
                                        _vm.$set(_vm.userAddress, "zip", $$v)
                                      },
                                      expression: "userAddress.zip"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("VfSelect", {
                                    attrs: {
                                      label: _vm.$$tm("user_address.pref"),
                                      vees: _vm.vees.user_address.pref,
                                      selects: _vm.opts.pref
                                    },
                                    model: {
                                      value: _vm.userAddress.pref,
                                      callback: function($$v) {
                                        _vm.$set(_vm.userAddress, "pref", $$v)
                                      },
                                      expression: "userAddress.pref"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c("VfTextField", {
                                    attrs: {
                                      label: _vm.$$tm("user_address.address1"),
                                      vees: _vm.vees.user_address.address1
                                    },
                                    model: {
                                      value: _vm.userAddress.address1,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.userAddress,
                                          "address1",
                                          $$v
                                        )
                                      },
                                      expression: "userAddress.address1"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c("VfTextField", {
                                    attrs: {
                                      label: _vm.$$tm("user_address.address2"),
                                      vees: _vm.vees.user_address.address2
                                    },
                                    model: {
                                      value: _vm.userAddress.address2,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.userAddress,
                                          "address2",
                                          $$v
                                        )
                                      },
                                      expression: "userAddress.address2"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.planDisp
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("VfSelect", {
                                        attrs: {
                                          label: _vm.$$tm("plan.name"),
                                          selects: _vm.opts.plans,
                                          vees: { rules: "required" }
                                        },
                                        model: {
                                          value: _vm.user.plan_id,
                                          callback: function($$v) {
                                            _vm.$set(_vm.user, "plan_id", $$v)
                                          },
                                          expression: "user.plan_id"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "mt-4" }, [
                                        _vm._v(
                                          "\n                プランの詳細については"
                                        ),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "https://talklabel.com",
                                              target: "_blank"
                                            }
                                          },
                                          [_vm._v("こちら")]
                                        ),
                                        _vm._v("をご覧下さい。\n              ")
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                [
                                  _c("pt-payjp-form", {
                                    on: {
                                      "payjp:created": _vm.payjpTokenCreated,
                                      "payjp:failed": _vm.payjpTokenFailed
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-divider", { staticClass: "ma-4" }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            type: "submit",
                            color: "primary",
                            loading: _vm.$wait.is("processing")
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("btn.payment")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }