var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "8" } }, [_c("PtTitle")], 1),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pt-8", attrs: { cols: "4", align: "right" } },
            [
              _c(
                "v-btn",
                { staticClass: "mr-2", attrs: { to: { name: "StaffIndex" } } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("btn.staff_index")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.toNewForm } },
                [_vm._v("\n        " + _vm._s(_vm.$t("btn.new")) + "\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.items,
                  "disable-sort": true,
                  "items-per-page": -1,
                  "hide-default-footer": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.name",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.toEditForm(item)
                                }
                              }
                            },
                            [_vm._v(_vm._s(item.name))]
                          )
                        ]
                      }
                    },
                    {
                      key: "item.channel_role_choices",
                      fn: function(ref) {
                        var item = ref.item
                        return _vm._l(item.channel_role_choices, function(
                          channel_role_choice
                        ) {
                          return _c(
                            "v-chip",
                            { staticClass: "ma-1", attrs: { small: "" } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$$te(
                                      "channel_role_choice.role_choice_type",
                                      channel_role_choice
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        })
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }