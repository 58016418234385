var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "align-center" },
    [
      _c(
        "v-col",
        { attrs: { sm: "12" } },
        [
          _c("h5", [
            _c("span", { staticClass: "text-bold" }, [
              _vm._v(_vm._s(_vm.title))
            ])
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _vm.src ? _c("VImg", { attrs: { src: _vm.src } }) : _vm._e(),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.description))])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }