var render, staticRenderFns
import script from "./PtFriendReportChart.vue?vue&type=script&lang=js&"
export * from "./PtFriendReportChart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./PtFriendReportChart.vue?vue&type=custom&index=0&blockType=div"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/talk_label/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('73f477f7')) {
      api.createRecord('73f477f7', component.options)
    } else {
      api.reload('73f477f7', component.options)
    }
    
  }
}
component.options.__file = "app/javascript/packs/components/parts/unique/PtFriendReportChart.vue"
export default component.exports