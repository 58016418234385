var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [
        _vm._v("\n    " + _vm._s(_vm.$t("account.sp_link_title")) + "\n  ")
      ]),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _vm._v(
            "\n    " + _vm._s(_vm.$t("account.sp_link_detail")) + "\n    "
          ),
          _c("hr"),
          _vm._v(" "),
          !_vm.user.sp_user
            ? [
                _c("h5", [_vm._v(_vm._s(_vm.$t("line_sp_link.not_sync")))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.$t("line_sp_link.not_sync_label")))])
              ]
            : _vm.user.sp_user.verified
            ? [
                _c("h5", [_vm._v(_vm._s(_vm.$t("line_sp_link.sync")))]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("line_sp_link.name_sync", { name: _vm.user.name })
                    )
                  )
                ])
              ]
            : [
                _c("h5", [_vm._v(_vm._s(_vm.$t("line_sp_link.wait_sync")))]),
                _vm._v(" "),
                _c("ul", { staticClass: "pl-0" }, [
                  _c("ol", { staticClass: "pl-0" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("line_sp_link.wait_sync_detail_01")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("ol", { staticClass: "pl-0" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("line_sp_link.wait_sync_detail_02")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("ol", { staticClass: "pl-0" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("line_sp_link.wait_sync_detail_03")) +
                        "\n        "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { align: "center" } }, [
                      _c("div", [
                        _vm._v(_vm._s(_vm.$t("line_sp_link.link_code")))
                      ]),
                      _vm._v(" "),
                      _c("h4", [
                        _c("strong", [_vm._v(_vm._s(_vm.user.sp_user.code))])
                      ])
                    ])
                  ],
                  1
                )
              ]
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _vm._v(" "),
          _vm.user.sp_user
            ? _c(
                "v-btn",
                {
                  attrs: { color: "danger" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("destroy")
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t(_vm.destroyBtnLabel)) + "\n    "
                  )
                ]
              )
            : _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("save")
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("line_sp_link.issue_link_code")) +
                      "\n    "
                  )
                ]
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }