var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("VfAlert", {
        attrs: { type: "error" },
        model: {
          value: _vm.errorMsg,
          callback: function($$v) {
            _vm.errorMsg = $$v
          },
          expression: "errorMsg"
        }
      }),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { md: "6" } },
            [
              _c("VfTextField", {
                attrs: {
                  label: _vm.$t("lbl.answer_form.item_name"),
                  vees: _vm.vees.answer_form_input.title
                },
                on: { input: _vm.updateTitle },
                model: {
                  value: _vm.internalValue.title,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "title", $$v)
                  },
                  expression: "internalValue.title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { md: "6" } },
            [
              _c("VfCheckbox", {
                attrs: { label: _vm.$t("lbl.answer_form.hide_item_name") },
                model: {
                  value: _vm.internalValue.hide_title_flg,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "hide_title_flg", $$v)
                  },
                  expression: "internalValue.hide_title_flg"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-4", attrs: { md: "6" } },
            [
              _c("VfTextField", {
                attrs: {
                  label: _vm.$t("lbl.answer_form.description"),
                  vees: _vm.vees.answer_form_input.short_description,
                  hint: _vm.$t("lbl.answer_form.text_field_desc_hint")
                },
                model: {
                  value: _vm.internalValue.description,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "description", $$v)
                  },
                  expression: "internalValue.description"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pb-4", attrs: { md: "6" } },
            [
              _c("VfTextField", {
                attrs: {
                  label: _vm.$t("lbl.answer_form.placeholder"),
                  vees: _vm.vees.answer_form_input.placeholder,
                  placeholder: _vm.$t("lbl.answer_form.placeholder_hint")
                },
                model: {
                  value: _vm.internalValue.placeholder,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "placeholder", $$v)
                  },
                  expression: "internalValue.placeholder"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { md: "6" } },
            [
              _c("VfSelect", {
                attrs: {
                  label: _vm.$t("lbl.answer_form.initial_display"),
                  selects:
                    _vm.opts.answer_form_input.additional_option_types
                      .text_field
                },
                model: {
                  value: _vm.internalValue.additional_option_type,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "additional_option_type", $$v)
                  },
                  expression: "internalValue.additional_option_type"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { md: "6" } },
            [
              _vm.internalValue.additional_option_type === "prepopulate"
                ? _c("VfTextField", {
                    attrs: {
                      label: "",
                      vees: _vm.vees.answer_form_input.prepopulate_value,
                      hint:
                        _vm.internalValue.validation_type === "time"
                          ? _vm.$t("lbl.answer_form.initial_time_desc")
                          : ""
                    },
                    model: {
                      value: _vm.internalValue.prepopulate_value,
                      callback: function($$v) {
                        _vm.$set(_vm.internalValue, "prepopulate_value", $$v)
                      },
                      expression: "internalValue.prepopulate_value"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.internalValue.validation_type === "time" &&
                        _vm.internalValue.additional_option_type ===
                          "do_nothing",
                      expression:
                        "\n          internalValue.validation_type === 'time' &&\n          internalValue.additional_option_type === 'do_nothing'\n        "
                    }
                  ],
                  staticClass:
                    "v-messages__message v-messages theme--light pt-8"
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("lbl.answer_form.initial_time_desc")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pt-6 pb-4" },
        [
          _c(
            "v-card",
            [
              _c("v-card-subtitle", { staticClass: "or-card-title mb-0" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("lbl.answer_form.where_assign_to")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _vm._l(_vm.internalValue.assign_value_to, function(
                    target,
                    target_i
                  ) {
                    return _c(
                      "v-card",
                      { key: target_i, staticClass: "mb-4" },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "py-0" },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "d-flex align-items-center center",
                                    attrs: { md: "2" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "lbl.answer_form.assign_to." +
                                              target
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "d-flex align-items-center center",
                                    attrs: { md: "8" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "lbl.answer_form.assign_to." +
                                              target +
                                              "_hint"
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-right",
                                    attrs: { md: "2" }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { icon: "", disabled: false },
                                        on: {
                                          click: function($event) {
                                            return _vm.removeTarget(target_i)
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-close-circle")
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "d-flex justify-content-center" },
                        _vm._l(_vm.opts.answer_form_input.assign_to, function(
                          assign,
                          assign_i
                        ) {
                          return _c(
                            "v-btn",
                            {
                              key: assign_i,
                              staticClass: "ma-2",
                              attrs: { disabled: _vm.targets.includes(assign) },
                              on: {
                                click: function($event) {
                                  return _vm.addTarget(assign)
                                }
                              }
                            },
                            [_vm._v(_vm._s(assign_i))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "4" } },
            [
              _c("VfSelect", {
                attrs: {
                  label: "",
                  selects: _vm.opts.answer_form_input.validation_types
                },
                on: { change: _vm.updateValidationRule },
                model: {
                  value: _vm.internalValue.validation_type,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "validation_type", $$v)
                  },
                  expression: "internalValue.validation_type"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pb-0 offset-4", attrs: { md: "2" } },
            [
              _c("VfCheckbox", {
                attrs: { label: _vm.$t("lbl.answer_form.require") },
                model: {
                  value: _vm.internalValue.require_flg,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "require_flg", $$v)
                  },
                  expression: "internalValue.require_flg"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { md: "2" } },
            [
              _c("VfCheckbox", {
                attrs: { label: _vm.$t("lbl.answer_form.hide") },
                model: {
                  value: _vm.internalValue.hidden_flg,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "hidden_flg", $$v)
                  },
                  expression: "internalValue.hidden_flg"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }