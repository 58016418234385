var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("VfAlert", {
        attrs: { type: "error" },
        model: {
          value: _vm.errorMsg,
          callback: function($$v) {
            _vm.errorMsg = $$v
          },
          expression: "errorMsg"
        }
      }),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { md: "6" } },
            [
              _c("VfSelect", {
                attrs: {
                  label: _vm.$$tm("action_label.change_message_status"),
                  vees: _vm.vees.change_message_status,
                  selects: _vm.opts.change_message_status,
                  "label-hidden": ""
                },
                model: {
                  value: _vm.internalValue.change_message_status,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "change_message_status", $$v)
                  },
                  expression: "internalValue.change_message_status"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { md: "6" } },
            [
              _c("VfSelect", {
                attrs: {
                  label: _vm.$$tm("action_label.change_friend_status"),
                  vees: _vm.vees.change_friend_status,
                  selects: _vm.opts.change_friend_status,
                  "label-hidden": ""
                },
                model: {
                  value: _vm.internalValue.change_friend_status,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "change_friend_status", $$v)
                  },
                  expression: "internalValue.change_friend_status"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }