var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": 500 },
      model: {
        value: _vm.internalValue,
        callback: function($$v) {
          _vm.internalValue = $$v
        },
        expression: "internalValue"
      }
    },
    [
      _c(
        "v-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.save($event)
            }
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$$tm("scenario.name"),
                              required: ""
                            },
                            model: {
                              value: _vm.scenario_name,
                              callback: function($$v) {
                                _vm.scenario_name = $$v
                              },
                              expression: "scenario_name"
                            }
                          }),
                          _vm._v(" "),
                          _vm.error
                            ? _c("v-alert", { attrs: { type: "error" } }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.error) +
                                    "\n            "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("VSpacer"),
                  _vm._v(" "),
                  _c("v-btn", { on: { click: _vm.close } }, [
                    _vm._v(_vm._s(_vm.$t("btn.cancel")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        type: "submit",
                        color: "primary",
                        loading: _vm.$wait.is("processing")
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("btn.create")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }