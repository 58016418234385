var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PtTitle"),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "4" } },
            [
              _c("VfDatePicker", {
                key: "from",
                attrs: { label: "from" },
                model: {
                  value: _vm.stDt,
                  callback: function($$v) {
                    _vm.stDt = $$v
                  },
                  expression: "stDt"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { md: "4" } },
            [
              _c("VfDatePicker", {
                key: "to",
                attrs: { label: "to" },
                model: {
                  value: _vm.edDt,
                  callback: function($$v) {
                    _vm.edDt = $$v
                  },
                  expression: "edDt"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { md: "4" } },
            [
              _c(
                "v-btn",
                { on: { click: _vm.search } },
                [
                  _c("v-icon", [_vm._v("mdi-magnify")]),
                  _vm._v("\n        表示\n      ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        [_c("PtFriendReportChart", { attrs: { items: _vm.items } })],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.items,
              "disable-sort": true,
              "hide-default-footer": "",
              "items-per-page": -1
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.block_count",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "FriendIndex",
                              params: { blocked_at: item.report_dt }
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(item.block_count) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "item.report_dt",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$$fdt(item.report_dt)) +
                          "\n      "
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }