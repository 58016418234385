var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mb-6" },
    [
      _c("v-card-title", [
        _vm._v("\n    " + _vm._s(_vm.$t("account.receive_setting")) + "\n  ")
      ]),
      _vm._v(" "),
      _c("v-card-text", [
        _vm._v(
          "\n    " + _vm._s(_vm.$t("account.receive_setting_title")) + "\n    "
        ),
        _c("br"),
        _vm._v(
          "\n    " + _vm._s(_vm.$t("account.receive_setting_detail")) + "\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { color: "primary" },
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: "Notificator" })
                }
              }
            },
            [_vm._v("\n      設定\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }