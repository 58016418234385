var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [_c("v-col", { attrs: { cols: "12" } }, [_c("PtTitle")], 1)],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "m-2" },
            [
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Search",
                      "append-icon": "mdi-magnify",
                      clearable: "",
                      "single-line": "",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.items,
              "item-key": "id",
              "disable-sort": true
            },
            on: { "click:row": _vm.rowClick },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.channel.name",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v("\n        " + _vm._s(item.name) + "\n      ")
                    ]
                  }
                },
                {
                  key: "item.name",
                  fn: function(ref) {
                    var item = ref.item
                    return _vm._l(item.users, function(user) {
                      return _c("div", { key: user.id }, [
                        _c("div", [_vm._v(_vm._s(user.name))])
                      ])
                    })
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }