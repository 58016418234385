<template>
  <div>
    <div v-if="nonLogged" class="d-flex justify-content-center">
      <div class="redirect-content-wrapper"></div>
      <div class="redirect-content">
        <div class="line-header">
          <img class="line_icon" src="~images/line_icon.png" />
          <div class="line-title">LINEアプリを開いて 続行してください。</div>
        </div>
        <a class="open_app_btn" :href="openLineAppUrl">アプリで開く</a>
        <div class="description">
          <div class="p1">
            <v-icon>mdi-help-circle</v-icon>
            ボタンを押してもLINEアプリに遷移しない場合
          </div>
          <div class="p2">
            ボタンを長押しして「LINEで開く」を選択してください。「LINEで開く」が表示されない場合は、このURLをWebブラウザで開いてください。
          </div>
        </div>
        <div class="btn_wrapper">
          <a class="btn" :href="liffLoginUrl">ブラウザでログインする</a>
          <a class="btn" href="https://line.me/ja">LINEアプリをダウンロード</a>
        </div>
      </div>
    </div>
    <div v-if="qrScreen" class="d-flex justify-content-center">
      <div class="redirect-content-wrapper"></div>
      <div class="qr-screen-content">
        <div>スマートフォンでご覧ください</div>
        <img class="" :src="qrUrl" />
        <div>LINEアプリを起動して、</div>
        <div>QRコードを読み取ってください。</div>
      </div>
    </div>
  </div>
</template>

<script>
import liff from '@line/liff'

export default {
  data: () => ({
    state: '',
    inflow_liff_id: '',
    line_account_id: '',
    line_login_channel_id: '',
    qrUrl: '',
    nonLogged: false,
    qrScreen: false,
  }),
  created() {
    this.init()
  },
  computed: {
    liffLoginUrl() {
      const redirect_uri = `${window.location.origin}/inflow_routes/line_login_callback${window.location.search}`
      const query = new URLSearchParams({
        response_type: 'code',
        client_id: this.line_login_channel_id,
        state: this.state,
        scope: 'profile openid email',
        redirect_uri,
      })

      return `https://access.line.me/oauth2/v2.1/authorize?${query.toString()}`
    },
    openLineAppUrl() {
      const httpsUrl = this.$$getLiffLineMeUrl({
        ...this.$data,
        scenario: 'openapp',
      })
      const intentSchema =
        '#Intent;package=jp.naver.line.android;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;scheme=https;end'
      const intentUrl = `${httpsUrl.replace('https', 'intent')}${intentSchema}`
      return liff.getOS() == 'ios' ? httpsUrl : intentUrl
    },
  },
  methods: {
    async init() {
      await this.getInflowRouteParams()
      this.liffInit()
    },
    async getInflowRouteParams() {
      this.state = this.$route.query.state
      this.inflow_liff_id = this.$route.query.liffId
      this.line_account_id = this.$route.query.basicId
      this.line_login_channel_id = this.$route.query.clientId
      const liffState = this.$route.query['liff.state']
      if (liffState) {
        const query = new URLSearchParams(liffState)
        this.state = query.get('state')
        this.inflow_liff_id = query.get('liffId')
        this.line_account_id = query.get('basicId')
        this.line_login_channel_id = query.get('clientId')
      }
      if (
        this.state &&
        !(
          this.inflow_liff_id &&
          this.line_account_id &&
          this.line_login_channel_id
        )
      ) {
        const resp = await this.axios({
          url: `/inflow_routes/get_data_by_state_param`,
          params: { state: this.state },
        })
        if (!resp?.data?.line_account_id)
          window.location.href = `${window.location.origin}/not_found`
        const liffLineMeUrl = this.$$getLiffLineMeUrl({
          state: this.state,
          ...resp.data,
        })
        window.location.href = liffLineMeUrl
      }
    },
    async createInflowRouteFriend(profile) {
      try {
        await this.axios({
          method: 'POST',
          url: `/inflow_routes/create_inflow_route_friend`,
          data: { ...profile, state: this.state },
        })
        const basicIdText = this.line_account_id.replace('@', '')
        const url = `https://line.me/R/home/public/profile?id=${basicIdText}`
        window.location.href = url
      } catch (error) {
        console.log('[Inflow][createInflowRouteFriend]', error)
      }
    },
    async liffInit() {
      liff
        .init({ liffId: this.inflow_liff_id })
        .then(async () => {
          if (liff.isLoggedIn()) {
            const profile = await liff.getProfile()
            await this.createInflowRouteFriend(profile)
          } else {
            if (this.isMobile()) {
              this.nonLogged = true
            } else {
              const liffLineMeUrl = this.$$getLiffLineMeUrl({ ...this.$data })
              const url = `&url=${encodeURIComponent(liffLineMeUrl)}`
              this.qrUrl = `https://qr.tools.lineml.jp/?size=300${url}`
              this.qrScreen = true
            }
          }
        })
        .catch(() => {
          return
        })
    },
    isMobile() {
      return (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino|android|ipad|playbook|silk/i.test(
          navigator.userAgent || navigator.vendor || window.opera
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
          (navigator.userAgent || navigator.vendor || window.opera).substr(0, 4)
        )
      )
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>

<style lang="scss" scoped>
.redirect-content-wrapper {
  background-color: #fff;
  width: 100vw;
  position: absolute;
  height: 100vh;
  min-height: 815px;
  top: 0;
}

.qr-screen-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 375px;
  margin-top: 16px;
  z-index: 1;
  img {
    margin: 30px 0;
    width: 274px;
  }
}

.redirect-content {
  max-width: 375px;
  margin-top: 50px;
  padding: 0 25px;
  z-index: 1;
  .line-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto;
    .line_icon {
      width: 86px;
    }
    .line-title {
      font-size: 31px;
      margin-top: 20px;
    }
  }
  .open_app_btn {
    background-color: #53c668;
    padding: 24px 0;
    border-radius: 44px;
    font-size: 18px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
  }
  .description {
    margin-top: 32px;
    padding: 20px 0;
    border-top: 2px solid #dddddd;
    border-bottom: 2px solid #dddddd;
    .p1 {
      font-size: 14px;
    }
    .p2 {
      font-size: 12px;
      margin-top: 16px;
    }
  }
  .btn_wrapper {
    margin-top: 20px;
    .btn {
      display: flex;
      justify-content: center;
      border-radius: 6px;
      padding: 16px 0;
      color: #666666;
      width: 100%;
      border: 2px solid #dddddd;
      margin-bottom: 16px;
      text-decoration: none;
      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }
  }
}
</style>
