var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "mx-auto px-12 py-6", attrs: { "max-width": "800" } },
        [
          _c(
            "v-card-title",
            [
              _c("v-row", { attrs: { justify: "center" } }, [
                _c("div", [
                  _vm._v(_vm._s(_vm.$t("meta.title." + _vm.$route.name)))
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("VDivider"),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                {
                  staticClass: "text-center my-8",
                  attrs: { justify: "center" }
                },
                [
                  _c("div", { staticClass: "or-break" }, [
                    _vm._v(_vm._s(_vm.$t("lbl.click_redirect_desc")))
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c("v-btn", { on: { click: _vm.init } }, [
                    _vm._v(_vm._s(_vm.$t("btn.click_redirect")))
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }