var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    attrs: { name: _vm.label, rules: _vm.rules },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var errors = ref.errors
          return [
            _c("v-checkbox", {
              staticClass: "label-mb-0",
              attrs: {
                label: _vm.label,
                name: _vm.name,
                "true-value": _vm.trueValue,
                "false-value": _vm.falseValue,
                value: _vm.checkboxValue,
                "persistent-hint": _vm.persistentHint,
                "hide-details": _vm.hideDetails,
                color: _vm.color,
                "multiple:": _vm.multiple,
                disabled: _vm.disabled,
                hint: _vm.hint
              },
              on: {
                change: function($event) {
                  return _vm.$emit("change", $event)
                }
              },
              model: {
                value: _vm.internalValue,
                callback: function($$v) {
                  _vm.internalValue = $$v
                },
                expression: "internalValue"
              }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }