var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PtTitle"),
      _vm._v(" "),
      _c("channel-notification-index", {
        attrs: { "line-notices": _vm.lineNotices },
        on: {
          "click:row": _vm.toEdit,
          "click:testNotification": _vm.sendTestNotification
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }