var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PtTitle"),
      _vm._v("\n  " + _vm._s(_vm.$t("subtitle.friend")) + "\n  "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _vm.searchWrap
                ? _c("PtSearchSelectedList", {
                    staticClass: "mb-4",
                    on: {
                      "pt-search-selected-list-save":
                        _vm.ptSearchSelectedListSave,
                      "pt-search-selected-list-clear":
                        _vm.ptSearchSelectedListSave
                    },
                    model: {
                      value: _vm.searchWrap,
                      callback: function($$v) {
                        _vm.searchWrap = $$v
                      },
                      expression: "searchWrap"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "6", align: "right" } },
            [
              _c(
                "v-btn",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogCSV = true
                    }
                  }
                },
                [_vm._v("csvエクスポート")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  on: {
                    click: function($event) {
                      return _vm.$router.push({ name: "ImportIndex" })
                    }
                  }
                },
                [_vm._v("CSVインポート")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c("PtFriendTable", {
            attrs: {
              headers: _vm.headers,
              items: _vm.items,
              "total-count": _vm.totalCount,
              options: _vm.options,
              "checked-items": _vm.checkedItems
            },
            on: {
              "update:options": function($event) {
                _vm.options = $event
              },
              "update:checkedItems": function($event) {
                _vm.checkedItems = $event
              },
              "update:checked-items": function($event) {
                _vm.checkedItems = $event
              },
              "checked-friends": _vm.checkedFriends,
              "open-dialog-message-status": _vm.openDialogMessageStatus
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "mt-4" },
        [
          _c("PtFriendUpdate", {
            attrs: {
              friends: _vm.items,
              "checked-items": _vm.checkedItems,
              "search-wrap": _vm.searchWrap,
              "total-count": _vm.totalCount
            },
            on: { friend_update: _vm.friendUpdate }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasRole
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "400" },
              model: {
                value: _vm.dialogMessageStatus,
                callback: function($$v) {
                  _vm.dialogMessageStatus = $$v
                },
                expression: "dialogMessageStatus"
              }
            },
            [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pt-6" },
                    [
                      _c("VfSelect", {
                        attrs: {
                          label: _vm.$$tm("friend.message_status"),
                          selects: _vm.opts.message_status
                        },
                        model: {
                          value: _vm.selectedFriendClone.message_status,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.selectedFriendClone,
                              "message_status",
                              $$v
                            )
                          },
                          expression: "selectedFriendClone.message_status"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("VSpacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        { on: { click: _vm.closeDialogMessageStatus } },
                        [_vm._v(_vm._s(_vm.$t("btn.cancel")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.updateDialogMessageStatus }
                        },
                        [_vm._v(_vm._s(_vm.$t("btn.update")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": 800 },
          model: {
            value: _vm.dialogCSV,
            callback: function($$v) {
              _vm.dialogCSV = $$v
            },
            expression: "dialogCSV"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" CSVダウンロード ")]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        _vm._l(_vm.csvOptions, function(option, key) {
                          return _c(
                            "v-col",
                            {
                              key: key,
                              attrs: { cols: "12", sm: "4", md: "4" }
                            },
                            [
                              _c("VfCheckbox", {
                                attrs: {
                                  label: option.text,
                                  name: option.name,
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.csvOptions[key].value,
                                  callback: function($$v) {
                                    _vm.$set(_vm.csvOptions[key], "value", $$v)
                                  },
                                  expression: "csvOptions[key].value"
                                }
                              })
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("PtDialogSelect", {
                                attrs: {
                                  "select-type": "hashtag",
                                  vees: { rules: "required" }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item.action_group",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c("PtActionLabelMessages", {
                                            staticClass: "or-list",
                                            model: {
                                              value:
                                                item.action_group
                                                  .make_action_arr,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item.action_group,
                                                  "make_action_arr",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.action_group.make_action_arr"
                                            }
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.dialogValue,
                                  callback: function($$v) {
                                    _vm.dialogValue = $$v
                                  },
                                  expression: "dialogValue"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _vm.selectAllFlg
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.switchSelectAll }
                            },
                            [_vm._v("\n            全選択\n          ")]
                          )
                        : _c(
                            "v-btn",
                            {
                              attrs: { color: "grey lighten-2" },
                              on: { click: _vm.switchSelectAll }
                            },
                            [_vm._v("\n            全解除\n          ")]
                          ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.downloadCSV }
                        },
                        [_vm._v(" CSVを出力 ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.linkToGooleSpreedSheet }
                        },
                        [
                          _vm._v(
                            "\n            スプレッドシートを出力\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }