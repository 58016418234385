var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PtTitle"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-2" },
        [
          _c("div", [_vm._v(_vm._s(_vm.$t("subtitle.staff_create")))]),
          _vm._v(" "),
          _c(
            "router-link",
            {
              attrs: { to: { name: "ChannelRoleForm", params: { id: "new" } } }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("btn.channel_role_link")) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "validation-observer",
        { ref: "observer" },
        [
          _c(
            "v-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.save($event)
                }
              }
            },
            [
              _c(
                "v-card",
                { attrs: { "max-width": "800" } },
                [
                  _c("VfLoadingOverlayAbsolute", {
                    attrs: { loading: _vm.loading }
                  }),
                  _vm._v(" "),
                  _c("v-card-title", [
                    _vm._v(_vm._s(_vm.$t("lbl.staff.entry")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("vf-text-field", {
                                attrs: {
                                  label: _vm.$$tm("user.email"),
                                  vees: { rules: "required" }
                                },
                                model: {
                                  value: _vm.user.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.user, "email", $$v)
                                  },
                                  expression: "user.email"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("vf-text-field", {
                                attrs: {
                                  label: _vm.$$tm("user.name"),
                                  vees: { rules: "required" }
                                },
                                model: {
                                  value: _vm.user.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.user, "name", $$v)
                                  },
                                  expression: "user.name"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            [
                              _c("VfSelect", {
                                attrs: {
                                  label: _vm.$$tm(
                                    "user_channel.channel_role_id"
                                  ),
                                  selects: _vm.selectChannelRoles,
                                  vees: { rules: "required" }
                                },
                                model: {
                                  value: _vm.channelRoleId,
                                  callback: function($$v) {
                                    _vm.channelRoleId = $$v
                                  },
                                  expression: "channelRoleId"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-btn", { on: { click: _vm.toIndex } }, [
                        _vm._v(_vm._s(_vm.$t("btn.back")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        { attrs: { type: "submit", color: "primary" } },
                        [_vm._v(_vm._s(_vm.$t("btn.create")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }