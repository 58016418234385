var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    attrs: { name: _vm.label, rules: _vm.rules, vid: Math.random().toString() },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var errors = ref.errors
          return [
            _c(
              "VTextarea",
              _vm._b(
                {
                  attrs: {
                    rows: _vm.rows,
                    "error-messages": errors,
                    placeholder: _vm.placeholder,
                    "auto-grow": _vm.autoGrow,
                    outlined: ""
                  },
                  model: {
                    value: _vm.internalValue,
                    callback: function($$v) {
                      _vm.internalValue = $$v
                    },
                    expression: "internalValue"
                  }
                },
                "VTextarea",
                _vm.baseProps,
                false
              )
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }