var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "mx-auto py-4", attrs: { "max-width": "800" } },
        [
          _c(
            "v-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submitForm($event)
                }
              }
            },
            [
              _c(
                "v-card-title",
                [
                  _c("v-row", { attrs: { justify: "center" } }, [
                    _c("div", [
                      _vm._v(_vm._s(_vm.$t("meta.title." + _vm.$route.name)))
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "mx-4" }),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "text-h5 font-weight-bold" },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "8", cols: "12" } },
                        [
                          _c("vf-text-field", {
                            attrs: { label: "メールアドレス" },
                            model: {
                              value: _vm.item.email,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "email", $$v)
                              },
                              expression: "item.email"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { md: "8", cols: "12" } },
                        [
                          _c("vf-text-field", {
                            attrs: { label: "パスワード", password: "" },
                            model: {
                              value: _vm.item.password,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "password", $$v)
                              },
                              expression: "item.password"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { md: "8", cols: "12" } },
                        [
                          _c("vf-text-field", {
                            attrs: {
                              label: "パスワード（確認）",
                              password: ""
                            },
                            model: {
                              value: _vm.item.password_confirmation,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "password_confirmation", $$v)
                              },
                              expression: "item.password_confirmation"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { staticClass: "mt-8", attrs: { color: "grey lighten-4" } },
                    [
                      _c("v-card-title", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("lbl.terms_of_service_title")) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("VDivider", { staticClass: "my-0" }),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c("h5", { staticClass: "mt-2" }, [
                            _vm._v(
                              _vm._s(_vm.$t("lbl.terms_of_service_title1"))
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "or-break" }, [
                            _vm._v(_vm._s(_vm.$t("lbl.terms_of_service_desc1")))
                          ]),
                          _vm._v(" "),
                          _c("VDivider", { staticClass: "mb-0" }),
                          _vm._v(" "),
                          _c("h5", { staticClass: "mt-4" }, [
                            _vm._v(
                              _vm._s(_vm.$t("lbl.terms_of_service_title2"))
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "or-break" }, [
                            _vm._v(_vm._s(_vm.$t("lbl.terms_of_service_desc2")))
                          ]),
                          _vm._v(" "),
                          _c("VDivider", { staticClass: "mb-0" }),
                          _vm._v(" "),
                          _c("h5", { staticClass: "mt-4" }, [
                            _vm._v(
                              _vm._s(_vm.$t("lbl.terms_of_service_title3"))
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "or-break" }, [
                            _c(
                              "h5",
                              { staticClass: "mt-4" },
                              [
                                _c("VTextarea", {
                                  attrs: {
                                    value: _vm.terms_of_service_desc,
                                    outlined: "",
                                    readonly: "",
                                    height: "300"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("VDivider", { staticClass: "mb-0" }),
                          _vm._v(" "),
                          _c("VfCheckbox", {
                            attrs: { label: _vm.$t("lbl.terms_of_service") },
                            model: {
                              value: _vm.terms_of_service,
                              callback: function($$v) {
                                _vm.terms_of_service = $$v
                              },
                              expression: "terms_of_service"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        type: "submit",
                        color: "primary",
                        disabled: !_vm.terms_of_service
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("btn.create")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }