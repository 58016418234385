var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PtTitle"),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mt-3 mb-3" },
        [
          _c(
            "v-col",
            { attrs: { md: "6" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.answerForm &&
                            _vm.answerForm.hasOwnProperty("name")
                            ? _vm.answerForm.name
                            : null
                        )
                      }
                    })
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { md: "2" } },
            [
              _c("v-btn", { on: { click: _vm.preview } }, [
                _vm._v(_vm._s(_vm.$t("btn.preview")))
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-tabs",
            {
              attrs: { grow: "" },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            _vm._l(_vm.items, function(item) {
              return _c(
                "v-tab",
                { key: item.value, on: { click: _vm.reloadPage } },
                [_vm._v(_vm._s(item.text))]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "v-tabs-items",
            {
              staticClass: "px-4 pb-4",
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-3" },
                    [
                      _c("v-col", { attrs: { md: "6" } }, [
                        _c("table", { staticClass: "table table-bordered" }, [
                          _c("tbody", [
                            _c("tr", [
                              _c("td", { staticClass: "font-weight-bold" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.$t("lbl.answer_form.number_answer")
                                    ) +
                                    "\n                  "
                                )
                              ]),
                              _vm._v(" "),
                              _vm.answerFormClone &&
                              _vm.answerFormClone.hasOwnProperty("created_at")
                                ? _c("td", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm
                                            .moment(
                                              _vm.answerFormClone.created_at
                                            )
                                            .format("YYYY/MM/DD HH:mm")
                                        ) +
                                        "\n                  "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("v-col", { attrs: { md: "6" } }, [
                        _c("table", { staticClass: "table table-bordered" }, [
                          _c("tbody", [
                            _c("tr", [
                              _c("td", { staticClass: "font-weight-bold" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "lbl.answer_form.total_anonymous_answer"
                                      )
                                    ) +
                                    "\n                  "
                                )
                              ]),
                              _vm._v(" "),
                              _vm.answerFormClone &&
                              _vm.answerFormClone.hasOwnProperty(
                                "total_anonymous_answer"
                              )
                                ? _c("td", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.answerFormClone
                                            .total_anonymous_answer +
                                            _vm.$t("lbl.answer_form.user")
                                        ) +
                                        "\n                  "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ])
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("h4", [
                    _vm._v(
                      _vm._s(_vm.$t("lbl.answer_form.answer_number_counter"))
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mt-3 mb-3" },
                    [
                      _c("v-col", { attrs: { md: "3" } }, [
                        _c("p", { staticClass: "font-weight-bold" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t("lbl.answer_form.number_of_form_answers")
                              ) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("v-col", { attrs: { md: "6" } }, [
                        _c("table", { staticClass: "table table-bordered" }, [
                          _c("tbody", [
                            _c("tr", [
                              _c("td", { staticClass: "font-weight-bold" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "lbl.answer_form.number_of_answers"
                                      )
                                    ) +
                                    "\n                  "
                                )
                              ]),
                              _vm._v(" "),
                              _vm.answerFormClone &&
                              _vm.answerFormClone.hasOwnProperty(
                                "response_count"
                              )
                                ? _c("td", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.answerFormClone.response_count
                                        ) +
                                        "\n                  "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ])
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _vm.answerFormClone &&
                  _vm.answerFormClone.hasOwnProperty("selected_opt_count")
                    ? _c(
                        "div",
                        _vm._l(_vm.answerFormClone.selected_opt_count, function(
                          item
                        ) {
                          return _c(
                            "v-row",
                            { staticClass: "mt-3 mb-3" },
                            [
                              _c("v-col", { attrs: { md: "3" } }, [
                                _c("p", { staticClass: "font-weight-bold" }, [
                                  _vm._v(_vm._s(item.input_title))
                                ])
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { md: "8" } }, [
                                _c(
                                  "table",
                                  {
                                    staticClass:
                                      "table table-bordered table-striped"
                                  },
                                  [
                                    _c(
                                      "tbody",
                                      _vm._l(item, function(value) {
                                        return _c(
                                          "tr",
                                          [
                                            typeof value === "object" &&
                                            value !== null &&
                                            value.option_value
                                              ? [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "font-weight-bold",
                                                      attrs: { width: "35%" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            value.option_value
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    { attrs: { width: "65%" } },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            value.select_count +
                                                              " / " +
                                                              _vm.$t(
                                                                "lbl.answer_form.max"
                                                              ) +
                                                              " " +
                                                              (value.capacity
                                                                ? value.capacity
                                                                : "-")
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                [
                  _c(
                    "validation-observer",
                    { ref: "observer" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "v-card",
                                { staticClass: "mt-3" },
                                [
                                  _c("v-card-title", [
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t(
                                            "lbl.answer_form.answer_counter_correction_title"
                                          )
                                        )
                                      }
                                    })
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { md: "3" } }, [
                            _c("p", { staticClass: "font-weight-bold" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t(
                                      "lbl.answer_form.number_of_form_answers"
                                    )
                                  ) +
                                  "\n              "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("v-col", { attrs: { md: "8" } }, [
                            _c(
                              "table",
                              { staticClass: "table table-bordered" },
                              [
                                _c("tbody", [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "font-weight-bold",
                                        attrs: { width: "20%" }
                                      },
                                      [
                                        _c("p", { staticClass: "mt-5" }, [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "lbl.answer_form.number_of_answers"
                                                )
                                              ) +
                                              "\n                      "
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { attrs: { width: "60%" } },
                                      [
                                        _c("VfTextField", {
                                          attrs: {
                                            number: "",
                                            min: 0,
                                            vees: {
                                              rules: "required|min_value:0",
                                              required: true
                                            },
                                            label: _vm.$t(
                                              "lbl.answer_form.number_of_answers"
                                            )
                                          },
                                          model: {
                                            value:
                                              _vm.answerForm.response_count,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.answerForm,
                                                "response_count",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "answerForm.response_count"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("td", { attrs: { width: "20%" } }, [
                                      _c("p", { staticClass: "mt-5" }, [
                                        _vm._v("-")
                                      ])
                                    ])
                                  ])
                                ])
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.answerFormClone &&
                      _vm.answerFormClone.hasOwnProperty("selected_opt_count")
                        ? _c(
                            "div",
                            _vm._l(_vm.answerForm.selected_opt_count, function(
                              item
                            ) {
                              return _c(
                                "v-row",
                                { staticClass: "mt-3 mb-3" },
                                [
                                  _c("v-col", { attrs: { md: "3" } }, [
                                    _c(
                                      "p",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v(_vm._s(item.input_title))]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("v-col", { attrs: { md: "8" } }, [
                                    _c(
                                      "table",
                                      {
                                        staticClass:
                                          "table table-bordered table-striped"
                                      },
                                      [
                                        _c(
                                          "tbody",
                                          _vm._l(item, function(value) {
                                            return _c(
                                              "tr",
                                              [
                                                typeof value === "object" &&
                                                value !== null &&
                                                value.option_value
                                                  ? [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "font-weight-bold",
                                                          attrs: {
                                                            width: "20%"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "mt-5"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  value.option_value
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            width: "60%"
                                                          }
                                                        },
                                                        [
                                                          _c("VfTextField", {
                                                            attrs: {
                                                              number: "",
                                                              min: 0,
                                                              label:
                                                                value.option_value,
                                                              vees: {
                                                                rules:
                                                                  "required|min_value:0",
                                                                required: true
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                value.select_count,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  value,
                                                                  "select_count",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "value.select_count"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            width: "20%"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "mt-5"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "lbl.answer_form.max"
                                                                    ) +
                                                                      " " +
                                                                      (value.capacity
                                                                        ? value.capacity
                                                                        : "-")
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          }),
                                          0
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pl-2 offset-md-3",
                              attrs: { md: "8" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "warning" },
                                  on: { click: _vm.saveAnswerCounter }
                                },
                                [_vm._v(_vm._s(_vm.$t("btn.to_fix")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                [
                  _c("ShowTabFriendAnswer", {
                    attrs: { "answer-data": _vm.answerForm, colors: _vm.colors }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                [
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        { staticClass: "mt-3" },
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "4" } },
                            [
                              _c("VfDatePicker", {
                                attrs: {
                                  max: new Date().toISOString().slice(0, 10),
                                  label: ""
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.$emit(
                                      "input",
                                      _vm.search.dateForm
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.search.dateForm,
                                  callback: function($$v) {
                                    _vm.$set(_vm.search, "dateForm", $$v)
                                  },
                                  expression: "search.dateForm"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-col", { attrs: { md: "1" } }, [
                            _c("p", { staticClass: "mt-4 text-center" }, [
                              _vm._v("から")
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { md: "4" } },
                            [
                              _c("VfDatePicker", {
                                attrs: {
                                  max: new Date().toISOString().slice(0, 10),
                                  label: ""
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.$emit("input", _vm.search.dateTo)
                                  }
                                },
                                model: {
                                  value: _vm.search.dateTo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.search, "dateTo", $$v)
                                  },
                                  expression: "search.dateTo"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { md: "2" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mt-3",
                                  on: { click: _vm.onSearch }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "btn.show_answers_for_this_period"
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      (_vm.dataShow && !_vm.dataShow.length) || !_vm.dataShow
                        ? _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { md: "12" } }, [
                                _c("p", [_vm._v("表示する回答がありません")])
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.dataShow, function(item) {
                    return _c(
                      "div",
                      [
                        _c("hr"),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { md: "3" } }, [
                              item && item.hasOwnProperty("friend")
                                ? _c("div", [
                                    _c("p", [_vm._v("回答者")]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.$router.push({
                                                name: "FriendShow",
                                                params: { id: item.friend.id }
                                              })
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "orgn-icon",
                                            attrs: {
                                              src: _vm.$$noimageHelper(
                                                item.friend.api_picture_url
                                              )
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [_vm._v(_vm._s(item.friend.name))]
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "mt-2" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            "(回答日 " +
                                              _vm
                                                .moment(item.created_at)
                                                .format("YYYY/MM/DD HH:mm:ss") +
                                              ")"
                                          ) +
                                          "\n                "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          "推定回答時間：" +
                                            item.convert_answer_time
                                        )
                                      )
                                    ])
                                  ])
                                : _c(
                                    "div",
                                    [
                                      _c("p", [_c("b", [_vm._v("匿名回答")])]),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "warning" },
                                          on: {
                                            click: function($event) {
                                              return _vm.addFriend(
                                                item.id,
                                                _vm.answerForm.name
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("友だちの回答として登録する")]
                                      ),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "mt-2" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              "(回答日" +
                                                _vm
                                                  .moment(item.created_at)
                                                  .format(
                                                    "YYYY/MM/DD HH:mm:ss"
                                                  ) +
                                                ")"
                                            ) +
                                            "\n                "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            "推定回答時間 ：" +
                                              item.convert_answer_time
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                              _vm._v(" "),
                              item.from_type != "none"
                                ? _c("div", [
                                    _c("div", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$$te(
                                              "answer_form_response.from_type",
                                              item
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    item.referer_url
                                      ? _c(
                                          "div",
                                          { staticClass: "text-caption" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: item.referer_url,
                                                  target: "_blank"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(item.referer_url) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("v-col", { attrs: { md: "9" } }, [
                              _c("p", [_c("b", [_vm._v("フォームの回答")])]),
                              _vm._v(" "),
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "table table-bordered table-striped"
                                },
                                [
                                  _c(
                                    "tbody",
                                    [
                                      _vm._l(item.form_response, function(
                                        answer
                                      ) {
                                        return [
                                          answer.hasOwnProperty(
                                            "beautify_value"
                                          ) &&
                                          Array.isArray(
                                            answer.beautify_value
                                          ) &&
                                          answer.beautify_value.length > 0 &&
                                          answer.beautify_value[0]
                                            ? _c("tr", [
                                                _c(
                                                  "td",
                                                  { attrs: { width: "40%" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(answer.input_title)
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { attrs: { width: "60%" } },
                                                  _vm._l(
                                                    answer.beautify_value,
                                                    function(val) {
                                                      return _c("p", [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(val) +
                                                            "\n                        "
                                                        )
                                                      ])
                                                    }
                                                  ),
                                                  0
                                                )
                                              ])
                                            : _c("tr", [
                                                _c(
                                                  "td",
                                                  { attrs: { width: "40%" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(answer.input_title)
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { attrs: { width: "60%" } },
                                                  [
                                                    answer.value &&
                                                    !Array.isArray(answer.value)
                                                      ? [
                                                          typeof answer.value ==
                                                          "object"
                                                            ? [
                                                                answer.value.hasOwnProperty(
                                                                  "upload_file_type"
                                                                ) &&
                                                                answer.value
                                                                  .upload_file_type ==
                                                                  "image"
                                                                  ? _c("div", [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs: {
                                                                            src:
                                                                              answer
                                                                                .value
                                                                                .file
                                                                                .preview,
                                                                            alt:
                                                                              ""
                                                                          }
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c("p", [
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            attrs: {
                                                                              href:
                                                                                answer
                                                                                  .value
                                                                                  .file
                                                                                  .original
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.getNameImage(
                                                                                  answer
                                                                                    .value
                                                                                    .file
                                                                                    .original
                                                                                )
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ])
                                                                    ])
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                answer.value.hasOwnProperty(
                                                                  "upload_file_type"
                                                                ) &&
                                                                answer.value
                                                                  .upload_file_type ==
                                                                  "document"
                                                                  ? _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-file-document"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "p",
                                                                          [
                                                                            _c(
                                                                              "a",
                                                                              {
                                                                                attrs: {
                                                                                  href:
                                                                                    answer
                                                                                      .value
                                                                                      .file
                                                                                      .original
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.getNameImage(
                                                                                      answer
                                                                                        .value
                                                                                        .file
                                                                                        .original
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ]
                                                            : [
                                                                _vm._v(
                                                                  "\n                            " +
                                                                    _vm._s(
                                                                      answer.value
                                                                    ) +
                                                                    "\n                          "
                                                                )
                                                              ]
                                                        ]
                                                      : _vm._e()
                                                  ],
                                                  2
                                                )
                                              ])
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _vm.pagination.totalPage > 1
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "offset-4 justify-content-center",
                                  attrs: { md: "4" }
                                },
                                [
                                  _c("v-pagination", {
                                    attrs: {
                                      length: _vm.pagination.totalPage,
                                      "total-visible": 5
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.paginate($event)
                                      }
                                    },
                                    model: {
                                      value: _vm.pagination.page,
                                      callback: function($$v) {
                                        _vm.$set(_vm.pagination, "page", $$v)
                                      },
                                      expression: "pagination.page"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                [
                  _c("ShowTabExportCSV", {
                    attrs: { "answer-data": _vm.answerForm, colors: _vm.colors }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }