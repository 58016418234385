var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "import-csv-log" },
    [
      _c("PtTitle"),
      _vm._v(" "),
      _c("h3", { staticClass: "text-24" }, [
        _vm._v(_vm._s(_vm.$t("subtitle.import_log")))
      ]),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "12" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-text", [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex align-center justify-space-between"
                      },
                      [
                        _c("div", [
                          _c("p", { staticClass: "font-weight-bold" }, [
                            _vm._v(_vm._s(_vm.csvDetail.csv_name))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("lbl.import.log.import_at")) +
                                "：" +
                                _vm._s(_vm.importAt) +
                                "\n              "
                            )
                          ])
                        ])
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "12" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "text-14" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("lbl.import.log.attention")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-card-text", [
                    _c("div", {
                      staticClass: "import-csv-log__fail-info",
                      domProps: {
                        innerHTML: _vm._s(
                          (_vm.csvDetail.log_import || "").trim()
                        )
                      }
                    }),
                    _vm._v(" "),
                    _vm.isConfirm
                      ? _c("p", { staticClass: "mt-5 text-14" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("lbl.import.log.action_warning")) +
                              "\n          "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mt-10" },
                      [
                        _vm.isConfirm
                          ? _c(
                              "v-btn",
                              {
                                attrs: { color: "success" },
                                on: { click: _vm.importData }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("lbl.import.log.btn_confirm_action")
                                  )
                                )
                              ]
                            )
                          : _c(
                              "v-btn",
                              { attrs: { color: "success", disabled: "" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("lbl.import.log.btn_complete_action")
                                  )
                                )
                              ]
                            ),
                        _vm._v(" "),
                        _c("p", { staticClass: "mt-10" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({
                                    name: "FriendIndex"
                                  })
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("lbl.import.btn_back")))]
                          )
                        ])
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }