<template>
  <v-dialog v-model="internalValue" :max-width="maxWidth">
    <v-card>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <VfAlert v-model="errorMsg" type="error" />
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <div class="text-center or-break">{{ detail }}</div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="close">{{
          cancelText || $t('btn.cancel')
        }}</v-btn>
        <v-btn color="blue darken-1" text @click="save">{{
          okText || $t('btn.yes')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: { type: Boolean, required: true },
    formType: { type: String, required: true },
    title: { type: String, required: true },
    savePath: { type: String, required: true },
    data: { type: Object, required: false },
    detail: { type: String, required: true },
    okText: { type: String, required: false },
    cancelText: { type: String, required: false },
    maxWidth: { type: Number, default: 500 },
    targetEmit: { type: String, default: 'form-success' },
  },
  data: () => ({
    loading: false,
    errorMsg: '',
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    internalValue(val) {
      if (val) {
        this.init()
      }
    },
  },
  methods: {
    init() {
      this.errorMsg = ''
    },
    close() {
      this.internalValue = false
    },
    save() {
      this.loading = true
      const path = this.savePath
      const prms = this.data ?? this.$$reqPrms({})
      this.$$sendForm(this.formType, path, prms)
        .then(() => {
          this.close()
          this.$emit(this.targetEmit)
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
