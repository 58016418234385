var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "pa-0" },
        [
          _c(
            "v-list-item",
            [
              _c("v-list-item-content", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("account.password_edit")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-list-item-action",
                [_c("btn-dialog-password-form", { on: { save: _vm.save } })],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        [_c("v-text-field", { attrs: { label: "********", disabled: "" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }