var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "10" } }, [_c("PtTitle")], 1),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "2" } }, [
            _c(
              "div",
              { staticStyle: { "text-align": "right", "margin-top": "20px" } },
              [
                _vm.reminderId !== "new"
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "error",
                          disabled: _vm.frem_active_flg
                        },
                        on: { click: _vm.destroy }
                      },
                      [_vm._v(_vm._s(_vm.$t("btn.delete")))]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.initDone
        ? _c(
            "validation-observer",
            { ref: "observer" },
            [
              _c(
                "v-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.save($event)
                    }
                  }
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "mb-6" },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "or-card-title mb-6" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("lbl.reminder.info")) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { md: "6" } },
                                [
                                  _c("VfSelect", {
                                    attrs: {
                                      label: _vm.$$tm("folder.name"),
                                      vees: _vm.vees.folder_id,
                                      selects: _vm.folders
                                    },
                                    model: {
                                      value: _vm.reminder.folder_id,
                                      callback: function($$v) {
                                        _vm.$set(_vm.reminder, "folder_id", $$v)
                                      },
                                      expression: "reminder.folder_id"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { md: "6" } },
                                [
                                  _c("VfTextField", {
                                    attrs: {
                                      label: _vm.$$tm("reminder.name"),
                                      vees: _vm.vees.name
                                    },
                                    model: {
                                      value: _vm.reminder.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.reminder, "name", $$v)
                                      },
                                      expression: "reminder.name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c("VSpacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({
                                    name: "ReminderIndex"
                                  })
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("btn.back")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            { attrs: { type: "submit", color: "primary" } },
                            [_vm._v(_vm._s(_vm.$t("btn.create")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.reminderId !== "new" && _vm.initDone
        ? _c("PtReminderTimingIndex", {
            attrs: {
              "reminder-id": _vm.reminderId,
              "frem-active-flg": _vm.frem_active_flg
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("VfDialogConfirm", {
        attrs: {
          title: _vm.$t("confirm_title.delete"),
          detail: _vm.$t("confirm.delete"),
          "save-path": _vm.savePath,
          "form-type": "delete",
          "target-emit": "delete-success"
        },
        on: { "delete-success": _vm.deleteSuccess },
        model: {
          value: _vm.deleteDialog,
          callback: function($$v) {
            _vm.deleteDialog = $$v
          },
          expression: "deleteDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }