var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "transparent", attrs: { outlined: "" } },
        [
          _c(
            "v-card-title",
            {
              staticClass: "pa-0 or-card-title",
              staticStyle: { height: "54px" }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("lbl.message_status_count")) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c("VfAlert", {
            attrs: { type: "error" },
            model: {
              value: _vm.errorMsg,
              callback: function($$v) {
                _vm.errorMsg = $$v
              },
              expression: "errorMsg"
            }
          }),
          _vm._v(" "),
          _c("v-simple-table", {
            staticClass: "message_status_table",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("ステータス")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("人数")])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.messageStatus, function(key, ja) {
                        return _c("tr", { key: key }, [
                          _c(
                            "td",
                            { staticClass: "text-start" },
                            [
                              _c(
                                "v-chip",
                                {
                                  attrs: {
                                    dark: "",
                                    label: "",
                                    color: _vm.colors[key]
                                  }
                                },
                                [_vm._v(_vm._s(ja))]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "text-start" },
                            [
                              _vm.counts[key] != null
                                ? _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        dark: "",
                                        color: "default",
                                        "text-color": "white"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.counts[key]))]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      }),
                      0
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }