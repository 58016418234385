var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("VfLoadingOverlay"),
      _vm._v(" "),
      _vm.show
        ? _c("LaDrawer", {
            attrs: { "can-show": _vm.canShow },
            model: {
              value: _vm.drawer,
              callback: function($$v) {
                _vm.drawer = $$v
              },
              expression: "drawer"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.show
        ? _c("LaHeader", {
            attrs: { "can-show": _vm.canShow, "show-header": _vm.showHeader },
            model: {
              value: _vm.drawer,
              callback: function($$v) {
                _vm.drawer = $$v
              },
              expression: "drawer"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-main",
        [
          _c("VfLoadingOverlayAbsolute", {
            attrs: { loading: _vm.$store.getters.loadingOverlayContent }
          }),
          _vm._v(" "),
          _c(
            "v-container",
            {
              staticClass: "app-container",
              class: _vm.fullFixed ? "full-fixed" : "",
              attrs: { fluid: "" }
            },
            [_c("VfGlobalMessage"), _vm._v(" "), _c("RouterView")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }