var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "import-check" },
    [
      _c("PtTitle"),
      _vm._v(" "),
      _c("h3", { staticClass: "text-24" }, [
        _vm._v(_vm._s(_vm.$t("lbl.import.check.sub_header")))
      ]),
      _vm._v(" "),
      _vm.isFileValid
        ? _c("p", { staticClass: "mt-6" }, [
            _vm._v(_vm._s(_vm.$t("subtitle.import_check")))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "12" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _vm.isFileValid && !_vm.loading
                        ? [
                            _c("v-simple-table", {
                              staticClass: "tbl-data-confirm",
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function() {
                                      return [
                                        _c("thead", [
                                          _c(
                                            "tr",
                                            [
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "text-left item-success"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl.import.check.tbl.id"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "text-left item-warning"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl.import.check.tbl.display_name"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "text-left item-skip"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl.import.check.tbl.line_name"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "text-left item-skip"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl.import.check.tbl.phone"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "text-left item-skip"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl.import.check.tbl.email"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "text-left item-warning"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl.import.check.tbl.badge_mark"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "text-left item-skip"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl.import.check.tbl.status_notify"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "text-left item-skip"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl.import.check.tbl.follow_register_date"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "text-left item-warning"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl.import.check.tbl.display_status"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "text-left item-skip"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl.import.check.tbl.user_block"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "text-left item-skip"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl.import.check.tbl.last_message"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "text-left item-skip"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl.import.check.tbl.last_message_date"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "text-left item-skip"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl.import.check.tbl.scenario_subscribe"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "text-left item-skip"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl.import.check.tbl.scenario_date"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "text-left item-skip"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl.import.check.tbl.tag"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.tagsExtend.length
                                                ? _vm._l(
                                                    _vm.tagsExtend,
                                                    function(tagExt) {
                                                      return _c(
                                                        "th",
                                                        {
                                                          key: tagExt,
                                                          staticClass:
                                                            "text-left item-warning"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(tagExt) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  )
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "tbody",
                                          [
                                            _vm._l(
                                              _vm.csvDetail.csv_data,
                                              function(item, item_idx) {
                                                return [
                                                  _c(
                                                    "tr",
                                                    {
                                                      key: item_idx,
                                                      class: {
                                                        "item-error": !item.status
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "item-success"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.id)
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "item-warning"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                item.system_disp_name
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "item-skip"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item["LINE登録名"]
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "item-skip"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item["電話番号"]
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "item-skip"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item[
                                                                "メールアドレス"
                                                              ]
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "item-warning"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getMessageStatus(
                                                                  item.message_status
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "item-skip"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                item[
                                                                  "ステータスメッセージ"
                                                                ]
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "item-skip"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                item[
                                                                  "登録（フォロー）日時"
                                                                ]
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "item-warning"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.hidden_flg
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "item-skip"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                item[
                                                                  "ユーザーブロック"
                                                                ]
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "item-skip"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item[
                                                                "最終メッセージ"
                                                              ]
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "item-skip"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getLastMessageDate(
                                                                  item[
                                                                    "最終メッセージ日時"
                                                                  ]
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "item-skip"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item[
                                                                "購読中シナリオ"
                                                              ]
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "item-skip"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item[
                                                                "シナリオ日数"
                                                              ]
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "item-skip"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item["タグ一覧"]
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.tagsExtend.length
                                                        ? _vm._l(
                                                            _vm.tagsExtend,
                                                            function(tagExt) {
                                                              return _c(
                                                                "td",
                                                                {
                                                                  key: tagExt,
                                                                  staticClass:
                                                                    "item-warning"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        item[
                                                                          tagExt
                                                                        ]
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  )
                                                ]
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3720449820
                              )
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "tbl-note mt-3" }, [
                              _c("div", { staticClass: "tbl-note__item" }, [
                                _c("span", {
                                  staticClass:
                                    "tbl-note__block tbl-note__block--success"
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("lbl.import.check.note.id"))
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "tbl-note__item" }, [
                                _c("span", {
                                  staticClass:
                                    "tbl-note__block tbl-note__block--warning"
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "lbl.import.check.note.confirmed_column"
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "tbl-note__item" }, [
                                _c("span", {
                                  staticClass:
                                    "tbl-note__block tbl-note__block--error"
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "lbl.import.check.note.failed_column"
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "tbl-note__item" }, [
                                _c("span", {
                                  staticClass:
                                    "tbl-note__block tbl-note__block--skip"
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "lbl.import.check.note.skip_column"
                                      )
                                    )
                                  )
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-row",
                              {
                                staticClass:
                                  "mt-10 justify-space-between align-center"
                              },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { md: "3" } },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "mb-0 font-weight-bold" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t(
                                                "lbl.import.check.input_tag_cell"
                                              )
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("VfSelect", {
                                      staticClass: "reset-spacing",
                                      attrs: {
                                        label: "",
                                        selects: _vm.deleteTagOptions
                                      },
                                      model: {
                                        value: _vm.deleteTag,
                                        callback: function($$v) {
                                          _vm.deleteTag = $$v
                                        },
                                        expression: "deleteTag"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { md: "2" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { large: "", color: "primary" },
                                        on: { click: _vm.acceptImport }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t(
                                                "lbl.import.check.btn_confirm_alow_data"
                                              )
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isFileValid && !_vm.loading
                        ? [
                            _c("div", [
                              _c("p", [
                                _vm._v(
                                  "アップロードしたCSVファイルは適切ではありません。"
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", [_vm._v("ID列が存在していません")])
                            ])
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-10" }, [
                        _c("p", [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({
                                    name: "ImportIndex"
                                  })
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("lbl.import.check.btn_back_to_import")
                                )
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({
                                    name: "FriendIndex"
                                  })
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("lbl.import.check.btn_back_to_list")
                                )
                              )
                            ]
                          )
                        ])
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }