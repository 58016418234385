var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "w-fc transparent", attrs: { outlined: "" } },
        [
          _c(
            "v-card-title",
            { staticClass: "pa-0 or-card-title" },
            [
              _c(
                "v-list-item",
                { staticClass: "pl-0" },
                [
                  _c("v-list-item-content", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("lbl.current_friend_info")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", color: "success" },
                          on: {
                            click: function($event) {
                              return _vm.$router.push({ name: "FriendIndex" })
                            }
                          }
                        },
                        [_vm._v("\n            友だちリストを開く\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("VfLoadingOverlayAbsolute", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c("VfAlert", {
            attrs: { type: "error" },
            model: {
              value: _vm.errorMsg,
              callback: function($$v) {
                _vm.errorMsg = $$v
              },
              expression: "errorMsg"
            }
          }),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "pl-0 pt-0" },
            [
              _c("v-simple-table", [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "border-right" }, [
                      _c("div", { staticClass: "d-flex justify-center" }, [
                        _vm._v("有効友だち数")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _c("div", { staticClass: "d-flex justify-center" }, [
                        _vm._v("ブロック/友だち解除数")
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tbody", [
                  _c("tr", [
                    _c("td", { staticClass: "border-right" }, [
                      _c("div", { staticClass: "count_container" }, [
                        _c("h5", { staticClass: "current_valid_count" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.friendsCounts.validCount) +
                              "\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "all_current_valid_count" }, [
                          _vm._v(
                            "(友だち総数: " +
                              _vm._s(_vm.friendsCounts.totalCount) +
                              " 人)"
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("div", { staticClass: "count_container" }, [
                        _c("h5", { staticClass: "current_valid_count" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.friendsCounts.invalidCount) +
                              "\n                  "
                          ),
                          _c("span", { staticClass: "percent" }, [
                            _vm._v(
                              "(" + _vm._s(_vm.friendsCounts.percent) + "%)"
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "all_current_valid_count" }, [
                          _vm._v(
                            "\n                  (相手から: " +
                              _vm._s(_vm.friendsCounts.blockedCount) +
                              " 人 自分から:\n                  " +
                              _vm._s(_vm.friendsCounts.blockingCount) +
                              " 人 )\n                "
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }