var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return "id" in _vm.defDeli
    ? _c(
        "div",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-right", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "m-1",
                      attrs: { block: "" },
                      on: { click: _vm.templateCopy }
                    },
                    [_vm._v(_vm._s(_vm.$t("btn.template_copy")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.internalValue, function(deli, deliIndex) {
            return _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "v-card",
                      { attrs: { outlined: "" } },
                      [
                        _c("v-card-title", [_vm._v("preview")]),
                        _vm._v(" "),
                        _c(
                          "v-card-text",
                          [
                            _c("PtLinePreviewRow", {
                              attrs: { deli: _vm.internalValue[deliIndex] }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": 800 },
              model: {
                value: _vm.selectDialog,
                callback: function($$v) {
                  _vm.selectDialog = $$v
                },
                expression: "selectDialog"
              }
            },
            [
              _c("PtDeliSelectFixedPhrase", {
                ref: "ptselector",
                on: {
                  "decide-item": _vm.decideItem,
                  "cancel-item": _vm.cancelItem
                },
                model: {
                  value: _vm.selectFixedPhrase,
                  callback: function($$v) {
                    _vm.selectFixedPhrase = $$v
                  },
                  expression: "selectFixedPhrase"
                }
              })
            ],
            1
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }